import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetMarkets200Response, SideResponse } from "../../../codegen-api";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import usePersistentState from "../../../hooks/usePersistentState";
import { getContractPriceStep } from "../../../utils/instruments";
import { countDecimalPlaces } from "../../../utils/math";
import { Chevron } from "../../shared/Chevron/style";
import Dropdown from "../../shared/Dropdown";
import SegmentedControl from "../../shared/SegmentedControl";
import OrderbookSection from "./OrderbookSection";
import OrderbookTypeFilters from "./OrderbookTypeFilter";
import TradeHistorySection from "./TradeHistorySection";
import { OrderbookFilterEnum } from "./shared";
import {
  Container,
  DropdownItem,
  FiltersContainer,
  MediumFilterContainer,
  Section,
  Title,
  TradeTab,
  TradeTabWrapper,
} from "./style";

enum OrderbookTradeHistoryType {
  ORDERBOOK = "orderbook",
  TRADES = "trade_history",
}

interface ITradeOrderbookSectionProps {
  onOrderbookRowClick: (
    price: string,
    size: string,
    side: SideResponse
  ) => void;
  perpInstrument?: GetMarkets200Response;
}

function TradeOrderbookSection({
  onOrderbookRowClick,
  perpInstrument,
}: ITradeOrderbookSectionProps) {
  // MEDIUM SCREEN ONLY
  const [orderbookTradeHistoryType, setOrderbookTradeHistoryType] = useState(
    OrderbookTradeHistoryType.ORDERBOOK
  );

  const { orderbookViewInUSD, setOrderbookViewInUSD } = usePersistentState();
  const [orderbookFilter, setOrderbookFilter] = useState<OrderbookFilterEnum>();
  const { t } = useTranslation("app", {
    keyPrefix: "Perps.TradeOrderbookSection.TradeOrderbookSection",
  });
  const [orderbookTickSize, setOrderbookTickSize] = useState<number>();
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [tickSizeDropdownOpen, setTickSizeDropdownOpen] = useState(false);

  const availableTickSizes = useMemo(() => {
    if (perpInstrument) {
      const priceStep = Number(perpInstrument.price_step);
      const decimals = countDecimalPlaces(priceStep);

      return [
        undefined,
        Number((priceStep * 10).toFixed(decimals)),
        Number((priceStep * 100).toFixed(decimals)),
        Number((priceStep * 1000).toFixed(decimals)),
      ];
    }
    return [];
  }, [perpInstrument]);

  // Update default OB tick sizes
  useEffect(() => {
    if (availableTickSizes.length) {
      setOrderbookTickSize(availableTickSizes[0]);
    } else {
      setOrderbookTickSize(undefined);
    }
  }, [availableTickSizes]);

  const toggleFilterDropdown = useCallback(() => {
    setFilterDropdownOpen((open) => !open);
  }, []);

  const toggleTickSizeDropdown = useCallback(() => {
    setTickSizeDropdownOpen((open) => !open);
  }, []);

  const filterComponent = useMemo(
    () => (
      <FiltersContainer>
        <OrderbookTypeFilters
          selectedFilterType={orderbookFilter}
          onSelectFilterType={setOrderbookFilter}
        />
        <div>
          <Dropdown
            onToggle={toggleFilterDropdown}
            toggleStyles={{
              padding: 0,
              marginRight: SPACING.one,
            }}
            dropdownMenuContainerStyles={{
              backgroundColor: LAYER_COLORS.four,
            }}
            title={
              <DropdownItem selected>
                {orderbookViewInUSD ? "USD" : perpInstrument?.underlying_asset}
                <Chevron
                  size="small"
                  direction={filterDropdownOpen ? "up" : "down"}
                />
              </DropdownItem>
            }
            items={[
              {
                label: (
                  <DropdownItem selected={!orderbookViewInUSD}>
                    {perpInstrument?.underlying_asset}
                  </DropdownItem>
                ),
                onSelect: () => setOrderbookViewInUSD(false),
              },
              {
                label: (
                  <DropdownItem selected={orderbookViewInUSD}>USD</DropdownItem>
                ),
                onSelect: () => setOrderbookViewInUSD(true),
              },
            ]}
          />
          <Dropdown
            onToggle={toggleTickSizeDropdown}
            toggleStyles={{
              padding: 0,
              marginRight: SPACING.one,
            }}
            dropdownMenuContainerStyles={{
              backgroundColor: LAYER_COLORS.four,
            }}
            title={
              <DropdownItem selected>
                {orderbookTickSize || perpInstrument?.price_step}
                <Chevron
                  size="small"
                  direction={tickSizeDropdownOpen ? "up" : "down"}
                />
              </DropdownItem>
            }
            items={availableTickSizes.map((tick) => ({
              label: (
                <DropdownItem selected={tick === orderbookTickSize}>
                  {tick || perpInstrument?.price_step}
                </DropdownItem>
              ),
              onSelect: () => setOrderbookTickSize(tick),
            }))}
          />
        </div>
      </FiltersContainer>
    ),
    [
      toggleFilterDropdown,
      orderbookViewInUSD,
      perpInstrument?.underlying_asset,
      perpInstrument?.price_step,
      filterDropdownOpen,
      orderbookFilter,
      setOrderbookViewInUSD,
      orderbookTickSize,
      availableTickSizes,
      tickSizeDropdownOpen,
      toggleTickSizeDropdown,
    ]
  );

  const controls = useMemo(
    () =>
      Object.keys(OrderbookTradeHistoryType).map((k) => {
        const key = k as keyof typeof OrderbookTradeHistoryType;
        return {
          value: OrderbookTradeHistoryType[key],
          display: <TradeTab>{t(OrderbookTradeHistoryType[key])}</TradeTab>,
          textColor:
            orderbookTradeHistoryType === OrderbookTradeHistoryType[key]
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        };
      }),
    [orderbookTradeHistoryType, t]
  );

  return (
    <Container>
      <Section>
        <Title>
          <TradeTabWrapper>
            <SegmentedControl
              segments={controls}
              value={orderbookTradeHistoryType}
              onSelect={(e) =>
                setOrderbookTradeHistoryType(e as OrderbookTradeHistoryType)
              }
              config={{
                widthType: "fullWidth",
                backgroundColor: LAYER_COLORS.two,
                activeBackgroundColor: LAYER_COLORS.three,
                borderRadius: "10px",
                button: {
                  fontSize: FONT_SIZE.one,
                },
              }}
            />
          </TradeTabWrapper>
        </Title>
        {orderbookTradeHistoryType === OrderbookTradeHistoryType.ORDERBOOK ? (
          <>
            <MediumFilterContainer>{filterComponent}</MediumFilterContainer>
            <OrderbookSection
              sizeDecimals={
                getContractPriceStep(perpInstrument).amount_precision
              }
              priceDecimals={
                getContractPriceStep(perpInstrument).price_precision
              }
              instrumentName={perpInstrument?.instrument_name}
              onOrderbookRowClick={onOrderbookRowClick}
              filter={orderbookFilter}
              showValueInUSD={orderbookViewInUSD}
              orderbookTickSize={orderbookTickSize}
            />
          </>
        ) : (
          <TradeHistorySection
            instrumentName={perpInstrument?.instrument_name}
            sizeDecimals={getContractPriceStep(perpInstrument).amount_precision}
            priceDecimals={getContractPriceStep(perpInstrument).price_precision}
          />
        )}
      </Section>
    </Container>
  );
}

export default TradeOrderbookSection;

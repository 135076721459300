import { ButtonHTMLAttributes, useMemo } from "react";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { ReactComponent as Details } from "../../../assets/svg/details.svg";
import { ReactComponent as Edit } from "../../../assets/svg/edit.svg";
import { ReactComponent as Share } from "../../../assets/svg/share.svg";
import { COLORS } from "../../../constants/design/colors";
import { ButtonThemeEnum } from "../../Buttons/styles";
import { ButtonWithBackground } from "./style";

type IRowActionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  (
    | {
        variant: "close" | "edit" | "details" | "share";
        text?: undefined;
        svgColor?: string;
        disabled?: boolean;
        buttonTheme?: ButtonThemeEnum;
      }
    | {
        variant: "text";
        text: string;
        svgColor?: string;
        disabled?: boolean;
      }
  );

function RowActionButton({
  variant,
  svgColor = COLORS.blue.one,
  disabled = false,
  onClick,
  ...props
}: IRowActionButtonProps) {
  const icon = useMemo(() => {
    switch (variant) {
      case "close":
        return <Close />;
      case "edit":
        return <Edit />;
      case "details":
        return <Details />;
      case "share":
        return <Share />;
      default:
        return null;
    }
  }, [variant]);

  return (
    <ButtonWithBackground
      isText={variant === "text"}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
      {...props}
      fillOnly={variant === "details"}
      svgColor={svgColor}
      disabled={disabled}
    >
      {variant === "text" ? props.text : icon}
    </ButtonWithBackground>
  );
}

export default RowActionButton;

import styled from "styled-components";
import { TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const Container = styled.div`
  height: ${COMPONENTS.footer.desktop}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${SPACING.three}px;
`;

export const BlockContainer = styled.a`
  ${FONT_CSS.body.five}
  text-decoration: none;
  cursor: pointer;
  color: ${TEXT_COLORS.one};

  &:hover {
    color: ${TEXT_COLORS.one};
  }

  span:first-child {
    ${FONT_CSS.label.two}
    color: ${TEXT_COLORS.three};
  }
`;

export const LeftContainer = styled.div`
  display: flex;
`;

export const RightContainer = styled.div`
  display: flex;

  a {
    ${FONT_CSS.label.two}
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AxiosError } from "axios";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import {
  GenericErrorResponse,
  MarginTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import { FIELD_COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import { FormValidatorKeysEnum } from "../../../enums/form";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import { useMarginType } from "../../../hooks/api/margin/useMarginType";
import { useToast } from "../../../hooks/toast";
import { AssetResponse } from "../../../utils/asset";
import { getAssetLogo } from "../../../utils/asset/assets";
import { ToastEnum, ToastStatusEnum } from "../../../utils/toast";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { InputRow } from "../../ConfirmationModal/style";
import { TooltipContainer } from "../../PortfolioSettings/Collateral/style";
import { Chevron } from "../../shared/Chevron/style";
import Dropdown from "../../shared/Dropdown";
import { TooltipWrapper } from "../../shared/Header/AccountModal/style";
import { Input } from "../../shared/Input";
import RowActionButton from "../../shared/RowActionButton";
import TooltipExplanation from "../../shared/Tooltip";
import { IInputProps } from "../form";
import { InputWrapper, Label, WarningLabel } from "../style";
import { LeverageModal } from "./LeverageModal";
import { MarginModal } from "./MarginModal";
import {
  CoreRing,
  DropdownItem,
  DropdownTitle,
  InnerRing,
  InputButtonsWrapper,
  LabelFlexWrapper,
  LearnMoreText,
  MarginButton,
  MarginLabel,
  MarginSelectorWrapper,
  MarginTypeText,
  OuterRing,
  Slider,
  SliderBackground,
  SliderInputWrapper,
  SliderPattern,
  SliderPatternWrapper,
  SliderWrapper,
  StyledThumb,
  StyledTrack,
  X,
} from "./style";
import { IPerpsMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";

export enum PerpFormFieldKeyEnum {
  PRICE = "price",
  AMOUNT = "amount",
  LEVERAGE = "leverage",
  TRIGGER_PRICE = "triggerPrice",
  MARGIN_TYPE = "marginType",
}

export type IPerpsFormFieldValues = {
  [key in PerpFormFieldKeyEnum]: string;
};

export const usePerpsForm = () =>
  useForm<IPerpsFormFieldValues>({
    mode: "onChange",
  });

interface IPriceInputProps extends IInputProps {
  minPrice: number;
  placeholder?: string;
  isStopOrder?: boolean;
}

export function PriceInput({
  register,
  errors,
  warning,
  minPrice,
  placeholder,
  isStopOrder,
}: IPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: tooltips } = useTranslation("tooltips");
  const { t: formError } = useTranslation("formErrors", { keyPrefix: "price" });
  const error = errors?.[PerpFormFieldKeyEnum.PRICE];
  const hasError = Boolean(error?.type);
  return (
    <InputWrapper>
      <LabelFlexWrapper>
        <Label>{t("limit_price")}</Label>
        {isStopOrder && (
          <TooltipExplanation
            title={t("limit_price")}
            explanation={tooltips("stopLimitPrice")}
            renderContent={({ ref, ...triggerHandler }) => (
              <TooltipWrapper ref={ref} {...triggerHandler}>
                <Info />
              </TooltipWrapper>
            )}
          />
        )}
      </LabelFlexWrapper>
      <Input
        error={hasError}
        placeholder={placeholder ?? "0"}
        type="number"
        {...register}
      />
      {error?.type === FormValidatorKeysEnum.required && (
        <WarningLabel>{formError("required")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.moreThanZero && (
        <WarningLabel>{formError("moreThanZero")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>
          {formError("cannotBeLessThanSize", { size: minPrice })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.limitPriceAboveTriggerPrice && (
        <WarningLabel>{formError("priceAboveTriggerPrice")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.limitPriceBelowTriggerPrice && (
        <WarningLabel>{formError("priceBelowTriggerPrice")}</WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

interface ITriggerPriceInputProps extends IPriceInputProps {
  markPrice?: string;
}

interface IExtraTriggerPriceInputProps extends ITriggerPriceInputProps {
  markPrice?: string;
  leftAccessory?: JSX.Element;
  resetInput: () => void;
}

export function TriggerPriceInput({
  register,
  errors,
  warning,
  minPrice,
  placeholder,
  markPrice,
}: ITriggerPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: tooltips } = useTranslation("tooltips");
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "triggerPrice",
  });
  const error = errors?.[PerpFormFieldKeyEnum.TRIGGER_PRICE];
  const hasError = Boolean(error?.type);
  return (
    <InputWrapper>
      <LabelFlexWrapper>
        <Label>{t("trigger_price")}</Label>
        <TooltipExplanation
          title={t("trigger_price")}
          explanation={tooltips("stopTriggerPrice")}
          renderContent={({ ref, ...triggerHandler }) => (
            <TooltipWrapper ref={ref} {...triggerHandler}>
              <Info />
            </TooltipWrapper>
          )}
        />
      </LabelFlexWrapper>
      <Input
        error={hasError}
        placeholder={placeholder ?? "0"}
        type="number"
        {...register}
      />
      {error?.type === FormValidatorKeysEnum.required && (
        <WarningLabel>{formError("required")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.moreThanZero && (
        <WarningLabel>{formError("moreThanZero")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>
          {formError("cannotBeLessThanSize", { size: minPrice })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceAboveMark && (
        <WarningLabel>
          {formError("priceAboveMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceBelowMark && (
        <WarningLabel>
          {formError("priceBelowMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

export function TakeProfitInput({
  register,
  errors,
  placeholder,
  markPrice,
  resetInput,
  leftAccessory,
}: IExtraTriggerPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "triggerPrice",
  });
  const error = errors?.tpTriggerPrice;
  const roiError = errors?.tpRoi;
  const hasError = Boolean(error?.type || roiError?.type);
  return (
    <InputWrapper style={{ width: "100%" }}>
      <LabelFlexWrapper>
        <Label>{t("take_profit")}</Label>
      </LabelFlexWrapper>
      <InputRow>
        <Input
          error={hasError}
          placeholder={placeholder ?? "0"}
          type="number"
          leftAccessory={leftAccessory}
          {...register}
        />
        <RowActionButton
          variant={"close"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            resetInput();
          }}
          style={{ minWidth: 48, minHeight: 48 }}
        />
      </InputRow>
      {error?.type === FormValidatorKeysEnum.triggerPriceAboveMark && (
        <WarningLabel>
          {formError("priceAboveMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceBelowMark && (
        <WarningLabel>
          {formError("priceBelowMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>{formError("decimalsTooSmall")}</WarningLabel>
      )}
      {roiError?.type === FormValidatorKeysEnum.roiTooHigh && (
        <WarningLabel>{formError("roiTooHigh")}</WarningLabel>
      )}
    </InputWrapper>
  );
}

export function StopLossInput({
  register,
  errors,
  placeholder,
  markPrice,
  resetInput,
  leftAccessory,
}: IExtraTriggerPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "triggerPrice",
  });
  const error = errors?.slTriggerPrice;
  const roiError = errors?.slRoi;
  const hasError = Boolean(error?.type || roiError?.type);
  return (
    <InputWrapper style={{ width: "100%" }}>
      <LabelFlexWrapper>
        <Label>Stop Loss</Label>
      </LabelFlexWrapper>
      <InputRow>
        <Input
          error={hasError}
          placeholder={placeholder ?? "0"}
          type="number"
          leftAccessory={leftAccessory}
          {...register}
        />
        <RowActionButton
          variant={"close"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            resetInput();
          }}
          style={{ minWidth: 48, minHeight: 48 }}
        />
      </InputRow>
      {error?.type === FormValidatorKeysEnum.triggerPriceAboveMark && (
        <WarningLabel>
          {formError("priceAboveMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.triggerPriceBelowMark && (
        <WarningLabel>
          {formError("priceBelowMarkPrice", {
            markPrice: markPrice || t("mark_price"),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>{formError("decimalsTooSmall")}</WarningLabel>
      )}
      {roiError?.type === FormValidatorKeysEnum.roiTooLow && (
        <WarningLabel>{formError("roiTooLow")}</WarningLabel>
      )}
    </InputWrapper>
  );
}

interface IOrderSizeInputProps extends IInputProps {
  style?: CSSProperties;
  selectedAsset?: AssetResponse;
  onChangeAsset: (asset?: AssetResponse) => void;
  minAmountSize: number;
  underlyingAsset?: AssetResponse;
  existingPositionSize?: string;
  existingOrderInfo?: {
    newOrderSizeLimit: string;
    side: SideResponse;
    size: string;
  };
  placeholder?: string;
}

export function OrderSizeInput({
  register,
  errors,
  warning,
  style,
  customError,
  selectedAsset,
  onChangeAsset,
  minAmountSize,
  existingPositionSize,
  existingOrderInfo,
  underlyingAsset,
  placeholder,
}: IOrderSizeInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "orderSize",
  });

  const [show, setShow] = useState(false);
  const error = errors?.[PerpFormFieldKeyEnum.AMOUNT];
  const hasInputError = Boolean(error?.type);
  const hasError = hasInputError || Boolean(customError);
  const logo = selectedAsset
    ? getAssetLogo(selectedAsset)
    : getAssetLogo("USDC");

  return (
    <InputWrapper style={style}>
      <LabelFlexWrapper>
        <Label>{t("order_size")}</Label>
      </LabelFlexWrapper>
      <Input
        error={hasError}
        placeholder={placeholder || "0"}
        {...register}
        rightAccessory={
          <Dropdown
            onToggle={(open) => setShow(open)}
            toggleStyles={{
              borderRadius: 100,
              padding: `0 ${SPACING.one}px`,
              background: FIELD_COLORS.two,
            }}
            dropdownMenuContainerStyles={{
              overflow: "hidden",
              borderRadius: "8px",
            }}
            title={
              <DropdownTitle>
                {logo && (
                  <img
                    alt={selectedAsset}
                    src={logo}
                    style={{
                      marginRight: SPACING.one,
                      width: 24,
                      height: 24,
                    }}
                  />
                )}
                <Chevron direction={show ? "up" : "down"} />
              </DropdownTitle>
            }
            items={[
              {
                label: (
                  <DropdownItem isSelected={!selectedAsset}>
                    <img alt={"USDC"} src={getAssetLogo("USDC")} />
                    USDC
                  </DropdownItem>
                ),
                onSelect: () => onChangeAsset(undefined),
              },
              ...(underlyingAsset
                ? [
                    {
                      label: (
                        <DropdownItem isSelected={!!selectedAsset}>
                          <img
                            alt={underlyingAsset}
                            src={getAssetLogo(underlyingAsset)}
                          />
                          {underlyingAsset}
                        </DropdownItem>
                      ),
                      onSelect: () => onChangeAsset(underlyingAsset),
                    },
                  ]
                : []),
            ]}
          />
        }
      />
      {error?.type === FormValidatorKeysEnum.required && (
        <WarningLabel>{formError("required")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.notEnoughBalance && (
        <WarningLabel>{formError("insufficient_buying_power")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.reduceOnlyOrderSizeValid && (
        <WarningLabel>
          {formError("reduce_size_invalid", { size: existingPositionSize })}
        </WarningLabel>
      )}
      {error?.type ===
        FormValidatorKeysEnum.reduceOnlyOrderSizeValidWithExistingOrder && (
        <WarningLabel>
          {existingOrderInfo?.side === SideResponse.Buy
            ? formError("reduce_size_invalid_existing_order_long", {
                newOrderSize: existingOrderInfo?.newOrderSizeLimit,
                existingOrderSize: existingOrderInfo?.size,
                positionSize: existingPositionSize,
              })
            : formError("reduce_size_invalid_existing_order_short", {
                newOrderSize: existingOrderInfo?.newOrderSizeLimit,
                existingOrderSize: existingOrderInfo?.size,
                positionSize: existingPositionSize,
              })}
        </WarningLabel>
      )}
      {Boolean(
        error?.type === FormValidatorKeysEnum.moreThanZero ||
          error?.type === FormValidatorKeysEnum.decimalsTooSmall
      ) && (
        <WarningLabel>
          {formError("min_order_size", { size: minAmountSize })}
        </WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
      {Boolean(!hasInputError && customError) && (
        <WarningLabel>{customError}</WarningLabel>
      )}
    </InputWrapper>
  );
}

interface ILeverageInputProps extends Omit<IInputProps, "register"> {
  leverageColor: string;
  leverage?: number;
  onChangeLeverage?: (leverage: number) => void;
  maxLeverage: number;
}

export function LeverageSelectInput({
  errors,
  warning,
  leverageColor,
  leverage,
  onChangeLeverage,
  maxLeverage = 20,
}: ILeverageInputProps) {
  // Slider pattern is dependent on the maxLeverage
  const sliderPatterns = [0, 1, 2, 3, 4, 5];

  useEffect(() => {
    if (leverage && leverage > maxLeverage) {
      onChangeLeverage?.(maxLeverage);
    }
  }, [leverage, maxLeverage, onChangeLeverage]);

  return (
    <InputWrapper>
      <InputButtonsWrapper>
        <SliderInputWrapper showSlider>
          <SliderWrapper>
            <Slider
              renderTrack={(props, { index }) => (
                <StyledTrack
                  {...(props as any)}
                  index={index}
                  color={leverageColor}
                />
              )}
              renderThumb={(props) => (
                <StyledThumb
                  {...(props as any)}
                  color={leverageColor}
                  width={`calc(100% / ${sliderPatterns.length})`}
                >
                  <div />
                </StyledThumb>
              )}
              value={Number(leverage) * 100 || 0}
              min={0}
              max={maxLeverage * 100}
              step={1}
              onChange={(value) => {
                if (typeof value === "number") {
                  onChangeLeverage?.(value / 100);
                }
              }}
            />
            <SliderBackground>
              {sliderPatterns.map((v) => {
                const currentValue = (maxLeverage / sliderPatterns.length) * v;
                return (
                  <SliderPatternWrapper
                    key={String(v)}
                    width={`calc(100% / ${sliderPatterns.length - 1})`}
                  >
                    <SliderPattern
                      activeColor={leverageColor}
                      active={currentValue <= (Number(leverage) * 100 || 0)}
                    />
                  </SliderPatternWrapper>
                );
              })}
            </SliderBackground>
          </SliderWrapper>
          <X
            style={{
              paddingRight: 0,
              margin: "auto",
              width: 50,
              textAlign: "center",
            }}
          >
            {Number(leverage) > 0
              ? ((Number(leverage) / maxLeverage) * 100).toFixed(0)
              : 0}
            %
          </X>
        </SliderInputWrapper>
      </InputButtonsWrapper>
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

interface IMarginSelectorProps {
  form: UseFormReturn<IPerpsFormFieldValues, any>;
  side: SideResponse;
  instrument?: IPerpsMarket;
}

interface IMarginIconProps {
  marginType: MarginTypeResponse;
  side: SideResponse;
  isSelected: boolean;
}
export function MarginIcon({
  marginType = MarginTypeResponse.Isolated,
  side,
  isSelected,
}: IMarginIconProps) {
  return (
    <OuterRing isSelected={isSelected} side={side}>
      <InnerRing isIsolated={marginType === MarginTypeResponse.Isolated}>
        <CoreRing side={side} />
      </InnerRing>
    </OuterRing>
  );
}
export function MarginSelector({
  form,
  side,
  instrument,
}: IMarginSelectorProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });
  const [showLeverageModal, setShowLeverageModal] = useState(false);
  const [showMarginModal, setShowMarginModal] = useState(false);
  const [learnMoreMarginType, setLearnMoreMarginType] =
    useState<MarginTypeResponse>();
  const marginType = form.watch(PerpFormFieldKeyEnum.MARGIN_TYPE);
  const { data: accountData, mutate: mutateAccount } = useGetAccount();
  const { mutateMarkets } = useContext(MarketInstrumentContext);
  const { updateMarginType } = useMarginType();
  const [disabledMarginType, setDisabledMarginType] = useState<
    MarginTypeResponse | undefined
  >();
  const [disabledMarginWarning, setDisabledMarginWarning] = useState<
    string | undefined
  >();
  const { addToast, addErrorToast } = useToast();
  const accountLeverage = useMemo(
    () =>
      accountData?.leverages?.find(
        (lev) => lev.instrument_id === instrument?.instrument_id
      )?.leverage,
    [accountData?.leverages, instrument?.instrument_id]
  );

  useEffect(() => {
    const existingPosition = accountData?.positions?.find(
      (pos) => pos.instrument_id === instrument?.instrument_id
    );

    // If there is an existing position, set the margin type to the existing position's margin type
    if (existingPosition?.margin_type) {
      form.setValue(
        PerpFormFieldKeyEnum.MARGIN_TYPE,
        existingPosition.margin_type
      );

      // Disable the other option if there is an existing position
      if (existingPosition.margin_type === MarginTypeResponse.Isolated) {
        setDisabledMarginType(MarginTypeResponse.Cross);
      } else {
        setDisabledMarginType(MarginTypeResponse.Isolated);
      }
    } else {
      // Remove disabled margin type if there is no existing position
      setDisabledMarginType(undefined);

      // Otherwise, set it to account.leverage margin type
      const accountMarginType = accountData?.leverages?.find(
        (lev) => lev.instrument_id === instrument?.instrument_id
      )?.margin_type;

      if (accountMarginType) {
        form.setValue(PerpFormFieldKeyEnum.MARGIN_TYPE, accountMarginType);
      }
    }
  }, [accountData, accountData?.positions, form, instrument?.instrument_id]);

  const onUpdateMarginType = useCallback(
    async (type: MarginTypeResponse) => {
      try {
        if (marginType === type || !accountData) {
          return;
        }

        if (instrument?.instrument_id) {
          if (type === disabledMarginType) {
            setDisabledMarginWarning(
              t("margin_type_disabled", {
                marginType: t(type.toLowerCase()),
                asset: instrument.underlying_asset,
              })
            );
          } else {
            setDisabledMarginWarning(undefined);
            await updateMarginType(Number(instrument?.instrument_id), type);
            form.setValue(PerpFormFieldKeyEnum.MARGIN_TYPE, type);
            mutateAccount();
            addToast(
              {
                header: t("updated_margin_type_success"),
                status: ToastStatusEnum.SUCCESS,
                type: ToastEnum.SIMPLE,
              },
              3000
            );
          }
        }
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        addErrorToast(
          "Error updating margin type",
          genericResponseAxiosError.response?.data?.error || ""
        );
      }
    },
    [
      accountData,
      addErrorToast,
      addToast,
      disabledMarginType,
      form,
      instrument?.instrument_id,
      instrument?.underlying_asset,
      marginType,
      mutateAccount,
      t,
      updateMarginType,
    ]
  );

  const onUpdateLeverageSuccess = useCallback(() => {
    mutateAccount();
    mutateMarkets?.();
  }, [mutateAccount, mutateMarkets]);

  const onLearnMore = useCallback((type: MarginTypeResponse) => {
    setShowMarginModal(true);
    setLearnMoreMarginType(type);
    setDisabledMarginWarning(undefined);
  }, []);

  const onHideMarginModal = useCallback(() => {
    setShowMarginModal(false);
    setLearnMoreMarginType(undefined);
    setDisabledMarginWarning(undefined);
  }, []);

  return (
    <>
      <LeverageModal
        show={showLeverageModal}
        onHide={() => setShowLeverageModal(false)}
        accountData={accountData}
        instrument={instrument}
        onUpdateLeverageSuccess={onUpdateLeverageSuccess}
      />
      <MarginModal
        show={showMarginModal}
        onHide={onHideMarginModal}
        marginType={learnMoreMarginType as MarginTypeResponse}
      />
      <InputWrapper style={{ marginBottom: 0 }}>
        <MarginLabel>{t("margin_and_leverage_settings")}</MarginLabel>
        <MarginSelectorWrapper>
          <MarginButton
            side={side}
            isSelected={marginType === MarginTypeResponse.Cross}
            onClick={() => onUpdateMarginType(MarginTypeResponse.Cross)}
            buttonTheme={ButtonThemeEnum.NEUTRAL2}
          >
            <MarginIcon
              isSelected={marginType === MarginTypeResponse.Cross}
              side={side}
              marginType={MarginTypeResponse.Cross}
            />
            <MarginTypeText>
              <span>{t("cross")}</span>
              <LearnMoreText
                onClick={() => onLearnMore(MarginTypeResponse.Cross)}
              >
                {t("learn_more")}
              </LearnMoreText>
            </MarginTypeText>
          </MarginButton>
          <TooltipExplanation
            title={t("isolated_margin_disabled")}
            explanation={
              <TooltipContainer>
                {t("isolated_margin_disabled_desc")}
              </TooltipContainer>
            }
            renderContent={({ ref, ...triggerHandler }) => (
              <TooltipWrapper
                ref={ref}
                {...triggerHandler}
                style={{ padding: 0 }}
              >
                <MarginButton
                  side={side}
                  isSelected={marginType === MarginTypeResponse.Isolated}
                  onClick={() =>
                    onUpdateMarginType(MarginTypeResponse.Isolated)
                  }
                  buttonTheme={ButtonThemeEnum.NEUTRAL2}
                  disabled
                  style={{ width: "100%" }}
                >
                  <MarginIcon
                    isSelected={marginType === MarginTypeResponse.Isolated}
                    side={side}
                    marginType={MarginTypeResponse.Isolated}
                  />
                  <MarginTypeText>
                    <span>{t("isolated")}</span>
                    <LearnMoreText
                      onClick={() => onLearnMore(MarginTypeResponse.Isolated)}
                    >
                      {t("learn_more")}
                    </LearnMoreText>
                  </MarginTypeText>
                </MarginButton>
              </TooltipWrapper>
            )}
          />
          <Button
            buttonTheme={ButtonThemeEnum.NEUTRAL2}
            onClick={() => setShowLeverageModal(true)}
          >
            {accountLeverage
              ? `${accountLeverage}x`
              : instrument?.max_leverage
              ? `${instrument?.max_leverage}x`
              : "-"}
            {accountData ? <Chevron size="small" direction={"down"} /> : null}
          </Button>
        </MarginSelectorWrapper>
        {disabledMarginWarning ? (
          <WarningLabel>{disabledMarginWarning}</WarningLabel>
        ) : null}
      </InputWrapper>
    </>
  );
}

import { useTranslation } from "react-i18next";
import { ExtraFarmBoostWrapper, LogoContainer, TextWrapper } from "./style";
import { ReactComponent as TradingIcon } from "../../assets/svg/bar-chart.svg";
import { TEXT_COLORS } from "../../constants/design/colors";
import ExternalLinkText from "../ExternalLinkText";

export interface IExtraFarmBoostDetailsProps {
  staked: boolean;
}

export function ExtraFarmBoostDetails({ staked }: IExtraFarmBoostDetailsProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });

  return (
    <ExtraFarmBoostWrapper>
      <LogoContainer>
        <TradingIcon />
      </LogoContainer>
      <TextWrapper>
        <span>
          {t("farm_boost_desc_1")}
          <span style={{ color: TEXT_COLORS.one }}>
            {staked ? "27%" : "13.5%"}
          </span>{" "}
          {t("farm_boost_desc_2")}
          <span style={{ color: TEXT_COLORS.one }}>
            {t("farm_boost_desc_3")}
          </span>
          {t("farm_boost_desc_4")}{" "}
          <ExternalLinkText
            href={
              "https://docs.aevo.xyz/trading-and-staking-incentives/earning-usdaevo/trade-on-aevo"
            }
            target="_blank"
            rel="noreferrer"
            color={TEXT_COLORS.two}
            wrapperStyle={{
              fontSize: 12,
              display: "inline-block",
            }}
          >
            {t("learn_more")}
          </ExternalLinkText>
        </span>
      </TextWrapper>
    </ExtraFarmBoostWrapper>
  );
}

import { AxiosError } from "axios";
import useSWR from "swr";
import { GetMarketsSummary200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import { pollingInterval } from "../../../services/api/pollingInterval";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { AssetResponse } from "../../../utils/asset";

// Custom sort sequence
const sortOrder: AssetResponse[] = [];

export const useGetMarketSummary = () => {
  const fetcher = publicApi();

  return useSWR<GetMarketsSummary200Response, AxiosError>(
    [APIEndpointEnum.MARKET_SUMMARY],
    async () => {
      const { data } = await (await fetcher.getMarketsSummary())();
      data.summaries?.sort((a, b) => {
        const aIndex = sortOrder.indexOf(a.asset);
        const bIndex = sortOrder.indexOf(b.asset);
        // Check if both elements exist in the fixed sequence array
        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex;
        }

        // Check if only one of the elements exists in the fixed sequence array
        if (aIndex !== -1) {
          return -1;
        }
        if (bIndex !== -1) {
          return 1;
        }

        // Sort by daily volume next
        const dVolA = Number(a.perpetual_info?.daily_volume || 0);
        const dVolB = Number(b.perpetual_info?.daily_volume || 0);

        return dVolB - dVolA;
      });
      return data;
    },
    {
      use: [useLoggerMiddleware],
      refreshInterval: pollingInterval[APIEndpointEnum.MARKET_SUMMARY],
      revalidateOnFocus: false,
    }
  );
};

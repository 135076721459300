import styled, { css } from "styled-components";
import {
  COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";
import { flashingCSS } from "../style";
import { AnnouncementType } from ".";

export const BannerWrapper = styled.div<{ type: AnnouncementType }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0 ${SPACING.twoHalf}px;
  min-height: ${COMPONENTS.banner}px;
  background: ${({ type }) =>
    type === "alert" ? COLORS.highlight.six : PRIMARY_COLORS.one};
  color: ${COLORS.blue.one};
`;

export const BannerText = styled.div<{ type: AnnouncementType }>`
  text-align: center;
  ${FONT_CSS.body.five}
  color: ${({ type }) =>
    type === "alert" ? COLORS.blue.one : TEXT_COLORS.one};
`;

export const Indicator = styled.div<{ type: AnnouncementType }>`
  width: 6px;
  height: 6px;
  border-radius: ${BORDER_RADIUS.two}px;
  background-color: ${({ type }) =>
    type === "alert" ? COLORS.blue.one : TEXT_COLORS.one};
  ${flashingCSS}
`;

export const BannerContent = styled.div<{ isMobile: boolean }>`
  max-height: ${COMPONENTS.banner}px;
  display: flex;
  align-items: center;
  > ${BannerText} {
    margin-right: ${SPACING.one}px;
  }
  > ${Indicator} {
    margin-right: ${SPACING.two}px;
  }
  ${FONT_CSS.body.five}
  > a {
    color: ${COLORS.blue.one};
  }
  ${({ isMobile }) => {
    if (isMobile) {
      return css`
        position: absolute;
        left: ${SPACING.three}px;
      `;
    }
    return "";
  }}
`;

export const AbsoluteCloseButton = styled.div`
  position: absolute;
  right: ${SPACING.three}px;
  display: flex;
  align-items: center;
`;

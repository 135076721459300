import { AxiosError } from "axios";
import useSWR from "swr";
import { useCallback, useContext } from "react";
import { GetFarmBoost200Response } from "../../../codegen-api";
import { authApi } from "../../../services/api/apiFetcher";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { AuthContext } from "../../../contexts/AuthContext";
import { unwrapApiError } from "../../../utils/errors";

export const useFarm = () => {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = authApi(apiKey, apiSecret);

  const swr = useSWR<GetFarmBoost200Response, AxiosError>(
    // If apiKey is not available, the request will not fetch
    apiKey ? [APIEndpointEnum.FARM_BOOST, apiKey] : undefined,
    async () => (await (await fetcher.getFarmBoost())()).data,
    {
      use: [useLoggerMiddleware],
      revalidateOnFocus: false,
    }
  );

  const setFarmBoost = useCallback(async () => {
    try {
      const res = (await (await fetcher.postFarmBoost())()).data;
      return res.farm_boost;
    } catch (error) {
      throw unwrapApiError(error);
    }
  }, [fetcher]);

  return {
    ...swr,
    setFarmBoost,
  };
};

import styled, { css } from "styled-components";
import {
  BORDER_COLORS,
  COLORS,
  ICON_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";

export const MainHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.two}px;
  height: ${COMPONENTS.header.main}px;
  z-index: 4;
`;

export const Divider = styled.div<{
  height?: string;
  horizontalMargin?: string;
  verticalMargin?: string;
}>`
  background-color: ${BORDER_COLORS.one};
  width: 1px;
  height: ${({ height }) => height ?? "100%"};

  ${({ horizontalMargin }) =>
    horizontalMargin
      ? css`
          margin-left: ${horizontalMargin};
          margin-right: ${horizontalMargin};
        `
      : css`
          margin-left: ${SPACING.twoHalf}px;
          margin-right: ${SPACING.twoHalf}px;
        `};

  ${({ verticalMargin }) =>
    verticalMargin
      ? css`
          margin-top: ${verticalMargin};
          margin-bottom: ${verticalMargin};
        `
      : null};
`;

export const LeftHeaderWrapper = styled.div`
  font-size: ${FONT_SIZE.two};
  height: fit-content;
  display: flex;
  align-items: center;
  margin: auto 0;

  a {
    text-decoration: none;
    cursor: pointer;
    color: ${TEXT_COLORS.two};
    margin-right: ${SPACING.three}px;
  }
`;

export const RightHeaderWrapper = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
  margin: auto 0;
`;

export const NotificationButton = styled.button`
  width: 32px;
  height: 32px;
  margin-right: ${SPACING.three}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightHeaderComponent = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkText = styled.span<{
  isActive?: boolean;
  isAevoLogo?: boolean;
  showBlueAevoLogo?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: ${SPACING.one}px;
  ${FONT_CSS.header.seven}
  transition: 0.2s ease-in-out;
  color: ${({ isActive, showBlueAevoLogo }) =>
    showBlueAevoLogo
      ? COLORS.blue.one
      : isActive
      ? TEXT_COLORS.one
      : TEXT_COLORS.three};
  svg {
    transition: stroke 0.2s ease-in-out;
    stroke: ${({ isActive, isAevoLogo }) =>
      isAevoLogo ? "none" : isActive ? TEXT_COLORS.one : TEXT_COLORS.three};
  }

  > svg > path {
    transition: fill 0.2s ease-in-out;
    fill: ${({ isActive, isAevoLogo, showBlueAevoLogo }) =>
      showBlueAevoLogo
        ? COLORS.blue.one
        : isAevoLogo
        ? isActive
          ? TEXT_COLORS.one
          : TEXT_COLORS.three
        : "none"};
  }
  
  &:hover {
    cursor: pointer;
    color: ${({ showBlueAevoLogo }) =>
      showBlueAevoLogo ? COLORS.blue.one : TEXT_COLORS.one};
    svg {
      stroke: ${({ isAevoLogo }) => (isAevoLogo ? "none" : TEXT_COLORS.one)};
    }
    > svg > path {
      ${({ isAevoLogo, showBlueAevoLogo }) =>
        isAevoLogo &&
        `fill: ${showBlueAevoLogo ? COLORS.blue.one : TEXT_COLORS.one};`}
  }
`;

export const MobileButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${LAYER_COLORS.one};
  height: 40px;
  width: 40px;
  border-radius: ${BORDER_RADIUS.five}px;
`;

export const MobileMenuButton = styled(MobileButton)`
  &:before {
    content: "";
    border-radius: 100px;
    background-color: ${ICON_COLORS.three};
    width: 18px;
    height: 1px;
    margin-bottom: ${SPACING.one}px;
  }
  &:after {
    content: "";
    border-radius: 100px;
    background-color: ${ICON_COLORS.three};
    width: 18px;
    height: 1px;
  }
`;

export const BellWrapper = styled.div<{ color?: string }>`
  position: relative;

  path {
    transition: 0.2s ease-in-out;
    stroke-opacity: 1;
    stroke: ${({ color }) => color ?? ICON_COLORS.three};
  }

  &:hover {
    path {
      stroke: ${({ color }) => color ?? COLORS.highlight.one};
    }
  }

  span {
    position: absolute;
    top: -6px;
    right: -12px;
    width: 16px;
    height: 16px;
  }
`;

export const CommunityRowLabel = styled.span`
  transition: all 0.2s ease-in-out;
  > svg {
    transition: all 0.2s ease-in-out;
    margin-left: ${SPACING.one}px;
    stroke: ${TEXT_COLORS.three};
  }
  padding-left: ${SPACING.two}px;
`;

export const DropdownWrapper = styled.div`
  a {
    margin: 0;
  }

  button {
    padding-left: 0 !important;
  }
`;

export const CommunityRow = styled.a.attrs({
  target: "_blank",
  rel: "noreferrer",
})<{ noBorder?: boolean; svgIsFill?: boolean }>`
  ${FONT_CSS.header.seven}
  color: ${TEXT_COLORS.three};
  padding: ${SPACING.two}px;
  height: 48px;
  width: 240px;

  display: flex;
  align-items: center;

  border-bottom: ${({ noBorder }) =>
    noBorder ? "none" : `1px solid ${BORDER_COLORS.two}`};

  > svg {
    width: 32px;
    height: 32px;
    background-color: ${LAYER_COLORS.four};
    border-radius: ${SPACING.two}px;
    padding: ${SPACING.two}px;
    transition: all 0.2s ease-in-out;
    ${({ svgIsFill }) =>
      svgIsFill
        ? css`
            fill: ${ICON_COLORS.two};
          `
        : css`
            stroke: ${ICON_COLORS.two};
          `}
  }

  &:hover {
    color: ${TEXT_COLORS.one};
    > svg {
      ${({ svgIsFill }) =>
        svgIsFill
          ? css`
              fill: ${ICON_COLORS.one};
            `
          : css`
              stroke: ${ICON_COLORS.one};
            `}
    }

    ${CommunityRowLabel} {
      svg {
        stroke: ${TEXT_COLORS.one};
      }
    }
  }
`;

export const AboutModalContainer = styled.div`
  ${FONT_CSS.body.three}
  width: 280px;
  color: ${TEXT_COLORS.two};

  a {
    color: ${TEXT_COLORS.one};
  }
`;

export const HeaderButton = styled.button.attrs({
  type: "button",
})`
  background: ${LAYER_COLORS.one};
  border-radius: 8px;
  margin-left: ${SPACING.two}px;
  height: ${COMPONENTS.header.buttons}px;
  width: ${COMPONENTS.header.buttons}px;
  display: grid;
  place-items: center;

  svg {
    width: 16px;
    height: 16px;
    stroke: ${ICON_COLORS.two};
  }
`;

export const StrategiesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${SPACING.one}px;
`;

export const AevoLogoWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  gap: ${SPACING.one}px;
`;

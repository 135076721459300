import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { KeyedMutator } from "swr";
import {
  GetMarkets200Response,
  InstrumentTypeResponse,
  OptionTypeResponse,
} from "../../codegen-api";
import { IOptionMarket, IPerpsMarket, useGetMarkets } from "./useGetMarkets";
import { MarketContext } from "../MarketContext";
import {
  IContractPriceStep,
  getContractPriceStep,
} from "../../utils/instruments";
import { AssetResponse } from "../../utils/asset";

export type IMarketPrecisionGetter = (
  asset?: AssetResponse,
  instrumentType?: InstrumentTypeResponse
) => IContractPriceStep;

interface IOptionsChainContext {
  selectedOptionInstrument: IOptionMarket | undefined;
  selectedPerpetualInstrument: IPerpsMarket | undefined;
  selectedValues: {
    orderType: OptionTypeResponse | undefined;
    strike: string | undefined;

    // This is in nanoseconds
    expiry: number | undefined;
  };
  mutateMarkets?: KeyedMutator<GetMarkets200Response[]>;
  activeOptionMarkets?: GetMarkets200Response[];
  activePerpMarkets?: GetMarkets200Response[];
  marketsData?: GetMarkets200Response[];
  orderTypes: OptionTypeResponse[];
  strikes: string[];
  expiries: number[];

  setStrike: React.Dispatch<React.SetStateAction<string | undefined>>;
  setExpiry: React.Dispatch<React.SetStateAction<number | undefined>>;
  setOrderType: React.Dispatch<
    React.SetStateAction<OptionTypeResponse | undefined>
  >;
  reset: () => void;
  getMarketPrecision: IMarketPrecisionGetter;
}

export const SELECTED_EXPIRY_OVERRIDE = 3;

// Stores all the available markets,
// and the filters available
export const MarketInstrumentContext =
  React.createContext<IOptionsChainContext>({
    selectedOptionInstrument: undefined,
    selectedPerpetualInstrument: undefined,
    selectedValues: {
      orderType: undefined,
      strike: undefined,
      expiry: undefined,
    },
    activeOptionMarkets: undefined,
    activePerpMarkets: undefined,
    marketsData: undefined,
    orderTypes: [],
    strikes: [],
    expiries: [],

    // OPTIONS
    setStrike: () => {},
    setExpiry: () => {},
    setOrderType: () => {},

    reset: () => {},
    getMarketPrecision: () => ({} as IContractPriceStep),
  });

interface IMarketInstrumentContextProviderProps extends PropsWithChildren {
  params?: {
    derivative?: InstrumentTypeResponse;
    asset?: AssetResponse;
    instrument?: string;
  };
  onAssetDerivativeChanged: (
    asset?: AssetResponse,
    instrumentType?: InstrumentTypeResponse,
    selectedInstrument?: string
  ) => void;
}

export function MarketInstrumentContextProvider({
  children,
  params,
  onAssetDerivativeChanged,
}: IMarketInstrumentContextProviderProps) {
  const [selectedStrike, setStrike] = useState<string>();
  const [selectedExpiry, setExpiry] = useState<number>();
  const [selectedOrderType, setOrderType] = useState<OptionTypeResponse>();

  const { market, setMarket } = useContext(MarketContext);

  const { activeMarkets: activeOptionMarkets } = useGetMarkets(
    undefined,
    InstrumentTypeResponse.Option
  );
  const { activeMarkets: activePerpMarkets } = useGetMarkets(
    undefined,
    InstrumentTypeResponse.Perpetual
  );

  const marketsData = useMemo(
    () =>
      market.derivative === InstrumentTypeResponse.Option
        ? activeOptionMarkets?.filter(
            (m) => m.underlying_asset === market.asset
          )
        : activePerpMarkets?.filter((m) => m.underlying_asset === market.asset),
    [activeOptionMarkets, activePerpMarkets, market]
  );

  const expiries = useMemo(() => {
    if (marketsData?.length) {
      // Expiry is in nano, so we strip off the last 9 digits
      const allExpiries = marketsData
        .map((v) => Number(v.expiry))
        .filter((v) => !!v);
      return Array.from(new Set(allExpiries));
    }
    return [];
  }, [marketsData]);

  const getMarketPrecision = useCallback(
    (
      asset?: AssetResponse,
      instrumentType: InstrumentTypeResponse = InstrumentTypeResponse.Perpetual
    ) => {
      const m =
        instrumentType === InstrumentTypeResponse.Option
          ? activeOptionMarkets?.find((v) => v.underlying_asset === asset)
          : activePerpMarkets?.find((v) => v.underlying_asset === asset);
      if (m) {
        return getContractPriceStep(m);
      }
      return {
        amount_step: 0.01,
        price_step: 0.01,
        amount_precision: 2,
        price_precision: 2,
      };
    },
    [activeOptionMarkets, activePerpMarkets]
  );

  const orderTypes = useMemo(() => {
    const filteredOptions =
      marketsData?.filter((v) =>
        selectedExpiry ? Number(v.expiry) === selectedExpiry : true
      ) || [];

    const types: OptionTypeResponse[] = [];
    const hasCall = filteredOptions.some(
      (o) => o.option_type === OptionTypeResponse.Call
    );
    const hasPut = filteredOptions.some(
      (o) => o.option_type === OptionTypeResponse.Put
    );

    if (hasCall) {
      types.push(OptionTypeResponse.Call);
    }
    if (hasPut) {
      types.push(OptionTypeResponse.Put);
    }

    return types;
  }, [marketsData, selectedExpiry]);

  const strikes = useMemo(() => {
    if (market.derivative === InstrumentTypeResponse.Option) {
      // Filter instruments from selectedOrderType if available
      const filteredOptions =
        marketsData?.filter(
          (v) =>
            (selectedOrderType ? v.option_type === selectedOrderType : true) &&
            (selectedExpiry ? Number(v.expiry) === selectedExpiry : true)
        ) || [];

      const uniqueStrikes = Array.from(
        new Set(filteredOptions.map(({ strike }) => strike!) || [])
      );
      return uniqueStrikes.sort((a, b) => Number(a) - Number(b));
    }
    return [];
  }, [market.derivative, marketsData, selectedExpiry, selectedOrderType]);

  // Return the selected values, or if editing order exists, return that instead
  const selectedValues = useMemo(
    () => ({
      strike: selectedStrike,
      expiry: selectedExpiry,
      orderType: selectedOrderType,
    }),
    [selectedExpiry, selectedOrderType, selectedStrike]
  );

  const selectedOptionInstrument = useMemo(() => {
    if (market.derivative === InstrumentTypeResponse.Option) {
      const { strike, expiry, orderType } = selectedValues;

      // Find the instrument using the selected values.
      if (!strike || !expiry || !orderType) {
        return undefined;
      }

      return marketsData?.find(
        (v) =>
          Number(v.expiry) === expiry &&
          v.option_type === orderType &&
          v.strike === strike
      ) as IOptionMarket;
    }
    return undefined;
  }, [market.derivative, marketsData, selectedValues]);

  const selectedPerpetualInstrument = useMemo(() => {
    if (market.derivative === InstrumentTypeResponse.Perpetual) {
      return marketsData?.[0];
    }
    return undefined;
  }, [market.derivative, marketsData]);

  const reset = useCallback(() => {
    setStrike(undefined);
    setExpiry(undefined);
    setOrderType(undefined);
  }, []);

  useEffect(() => {
    if (selectedOrderType && !orderTypes.includes(selectedOrderType)) {
      setOrderType(orderTypes[0]);
    }
  }, [selectedOrderType, orderTypes]);

  // Whenever selectedStrike changes and its not included in strikes,
  // remove selected strike
  useEffect(() => {
    if (selectedStrike && !strikes.includes(selectedStrike)) {
      setStrike(undefined);
    }
  }, [selectedStrike, strikes]);

  // When there are existing expiries but none is selected, default to the first
  useEffect(() => {
    setExpiry((prev) => {
      if (!!prev || !expiries.length) {
        return prev;
      }
      return expiries[SELECTED_EXPIRY_OVERRIDE];
    });
  }, [expiries]);

  const [assetDerivativeParamProcessed, setAssetDerivativeParamProcessed] =
    useState(false);
  const [instrumentParamsProcessed, setInstrumentParamsProcessed] =
    useState(false);

  // 1. Changing of params should set the market
  useEffect(() => {
    const allMarkets = [
      ...(activeOptionMarkets || []),
      ...(activePerpMarkets || []),
    ];
    if (!params || !allMarkets.length) {
      return;
    }
    const { asset, derivative } = params;
    setAssetDerivativeParamProcessed((processed) => {
      if (processed) {
        return processed;
      }

      setMarket((prev) => {
        if (!derivative && !asset) {
          return prev;
        }

        if (derivative !== prev.derivative || asset !== prev.asset) {
          const assetValid =
            derivative === InstrumentTypeResponse.Option
              ? activeOptionMarkets?.some((m) => m.underlying_asset === asset)
              : activePerpMarkets?.some((m) => m.underlying_asset === asset);
          return {
            derivative: (derivative ||
              InstrumentTypeResponse.Option) as InstrumentTypeResponse,
            asset: asset && assetValid ? asset : "ETH",
          };
        }
        return prev;
      });
      return true;
    });
  }, [activeOptionMarkets, activePerpMarkets, params, setMarket]);

  // 2. Auto selects instrument if exist in params, and marketsData is loaded
  useEffect(() => {
    if (!params) {
      return;
    }
    setInstrumentParamsProcessed((processed) => {
      if (processed) {
        return processed;
      }

      const { instrument } = params;
      if (marketsData) {
        const marketInstrument = marketsData.find(
          (m) => m.instrument_name === instrument
        );
        if (marketInstrument) {
          // Only applies to options
          if (
            marketInstrument.expiry &&
            marketInstrument.strike &&
            marketInstrument.option_type
          ) {
            setStrike(() => {
              setOrderType(() => {
                setExpiry(() => Number(marketInstrument.expiry));
                return marketInstrument.option_type;
              });
              return marketInstrument.strike;
            });
          }
        }
        return true;
      }
      return false;
    });
  }, [marketsData, params, setExpiry, setOrderType, setStrike]);

  // 3. Changing of markets and instrument should set the route, but only AFTER the params are processed
  useEffect(() => {
    const { asset, derivative } = market;
    if (
      asset &&
      derivative &&
      assetDerivativeParamProcessed &&
      instrumentParamsProcessed
    ) {
      const selectedInstrument =
        selectedOptionInstrument || selectedPerpetualInstrument;
      onAssetDerivativeChanged(
        asset,
        derivative,
        selectedInstrument?.instrument_name
      );
    }
  }, [
    assetDerivativeParamProcessed,
    instrumentParamsProcessed,
    market,
    selectedOptionInstrument,
    selectedPerpetualInstrument,
    onAssetDerivativeChanged,
  ]);

  return (
    <MarketInstrumentContext.Provider
      value={{
        marketsData,
        activeOptionMarkets,
        activePerpMarkets,
        selectedValues,
        selectedOptionInstrument,
        selectedPerpetualInstrument,
        orderTypes,
        strikes,
        expiries,
        setStrike,
        setExpiry,
        setOrderType,
        reset,
        getMarketPrecision,
      }}
    >
      {children}
    </MarketInstrumentContext.Provider>
  );
}

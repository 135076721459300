/* eslint-disable no-unreachable */
import { useCallback, useContext, useState } from "react";
import useSound from "use-sound";
import { SFXSettings, SettingsContext } from "../../contexts/SettingsContext";

import sfxSound from "../../assets/audio/sfx.mp3";

export interface IPlayOptions {
  id?: string;
  forceSoundEnabled?: boolean;
  playbackRate?: number;
}

export declare type PlayFunction = (options?: IPlayOptions) => void;

// Sound can only be played ever x millis
const SOUND_FX_RATE_LIMIT_MS = 300;

type ISoundToLastPlayedTimeMillis = {
  [key in SFXSettings]?: number;
};

type ISoundSpriteSettings = {
  [key in SFXSettings]: [number, number];
};

export const useSFX = () => {
  const soundSprite: ISoundSpriteSettings = {
    disable_all: [0, 0],
    error: [0, 100],
    order_cancelled: [100, 200],
    order_placed: [300, 450],
    order_filled: [750, 1050],
    points: [1850, 1550],
    yv_deposit: [3400, 400],
    infographic_transition: [3850, 3650],
    positive_game_sound: [7500, 1208],
    social_media_like: [8808, 1637],
  };

  const [play] = useSound(sfxSound, { sprite: soundSprite });

  const [, setSoundRateLimitCounter] = useState<ISoundToLastPlayedTimeMillis>(
    {}
  );

  const { sfx: userSettings } = useContext(SettingsContext);

  const playSound = useCallback(
    (sound: SFXSettings, force?: boolean) => {
      if (force || userSettings.has(sound)) {
        // Keep track of rate limits
        setSoundRateLimitCounter((prev) => {
          const lastPlayed = prev[sound];
          if (
            !lastPlayed ||
            Date.now() >= lastPlayed + SOUND_FX_RATE_LIMIT_MS
          ) {
            // Play sound if not rate limited
            try {
              play({ id: sound });
            } catch (error) {
              // Do nothing
            }
          }

          return {
            ...prev,
            [sound]: Date.now(),
          };
        });
      }
    },
    [play, userSettings]
  );

  return {
    playSound,
  };
};

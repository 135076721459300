// All table headers

import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../../../contexts/SettingsContext";

export interface IHeaderColumn {
  label: string;
  align: "right" | "left" | "center" | undefined;
  explanation: string;
}
interface IBidHeader {
  priceIvBid?: IHeaderColumn;
  bidSize: IHeaderColumn;
  markPrice?: IHeaderColumn;
  askSize: IHeaderColumn;
  priceIvAsk?: IHeaderColumn;
}

interface IHeader {
  oi?: IHeaderColumn;
  delta?: IHeaderColumn;
  position?: IHeaderColumn;
}

export const useOptionsChainTableHeaders = () => {
  const { t } = useTranslation("app", { keyPrefix: "OptionsChain.OptionsTable.headers" });
  const { t: tooltip } = useTranslation("tooltips");
  const { optionsChain } = useContext(SettingsContext);

  const bidHeaders: IBidHeader = useMemo(() => {
    const initHeaders: IBidHeader = {
      bidSize: {
        label: t("bid_size"),
        align: "right",
        explanation: tooltip("bidSize"),
      },
      priceIvBid: {
        label: t("bid_iv"),
        align: "right",
        explanation: tooltip("priceBidIv"),
      },
      markPrice: {
        label: t("mark_price"),
        align: "right",
        explanation: tooltip("markPrice"),
      },
      priceIvAsk: {
        label: t("ask_iv"),
        align: "right",
        explanation: tooltip("priceAskIv"),
      },
      askSize: {
        label: t("ask_size"),
        align: "right",
        explanation: tooltip("askSize"),
      },
    };

    if (!optionsChain.has("bid_iv")) delete initHeaders.priceIvBid;
    if (!optionsChain.has("mark_price")) delete initHeaders.markPrice;
    if (!optionsChain.has("ask_iv")) delete initHeaders.priceIvAsk;

    return initHeaders;
  }, [optionsChain, t, tooltip]);

  const headers: IHeader = useMemo(() => {
    const initHeaders: IHeader = {
      delta: {
        label: t("delta"),
        align: "right",
        explanation: tooltip("delta"),
      },
      oi: {
        label: t("oi"),
        align: "right",
        explanation: tooltip("openInterest"),
      },
      position: {
        label: t("position"),
        align: "right",
        explanation: tooltip("position"),
      },
    };

    if (!optionsChain.has("delta")) delete initHeaders.delta;
    if (!optionsChain.has("position")) delete initHeaders.position;

    return initHeaders;
  }, [optionsChain, t, tooltip]);

  return {
    bidHeaders,
    headers,
  };
};

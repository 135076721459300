import styled from "styled-components";
import { COLORS, LAYER_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { StrikeHeader, TableHeaderWrapper } from "../../shared/Table/style";
import { OptionTypeResponse } from "../../../codegen-api";
import HeaderArrow from "../../../assets/svg/header-arrow.svg";

export const OptionsTableHeaderWrapper = styled(TableHeaderWrapper)`
  flex-direction: column;
  font-size: ${FONT_SIZE.oneHalf};
`;

export const DateHeader = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  display: flex;
  justify-content: center;
`;

export const OptionTypeArrow = styled.img.attrs<{ type: OptionTypeResponse }>(
  ({ type }) => ({
    src: HeaderArrow,
    alt: type,
  })
)<{ type: OptionTypeResponse }>`
  margin-left: ${SPACING.two}px;
  transform: ${({ type }) =>
    type === OptionTypeResponse.Call ? "none" : "rotate(90deg)"};
  width: 20px;
  height: 20px;
`;

export const DateHeaderWrapper = styled.div`
  background-color: ${COLORS.highlight.six} !important;
  height: ${COMPONENTS.strikeHeader}px !important;

  * {
    color: ${COLORS.blue.one} !important;
  }

  ${StrikeHeader} {
    background-color: ${LAYER_COLORS.highlightLight};
  }
`;

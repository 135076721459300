import { DropDirection } from "react-bootstrap/esm/DropdownContext";
import { COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import Dropdown from "../Dropdown";
import { DropdownItem, DropdownTitle } from "./style";
import { Chevron } from "../Chevron/style";

interface IDropdownSimpleProps<T extends string> {
  show: boolean;
  onToggle: (show: boolean) => void;
  title: string | JSX.Element;
  items: T[];
  getItemLabel?: (item: T) => string;
  selectedItem?: T;
  onSelectItem: (item: T) => void;
  dropDirection?: DropDirection;
}

function DropdownSimple<T extends string>({
  show,
  onToggle,
  title,
  items,
  getItemLabel,
  selectedItem,
  onSelectItem,
  dropDirection,
}: IDropdownSimpleProps<T>) {
  return (
    <Dropdown
      dropDirection={dropDirection}
      onToggle={onToggle}
      toggleStyles={{
        backgroundColor: COLORS.white.five,
        padding: SPACING.two,
        marginLeft: SPACING.two,
      }}
      dropdownMenuContainerStyles={{
        overflow: "hidden",
        borderRadius: "8px",
      }}
      title={
        <DropdownTitle>
          <span>{title}</span>
          <Chevron size="small" direction={show ? "up" : "down"} />
        </DropdownTitle>
      }
      items={Object.values(items).map((v: T) => ({
        label: (
          <DropdownItem key={v} isSelected={v === selectedItem}>
            {getItemLabel ? getItemLabel(v) : v}
          </DropdownItem>
        ),
        onSelect: () => onSelectItem(v),
      }))}
    />
  );
}

export default DropdownSimple;

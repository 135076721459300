import { useCallback, useContext, useRef, useState } from "react";
import { ScrollableSection, TradingPageWrapper } from "../style";
import {
  Container,
  Dropdown,
  DropdownWrapper,
  MobileSettingsButton,
  OptionsChainSection,
} from "./style";

import { ReactComponent as Settings } from "../../../assets/svg/settings.svg";
import { InstrumentTypeResponse } from "../../../codegen-api";
import MarketSelectionButton from "../../../components/MarketSelectionButton";
import MobileOptionsChain from "../../../components/OptionsChain/MobileOptionsChain";
import OptionsHistory from "../../../components/OptionsHistory";
import MobilePerpsSection from "../../../components/Perps/MobilePerpsSection";
import { SettingsModal } from "../../../components/SettingsModal";
import LazyOptionsTradingHeader from "../../../components/TradingHeader/LazyOptionsTradingHeader";
import LazyPerpsTradingHeader from "../../../components/TradingHeader/LazyPerpsTradingHeader";
import { volatileAssets } from "../../../constants/assets";
import { LAYER_COLORS } from "../../../constants/design/colors";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";
import { MarketContext } from "../../../contexts/MarketContext";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import { AssetResponse } from "../../../utils/asset";
import TradingPageTabs, { TradingPageTabEnum } from "../TradingPageTabs";
import VolatilityWarningBanner from "../VolatilityWarningBanner";

function MobileTradingPage() {
  const { market } = useContext(MarketContext);
  const { selectedPerpetualInstrument } = useContext(MarketInstrumentContext);

  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [currentTab, setCurrentTab] = useState<TradingPageTabEnum>(
    TradingPageTabEnum.OPTIONS_CHAIN
  );
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);

  const onSettingsClick = useCallback(() => {
    setShowSettingsModal(true);
  }, []);

  return (
    <TradingPageWrapper isMobile style={{ padding: 0 }}>
      <SettingsModal
        show={showSettingsModal}
        onHide={() => setShowSettingsModal(false)}
      />
      <Container isMobileScreen>
        <DropdownWrapper>
          <Dropdown>
            <MarketSelectionButton isMobile />
          </Dropdown>
          <MobileSettingsButton onClick={onSettingsClick}>
            <Settings />
          </MobileSettingsButton>
        </DropdownWrapper>
        {market.derivative === InstrumentTypeResponse.Option && (
          <OptionsChainSection>
            <ScrollableSection
              style={{
                margin: SPACING.two,
                height: `calc(100% - ${SPACING.three}px)`,
              }}
              ref={scrollableContainerRef}
            >
              <LazyOptionsTradingHeader isMobileScreen />
              <div
                style={{
                  background: LAYER_COLORS.one,
                  borderRadius: BORDER_RADIUS.five,
                }}
              >
                <div style={{ padding: SPACING.two }}>
                  <TradingPageTabs
                    isMobileScreen
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                  />
                </div>
                <div>
                  {currentTab === TradingPageTabEnum.OPTIONS_CHAIN ? (
                    <MobileOptionsChain
                      scrollableContainerRef={scrollableContainerRef}
                    />
                  ) : (
                    <OptionsHistory isMobileScreen />
                  )}
                </div>
              </div>
            </ScrollableSection>
          </OptionsChainSection>
        )}
        {market.derivative === InstrumentTypeResponse.Perpetual && (
          <OptionsChainSection>
            <ScrollableSection
              style={{
                margin: SPACING.two,
                height: `calc(100% - ${SPACING.three}px)`,
              }}
            >
              {volatileAssets.includes(
                selectedPerpetualInstrument?.underlying_asset as AssetResponse
              ) && (
                <VolatilityWarningBanner
                  dismissKey={selectedPerpetualInstrument?.underlying_asset}
                />
              )}
              <div style={{ marginBottom: SPACING.two }}>
                <LazyPerpsTradingHeader
                  isMobileScreen
                  perpInstrument={selectedPerpetualInstrument}
                />
              </div>
              <MobilePerpsSection
                perpInstrument={selectedPerpetualInstrument}
              />
            </ScrollableSection>
          </OptionsChainSection>
        )}
      </Container>
    </TradingPageWrapper>
  );
}
export default MobileTradingPage;

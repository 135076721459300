import * as Sentry from "@sentry/react";
import copy from "copy-to-clipboard";
import { ButtonThemeEnum } from "../components/Buttons/styles";
import { COLORS, TEXT_COLORS } from "../constants/design/colors";

export const truncateString = (str: string, maxLen: number) => {
  if (str.length > maxLen) {
    return `${str.slice(0, maxLen)}...`;
  }
  return str;
};

export const shortenString = (
  str: string,
  startLen: number,
  endLen: number
) => {
  if (str.length > startLen + endLen) {
    return `${truncateString(str, startLen)}${str.slice(-endLen)}`;
  }
  return str;
};

export const shortenAddress = (address: string) => shortenString(address, 6, 4);

/**
 * Given a string, convert the first letter of every word to uppercase
 * and returns the new string
 * @param str Any string
 */
export const startCase = (str: string) =>
  str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const copyStringToClipboard = (text: string) => {
  copy(text);
};

export const getProfitTextColor = (value: number, secondaryColor?: boolean) => {
  if (value > 0) {
    return secondaryColor ? COLORS.positive.six : COLORS.positive.one;
  }
  if (value < 0) {
    return secondaryColor ? COLORS.negative.six : COLORS.negative.one;
  }
  return TEXT_COLORS.one;
};

export const getProfitTheme = (value: number, secondaryColor?: boolean) => {
  if (value > 0) {
    return secondaryColor ? ButtonThemeEnum.NEGATIVE : ButtonThemeEnum.POSITIVE;
  }
  if (value < 0) {
    return secondaryColor ? ButtonThemeEnum.POSITIVE : ButtonThemeEnum.NEGATIVE;
  }
  return ButtonThemeEnum.NEUTRAL1;
};

/**
 * Calls JSON.stringify on an object, logs an error on Sentry if failed
 * @param obj any object
 */
export const jsonStringify = (obj: any) => {
  try {
    return JSON.stringify(obj);
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        label: "Failed parsing json to string",
        obj,
      },
    });
    throw error;
  }
};

/**
 * Calls JSON.parse on an object, logs an error on Sentry if failed
 * @param obj any object
 */
export const jsonParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        label: "Failed parsing string to json",
        str,
      },
    });
    throw error;
  }
};

import currency from "currency.js";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { COLORS } from "../../../constants/design/colors";
import { IPerpsMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { IOHLCResponse, useOHLC } from "../../../hooks/api/ohlc/useOHLC";
import { getContractPriceStep } from "../../../utils/instruments";
import {
  BarTrack,
  OpenCloseTrack,
  PerformanceBarContainer,
  PerformanceDetailCard,
  PerformanceDetailsContainer,
} from "./style";

interface IPerformanceDetailProps {
  title: string;
  open: number;
  high: number;
  low: number;
  close: number;
  pricePrecision: number;
}

function PerformanceDetail({
  title,
  open,
  high,
  low,
  close,
  pricePrecision,
}: IPerformanceDetailProps) {
  const difference = close - open;
  const differencePercent = difference ? difference / open : 0;

  const totalLength = high - low;

  const openCloseTrackLeftPercent =
    ((Math.min(close, open) - low) / totalLength) * 100;
  const openCloseTrackRightPercent =
    ((high - Math.max(close, open)) / totalLength) * 100;

  return (
    <PerformanceDetailCard>
      <div>
        <span>{title}</span>
        <span
          style={{
            color:
              differencePercent >= 0
                ? COLORS.positive.one
                : COLORS.negative.one,
          }}
        >
          {differencePercent >= 0 ? "+" : ""}
          {(differencePercent * 100).toFixed(2)}%
        </span>
      </div>
      <PerformanceBarContainer>
        <BarTrack />
        <OpenCloseTrack
          isNegative={differencePercent < 0}
          style={{
            // Figure out the left and width
            left: `${openCloseTrackLeftPercent}%`,
            right: `${openCloseTrackRightPercent}%`,
          }}
        >
          <div />
        </OpenCloseTrack>
      </PerformanceBarContainer>
      <div>
        <span>{currency(low, { precision: pricePrecision }).format()}</span>
        <span>{currency(high, { precision: pricePrecision }).format()}</span>
      </div>
    </PerformanceDetailCard>
  );
}

interface IPerformanceDetailsProps {
  perpInstrument?: IPerpsMarket;
}

const getConfig = () => ({
  daily: {
    resolution: "15",
    from: moment().utc().startOf("day").unix(),
    to: moment().unix(),
  },
  weekly: {
    resolution: "30",
    from: moment().utc().startOf("week").unix(),
    to: moment().unix(),
  },
  monthly: {
    resolution: "1D",
    from: moment().utc().startOf("month").unix(),
    to: moment().unix(),
  },
});

function PerformanceDetails({ perpInstrument }: IPerformanceDetailsProps) {
  const [config, setConfig] = useState(getConfig());
  const { price_precision } = getContractPriceStep(perpInstrument);

  // Updates config with intervals
  useEffect(() => {
    const int = setInterval(() => {
      setConfig(getConfig());
    }, 60000);

    return () => clearInterval(int);
  }, []);

  const { data: dataDaily } = useOHLC(
    config.daily.resolution,
    config.daily.from,
    config.daily.to,
    perpInstrument?.instrument_name,
    perpInstrument?.underlying_asset
  );
  const { data: dataWeekly } = useOHLC(
    config.weekly.resolution,
    config.weekly.from,
    config.weekly.to,
    perpInstrument?.instrument_name,
    perpInstrument?.underlying_asset
  );
  const { data: dataMonthly } = useOHLC(
    config.monthly.resolution,
    config.monthly.from,
    config.monthly.to,
    perpInstrument?.instrument_name,
    perpInstrument?.underlying_asset
  );

  const getSingularOHLC = useCallback((resp?: IOHLCResponse) => {
    if (!resp) {
      return {
        open: 0,
        close: 0,
        high: 0,
        low: 0,
      };
    }
    const open = resp.o[0];
    const close = resp.c[resp.c.length - 1];
    const high = resp.h.sort((a, b) => a - b).reverse()[0];
    const low = resp.l.sort((a, b) => a - b)[0];

    return {
      open,
      close,
      high,
      low,
    };
  }, []);

  const dailyOHLC = getSingularOHLC(dataDaily);
  const weeklyOHLC = getSingularOHLC(dataWeekly);
  const monthlyOHLC = getSingularOHLC(dataMonthly);

  return (
    <PerformanceDetailsContainer>
      Performance
      <PerformanceDetail
        title={"1D"}
        open={dailyOHLC.open}
        high={dailyOHLC.high}
        low={dailyOHLC.low}
        close={dailyOHLC.close}
        pricePrecision={price_precision}
      />
      <PerformanceDetail
        title={"1W"}
        open={weeklyOHLC.open}
        high={weeklyOHLC.high}
        low={weeklyOHLC.low}
        close={weeklyOHLC.close}
        pricePrecision={price_precision}
      />
      <PerformanceDetail
        title={"1M"}
        open={monthlyOHLC.open}
        high={monthlyOHLC.high}
        low={monthlyOHLC.low}
        close={monthlyOHLC.close}
        pricePrecision={price_precision}
      />
    </PerformanceDetailsContainer>
  );
}

export default PerformanceDetails;

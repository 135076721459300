/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import currency from "currency.js";
import moment from "moment";
import { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import {
  GetOptionsHistory200Response,
  OptionTypeResponse,
  SideResponse,
} from "../../codegen-api";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { MarketContext } from "../../contexts/MarketContext";
import { useOptionsHistory } from "../../hooks/api/optionsHistory/useOptionsHistory";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { nanosToSeconds } from "../../utils/date";
import { formatSizePrecision } from "../../utils/format";
import { Select } from "../shared/Select";
import { Spinner } from "../shared/Spinner";
import { DefaultCellForColumn } from "../shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../shared/Table/DefaultHeaderForColumn";
import { Align, RowWithSizeIndicator } from "../shared/Table/style";
import {
  EmptyContent,
  FiltersContainer,
  LoadingContainer,
  OptionsHistoryTableWrapper,
  OptionsHistoryWrapper,
  TableContainer,
} from "./style";

enum OptionHistoryOptionEnum {
  ALL = "all_trades",
  CALLS = "calls",
  PUTS = "puts",
}

interface IOptionsHistoryProps {
  isMobileScreen?: boolean;
}

function OptionsHistory({ isMobileScreen = false }: IOptionsHistoryProps) {
  const { market } = useContext(MarketContext);
  const { t } = useTranslation("app", { keyPrefix: "OptionsHistory" });
  const { t: tooltip } = useTranslation("tooltips");
  const tableRef = useRef<HTMLDivElement>(null);

  const [activeTradeHistoryOption, setActiveTradeHistoryOption] = useState(
    OptionHistoryOptionEnum.ALL
  );

  const selectedOption = useMemo(() => {
    switch (activeTradeHistoryOption) {
      case OptionHistoryOptionEnum.CALLS:
        return OptionTypeResponse.Call;
      case OptionHistoryOptionEnum.PUTS:
        return OptionTypeResponse.Put;
      default:
        return undefined;
    }
  }, [activeTradeHistoryOption]);

  const { data: optionHistories, isValidating } = useOptionsHistory(
    market.asset,
    selectedOption,
    undefined,
    50
  );

  const tradeHistoryOptions = useMemo(() => {
    const options = Object.values(OptionHistoryOptionEnum).map((opt) => ({
      label: t(opt),
      isActive: activeTradeHistoryOption === opt,
      onClick: () => setActiveTradeHistoryOption(opt),
    }));
    return options;
  }, [activeTradeHistoryOption, t]);

  const maxFillValue = useMemo(
    () =>
      optionHistories
        ?.slice()
        ?.sort((a, b) => Number(b?.amount || 0) - Number(a?.amount || 0))?.[0]
        ?.amount,
    [optionHistories]
  );

  const columns: ITableColumn<GetOptionsHistory200Response>[] = useMemo(
    () => [
      {
        title: t("size"),
        accessor: "side",
        tooltip: tooltip("side"),
        Cell: ({ value, row }) => {
          const { side, amount } = row.original;
          const fillPercent =
            (Number(amount || 0) / Number(maxFillValue || 1)) * 100;

          return (
            <RowWithSizeIndicator
              fillColor={
                value === SideResponse.Buy
                  ? COLORS.positive.four
                  : COLORS.negative.four
              }
              fillPercent={fillPercent}
              fillTo={"right"}
              style={{
                height: "100%",
              }}
            >
              <Align
                align={"left"}
                style={{
                  color:
                    side === SideResponse.Buy
                      ? COLORS.positive.one
                      : COLORS.negative.one,
                }}
              >
                {formatSizePrecision(Number(amount))}
              </Align>
            </RowWithSizeIndicator>
          );
        },
      },
      {
        title: t("contract"),
        accessor: "option_type",
        tooltip: tooltip("optionType"),
      },
      {
        title: t("expiry"),
        accessor: "expiry",
        tooltip: tooltip("expiry"),
        Cell: ({ value }) => (
          <Align align={"left"}>{moment(value).format("DD MMM YYYY")}</Align>
        ),
      },
      {
        title: t("strike"),
        accessor: "strike",
        tooltip: tooltip("strike"),
        Cell: ({ value }) => (
          <Align align={"left"}>{currency(value || "").format()}</Align>
        ),
      },
      {
        title: t("price"),
        accessor: "avg_price",
        tooltip: tooltip("tradePrice"),
        align: "right",
        Cell: ({ value }) => (
          <Align align={"right"}>{currency(Number(value)).format()}</Align>
        ),
      },
      {
        title: t("iv"),
        accessor: "iv",
        align: "right",
        tooltip: tooltip("iv"),
        Cell: ({ value }) => (
          <Align align={"right"}>
            {(Number(value || 0) * 100).toFixed(2)}%
          </Align>
        ),
      },
      {
        title: t("time_and_date"),
        accessor: "created_timestamp",
        align: "right",
        tooltip: tooltip("tradeTimestamp"),
        Cell: ({ value }) => (
          <Align align={"right"} style={{ color: TEXT_COLORS.two }}>
            {moment.unix(nanosToSeconds(value)).format("HH:mm:ss - DD MMM YY")}
          </Align>
        ),
      },
    ],
    [maxFillValue, t, tooltip]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: optionHistories || [],
      defaultColumn: {
        Header: DefaultHeaderForColumn,
        Cell: DefaultCellForColumn,
      } as any,
    });

  return (
    <OptionsHistoryWrapper isMobileScreen={isMobileScreen}>
      <FiltersContainer>
        <Select isRound options={tradeHistoryOptions} />
      </FiltersContainer>
      {!optionHistories?.length ? (
        <EmptyContent>
          {isValidating ? (
            <LoadingContainer>
              <Spinner />
            </LoadingContainer>
          ) : (
            t("no_history")
          )}
        </EmptyContent>
      ) : (
        <TableContainer>
          <OptionsHistoryTableWrapper
            // Added keys so the table resets scroll position whenever asset/optionType changed
            key={market.asset}
            ref={tableRef}
          >
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  // eslint-disable-next-line react/jsx-key
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      // eslint-disable-next-line react/jsx-key
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  const { ...rowProps } = row.getRowProps();
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <tr {...rowProps}>
                      {row.cells
                        .filter(({ column }) => column.id !== "size")
                        .map((cell) => {
                          const colSpan =
                            cell.column.id === "direction" ? 2 : undefined;
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <td {...cell.getCellProps()} colSpan={colSpan}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </OptionsHistoryTableWrapper>
        </TableContainer>
      )}
    </OptionsHistoryWrapper>
  );
}

export default OptionsHistory;

/* eslint-disable consistent-return */
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction } from "react-router-dom";
import { ReactComponent as TradingIcon } from "../../assets/svg/bar-chart.svg";
import { ReactComponent as PortfolioIcon } from "../../assets/svg/pie-chart.svg";
import { ReactComponent as RewardsIcon } from "../../assets/svg/rewards.svg";
import { ReactComponent as AevoIcon } from "../../assets/logo/logo_white.svg";
import { PageEndpointEnum } from "../../enums/endpoint";
import { Container, Tab } from "./style";

enum MobileTabsEnum {
  Trading = "Trading",
  // Strategies = "Strategies",
  Portfolio = "Portfolio",
  Aevo = "Aevo",
  Referrals = "Referrals",
}

const icon: { [key in MobileTabsEnum]: React.FunctionComponent } = {
  [MobileTabsEnum.Trading]: TradingIcon,
  // [MobileTabsEnum.Strategies]: StrategiesIcon,
  [MobileTabsEnum.Portfolio]: PortfolioIcon,
  [MobileTabsEnum.Aevo]: AevoIcon,
  [MobileTabsEnum.Referrals]: RewardsIcon,
};

interface IMobileTabsProps {
  currentPage: PageEndpointEnum;
  navigate: NavigateFunction;
}

function MobileTabs({ currentPage, navigate }: IMobileTabsProps) {
  const { t } = useTranslation("app", { keyPrefix: "MobileTabs" });

  const selectedTab = useMemo(() => {
    switch (currentPage) {
      case PageEndpointEnum.EARN:
      case PageEndpointEnum.PORTFOLIO:
        return MobileTabsEnum.Portfolio;
      case PageEndpointEnum.REFERRALS:
        return MobileTabsEnum.Referrals;
      case PageEndpointEnum.AEVO:
        return MobileTabsEnum.Aevo;
      case PageEndpointEnum.TRADING:
        return MobileTabsEnum.Trading;
      default:
        return undefined;
    }
  }, [currentPage]);

  const navigateTo = useCallback(
    (tab: MobileTabsEnum) => {
      let page = PageEndpointEnum.TRADING;
      switch (tab) {
        case MobileTabsEnum.Portfolio:
          page = PageEndpointEnum.PORTFOLIO;
          break;
        case MobileTabsEnum.Trading:
          page = PageEndpointEnum.TRADING;
          break;
        case MobileTabsEnum.Referrals:
          page = PageEndpointEnum.REFERRALS;
          break;
        case MobileTabsEnum.Aevo:
          page = PageEndpointEnum.AEVO;
          break;
      }
      navigate(page);
    },
    [navigate]
  );

  return (
    <Container>
      {Object.values(MobileTabsEnum).map((v) => {
        const Icon = icon[v];
        return (
          <Tab
            key={v}
            isSelected={v === selectedTab}
            onClick={() => navigateTo(v)}
            isAevoLogo={v === MobileTabsEnum.Aevo}
          >
            <Icon />
            <span>{t(v.toLowerCase())}</span>
          </Tab>
        );
      })}
    </Container>
  );
}

export default MobileTabs;

import styled, { css } from "styled-components";
import { TxTypeResponse } from "../../codegen-api";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";
import { FONT_CSS } from "../../constants/design/fontSize";

export const CollateralText = styled.div`
  display: flex;

  p {
    margin: auto 0;
  }

  img {
    height: 24px;
    width: 24px;
    margin-right: ${SPACING.two}px;
  }
`;

export const TransactionButton = styled.button`
  color: ${TEXT_COLORS.two};
  transition: 0.2s all ease-in-out;

  &:hover {
    color: ${TEXT_COLORS.one};
  }

  svg {
    margin-left: ${SPACING.two}px;
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  margin-right: ${SPACING.two}px;
  height: 32px;
  width: 32px;
  display: grid;
  align-items: center;

  svg {
    margin: auto;
  }
`;

export const AssetLogoWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: 0;
  right: -${SPACING.one}px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ArrowWrapper = styled(IconWrapper)<{ type?: TxTypeResponse }>`
  position: relative;
  background-color: ${LAYER_COLORS.two};

  > svg:not(${AssetLogoWrapper}) {
    ${({ type }) => {
      switch (type) {
        case TxTypeResponse.Withdraw:
        case TxTypeResponse.YvWithdraw:
          return css`
            transform: rotate(180deg);
          `;
        case TxTypeResponse.Send:
          return css`
            transform: rotate(270deg);
          `;
        case TxTypeResponse.Receive:
          return css`
            transform: rotate(90deg);
          `;
        default:
          return "";
      }
    }}
  }
`;

export const RewardsWrapper = styled(IconWrapper)`
  position: relative;
  background-color: ${COLORS.highlight.five};

  > *:not(${AssetLogoWrapper}) {
    svg {
      stroke: ${COLORS.highlight.one};
    }
  }
  > ${AssetLogoWrapper} {
    svg {
      fill: ${COLORS.highlight.one};
    }
  }
`;

export const CopyButton = styled.button`
  color: ${TEXT_COLORS.one};
  svg {
    margin-right: ${SPACING.two}px;
  }

  &:hover {
    opacity: 0.5;
  }
`;

export const TxTypeContainer = styled.div`
  display: flex;
  flex-direction: column;

  span:nth-child(2) {
    ${FONT_CSS.body.six}
    color: ${TEXT_COLORS.two};
  }
`;

export const DisclaimerMessage = styled.div`
  ${FONT_CSS.label.two}
  color: ${COLORS.highlight.one};
`;

export const DisclaimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${SPACING.three}px;
  gap: ${SPACING.three}px;
  padding: ${SPACING.two}px;
  width: 100%;
  background: ${COLORS.highlight.six};
  border-radius: ${BORDER_RADIUS.five}px;
`;

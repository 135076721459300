import currency from "currency.js";
import { ethers } from "ethers";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CollateralAssetResponse } from "../../../codegen-api";
import { ERC20_ADDRESSES } from "../../../constants/addresses";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import { ONCHAIN_ESTIMATES } from "../../../constants/onchainEstimates";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { ChainIdEnum, socketDepositChains } from "../../../enums/chain";
import { ChainCodeErrorEnum } from "../../../enums/rpcErrorCodes";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import { useClock } from "../../../hooks/api/clock/useClock";
import { useWithdrawalTransfer } from "../../../hooks/api/withdrawTransfer/useWithdrawalTransfer";
import { useYieldVault } from "../../../hooks/api/yieldVault/useYieldVault";
import useDepositERC20OrETH from "../../../hooks/contracts/useCollateralAssetOrETH";
import useDeposit from "../../../hooks/contracts/useDeposit";
import { useToast } from "../../../hooks/toast";
import { useSFX } from "../../../hooks/useSFX";
import useWallet, { ID_TO_CHAINS } from "../../../hooks/wallet/useWallet";
import { ISignature } from "../../../interfaces/Signing";
import {
  getAssetLogo,
  getAssetShortName,
  getCollateralAssets,
} from "../../../utils/asset/assets";
import { CHAIN_EXPLORER_URLS } from "../../../utils/chain";
import { formatAmount } from "../../../utils/format";
import { roundToNearest } from "../../../utils/math";
import { ToastEnum, ToastStatusEnum } from "../../../utils/toast";
import {
  supportedChainId,
  supportedSigningChainIds,
} from "../../../utils/wallet/connectors";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { InfoRow } from "../../ConnectWalletModal/Deposit/style";
import { ApyWrapper } from "../../PortfolioSettings/Collateral/style";
import SelectMultichainNetwork from "../../SelectMultichainNetwork";
import { Input } from "../../shared/Input";
import SegmentedControl, {
  ISegmentedControlConfig,
} from "../../shared/SegmentedControl";
import { Spinner } from "../../shared/Spinner";
import { DisclaimerContainer, DisclaimerMessage } from "../RedeemModal/style";
import {
  ApprovalSuccessMessage,
  ButtonsContainer,
  ErrorMessage,
  InputContainer,
  InputError,
  InputLabel,
  MaxButton,
} from "../style";
import {
  Description,
  IconContainer,
  Spacer,
  USDCSourceContainer,
} from "./style";
import { ExtrasText } from "../../ConnectWalletModal/style";

export const USDCSource = {
  Aevo: "Aevo",
  External: "External",
} as const;

export type USDCSourceType = typeof USDCSource[keyof typeof USDCSource];

interface IDepositWithdrawModalProps {
  show?: boolean;
  onHide?: () => void;
  setPendingDepositTxURL: (url?: string) => void;
}

const ROUND_AUM_TO_NEAREST = 1000;

function EarnForm({
  show,
  setPendingDepositTxURL,
  onHide,
}: IDepositWithdrawModalProps) {
  const wallet = useWallet();
  const { getTimestamp } = useClock();
  const { provider, chainId, setChainToSwitch } = wallet;

  const { createTransfer } = useWithdrawalTransfer(
    wallet,
    CollateralAssetResponse.Usdc
  );
  const [selectedDepositChain, setSelectedDepositChain] = useState(
    supportedSigningChainIds[0]
  );
  const { t: apiError } = useTranslation("apiErrors");
  const [usdcSource, setUsdcSource] = useState<USDCSourceType>(USDCSource.Aevo);

  const [showReadyToDepositMessage, setShowReadyToDepositMessage] =
    useState(false);
  const assetToDeposit = CollateralAssetResponse.Usdc;

  const [assetAllowance, setAssetAllowance] = useState<string>();

  const onSameNetwork = useMemo(
    () => chainId === selectedDepositChain,
    [chainId, selectedDepositChain]
  );

  // When chain is switched, set selected deposit chain
  useEffect(() => {
    if (chainId && supportedSigningChainIds.includes(chainId)) {
      setSelectedDepositChain(chainId);
    }
  }, [chainId]);

  const {
    l1Contract,
    l2Address,
    getAllowance,
    getAssetPermitSignature,
    approveAsset,
    ethBalance,
    userBalance,
    decimals,
  } = useDepositERC20OrETH(wallet, assetToDeposit);

  const {
    l1DepositHelper,
    approvalSpender,
    depositERC20WithPermit,
    depositFees,
    depositERC20OptimismOrArbitrum,
  } = useDeposit(wallet, assetToDeposit);

  const { data: accountDetails } = useGetAccount();
  const { data: yieldVaultData } = useYieldVault();
  const { addToast } = useToast();
  const { t } = useTranslation("app", {
    keyPrefix: "DepositWithdrawModal.EarnModal",
  });
  const { t: formError } = useTranslation("formErrors");
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onChange",
  });

  const amount: string | undefined = useWatch({ control, name: "amount" });

  const [approvedAmountAndSignature, setApprovedAmountAndSignature] = useState<{
    approvedAmount: string | undefined;
    signature: ISignature | undefined;
    deadline: number;
  }>({
    approvedAmount: undefined,
    signature: undefined,
    deadline: 0,
  });

  const [loading, setLoading] = useState(false);
  const [depositWithdrawError, setDepositWithdrawError] = useState<
    string | undefined
  >();

  const notEnoughEthForFees = ethBalance.lt(depositFees || 0);
  const { playSound } = useSFX();
  const { accountApiKeyState, account } = useContext(AuthContext);
  const { setShowConnectModal } = useContext(ConnectWalletContext);

  const vaultApyText = useMemo(
    () =>
      yieldVaultData?.apy
        ? `${Number(yieldVaultData.apy) >= 0 ? "+" : ""}${Number(
            yieldVaultData.apy
          ).toFixed(2)}%`
        : "---",
    [yieldVaultData]
  );
  const usdcBalanceToDeposit = useMemo(() => {
    const l1UsdcBalance = userBalance
      ? ethers.utils.formatUnits(userBalance, decimals)
      : undefined;

    const usdcCollateral = accountDetails
      ? accountDetails?.collaterals?.find(
          (c) => c.collateral_asset === CollateralAssetResponse.Usdc
        )?.balance || "0"
      : undefined;

    return usdcSource === USDCSource.Aevo ? usdcCollateral : l1UsdcBalance;
  }, [accountDetails, decimals, usdcSource, userBalance]);

  const vaultRemainingCapacity = useMemo(
    () =>
      yieldVaultData
        ? String(
            Math.max(
              Number(yieldVaultData.cap) -
                roundToNearest(
                  Number(yieldVaultData.aum),
                  ROUND_AUM_TO_NEAREST,
                  true
                ),
              0
            )
          )
        : undefined,
    [yieldVaultData]
  );

  // When asset is not allowed, switch network and reset asset
  useEffect(() => {
    setSelectedDepositChain((chain) => {
      const allowedAssets = getCollateralAssets();
      if (!allowedAssets.includes(assetToDeposit)) {
        return supportedChainId;
      }
      return chain;
    });
  }, [assetToDeposit]);

  // When chain is switched, set selected deposit chain
  useEffect(() => {
    if (chainId && supportedSigningChainIds.includes(chainId)) {
      setSelectedDepositChain(chainId);
    }
  }, [chainId]);

  // When asset is changed, get allowance if needed
  useEffect(() => {
    if (approvalSpender && (!getAssetPermitSignature || !assetAllowance)) {
      const loadAssetAllowance = async () => {
        setLoading(true);
        try {
          const allowance = await getAllowance(approvalSpender);
          setAssetAllowance(allowance);
        } catch (error) {
          // Nothing
        } finally {
          setLoading(false);
        }
      };
      loadAssetAllowance();
    } else {
      setAssetAllowance("0");
    }
  }, [assetAllowance, getAllowance, getAssetPermitSignature, approvalSpender]);

  // Reset errors
  useEffect(() => {
    clearErrors();
    setDepositWithdrawError(undefined);
    setApprovedAmountAndSignature({
      approvedAmount: undefined,
      signature: undefined,
      deadline: 0,
    });
    setValue("amount", undefined);
  }, [clearErrors, show, selectedDepositChain, usdcSource, setValue]);

  const hasValidSignature = useMemo(() => {
    if (
      amount &&
      amount === approvedAmountAndSignature.approvedAmount &&
      approvedAmountAndSignature.signature
    ) {
      return true;
    }
    return false;
  }, [
    amount,
    approvedAmountAndSignature.approvedAmount,
    approvedAmountAndSignature.signature,
  ]);

  const onSetUsdcSource = useCallback(
    (value: string) => setUsdcSource(value as USDCSourceType),
    []
  );
  const depositReady = useMemo(() => {
    if (!onSameNetwork) {
      return false;
    }

    // IF permit is allowed, check for valid signature.
    // ELSE, check for allowance
    if (getAssetPermitSignature) {
      return hasValidSignature;
    }
    return Number(assetAllowance) >= (Number(amount) || 0);
  }, [
    amount,
    assetAllowance,
    getAssetPermitSignature,
    hasValidSignature,
    onSameNetwork,
  ]);

  const estDepositTime = useMemo(() => {
    if (socketDepositChains.includes(selectedDepositChain)) {
      return `${ONCHAIN_ESTIMATES.socketDeposit.value} ${ONCHAIN_ESTIMATES.socketDeposit.unit}`;
    }
    return `${ONCHAIN_ESTIMATES.deposit.value} ${ONCHAIN_ESTIMATES.deposit.unit}`;
  }, [selectedDepositChain]);

  const submitButtonContent = useMemo(() => {
    if (!onSameNetwork && usdcSource === USDCSource.External) {
      return (
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.HIGHLIGHT}
          type="button"
          onClick={() => {
            setSelectedDepositChain(selectedDepositChain);
            setChainToSwitch(selectedDepositChain);
          }}
        >
          {t("switch_to", { chain: ID_TO_CHAINS[selectedDepositChain] })}
        </Button>
      );
    }
    if (accountApiKeyState === AccountStateEnum.REQUIRE_REGISTER_SIGNING) {
      return (
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.HIGHLIGHT}
          type="button"
          onClick={() => setShowConnectModal(true)}
        >
          {t("complete_sign_in")}
        </Button>
      );
    }
    if (usdcSource === USDCSource.Aevo) {
      return (
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          type={"submit"}
          disabled={
            loading ||
            !isValid ||
            yieldVaultData?.is_paused ||
            !Number(vaultRemainingCapacity)
          }
        >
          {loading ? <Spinner /> : "Deposit"}
        </Button>
      );
    }
    return (
      <Button
        fullWidth
        buttonTheme={ButtonThemeEnum.NEUTRAL2}
        type={"submit"}
        disabled={loading || !isValid || !Number(vaultRemainingCapacity)}
      >
        {
          // eslint-disable-next-line no-nested-ternary
          loading ? (
            <Spinner />
          ) : // eslint-disable-next-line no-nested-ternary
          !depositReady ? (
            getAssetPermitSignature ? (
              t("permit")
            ) : (
              t("approve")
            )
          ) : (
            t("deposit")
          )
        }
      </Button>
    );
  }, [
    onSameNetwork,
    usdcSource,
    accountApiKeyState,
    loading,
    isValid,
    vaultRemainingCapacity,
    depositReady,
    getAssetPermitSignature,
    t,
    selectedDepositChain,
    setChainToSwitch,
    setShowConnectModal,
    yieldVaultData?.is_paused,
  ]);

  const disclaimerContent = useMemo(() => {
    if (yieldVaultData?.is_paused) {
      return (
        <DisclaimerContainer>
          <DisclaimerMessage>{t("vault_paused_text")}</DisclaimerMessage>
        </DisclaimerContainer>
      );
    }
    return undefined;
  }, [t, yieldVaultData?.is_paused]);

  const messageContent = useMemo(() => {
    if (depositWithdrawError) {
      return <ErrorMessage>{depositWithdrawError}</ErrorMessage>;
    }
    if (notEnoughEthForFees && amount && usdcSource === "External") {
      return <ErrorMessage>{t("insufficient_eth_balance")}</ErrorMessage>;
    }
    if (depositReady && showReadyToDepositMessage) {
      return (
        <ApprovalSuccessMessage>{t("ready_to_deposit")}</ApprovalSuccessMessage>
      );
    }
    return undefined;
  }, [
    amount,
    depositReady,
    depositWithdrawError,
    notEnoughEthForFees,
    showReadyToDepositMessage,
    t,
    usdcSource,
  ]);

  const onMaxClick = useCallback(() => {
    clearErrors("amount");
    setValue("amount", String(usdcBalanceToDeposit || 0));
    trigger("amount");
  }, [clearErrors, setValue, usdcBalanceToDeposit, trigger]);

  const handlePermitAsset = useCallback(
    async (amt: string) => {
      if (
        l1Contract?.address &&
        getAssetPermitSignature &&
        l1DepositHelper &&
        provider
      ) {
        try {
          setLoading(true);

          const deadline = getTimestamp() + 60 * 60;
          const signature = await getAssetPermitSignature(
            l1DepositHelper.address,
            amt,
            deadline
          );
          setApprovedAmountAndSignature({
            signature,
            deadline,
            approvedAmount: amt,
          });
          setShowReadyToDepositMessage(true);
        } catch (error: any) {
          setDepositWithdrawError("Approval failed");
          // eslint-disable-next-line no-console
          console.log({ error });
        } finally {
          setLoading(false);
        }
      }
    },
    [
      l1Contract?.address,
      getAssetPermitSignature,
      l1DepositHelper,
      provider,
      getTimestamp,
    ]
  );

  const handleApproveAsset = useCallback(
    async (amt: string) => {
      if (!approvalSpender) {
        return;
      }
      try {
        setLoading(true);
        await approveAsset(approvalSpender, amt);
        setAssetAllowance(amt);
        setShowReadyToDepositMessage(true);
      } catch (error: any) {
        setDepositWithdrawError(t("approval_failed"));
        // eslint-disable-next-line no-console
        console.log({ error });
      } finally {
        setLoading(false);
      }
    },
    [approveAsset, approvalSpender, t]
  );

  const handleDeposit = useCallback(async () => {
    if (account && amount) {
      let depositTx: ethers.ContractTransaction | undefined;

      // If permit is allowed, use that
      try {
        setLoading(true);

        const depositAmtBN = ethers.utils.parseUnits(amount, decimals);

        if (
          socketDepositChains.includes(chainId as ChainIdEnum) &&
          l1Contract?.address
        ) {
          // Cross chain deposit
          const response = await depositERC20OptimismOrArbitrum(
            depositAmtBN,
            l1Contract.address,
            assetToDeposit,
            true
          );
          if (response?.tx) {
            depositTx = response.tx;
          } else if (response?.insufficientLimit) {
            const chainEnum = chainId as ChainIdEnum;
            const otherNetworks = supportedSigningChainIds
              .filter((c) => c !== chainEnum)
              .map((c) => ID_TO_CHAINS[c]);
            const otherNetworksText =
              otherNetworks.length === 2
                ? otherNetworks.join(` ${t("or")} `)
                : otherNetworks.join(", ");
            const currentNetworkName = ID_TO_CHAINS[chainEnum];

            // No limit
            if (response.insufficientLimit.isZero()) {
              setDepositWithdrawError(
                t("no_bridge_capacity", {
                  currentChain: currentNetworkName,
                  otherChains: otherNetworksText,
                })
              );
            } else {
              const limitStr = ethers.utils.formatUnits(
                response.insufficientLimit,
                decimals
              );
              let text = t("insufficient_bridge_capacity", {
                chain: ID_TO_CHAINS[chainEnum],
              });
              text += t("reduce_deposit_size", {
                size: `${currency(limitStr).format({
                  symbol: "",
                })} ${assetToDeposit}`,
                otherChains: otherNetworksText,
              });
              setDepositWithdrawError(text);
            }
          }
        } else if (l1Contract?.address && l2Address) {
          // If can permit deposit erc20, use that
          if (
            approvedAmountAndSignature.deadline &&
            approvedAmountAndSignature.signature
          ) {
            depositTx = await depositERC20WithPermit(
              l1Contract.address,
              l2Address,
              depositAmtBN,
              approvedAmountAndSignature.deadline,
              approvedAmountAndSignature.signature,
              true
            );
          }
        }

        // Only hides if a deposit tx was triggered
        if (depositTx) {
          playSound("yv_deposit", true);
          addToast(
            {
              type: ToastEnum.SIMPLE,
              header: t("deposit_initiated"),
              subheader: t("deposit_initiated_desc", {
                amount: `${currency(amount).format({
                  symbol: "",
                })} ${getAssetShortName(assetToDeposit)}`,
                time: `${ONCHAIN_ESTIMATES.deposit.value} ${ONCHAIN_ESTIMATES.deposit.unit}`,
              }),
              status: ToastStatusEnum.SUCCESS,
            },
            10000
          );
        }
      } catch (error: any) {
        if (error.code === ChainCodeErrorEnum.CANCELLED) {
          setDepositWithdrawError(t("deposit_cancelled"));
        } else {
          setDepositWithdrawError(t("deposit_failed"));
          // eslint-disable-next-line no-console
          console.log({ error });
        }
      } finally {
        setLoading(false);
      }

      // Set pending deposit TX
      if (depositTx) {
        const explorerUrl = CHAIN_EXPLORER_URLS[chainId as ChainIdEnum];
        try {
          setPendingDepositTxURL(`${explorerUrl}/tx/${depositTx.hash}`);
          await depositTx.wait(1);
        } catch (error) {
          setDepositWithdrawError(t("deposit_failed"));
        } finally {
          setPendingDepositTxURL(undefined);
        }

        // wait for tx and then close
        await depositTx.wait(1);
        setPendingDepositTxURL(undefined);
        onHide?.();
      }
    }
  }, [
    account,
    amount,
    decimals,
    chainId,
    l1Contract?.address,
    l2Address,
    depositERC20OptimismOrArbitrum,
    assetToDeposit,
    t,
    approvedAmountAndSignature.deadline,
    approvedAmountAndSignature.signature,
    depositERC20WithPermit,
    playSound,
    addToast,
    setPendingDepositTxURL,
    onHide,
  ]);

  const depositFromL1 = useCallback(async () => {
    const amt = amount || "0";
    if (!depositReady) {
      if (getAssetPermitSignature) {
        handlePermitAsset(amt);
      } else {
        handleApproveAsset(amt);
      }
    } else {
      handleDeposit();
    }
  }, [
    amount,
    depositReady,
    getAssetPermitSignature,
    handleApproveAsset,
    handleDeposit,
    handlePermitAsset,
  ]);

  const depositFromExchange = useCallback(async () => {
    if (account && amount) {
      const fundingVaultAddress = ERC20_ADDRESSES[supportedChainId]?.aeusd;
      try {
        if (!fundingVaultAddress) {
          return;
        }
        setLoading(true);
        await createTransfer(
          amount,
          fundingVaultAddress,
          () => {},
          "YV_DEPOSIT"
        );
        playSound("yv_deposit", true);
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Vault Deposit Complete",
            subheader: `${currency(amount).format({
              symbol: "",
            })} ${getAssetShortName(assetToDeposit)} deposited`,
            status: ToastStatusEnum.SUCCESS,
            icon: (
              <IconContainer>
                <img
                  src={getAssetLogo(CollateralAssetResponse.AeUsd)}
                  alt={CollateralAssetResponse.AeUsd}
                />
              </IconContainer>
            ),
          },
          10000
        );
        onHide?.();
      } catch (error: any) {
        if (error.code === ChainCodeErrorEnum.CANCELLED) {
          setDepositWithdrawError(t("deposit_cancelled"));
        } else {
          setDepositWithdrawError(
            apiError(error.message) || t("deposit_failed")
          );
          // eslint-disable-next-line no-console
          console.log({ error });
        }
      } finally {
        setLoading(false);
      }
    }
  }, [
    account,
    addToast,
    amount,
    apiError,
    assetToDeposit,
    createTransfer,
    onHide,
    playSound,
    t,
  ]);

  const onSubmit = useCallback(async () => {
    setDepositWithdrawError(undefined);

    if (!account) {
      return;
    }

    if (usdcSource === USDCSource.External) {
      depositFromL1();
    } else {
      depositFromExchange();
    }
  }, [account, depositFromL1, depositFromExchange, usdcSource]);

  const USDCSourceSegmentProps = useMemo(() => {
    const config: ISegmentedControlConfig = {
      widthType: "fullWidth",
      backgroundColor: LAYER_COLORS.two,
      activeBackgroundColor: LAYER_COLORS.three,
      borderRadius: "10px",
      button: {
        height: 32,
        fontSize: FONT_SIZE.one,
      },
    };
    return {
      config,
      segments: [
        {
          value: USDCSource.Aevo,
          display: USDCSource.Aevo,
        },
        {
          value: USDCSource.External,
          display: USDCSource.External,
        },
      ],
    };
  }, []);

  const socketDepositInfo = useMemo(
    () =>
      chainId === selectedDepositChain &&
      selectedDepositChain !== supportedChainId
        ? {
            ethBalance,
            depositFee: depositFees,
          }
        : undefined,
    [chainId, depositFees, ethBalance, selectedDepositChain]
  );

  const infoRows = useMemo(() => {
    if (usdcSource === USDCSource.Aevo) {
      return (
        <>
          <InfoRow>
            <span>
              {t("exchange_balance")} (
              {getAssetShortName(assetToDeposit, selectedDepositChain)})
            </span>
            <span
              style={{
                color:
                  errors.amount?.type === "lessThanEqualToBalance"
                    ? COLORS.negative.one
                    : TEXT_COLORS.one,
              }}
            >
              {usdcBalanceToDeposit
                ? currency(usdcBalanceToDeposit).format({
                    symbol: "",
                  })
                : "---"}
            </span>
          </InfoRow>
          <InfoRow>
            <span>{t("apy")}</span>
            <ApyWrapper>{vaultApyText}</ApyWrapper>
          </InfoRow>
          {errors.amount?.type === "lessThanVaultCapacity" && (
            <InfoRow>
              <span>{t("remaining_vault_capacity")}</span>
              <span>
                {vaultRemainingCapacity
                  ? currency(vaultRemainingCapacity).format({
                      symbol: "",
                    })
                  : "---"}
              </span>
            </InfoRow>
          )}
        </>
      );
    }
    return (
      <>
        <InfoRow>
          <span>
            {t("balance")} (
            {getAssetShortName(assetToDeposit, selectedDepositChain)})
          </span>
          <span
            style={{
              color:
                errors.amount?.type === "lessThanEqualToBalance"
                  ? COLORS.negative.one
                  : TEXT_COLORS.one,
            }}
          >
            {onSameNetwork && usdcBalanceToDeposit
              ? currency(usdcBalanceToDeposit).format({
                  symbol: "",
                })
              : "---"}
          </span>
        </InfoRow>
        <InfoRow>
          <span>{t("apy")}</span>
          <ApyWrapper>{vaultApyText}</ApyWrapper>
        </InfoRow>
        <InfoRow>
          <span>{t("estimated_deposit_time")}</span>
          <span>{estDepositTime}</span>
        </InfoRow>
        {socketDepositInfo && (
          <InfoRow error={notEnoughEthForFees}>
            <span>{t("wallet_balance")} (ETH)</span>
            <span>
              {Number(
                ethers.utils.formatEther(socketDepositInfo.ethBalance)
              ).toFixed(4)}
            </span>
          </InfoRow>
        )}
        {errors.amount?.type === "lessThanVaultCapacity" && (
          <InfoRow>
            <span>{t("remaining_vault_capacity")}</span>
            <span>
              {vaultRemainingCapacity
                ? currency(vaultRemainingCapacity).format({
                    symbol: "",
                  })
                : "---"}
            </span>
          </InfoRow>
        )}
        {Boolean(!errors.amount?.type && depositWithdrawError) && (
          <ExtrasText type="error">{depositWithdrawError}</ExtrasText>
        )}
      </>
    );
  }, [
    assetToDeposit,
    depositWithdrawError,
    errors.amount?.type,
    estDepositTime,
    notEnoughEthForFees,
    onSameNetwork,
    selectedDepositChain,
    socketDepositInfo,
    t,
    usdcBalanceToDeposit,
    usdcSource,
    vaultApyText,
    vaultRemainingCapacity,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputContainer>
        <USDCSourceContainer>
          <InputLabel>{t("usdc_source")}</InputLabel>
          <SegmentedControl
            segments={USDCSourceSegmentProps.segments}
            value={t(usdcSource)}
            onSelect={onSetUsdcSource}
            config={USDCSourceSegmentProps.config}
          />
        </USDCSourceContainer>
        {usdcSource === USDCSource.External && (
          <>
            <InputLabel>{t("deposit_from")}</InputLabel>
            <SelectMultichainNetwork
              selectedChain={selectedDepositChain}
              onSelectChain={setSelectedDepositChain}
            />
          </>
        )}
        <InputLabel>{t("amount")}</InputLabel>
        <Input
          placeholder="0.00"
          leftAccessory={
            <img
              src={getAssetLogo(assetToDeposit)}
              width={24}
              height={24}
              alt={assetToDeposit}
            />
          }
          rightAccessory={
            <MaxButton
              onClick={onMaxClick}
              disabled={!onSameNetwork && usdcSource === USDCSource.External}
              type="button"
            >
              {t("max")}
            </MaxButton>
          }
          type="number"
          disabled={
            loading ||
            (usdcSource === USDCSource.Aevo && yieldVaultData?.is_paused)
          }
          {...register("amount", {
            required: true,
            validate: {
              moreThanZero: (v) => parseFloat(v) > 0,
              lessThanEqualToBalance: (v) =>
                parseFloat(v) <= parseFloat(usdcBalanceToDeposit || "0"),
              lessThanVaultCapacity: (v) =>
                parseFloat(v) <=
                parseFloat(vaultRemainingCapacity || String(Number.MAX_VALUE)),
            },
          })}
          error={Boolean(Object.keys(errors).length)}
        />
        {errors.amount?.type === "required" && (
          <InputError>{formError("amount.required")}</InputError>
        )}
        {errors.amount?.type === "moreThanZero" && (
          <InputError>{formError("amount.moreThanZero")}</InputError>
        )}
        {errors.amount?.type === "lessThanEqualToBalance" && (
          <InputError>{formError("amount.lessThanEqualToBalance")}</InputError>
        )}
        {errors.amount?.type === "lessThanVaultCapacity" && (
          <InputError>
            {formError("amount.lessThanVaultCapacity", {
              capacity: currency(vaultRemainingCapacity || "0").format(),
            })}
          </InputError>
        )}
      </InputContainer>
      {infoRows}
      <Spacer />
      {messageContent}

      {/* CAP LIMIT HIT MESSAGE */}
      {Number(vaultRemainingCapacity) === 0 && (
        <Description
          style={{
            marginBottom: SPACING.three,
          }}
        >
          {t("cap_limit_hit", {
            cap: formatAmount(Number(yieldVaultData?.cap || 0)).toLowerCase(),
          })}
        </Description>
      )}

      <ButtonsContainer>{submitButtonContent}</ButtonsContainer>
      {disclaimerContent}
    </form>
  );
}

export default EarnForm;

// Used as reference when handling useForm validate errors
export enum FormValidatorKeysEnum {
  required = "required",
  decimalsTooSmall = "decimalsTooSmall",
  moreThanZero = "moreThanZero",
  notEnoughBalance = "notEnoughBalance",
  orderValueTooSmall = "orderValueTooSmall",
  limitPriceAboveTriggerPrice = "limitPriceAboveTriggerPrice",
  limitPriceBelowTriggerPrice = "limitPriceBelowTriggerPrice",
  triggerPriceAboveMark = "triggerPriceAboveMark",
  triggerPriceBelowMark = "triggerPriceBelowMark",
  reduceOnlyOrderSizeValid = "reduceOnlyOrderSizeValid",
  reduceOnlyOrderSizeValidWithExistingOrder = "reduceOnlyOrderSizeValidWithExistingOrder",
  roiTooHigh = "roiTooHigh",
  roiTooLow = "roiTooLow",
}

import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import Marquee from "react-fast-marquee";
import { SelectWrapper } from "../../components/shared/Select/style";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING, WIDTHS } from "../../constants/design/spacing";
import { foregroundComponent } from "../../style";

export const TradingPageWrapper = styled.div<{ isMobile?: boolean }>`
  position: relative;
  flex: 1;
  padding: 0 ${SPACING.two}px;
  display: grid;
  grid-gap: ${SPACING.two}px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      min-height: 0;
      height: calc(
        100% - ${COMPONENTS.header.main}px - ${COMPONENTS.mobileTabBar}px
      );
    `}

  @media (min-width: ${WIDTHS.S}px) {
    grid-template-columns: auto ${COMPONENTS.tradeSectionWidth}px;
    grid-template-rows: ${COMPONENTS.header.sub}px 1fr;
  }
`;

export const HeaderSection = styled.div`
  grid-template-columns: ${COMPONENTS.marketSelectionDropdown}px 1fr;
  grid-column: 1 / span 2;
  display: grid;
  grid-gap: ${SPACING.two}px;
  justify-content: space-between;
`;

export const TradesSection = styled.div<{ opacity?: number }>`
  opacity: ${({ opacity }) => opacity ?? 1};
  transition: opacity 0.25s ease-in-out;
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  width: ${COMPONENTS.tradeSectionWidth}px;
`;

export const OptionsChainSection = styled.div`
  display: flex;
  flex-direction: column;
  background: ${LAYER_COLORS.one};
  border-radius: 12px;
  overflow: hidden;

  > {
    padding: 0 ${SPACING.three}px;
  }

  > ${SelectWrapper} {
    margin: ${SPACING.three}px;
  }
`;

export const TradingPageContent = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
`;

export const MarketPortfolioPageWrapper = styled.div`
  > {
    padding: 0 ${SPACING.three}px;
  }

  height: 100%;
`;

export const MarketSelectionWrapper = styled.div<{ opacity?: number }>`
  opacity: ${({ opacity }) => opacity ?? 1};
  transition: opacity 0.2s ease-in-out;
  overflow: scroll;
`;

export const TradeFormWrapper = styled.div`
  position: relative;
  flex: 1;

  overflow: scroll;
  ${foregroundComponent};
`;

export const OptionsPerpsSection = styled.div<{
  opacity?: number;
  bottomCollapsed?: boolean;
  bottomCollapsedHeight?: string;
}>`
  transition: all 0.2s ease-in-out;
  opacity: ${({ opacity }) => opacity ?? 1};
  display: grid;
  grid-gap: ${SPACING.two}px;
  overflow: hidden;

  @media only screen and (max-width: ${WIDTHS.SM}px) {
    grid-template-rows: 1fr ${COMPONENTS.tradeBottomBar.height.small}px;
  }
  @media only screen and (min-width: ${WIDTHS.SM}px) {
    grid-template-rows: 1fr ${COMPONENTS.tradeBottomBar.height.large}px;
  }

  ${({ bottomCollapsed, bottomCollapsedHeight }) =>
    bottomCollapsed &&
    css`
      @media only screen and (max-width: ${WIDTHS.SM}px) {
        grid-template-rows: 1fr ${bottomCollapsedHeight || 0};
      }
      @media only screen and (min-width: ${WIDTHS.SM}px) {
        grid-template-rows: 1fr ${bottomCollapsedHeight || 0};
      }
    `}
`;

export const OptionsSection = styled(OptionsPerpsSection)``;

export const PerpsSection = styled(OptionsPerpsSection)`
  grid-template-columns: 1fr ${COMPONENTS.perpsOrderbookWidth}px;
`;

export const ScrollableSection = styled.div`
  overflow: scroll;
  height: 100%;
  transition: height 0.2s ease-in-out;
`;

export const PageTopScrim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${COMPONENTS.header.sub + SPACING.three + SPACING.three}px;
  background: linear-gradient(
    180deg,
    rgba(6, 6, 12, 1) 0%,
    rgba(6, 6, 12, 0.24) 100%
  );
  pointer-events: none;
  z-index: 2;
`;

export const Tabs = styled.div`
  display: flex;
  padding-bottom: ${SPACING.three}px;
`;

export const Tab = styled.button<{ selected?: boolean }>`
  font-size: ${FONT_SIZE.two};
  margin-right: ${SPACING.three}px;
  color: ${({ selected }) => (selected ? TEXT_COLORS.one : TEXT_COLORS.three)};
  padding: 0;
`;

export const WarningBanner = styled(motion.div)`
  ${FONT_CSS.body.five}
  background: ${COLORS.system.three};
  color: ${COLORS.system.one};
  padding: ${SPACING.one}px ${SPACING.two}px;
  padding-right: ${SPACING.three + SPACING.two}px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  min-height: ${COMPONENTS.banner}px;
  position: relative;
  margin-bottom: ${SPACING.two}px;

  svg {
    margin-right: ${SPACING.two}px;
    stroke: ${COLORS.system.one};
  }

  button {
    position: absolute;
    right: ${SPACING.three}px;

    > svg {
      margin-right: 0;
    }
  }
`;

export const BitcoinMarquee = styled(Marquee)`
  background-color: transparent;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: 10px;
  width: 100%;
  z-index: 30;
`;

export const TradingTabWrapper = styled.div<{ isMobileScreen?: boolean }>`
  height: ${COMPONENTS.toggleTabs}px;

  ${({ isMobileScreen }) =>
    isMobileScreen
      ? css`
          width: 100%;
        `
      : css`
          margin: ${SPACING.two}px;
          width: fit-content;
        `};
`;

export const TradingTab = styled.div`
  padding: ${SPACING.two}px ${SPACING.four}px;
`;

/* eslint-disable no-unreachable */
import { InstrumentTypeResponse } from "../../codegen-api";
import { COMPONENTS } from "../../constants/design/spacing";
import {
  IOptionMarket,
  IPerpsMarket,
} from "../../contexts/MarketInstrumentContext/useGetMarkets";
import useIndexWSS from "../../hooks/wss/useIndexWSS";
import { BaseModal } from "../BaseModal";
import OptionsTradeForm from "../TradeForm/OptionsTradeForm";
import PerpsTradeForm, {
  IPerpsFormDefaultValues,
} from "../TradeForm/PerpsTradeForm";
import Price from "../TradingHeader/Price";
import { ModalTitlePrice, TradeFormContainer } from "./style";

export type IMobileTradeFormModalProps = {
  show?: boolean;
  onHide: () => void;
} & (
  | {
      instrumentType: typeof InstrumentTypeResponse.Option;
      selectedInstrument?: IOptionMarket;
      defaultValues?: undefined;
    }
  | {
      instrumentType: typeof InstrumentTypeResponse.Perpetual;
      selectedInstrument?: IPerpsMarket;
      defaultValues?: IPerpsFormDefaultValues;
    }
);

function MobileTradeFormModal({
  show,
  onHide,
  instrumentType,
  selectedInstrument,
  defaultValues,
}: IMobileTradeFormModalProps) {
  const { index } = useIndexWSS(selectedInstrument?.underlying_asset);

  return (
    <BaseModal
      noContentPadding
      show={show}
      onHide={onHide}
      title={
        <ModalTitlePrice>
          <span>{selectedInstrument?.underlying_asset}-USD</span>
          <Price
            currentPrice={index?.price ? Number(index.price) : undefined}
            asset={selectedInstrument?.underlying_asset}
            type="priceOnly"
          />
        </ModalTitlePrice>
      }
      titleStyle={{
        flex: 1,
        padding: 0,
        marginLeft: COMPONENTS.modalHeaderHeight,
      }}
    >
      <TradeFormContainer>
        {instrumentType === InstrumentTypeResponse.Option && (
          <OptionsTradeForm
            mobileMode
            selectedInstrument={selectedInstrument}
            onClose={onHide}
          />
        )}
        {instrumentType === InstrumentTypeResponse.Perpetual && (
          <PerpsTradeForm
            mobileMode
            defaultValues={defaultValues}
            perpInstrument={selectedInstrument}
            onClose={onHide}
          />
        )}
      </TradeFormContainer>
    </BaseModal>
  );
}

export default MobileTradeFormModal;

/* eslint-disable no-nested-ternary */
import currency from "currency.js";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConfirmationModal, {
  IConfirmationButton,
  IConfirmationModalStat,
} from ".";
import {
  GetAccount200ResponsePositionsInner,
  InstrumentTypeResponse,
  SideResponse,
  Stop,
} from "../../codegen-api";
import {
  BACKGROUND_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { FormValidatorKeysEnum } from "../../enums/form";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useOrder } from "../../hooks/api/order/useOrder";
import { useToast } from "../../hooks/toast";
import { useSFX } from "../../hooks/useSFX";
import useMarkPriceWSS from "../../hooks/wss/useMarkPriceWSS";
import { roundToStepSize } from "../../utils/format";
import {
  getAssetFromSymbol,
  getContractPriceStep,
} from "../../utils/instruments";
import { calculateExitPriceFromRoi, calculatePnl } from "../../utils/math";
import { getProfitTextColor } from "../../utils/strings";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { IBaseModalProps } from "../BaseModal";
import {
  StopLossInput,
  TakeProfitInput,
} from "../TradeForm/PerpsTradeForm/form";
import { Form } from "../TradeForm/style";
import { ModalErrorMessage } from "../shared/style";
import { DropdownItem, DropdownTitle, TextWrapper } from "./style";
import Dropdown from "../shared/Dropdown";
import { Chevron } from "../shared/Chevron/style";
import { AccountStateEnum, AuthContext } from "../../contexts/AuthContext";
import { ButtonThemeEnum } from "../Buttons/styles";
import { ConnectWalletContext } from "../../contexts/ConnectWalletContext";

interface IClosePositionModal extends IBaseModalProps {
  position?: GetAccount200ResponsePositionsInner;
}

export const Tpsl = {
  Price: "USD",
  Roi: "%ROI",
} as const;

export type TpslType = typeof Tpsl[keyof typeof Tpsl];

/**
 * The modal responsible for creating and cancelling tpsl for position orders
 */
function TPSLForPositionModal({
  position,
  onHide,
  show,
  ...modalProps
}: IClosePositionModal) {
  const { t: apiError } = useTranslation("apiErrors");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const defaultTpslType = Tpsl.Price;
  const [tpType, setTpType] = useState<TpslType>(defaultTpslType);
  const [slType, setSlType] = useState<TpslType>(defaultTpslType);
  const [tpDropdownOpen, setTpDropdownOpen] = useState<boolean>(false);
  const [slDropdownOpen, setSlDropdownOpen] = useState<boolean>(false);
  const { accountSigningKeyState } = useContext(AuthContext);

  const uniqueTickers = useMemo(
    () =>
      position
        ? {
            [position?.instrument_name]: {
              asset: position.asset,
              derivative: position.option
                ? InstrumentTypeResponse.Option
                : InstrumentTypeResponse.Perpetual,
            },
          }
        : {},
    [position]
  );

  const { instrumentMark } = useMarkPriceWSS(uniqueTickers);
  const mark = position ? instrumentMark[position.instrument_name] : undefined;
  const markPrice = mark?.mark_price || position?.mark_price;

  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.TPSLForPositionModal",
  });
  const { addToast } = useToast();
  const { createTPSLOrder, createTPSLOrders } = useOrder();
  const { mutate } = useGetAccount();
  const { playSound } = useSFX();
  const { getMarketPrecision } = useContext(MarketInstrumentContext);

  const pricePrecision = getMarketPrecision(
    position?.asset,
    position?.instrument_type
  ).price_precision;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    clearErrors,
    trigger,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const tpTriggerPriceName = "tpTriggerPrice";
  const slTriggerPriceName = "slTriggerPrice";
  const tpRoiName = "tpRoi";
  const slRoiName = "slRoi";
  const tpTriggerPrice = useWatch({ control, name: tpTriggerPriceName });
  const slTriggerPrice = useWatch({ control, name: slTriggerPriceName });
  const tpRoi = useWatch({ control, name: tpRoiName });
  const slRoi = useWatch({ control, name: slRoiName });

  const triggerPriceFromRoi = useCallback(
    (roi: number) => {
      if (!position) {
        return undefined;
      }
      return calculateExitPriceFromRoi(
        Number(position?.avg_entry_price),
        roi / 100,
        Number(position?.maintenance_margin),
        position?.side!,
        Number(position?.amount)
      );
    },
    [position]
  );

  const [actualTpTriggerPrice, actualSlTriggerPrice] = useMemo(() => {
    const tpToUse =
      tpType === Tpsl.Price ? tpTriggerPrice : triggerPriceFromRoi(tpRoi);
    const slToUse =
      slType === Tpsl.Price ? slTriggerPrice : triggerPriceFromRoi(slRoi);
    return [Math.max(0, tpToUse), Math.max(0, slToUse)];
  }, [
    slRoi,
    slTriggerPrice,
    slType,
    tpRoi,
    tpTriggerPrice,
    tpType,
    triggerPriceFromRoi,
  ]);

  const { activeOptionMarkets, activePerpMarkets } = useContext(
    MarketInstrumentContext
  );
  const asset = useMemo(
    () => getAssetFromSymbol(position?.instrument_name || ""),
    [position?.instrument_name]
  );

  const instrument = useMemo(() => {
    if (asset && position) {
      const markets =
        position.instrument_type === InstrumentTypeResponse.Option
          ? activeOptionMarkets
          : activePerpMarkets;
      return markets?.find((m) => m.underlying_asset === asset);
    }
    return undefined;
  }, [activeOptionMarkets, activePerpMarkets, asset, position]);

  const contractPriceStep = useMemo(
    () => getContractPriceStep(instrument),
    [instrument]
  );

  const onHideModal = useCallback(() => {
    reset();
    onHide?.();
    setTpType(defaultTpslType);
    setSlType(defaultTpslType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onHide]);

  const onConfirmTPSLForPosition = useCallback(async () => {
    if (!position) {
      return;
    }

    try {
      setLoading(true);

      const tpslSide =
        position.side === SideResponse.Buy
          ? SideResponse.Sell
          : SideResponse.Buy;
      await createTPSLOrders(
        position.triggers,
        createTPSLOrder(
          position.instrument_id,
          Stop.TakeProfit,
          tpslSide,
          actualTpTriggerPrice
        ),
        createTPSLOrder(
          position.instrument_id,
          Stop.StopLoss,
          tpslSide,
          actualSlTriggerPrice
        )
      );

      mutate();
      onHideModal();
      playSound("order_placed");
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: t("order_confirmed_header"),
          subheader: t("order_confirmed_subheader"),
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    } catch (err: any) {
      setErrorMessage(apiError(err.message) || "");
    } finally {
      setErrorMessage("");
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualSlTriggerPrice,
    actualTpTriggerPrice,
    addToast,
    apiError,
    createTPSLOrder,
    createTPSLOrders,
    mutate,
    onHideModal,
    playSound,
    position,
    t,
  ]);

  const stats: IConfirmationModalStat[] = useMemo(() => {
    if (!position) {
      return [];
    }

    const items = [
      {
        title: `${t("position")} (${asset})`,
        data: position ? Number(position.amount).toFixed(2) : "---",
        side: position?.side,
      },
      {
        title: t("avg_entry_price"),
        data: currency(position.avg_entry_price || 0, {
          precision: pricePrecision,
        }).format(),
      },
      {
        title: t("mark_price"),
        data: currency(markPrice || 0, {
          precision: pricePrecision,
        }).format(),
      },
    ];

    return items;
  }, [position, t, asset, pricePrecision, markPrice]);

  const { setShowConnectModal } = useContext(ConnectWalletContext);

  const confirmationButtons: IConfirmationButton[] = useMemo(() => {
    const buttons: IConfirmationButton[] = [];
    if (accountSigningKeyState !== AccountStateEnum.OK) {
      buttons.push({
        title: t("complete_sign_in"),
        onClick: () => setShowConnectModal(true),
        theme: ButtonThemeEnum.HIGHLIGHT,
      });
    } else {
      buttons.push({
        title: t("confirm"),
        disabled:
          Boolean(errors?.tpTriggerPrice?.type) ||
          Boolean(errors?.slTriggerPrice?.type) ||
          Boolean(errors?.tpRoi?.type) ||
          Boolean(errors?.slRoi?.type),
        onClick: onConfirmTPSLForPosition,
      });
    }

    return buttons;
  }, [
    accountSigningKeyState,
    errors?.slRoi?.type,
    errors?.slTriggerPrice?.type,
    errors?.tpRoi?.type,
    errors?.tpTriggerPrice?.type,
    onConfirmTPSLForPosition,
    setShowConnectModal,
    t,
  ]);

  const triggerPriceValidateFn = useCallback(
    (isTakeProfit: boolean) => {
      // If BUY STOP LOSS or SELL TAKE PROFIT, trigger price must be higher than mark
      if (
        (position?.side === SideResponse.Buy && isTakeProfit) ||
        (position?.side === SideResponse.Sell && !isTakeProfit)
      ) {
        return {
          [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
          [FormValidatorKeysEnum.triggerPriceAboveMark]: (v: string) =>
            !v || parseFloat(v) >= Number(markPrice || 0),
        };
      }
      // If SELL STOP LOSS or BUY TAKE PROFIT, trigger price must be lower than mark
      return {
        [FormValidatorKeysEnum.triggerPriceBelowMark]: (v: string) =>
          !v || parseFloat(v) <= Number(markPrice || 0),
        [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
      };
    },
    [markPrice, position?.side]
  );

  // Trigger updates when inputs change
  useEffect(() => {
    clearErrors();
    trigger(tpTriggerPriceName);
    trigger(slTriggerPriceName);
    trigger(tpRoiName);
    trigger(slRoiName);
  }, [
    trigger,
    tpTriggerPrice,
    slTriggerPrice,
    tpRoiName,
    slRoiName,
    clearErrors,
  ]);

  const resetTpInput = useCallback(() => {
    setValue(tpTriggerPriceName, undefined);
    setValue(tpRoiName, undefined);
    trigger(tpTriggerPriceName);
    trigger(tpRoiName);
  }, [setValue, trigger]);

  const resetSlInput = useCallback(() => {
    setValue(slTriggerPriceName, undefined);
    setValue(slRoiName, undefined);
    trigger(slTriggerPriceName);
    trigger(slRoiName);
  }, [setValue, trigger]);

  // Trigger updates when inputs change
  useEffect(() => {
    setValue(tpTriggerPriceName, undefined);
    setValue(tpRoiName, undefined);
  }, [setValue, tpType]);

  // Trigger updates when inputs change
  useEffect(() => {
    setValue(slTriggerPriceName, undefined);
    setValue(slRoiName, undefined);
  }, [setValue, slType]);

  // Whenever the modal is presented, reset state.
  useEffect(() => {
    if (position?.triggers?.take_profit?.trigger) {
      setValue(tpTriggerPriceName, position?.triggers?.take_profit.trigger);
    }
  }, [position?.triggers?.take_profit?.trigger, setValue]);

  // Whenever the modal is presented, reset state.
  useEffect(() => {
    if (position?.triggers?.stop_loss?.trigger) {
      setValue(slTriggerPriceName, position?.triggers?.stop_loss.trigger);
    }
  }, [position?.triggers?.stop_loss?.trigger, setValue]);

  useEffect(() => {
    const value = parseFloat(slRoi);
    if (!Number.isNaN(value) && value > 0) {
      // If the value is positive, set it to its negative counterpart
      setValue(slRoiName, -Math.abs(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slRoi]);

  const takeProfitExpectedPnl = useMemo(() => {
    if (!position?.avg_entry_price || !actualTpTriggerPrice) {
      return undefined;
    }
    return (
      calculatePnl(
        Number(position.avg_entry_price) || 0,
        Number(actualTpTriggerPrice) || 0,
        position.side
      ) * Number(position.amount)
    );
  }, [actualTpTriggerPrice, position]);

  const stopLossExpectedPnl = useMemo(() => {
    if (!position?.avg_entry_price || !actualSlTriggerPrice) {
      return undefined;
    }
    return (
      calculatePnl(
        Number(position.avg_entry_price) || 0,
        Number(actualSlTriggerPrice) || 0,
        position.side
      ) * Number(position.amount)
    );
  }, [actualSlTriggerPrice, position]);

  const takeProfitRegister = useMemo(() => {
    if (tpType === Tpsl.Roi) {
      return register(tpRoiName, {
        disabled: loading,
        required: false,
        validate: {
          [FormValidatorKeysEnum.roiTooHigh]: (v) =>
            position?.side !== SideResponse.Buy || !v || parseFloat(v) < 1000,
        },
      });
    }
    return register(tpTriggerPriceName, {
      disabled: loading,
      required: false,
      validate: {
        ...triggerPriceValidateFn(true),
        [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
          !v ||
          roundToStepSize(
            Number(v),
            contractPriceStep.price_step,
            contractPriceStep.price_precision
          ) >=
            1 / 10 ** contractPriceStep.price_precision,
      },
    });
  }, [
    contractPriceStep.price_precision,
    contractPriceStep.price_step,
    loading,
    position?.side,
    register,
    tpType,
    triggerPriceValidateFn,
  ]);

  const stopLossRegister = useMemo(() => {
    if (slType === Tpsl.Roi) {
      return register(slRoiName, {
        disabled: loading,
        required: false,
        validate: {
          [FormValidatorKeysEnum.roiTooLow]: (v) =>
            position?.side === SideResponse.Buy || !v || parseFloat(v) > -1000,
        },
      });
    }
    return register(slTriggerPriceName, {
      disabled: loading,
      required: false,
      validate: {
        ...triggerPriceValidateFn(false),
        [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
          !v ||
          roundToStepSize(
            Number(v),
            contractPriceStep.price_step,
            contractPriceStep.price_precision
          ) >=
            1 / 10 ** contractPriceStep.price_precision,
      },
    });
  }, [
    contractPriceStep.price_precision,
    contractPriceStep.price_step,
    loading,
    position?.side,
    register,
    slType,
    triggerPriceValidateFn,
  ]);

  const form = useMemo(() => {
    if (!position) {
      return undefined;
    }

    return (
      <>
        <Form
          style={{ padding: 0, marginBottom: SPACING.three }}
          onSubmit={handleSubmit(onConfirmTPSLForPosition)}
        >
          <TakeProfitInput
            key={tpType}
            placeholder={"0.00"}
            markPrice={currency(markPrice || 0, {
              precision: pricePrecision,
            }).format()}
            leftAccessory={
              <Dropdown
                onToggle={setTpDropdownOpen}
                dropdownMenuContainerStyles={{
                  overflow: "hidden",
                  backgroundColor: LAYER_COLORS.three,
                  textAlign: "center",
                }}
                title={
                  <DropdownTitle>
                    <span>{tpType}</span>
                    <Chevron
                      size="small"
                      direction={tpDropdownOpen ? "up" : "down"}
                    />
                  </DropdownTitle>
                }
                items={Object.values(Tpsl).map((v) => ({
                  label: (
                    <DropdownItem selected={v === tpType}>
                      <span>{v}</span>
                    </DropdownItem>
                  ),
                  onSelect: () => setTpType(v),
                }))}
              />
            }
            minPrice={contractPriceStep.price_step}
            register={takeProfitRegister}
            resetInput={resetTpInput}
            errors={errors}
          />
          <TextWrapper>
            {t("tp")} {t("desc_1")}{" "}
            <p style={{ color: TEXT_COLORS.one }}>{t("mark_price")}</p>{" "}
            {t("reaches")}{" "}
            <p style={{ color: TEXT_COLORS.one }}>
              {actualTpTriggerPrice
                ? currency(actualTpTriggerPrice, {
                    precision: pricePrecision,
                  }).format()
                : "- -"}
            </p>{" "}
            {t("desc_2")}{" "}
            <p
              style={{ color: getProfitTextColor(takeProfitExpectedPnl || 0) }}
            >
              {currency(takeProfitExpectedPnl || "0").format()}
            </p>
          </TextWrapper>
          <div style={{ marginTop: SPACING.three }}>
            <StopLossInput
              key={slType}
              placeholder={"0.00"}
              markPrice={currency(markPrice || 0, {
                precision: pricePrecision,
              }).format()}
              leftAccessory={
                <Dropdown
                  onToggle={setSlDropdownOpen}
                  dropdownMenuContainerStyles={{
                    overflow: "hidden",
                    backgroundColor: LAYER_COLORS.three,
                    textAlign: "center",
                  }}
                  title={
                    <DropdownTitle>
                      <span>{slType}</span>
                      <Chevron
                        size="small"
                        direction={slDropdownOpen ? "up" : "down"}
                      />
                    </DropdownTitle>
                  }
                  items={Object.values(Tpsl).map((v) => ({
                    label: (
                      <DropdownItem selected={v === slType}>
                        <span>{v}</span>
                      </DropdownItem>
                    ),
                    onSelect: () => setSlType(v),
                  }))}
                />
              }
              minPrice={contractPriceStep.price_step}
              register={stopLossRegister}
              resetInput={resetSlInput}
              errors={errors}
            />
          </div>
          <TextWrapper>
            {t("sl")} {t("desc_1")}{" "}
            <p style={{ color: TEXT_COLORS.one }}>{t("mark_price")}</p>{" "}
            {t("reaches")}{" "}
            <p style={{ color: TEXT_COLORS.one }}>
              {actualSlTriggerPrice
                ? currency(actualSlTriggerPrice, {
                    precision: pricePrecision,
                  }).format()
                : "- -"}
            </p>{" "}
            {t("desc_2")}{" "}
            <p style={{ color: getProfitTextColor(stopLossExpectedPnl || 0) }}>
              {currency(stopLossExpectedPnl || "0").format()}
            </p>
          </TextWrapper>
        </Form>
        {errorMessage && <ModalErrorMessage>{errorMessage}</ModalErrorMessage>}
      </>
    );
  }, [
    actualSlTriggerPrice,
    actualTpTriggerPrice,
    contractPriceStep.price_step,
    errorMessage,
    errors,
    handleSubmit,
    markPrice,
    onConfirmTPSLForPosition,
    position,
    pricePrecision,
    resetSlInput,
    resetTpInput,
    slDropdownOpen,
    slType,
    stopLossExpectedPnl,
    stopLossRegister,
    t,
    takeProfitExpectedPnl,
    takeProfitRegister,
    tpDropdownOpen,
    tpType,
  ]);

  if (!position) {
    return null;
  }

  return (
    <ConfirmationModal
      title={t("confirmation_modal_title")}
      primaryColor={COLORS.blue.one}
      style={{
        backgroundColor: BACKGROUND_COLORS.seven,
        width: 320,
      }}
      stats={stats}
      confirmationButtons={confirmationButtons}
      confirmationWrapperStyle={{ display: "flex" }}
      isLoading={loading}
      onHide={onHideModal}
      show={show}
      {...modalProps}
      extras={form}
      statsFirst={false}
    />
  );
}

export default TPSLForPositionModal;

import styled from "styled-components";
import { FONT_STYLE } from "../../../constants/design/fontSize";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";

export const OrderProtectionWrapper = styled.div`
  padding: ${SPACING.two}px;
  background-color: ${LAYER_COLORS.two};
  margin-top: ${SPACING.three}px;
  border-radius: 4px;

  > div {
    ${FONT_STYLE.label.two};
    margin-bottom: ${SPACING.two}px;
    color: ${COLORS.negative.one};
    display: flex;
    justify-content: space-between;

    svg {
      stroke: ${COLORS.negative.one};
      margin-left: ${SPACING.two}px;
    }
  }

  > p {
    ${FONT_STYLE.label.three};
    margin: 0;
    color: ${TEXT_COLORS.two};

    > span {
      color: ${TEXT_COLORS.one};
    }
  }
`;

export const OrderProtectionTitle = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

/* eslint-disable no-nested-ternary */
import { useMemo } from "react";
import currency from "currency.js";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  GetAccount200ResponsePositionsInner,
  SideResponse,
} from "../../codegen-api";
import { Spinner } from "../shared/Spinner";
import { StepRow } from "./style";
import { ReactComponent as Check } from "../../assets/svg/checkmark.svg";
import Tag from "./Tag";
import { COLORS } from "../../constants/design/colors";
import { nanosToSeconds } from "../../utils/date";
import { SPACING } from "../../constants/design/spacing";

interface IRFQProps {
  position: GetAccount200ResponsePositionsInner;
  hasRequest: boolean;
  hasQuote: boolean;
}

export function RFQ({ position, hasRequest, hasQuote }: IRFQProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.ClosePositionModal",
  });

  const tag = useMemo(
    () => ({
      texts: position.option
        ? [
            position.side === SideResponse.Buy ? t("long") : t("short"),
            position.option.option_type,
            moment
              .unix(nanosToSeconds(position.option.expiry))
              .format("DD MMM YY"),
            currency(position.option.strike, { precision: 0 }).format(),
            position.amount,
          ]
        : [
            position.side === SideResponse.Buy ? t("long") : t("short"),
            position.instrument_name,
            position.amount,
          ],
      color:
        position.side === SideResponse.Buy
          ? COLORS.positive.one
          : COLORS.negative.one,
      backgroundColor:
        position.side === SideResponse.Buy
          ? COLORS.positive.four
          : COLORS.negative.four,
    }),
    [position, t]
  );

  return (
    <div>
      <Tag
        style={{ margin: "auto", marginBottom: SPACING.three }}
        texts={tag.texts}
        color={tag.color}
        backgroundColor={tag.backgroundColor}
      />
      <StepRow isChecked={hasRequest}>
        <span>
          {hasRequest ? t("creating_request") : t("awaiting_request")}
        </span>
        <span>{hasRequest ? <Check /> : <Spinner />}</span>
      </StepRow>
      <StepRow isChecked={hasQuote && hasRequest ? true : !hasRequest}>
        <span>{t("awaiting_quotation")}</span>
        <span>{hasQuote ? <Check /> : hasRequest ? <Spinner /> : null}</span>
      </StepRow>
    </div>
  );
}

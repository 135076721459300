import { useEffect, useState } from "react";
import { IToast } from "../../../interfaces/Toast";
import { ToastEnum, ToastStatusEnum } from "../../../utils/toast";
import {
  ButtonDivider,
  CloseButton,
  Header,
  StatLabel,
  SubheaderWrapper,
  ToastHeaderWrapper,
  ToastInfoWrapper,
  ToastWrapper,
} from "./style";

import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import success from "../../../assets/svg/success.svg";
import error from "../../../assets/svg/error.svg";
import { useToast } from "../../../hooks/toast";

interface ISingleToastProps {
  toast: IToast;
}

function SingleToast({ toast }: ISingleToastProps) {
  const { removeToast } = useToast();
  const [preserve, setPreserve] = useState(false);

  // Toast is responsible for removing itself
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!preserve) {
        removeToast(toast.hash);
      }
    }, toast.interval || 5000);

    return () => clearTimeout(timeout);
  }, [removeToast, toast, preserve]);

  return (
    <ToastWrapper
      key={toast.hash}
      onMouseEnter={() => setPreserve(true)}
      onMouseLeave={() => setPreserve(false)}
      initial={{
        opacity: 0,
      }}
      animate={{
        x: [100, 0, 0, 0],
        opacity: [0, 1, 1, 1],
      }}
      exit={{
        transition: {
          duration: 0.5,
        },
        x: toast.interval ? 100 : 0,
        opacity: toast.interval ? 0 : 1,
      }}
      transition={{
        duration: 1,
      }}
    >
      <ToastHeaderWrapper>
        <div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {toast.icon ? (
            typeof toast.icon === "string"
              ? <img src={toast.icon} alt={toast.hash} />
              : toast.icon
          ) : (
            <img
              src={
                toast.status === ToastStatusEnum.SUCCESS ? success : error
              }
              alt={toast.hash}
            />
          )}
          <Header>
            {toast.header}
            <SubheaderWrapper>
              {toast.subheader}
            </SubheaderWrapper>
          </Header>
        </div>
        <ButtonDivider />
        <div>
          <CloseButton onClick={() => removeToast(toast.hash)}>
            <Close />
          </CloseButton>
        </div>
      </ToastHeaderWrapper>
      {(toast.type === ToastEnum.INFO && Boolean(toast.stats.length)) && (
        <ToastInfoWrapper>
          {toast.stats.map((stat) => (
            <div key={`${stat.label}-${stat.value}`}>
              <StatLabel>{stat.label}</StatLabel>
              <span>{stat.value}</span>
            </div>
          ))}
        </ToastInfoWrapper>
      )}
    </ToastWrapper>
  );
}

export default SingleToast;

import { useMemo } from "react";
import currency from "currency.js";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { OptionTypeResponse } from "../../../codegen-api";
import MarketIcon from "./MarketIcon";
import {
  MarketCellContainer,
  MarketCellContent,
  Separator,
  SettlementInProgress,
} from "./style";
import { nanosToSeconds } from "../../../utils/date";

// PERPS and OPTION type
type IMarketCellProps = {
  instrumentName?: string;
  showSettlementInProgress?: boolean;
} & (
  | {
      // PERPS
      optionType?: undefined;
      expiry?: undefined;
      strike?: undefined;
      leverage: number;
    }
  | {
      // OPTIONS
      optionType?: OptionTypeResponse;
      // Nanos
      expiry?: string;
      strike?: string;
      leverage?: undefined;
    }
);

function MarketCell({
  instrumentName,
  optionType,
  expiry,
  strike,
  showSettlementInProgress,
  leverage,
}: IMarketCellProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "PortfolioSettings.Table.MarketCell",
  });

  const content = useMemo(() => {
    if (
      optionType !== undefined &&
      expiry !== undefined &&
      strike !== undefined
    ) {
      return (
        <div>
          <MarketCellContent>
            {t(optionType)}
            <Separator />
            {moment.unix(nanosToSeconds(expiry)).format("DD MMM YY")}
            <Separator />
            {currency(strike, { precision: 0 }).format()}
          </MarketCellContent>
          {showSettlementInProgress &&
            moment.unix(nanosToSeconds(expiry)).isSameOrBefore(moment()) && (
              <SettlementInProgress>
                {t("settlement_in_progress")}
              </SettlementInProgress>
            )}
        </div>
      );
    }
    return (
      <div>
        <MarketCellContent>
          {instrumentName}
          {leverage ? (
            <>
              <Separator />
              {leverage}x
            </>
          ) : null}
        </MarketCellContent>
      </div>
    );
  }, [
    expiry,
    instrumentName,
    leverage,
    optionType,
    showSettlementInProgress,
    strike,
    t,
  ]);

  return (
    <MarketCellContainer>
      <MarketIcon instrumentName={instrumentName} />
      {content}
    </MarketCellContainer>
  );
}

export default MarketCell;

import currency from "currency.js";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { MIN_ORDER_VALUE } from "../../../constants/precision/form";
import { FormValidatorKeysEnum } from "../../../enums/form";
import { Input } from "../../shared/Input";
import { IInputProps } from "../form";
import { InputWrapper, Label, WarningLabel } from "../style";
import { SideResponse } from "../../../codegen-api";

interface IAmountInputProps extends IInputProps {
  style?: CSSProperties;
  minAmountSize: number;
  existingPositionSize?: string;
  existingOrderInfo?: {
    newOrderSizeLimit: string;
    side: SideResponse;
    size: string;
  };
}

export function SizeInput({
  register,
  errors,
  warning,
  style,
  customError,
  minAmountSize,
  existingPositionSize,
  existingOrderInfo,
}: IAmountInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.OptionsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", {
    keyPrefix: "amount",
  });
  const error = errors?.amount;
  const hasInputError = Boolean(error?.type);
  const hasError = hasInputError || Boolean(customError);

  return (
    <InputWrapper style={style}>
      <Label>{t("number_of_contracts")}</Label>
      <Input error={hasError} placeholder="0" {...register} />
      {error?.type === FormValidatorKeysEnum.required && (
        <WarningLabel>{formError("required")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.notEnoughBalance && (
        <WarningLabel>{formError("insufficientAvailableBalance")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.moreThanZero && (
        <WarningLabel>{formError("moreThanZero")}</WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>
          {formError("cannotBeLessThanSize", { size: minAmountSize })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.orderValueTooSmall && (
        <WarningLabel>
          {formError("totalValueMustBeOverSize", {
            size: currency(MIN_ORDER_VALUE).format(),
          })}
        </WarningLabel>
      )}
      {error?.type === FormValidatorKeysEnum.reduceOnlyOrderSizeValid && (
        <WarningLabel>
          {formError("reduce_size_invalid", { size: existingPositionSize })}
        </WarningLabel>
      )}
      {error?.type ===
        FormValidatorKeysEnum.reduceOnlyOrderSizeValidWithExistingOrder && (
        <WarningLabel>
          {existingOrderInfo?.side === SideResponse.Buy
            ? formError("reduce_size_invalid_existing_order_long", {
                newOrderSize: existingOrderInfo?.newOrderSizeLimit,
                existingOrderSize: existingOrderInfo?.size,
                positionSize: existingPositionSize,
              })
            : formError("reduce_size_invalid_existing_order_short", {
                newOrderSize: existingOrderInfo?.newOrderSizeLimit,
                existingOrderSize: existingOrderInfo?.size,
                positionSize: existingPositionSize,
              })}
        </WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
      {Boolean(!hasInputError && customError) && (
        <WarningLabel>{customError}</WarningLabel>
      )}
    </InputWrapper>
  );
}

interface IPriceInputProps extends IInputProps {
  minPrice: number;
}

export function PriceInput({
  register,
  errors,
  warning,
  minPrice,
}: IPriceInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.OptionsTradeForm.Form",
  });
  const { t: formError } = useTranslation("formErrors", { keyPrefix: "price" });
  const hasError = Boolean(errors?.price?.type);
  return (
    <InputWrapper>
      <Label>{t("price")}</Label>
      <Input error={hasError} placeholder="0" type="number" {...register} />
      {errors?.price?.type === FormValidatorKeysEnum.required && (
        <WarningLabel>{formError("required")}</WarningLabel>
      )}
      {errors?.price?.type === FormValidatorKeysEnum.moreThanZero && (
        <WarningLabel>{formError("moreThanZero")}</WarningLabel>
      )}
      {errors?.price?.type === FormValidatorKeysEnum.decimalsTooSmall && (
        <WarningLabel>
          {formError("cannotBeLessThanSize", { size: minPrice })}
        </WarningLabel>
      )}
      {Boolean(!Object.keys(errors).length && warning) && (
        <WarningLabel type="warning">{warning}</WarningLabel>
      )}
    </InputWrapper>
  );
}

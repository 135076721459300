import styled from "styled-components";
import { COLORS, LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const DropdownTitle = styled.div`
  ${FONT_CSS.label.two}
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    margin-right: ${SPACING.one}px;
  }
`;

export const DropdownItem = styled.div<{isSelected?: boolean}>`
  ${FONT_CSS.label.two}
  padding: ${SPACING.two}px;
  color: ${({ isSelected }) => (isSelected ? COLORS.blue.one : TEXT_COLORS.three)};
  background-color: ${LAYER_COLORS.four};

  &:hover {
    color: ${({ isSelected }) => (isSelected ? COLORS.blue.one : TEXT_COLORS.one)};
  }
`;

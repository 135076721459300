import currency from "currency.js";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { forwardRef, MutableRefObject, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OptionTypeResponse, SideResponse } from "../../../codegen-api";
import { COLORS, LAYER_COLORS } from "../../../constants/design/colors";
import usePrevious from "../../../hooks/usePrevious";
import { longhandTimeLeftToExpiry, nanosToSeconds } from "../../../utils/date";
import { Spinner } from "../../shared/Spinner";
import { Row as TableRow, StrikeColumn, StrikeHeader } from "../../shared/Table/style";
import TooltipExplanation from "../../shared/Tooltip";
import { Cell, OptionRow, OptionsRowWrapper, SideTableWrapper } from "../style";
import BidAskSizeCell from "./Cells/BidAskSizeCell";
import PositionCell from "./Cells/PositionCell";
import { IHeaderColumn, useOptionsChainTableHeaders } from "./headers";
import { DateHeader, DateHeaderWrapper, OptionsTableHeaderWrapper, OptionTypeArrow } from "./style";
import { formatSizePrecision } from "../../../utils/format";

export type OptionsChainTableMode = OptionTypeResponse | "both";

interface ITableHeaderProps {
  strikeWidth: number;
  expiry?: number;
  mode: OptionsChainTableMode;
}

interface ITableColumnProps {
  strikeWidth: number;
}

export interface ITickerWithInstrumentId {
  bid: {
    price?: string;
    volume?: string;
    iv?: string;
    // if user have any existing order
    orderVolume: number;
  };
  ask: {
    price?: string;
    volume?: string;
    iv?: string;
    // if user have any existing order
    orderVolume: number;
  };
  instrumentName: string;
  instrumentId: number;
  position: number;
  side: SideResponse;
  delta?: string;
  iv?: string;
  markPrice: string;
  open_interest: string;
}

export interface ITableContentRow {
  strike: string;
  ticker: ITickerWithInstrumentId;
  expiry: number;
}

export interface ITableContentRowWithCallPut extends Omit<ITableContentRow, "ticker"> {
  strike: string;
  // A row of a given strike might not have both call and put options.
  call: ITickerWithInstrumentId;
  put: ITickerWithInstrumentId;
  expiry: number;
}

export interface IOptionVolume {
  calls: {
    bids: number;
    asks: number;
  };
  puts: {
    bids: number;
    asks: number;
  };
}

// ====================
//    TABLE CONTENT
// ====================

export function TableHeader({ strikeWidth, expiry, mode }: ITableHeaderProps) {
  const sideTableWidth: string = mode === "both" ? `calc((100% - ${strikeWidth}px)/2)` : "100%";

  const { t } = useTranslation("app", { keyPrefix: "OptionsChain.OptionsTable.OptionsTable" });
  const { t: translateAll } = useTranslation();
  const { t: tooltip } = useTranslation("tooltips");
  const { bidHeaders, headers } = useOptionsChainTableHeaders();
  const [displayText, setDisplayText] = useState<string>();

  const getTimeLeft = useCallback((expNanos: number) => {
    const exp = moment.unix(nanosToSeconds(expNanos));
    const duration = moment.duration(exp.diff(new Date()));

    if (duration.days() < 1) {
      return longhandTimeLeftToExpiry(translateAll, nanosToSeconds(expNanos)) || "Expired";
    }
    return `${exp.diff(new Date(), "days")} ${exp.diff(new Date(), "days") > 1 ? t("days") : t("day")}`;
  }, [t, translateAll]);

  // Updates time left every 5 seconds
  useEffect(() => {
    if (expiry) {
      const timeLeft = getTimeLeft(expiry);
      setDisplayText(timeLeft);

      const timer = setInterval(() => {
        setDisplayText(() => getTimeLeft(expiry));
      }, 5000);
      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, [expiry, getTimeLeft]);

  return (
    <OptionsTableHeaderWrapper>
      <DateHeaderWrapper>
        {mode !== "both" && (
          <StrikeHeader
            width={strikeWidth}
            style={{
              backgroundColor: LAYER_COLORS.highlightLight,
            }}
          >
            <AnimatePresence exitBeforeEnter>
              <motion.span
                key={displayText}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                }}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                {displayText || <Spinner />}
              </motion.span>
            </AnimatePresence>
          </StrikeHeader>
        )}
        {(mode === "both" || mode === "call") && (
          <DateHeader width={sideTableWidth}>
            <span>
              {t("calls")} <OptionTypeArrow type={OptionTypeResponse.Call} />
            </span>
          </DateHeader>
        )}
        {mode === "both" && (
          <StrikeHeader
            width={strikeWidth}
            style={{
              backgroundColor: LAYER_COLORS.highlightLight,
            }}
          >
            <AnimatePresence exitBeforeEnter>
              <motion.span
                key={displayText}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                }}
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                {displayText || <Spinner />}
              </motion.span>
            </AnimatePresence>
          </StrikeHeader>
        )}
        {(mode === "both" || mode === "put") && (
          <DateHeader width={sideTableWidth}>
            <span>
              {t("puts")} <OptionTypeArrow type={OptionTypeResponse.Put} />
            </span>
          </DateHeader>
        )}
      </DateHeaderWrapper>
      <TableRow>
        {mode === "both" ? (
          <>
            <SideTableWrapper width={sideTableWidth}>
              {Object.values(bidHeaders).map((header) => (
                <TooltipExplanation
                  title={header.label}
                  explanation={header.explanation}
                  key={header.label}
                  renderContent={({ ref, ...triggerHandler }) => (
                    <Cell ref={ref} align={header.align} {...triggerHandler}>
                      {header.label}
                    </Cell>
                  )}
                />
              ))}
              {Object.values(headers).map((header) => (
                <TooltipExplanation
                  title={header.label}
                  explanation={header.explanation}
                  key={header.label}
                  renderContent={({ ref, ...triggerHandler }) => (
                    <Cell ref={ref} align={header.align} {...triggerHandler}>
                      {header.label}
                    </Cell>
                  )}
                />
              ))}
            </SideTableWrapper>
            <StrikeHeader width={strikeWidth}>
              <TooltipExplanation
                title={t("strike")}
                explanation={tooltip("strike")}
                renderContent={({ ref, ...triggerHandler }) => (
                  <span ref={ref} {...triggerHandler}>
                    {t("strike")}
                  </span>
                )}
              />
            </StrikeHeader>
            <SideTableWrapper width={sideTableWidth}>
              {Object.values(headers)
                .reverse()
                .map((header) => (
                  <TooltipExplanation
                    title={header.label}
                    explanation={header.explanation}
                    key={header.label}
                    renderContent={({ ref, ...triggerHandler }) => (
                      <Cell ref={ref} align={header.align} {...triggerHandler}>
                        {header.label}
                      </Cell>
                    )}
                  />
                ))}
              {Object.values(bidHeaders).map((header) => (
                <TooltipExplanation
                  title={header.label}
                  explanation={header.explanation}
                  key={header.label}
                  renderContent={({ ref, ...triggerHandler }) => (
                    <Cell ref={ref} align={header.align} {...triggerHandler}>
                      {header.label}
                    </Cell>
                  )}
                />
              ))}
            </SideTableWrapper>
          </>
        ) : (
          <>
            <TooltipExplanation
              title={t("strike")}
              explanation={tooltip("strike")}
              renderContent={({ ref, ...triggerHandler }) => (
                <StrikeHeader width={strikeWidth} ref={ref} {...triggerHandler}>
                  <span>{t("strike")}</span>
                </StrikeHeader>
              )}
            />
            <SideTableWrapper width={sideTableWidth}>
              {/* Position */}
              {headers.position && (
                <TooltipExplanation
                  key={headers.position.label}
                  title={headers.position.label}
                  explanation={headers.position.explanation}
                  renderContent={({ ref, ...triggerHandler }) => (
                    <Cell align={headers.position!.align} ref={ref} {...triggerHandler}>
                      {headers.position!.label}
                    </Cell>
                  )}
                />
              )}

              {Object.values(bidHeaders).map((header) => (
                <TooltipExplanation
                  key={header.label}
                  title={header.label}
                  explanation={header.explanation}
                  renderContent={({ ref, ...triggerHandler }) => (
                    <Cell align={header.align} ref={ref} {...triggerHandler}>
                      {header.label}
                    </Cell>
                  )}
                />
              ))}
              {Object.keys(headers)
                .filter((v) => v !== "position")
                .map((key) => {
                  const header: IHeaderColumn = headers[key as keyof typeof headers]!;
                  return (
                    <TooltipExplanation
                      key={header.label}
                      title={header.label}
                      explanation={header.explanation}
                      renderContent={({ ref, ...triggerHandler }) => (
                        <Cell align={header.align} ref={ref} {...triggerHandler}>
                          {header.label}
                        </Cell>
                      )}
                    />
                  );
                })}
            </SideTableWrapper>
          </>
        )}
      </TableRow>
    </OptionsTableHeaderWrapper>
  );
}

// ====================
//    TABLE CONTENT
// ====================

interface IRowProps {
  row: ITableContentRowWithCallPut;
  onTrade: (orderType: OptionTypeResponse, expiry: number, strike: string) => void;
  totalCallsPutsSize: IOptionVolume;
  sideTableWidth: string;
  strikeWidth: number;
  activeInstrumentId: string | undefined;
  mode: OptionsChainTableMode;
  decimals: number;
}

const Row = forwardRef<any, IRowProps>(
  ({ row, onTrade, totalCallsPutsSize, sideTableWidth, strikeWidth, activeInstrumentId, mode, decimals }, ref) => {
    const { bidHeaders, headers } = useOptionsChainTableHeaders();

    // Store ref to previous prop
    const prevRow = usePrevious(row);
    const needsAnimation = useMemo(() => {
      const defaultValues = {
        call: {
          bid: false,
          ask: false,
        },
        put: {
          bid: false,
          ask: false,
        },
      };
      if (prevRow) {
        return {
          call: {
            bid: Number(prevRow.call.bid.volume) !== Number(row.call.bid.volume),
            ask: Number(prevRow.call.ask.volume) !== Number(row.call.ask.volume),
          },
          put: {
            bid: Number(prevRow.put.bid.volume) !== Number(row.put.bid.volume),
            ask: Number(prevRow.put.ask.volume) !== Number(row.put.ask.volume),
          },
        };
      }
      return defaultValues;
    }, [prevRow, row]);

    const activeCellType: OptionTypeResponse | undefined = useMemo(() => {
      if (activeInstrumentId) {
        if (activeInstrumentId === row.call.instrumentName) {
          return OptionTypeResponse.Call;
        }
        if (activeInstrumentId === row.put.instrumentName) {
          return OptionTypeResponse.Put;
        }
      }
      return undefined;
    }, [activeInstrumentId, row]);

    const computeIV = useCallback(
      (type: OptionTypeResponse, side: SideResponse): string => {
        const bidAsk = side === SideResponse.Buy ? "bid" : "ask";
        if (Number(row[type][bidAsk]?.iv) !== 0) {
          return `${(Number(row[type][bidAsk]?.iv) * 100).toFixed(2)}%`;
        }

        return "-";
      },
      [row]
    );

    if (mode === "both") {
      const callOI = Number(row.call.open_interest) ? formatSizePrecision(row.call.open_interest, decimals) : "-";
      const putOI = Number(row.put.open_interest) ? formatSizePrecision(row.put.open_interest, decimals) : "-";
      return (
        <OptionsRowWrapper ref={ref}>
          <>
            <OptionRow
              disabled={!row.call.instrumentName}
              width={sideTableWidth}
              onClick={
                row.call.instrumentName ? () => onTrade(OptionTypeResponse.Call, row.expiry, row.strike) : undefined
              }
              isActive={activeCellType === OptionTypeResponse.Call}
              side={"left"}
            >
              <BidAskSizeCell
                orderVolume={row.call.bid.orderVolume}
                totalVolume={totalCallsPutsSize.calls.bids}
                volume={Number(row.call.bid.volume)}
                needsAnimation={needsAnimation.call.bid}
                side={SideResponse.Buy}
                align={bidHeaders.bidSize.align}
              />
              {bidHeaders.priceIvBid && (
                <Cell dim align={bidHeaders.priceIvBid.align}>
                  <Cell style={{ color: COLORS.positive.one }} align={bidHeaders.priceIvBid.align}>
                    {row.call.bid.price && Number(row.call.bid.price) ? currency(row.call.bid.price).format() : "-"}
                  </Cell>
                  {computeIV(OptionTypeResponse.Call, SideResponse.Buy)}
                </Cell>
              )}
              {bidHeaders.markPrice && (
                <Cell>
                  <Cell align={bidHeaders.markPrice.align}>
                    {Number(row.call.markPrice) > 0.01
                      ? currency(Number(row.call.markPrice)).format({ precision: 2 })
                      : "-"}
                  </Cell>
                  <Cell dim align={bidHeaders.markPrice.align} fontSize="small">
                    {Number(row.call.markPrice) > 0.01 ? `${(Number(row.call.iv) * 100).toFixed(2)}%` : "-"}
                  </Cell>
                </Cell>
              )}
              {bidHeaders.priceIvAsk && (
                <Cell dim align={bidHeaders.priceIvAsk.align}>
                  <Cell style={{ color: COLORS.negative.one }} align={bidHeaders.priceIvAsk.align}>
                    {row.call.ask.price && Number(row.call.ask.price) ? currency(row.call.ask.price).format() : "-"}
                  </Cell>
                  {computeIV(OptionTypeResponse.Call, SideResponse.Sell)}
                </Cell>
              )}
              <BidAskSizeCell
                orderVolume={row.call.ask.orderVolume}
                totalVolume={totalCallsPutsSize.calls.asks}
                volume={Number(row.call.ask.volume)}
                needsAnimation={needsAnimation.call.ask}
                side={SideResponse.Sell}
                align={bidHeaders.askSize.align}
              />
              {headers.delta && (
                <Cell dim align={headers.delta.align}>
                  {row.call.delta && Number(row.call.delta).toFixed(2)}
                </Cell>
              )}
              {headers.oi && (
                <Cell dim align={headers.oi.align}>
                  {callOI}
                </Cell>
              )}
              {headers.position && (
                <PositionCell align={headers.position.align} position={row.call.position} side={row.call.side} />
              )}
            </OptionRow>
            <StrikeColumn
              width={strikeWidth}
              activeBorderSide={activeCellType && (activeCellType === OptionTypeResponse.Call ? "right" : "left")}
            >
              <span>{row.strike ? currency(row.strike, { precision: 0 }).format() : "-"}</span>
            </StrikeColumn>
            <OptionRow
              disabled={!row.put.instrumentName}
              width={sideTableWidth}
              onClick={
                row.put.instrumentName ? () => onTrade(OptionTypeResponse.Put, row.expiry, row.strike) : undefined
              }
              isActive={activeCellType === OptionTypeResponse.Put}
              side={"right"}
            >
              {headers.position && (
                <PositionCell align={headers.position.align} position={row.put.position} side={row.put.side} />
              )}
              {headers.oi && (
                <Cell dim align={headers.oi.align}>
                  {putOI}
                </Cell>
              )}
              {headers.delta && (
                <Cell dim align={headers.delta.align}>
                  {row.put.delta && Number(row.put.delta).toFixed(2)}
                </Cell>
              )}
              <BidAskSizeCell
                orderVolume={row.put.bid.orderVolume}
                totalVolume={totalCallsPutsSize.puts.bids}
                volume={Number(row.put.bid.volume)}
                needsAnimation={needsAnimation.put.bid}
                side={SideResponse.Buy}
                align={bidHeaders.bidSize.align}
              />
              {bidHeaders.priceIvBid && (
                <Cell dim align={bidHeaders.priceIvBid.align}>
                  <Cell style={{ color: COLORS.positive.one }} align={bidHeaders.priceIvBid.align}>
                    {row.put.bid.price && Number(row.put.bid.price) ? currency(row.put.bid.price).format() : "-"}
                  </Cell>
                  {computeIV(OptionTypeResponse.Put, SideResponse.Buy)}
                </Cell>
              )}
              {bidHeaders.markPrice && (
                <Cell>
                  <Cell align={bidHeaders.markPrice.align}>
                    {Number(row.put.markPrice) > 0 ? currency(Number(row.put.markPrice)).format({ precision: 2 }) : "-"}
                  </Cell>
                  <Cell dim align={bidHeaders.markPrice.align} fontSize="small">
                    {Number(row.put.iv) > 0 ? `${(Number(row.put.iv) * 100).toFixed(2)}%` : "-"}
                  </Cell>
                </Cell>
              )}
              {bidHeaders.priceIvAsk && (
                <Cell dim align={bidHeaders.priceIvAsk.align}>
                  <Cell style={{ color: COLORS.negative.one }} align={bidHeaders.priceIvAsk.align}>
                    {row.put.ask.price && Number(row.put.ask.price) ? currency(row.put.ask.price).format() : "-"}
                  </Cell>
                  {computeIV(OptionTypeResponse.Put, SideResponse.Sell)}
                </Cell>
              )}
              <BidAskSizeCell
                orderVolume={row.put.ask.orderVolume}
                totalVolume={totalCallsPutsSize.puts.asks}
                volume={Number(row.put.ask.volume)}
                needsAnimation={needsAnimation.put.ask}
                side={SideResponse.Sell}
                align={bidHeaders.askSize.align}
              />
            </OptionRow>
          </>
        </OptionsRowWrapper>
      );
    }

    const rowInfo = row[mode];
    const needsAnimationMode = needsAnimation[mode];
    const totalVolumeMode = mode === "call" ? totalCallsPutsSize.calls : totalCallsPutsSize.puts;

    return (
      <OptionsRowWrapper ref={ref}>
        <>
          <StrikeColumn width={strikeWidth} activeBorderSide={activeCellType && "left"}>
            <span>{row.strike ? currency(row.strike, { precision: 0 }).format() : "-"}</span>
          </StrikeColumn>
          <OptionRow
            disabled={!rowInfo.instrumentName}
            width={sideTableWidth}
            onClick={rowInfo.instrumentName ? () => onTrade(mode, row.expiry, row.strike) : undefined}
            isActive={!!activeCellType}
            side={"right"}
          >
            {headers.position && (
              <PositionCell align={headers.position?.align} position={rowInfo.position} side={rowInfo.side} />
            )}
            <BidAskSizeCell
              orderVolume={rowInfo.bid.orderVolume}
              totalVolume={totalVolumeMode.bids}
              volume={Number(rowInfo.bid.volume)}
              needsAnimation={needsAnimationMode.bid}
              side={SideResponse.Buy}
              align={bidHeaders.bidSize.align}
            />
            {bidHeaders.priceIvBid && (
              <Cell dim align={bidHeaders.priceIvBid.align}>
                <Cell style={{ color: COLORS.positive.one }} align={bidHeaders.priceIvBid.align}>
                  {rowInfo.bid.price && Number(rowInfo.bid.price) ? currency(rowInfo.bid.price).format() : "-"}
                </Cell>
                {computeIV(mode, SideResponse.Buy)}
              </Cell>
            )}
            {bidHeaders.markPrice && (
              <Cell>
                <Cell align={bidHeaders.markPrice.align}>
                  {Number(rowInfo.markPrice) > 0.01
                    ? currency(Number(rowInfo.markPrice)).format({ precision: 2 })
                    : "-"}
                </Cell>
                <Cell dim fontSize="small" align={bidHeaders.markPrice.align}>
                  {Number(rowInfo.markPrice) > 0.01 ? `${(Number(rowInfo.iv) * 100).toFixed(2)}%` : "-"}
                </Cell>
              </Cell>
            )}
            {bidHeaders.priceIvAsk && (
              <Cell dim align={bidHeaders.priceIvAsk.align}>
                <Cell style={{ color: COLORS.negative.one }} align={bidHeaders.priceIvAsk.align}>
                  {rowInfo.ask.price && Number(rowInfo.ask.price) ? currency(rowInfo.ask.price).format() : "-"}
                </Cell>
                {computeIV(mode, SideResponse.Sell)}
              </Cell>
            )}
            <BidAskSizeCell
              orderVolume={rowInfo.ask.orderVolume}
              totalVolume={totalVolumeMode.asks}
              volume={Number(rowInfo.ask.volume)}
              needsAnimation={needsAnimationMode.ask}
              side={SideResponse.Sell}
              align={bidHeaders.askSize.align}
            />
            {headers.delta && (
              <Cell dim align={headers.delta.align}>
                {Number(rowInfo.delta) !== 0 && Number(rowInfo.delta).toFixed(2)}
              </Cell>
            )}
            {headers.oi && (
              <Cell dim align={headers.oi.align}>
                {Number(rowInfo.open_interest)
                  ? formatSizePrecision(Number(rowInfo.open_interest || 0), decimals)
                  : "-"}
              </Cell>
            )}
          </OptionRow>
        </>
      </OptionsRowWrapper>
    );
  }
);

interface ITableContentProps extends ITableColumnProps {
  rows: ITableContentRowWithCallPut[];
  rowRefs: MutableRefObject<HTMLDivElement[]>;

  // Total size of ALL available options
  totalCallsPutsSize: IOptionVolume;
  onTrade: (orderType: OptionTypeResponse, expiry: number, strike: string) => void;
  activeInstrumentId: string | undefined;
  mode: OptionsChainTableMode;
  decimals: number;
}
export function TableContent({
  strikeWidth,
  rows,
  rowRefs,
  totalCallsPutsSize,
  onTrade,
  activeInstrumentId,
  mode,
  decimals,
}: ITableContentProps) {
  const sideTableWidth: string = mode === "both" ? `calc((100% - ${strikeWidth}px)/2)` : "100%";

  const assignRef = useCallback(
    (ref: HTMLDivElement, index: number) => {
      // eslint-disable-next-line no-param-reassign
      rowRefs.current[index] = ref;
    },
    [rowRefs]
  );

  return (
    <div>
      {rows.map((row, index) => (
        <Row
          // eslint-disable-next-line no-param-reassign
          ref={(ref) => assignRef(ref, index)}
          decimals={decimals}
          activeInstrumentId={activeInstrumentId}
          key={row.strike || `placeholder-${index}`}
          row={row}
          mode={mode}
          onTrade={onTrade}
          sideTableWidth={sideTableWidth}
          totalCallsPutsSize={totalCallsPutsSize}
          strikeWidth={strikeWidth}
        />
      ))}
    </div>
  );
}

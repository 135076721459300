import currency from "currency.js";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationModal from ".";
import { SideResponse, GetOrders200Response } from "../../codegen-api";
import { BACKGROUND_COLORS, COLORS } from "../../constants/design/colors";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useOrder } from "../../hooks/api/order/useOrder";
import { useToast } from "../../hooks/toast";
import { useSFX } from "../../hooks/useSFX";
import { nanosToSeconds } from "../../utils/date";
import { formatSizePrecision } from "../../utils/format";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { IBaseModalProps } from "../BaseModal";
import { Chip } from "../shared/Chip";
import { Spinner } from "../shared/Spinner";
import { FooterContainer, StatContainer } from "./style";
import { getAssetFromSymbol } from "../../utils/instruments";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";

interface IRemoveOrderModalProps extends IBaseModalProps {
  order?: GetOrders200Response;
}

/**
 * The modal responsible for removing orders
 */
function RemoveOrderModal({
  order,
  onHide,
  show,
  ...modalProps
}: IRemoveOrderModalProps) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { playSound } = useSFX();
  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.RemoveOrderModal",
  });
  const { addToast } = useToast();
  const { removeOrder } = useOrder();
  const { data: accountData } = useGetAccount();
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const { t: apiError } = useTranslation("apiErrors");

  const asset = useMemo(
    () => getAssetFromSymbol(order?.instrument_name || ""),
    [order?.instrument_name]
  );

  // Whenever the modal is presented, reset state.
  useEffect(() => {
    if (show) {
      setErrorMessage(undefined);
    }
  }, [show, onHide]);

  const onRemoveOrder = useCallback(async () => {
    if (!order) {
      return;
    }

    try {
      setErrorMessage(undefined);
      setLoading(true);
      await removeOrder(order.order_id);
      playSound("order_cancelled");
      onHide?.();
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: t("order_edited_header"),
          subheader: t("order_edited_subheader"),
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    } catch (error: any) {
      setErrorMessage(apiError(error.message) || t("remove_order_error"));
    } finally {
      setLoading(false);
    }
  }, [addToast, apiError, onHide, order, playSound, removeOrder, t]);

  const currentPosition = useMemo(() => {
    if (!accountData) {
      return undefined;
    }
    return (accountData.positions || []).find(
      (pos) => pos.instrument_id === order?.instrument_id
    );
  }, [accountData, order?.instrument_id]);

  const stats = useMemo(() => {
    if (!order) {
      return [];
    }

    const items = [
      {
        title: t("bid_price"),
        data: currency(order.price, {
          precision: getMarketPrecision(asset, order.instrument_type)
            .price_precision,
        }).format(),
      },
      {
        title: t("contracts"),
        data: order.close_position
          ? t("close_position")
          : Number(order.amount).toFixed(2),
      },
      {
        title: t("total"),
        data: currency(Number(order.amount) * Number(order.price), {
          precision: getMarketPrecision(asset, order.instrument_type)
            .price_precision,
        }).format(),
      },
    ];

    if (order.side === SideResponse.Sell) {
      items.push({
        title: t("premium_earned"),
        data: currency(Number(order.amount) * Number(order.price), {
          precision: getMarketPrecision(asset, order.instrument_type)
            .price_precision,
        }).format(),
      });

      if (order.initial_margin) {
        items.push({
          title: t("margin_required"),
          data: currency(order.initial_margin).format(),
        });
      }
    }

    return items;
  }, [asset, getMarketPrecision, order, t]);

  if (!order) {
    return null;
  }

  return (
    <ConfirmationModal
      title={
        order.side === SideResponse.Sell ? t("remove_offer") : t("remove_bid")
      }
      primaryColor={COLORS.blue.one}
      style={{
        backgroundColor: BACKGROUND_COLORS.seven,
      }}
      tag={{
        texts:
          order.expiry && order.strike
            ? [
                order.side === SideResponse.Buy ? t("buy") : t("sell"),
                order.order_type,
                moment.unix(nanosToSeconds(order.expiry)).format("DD MMM YY"),
                currency(order.strike, { precision: 0 }).format(),
              ]
            : [
                order.side === SideResponse.Buy ? t("buy") : t("sell"),
                order.order_type,
                order.instrument_name,
              ],
        color:
          order.side === SideResponse.Buy
            ? COLORS.positive.one
            : COLORS.negative.one,
        backgroundColor:
          order.side === SideResponse.Buy
            ? COLORS.positive.five
            : COLORS.negative.five,
      }}
      stats={stats}
      confirmationButtons={[
        {
          // eslint-disable-next-line no-nested-ternary
          title: loading ? (
            <Spinner />
          ) : order.side === SideResponse.Sell ? (
            t("remove_offer")
          ) : (
            t("remove_bid")
          ),
          onClick: onRemoveOrder,
        },
      ]}
      isLoading={loading}
      onHide={onHide}
      show={show}
      error={errorMessage}
      extras={
        <FooterContainer>
          <StatContainer>
            <span>
              {t("position")}
              {currentPosition && (
                <Chip
                  theme={
                    currentPosition.side === SideResponse.Buy ? "green" : "red"
                  }
                  value={
                    currentPosition.side === SideResponse.Buy
                      ? t("long")
                      : t("short")
                  }
                />
              )}
            </span>
            <span>
              {currentPosition
                ? formatSizePrecision(currentPosition.amount)
                : "---"}
            </span>
          </StatContainer>
        </FooterContainer>
      }
      {...modalProps}
    />
  );
}

export default RemoveOrderModal;

import { useContext, useEffect, useState } from "react";
import { InstrumentTypeResponse } from "../../codegen-api";
import { WebsocketContext } from "../../contexts/WebsocketAuthContext";
import { jsonParse, jsonStringify } from "../../utils/strings";
import { IWSSMarkPriceResponse, IWSSMark } from "./model/markPrice";
import { WebsocketChannelEnum } from "./model/shared";
import { AssetResponse } from "../../utils/asset";

export type InstrumentToMark = {
  [key: string]: IWSSMark;
};

export type IMultiMarkPriceAssetDerivative = {
  [instrumentName: string]: {
    asset: AssetResponse;
    derivative: InstrumentTypeResponse;
  };
};

const getMarkPriceChannel = (tickers: IMultiMarkPriceAssetDerivative) =>
  Object.values(tickers).map(
    ({ asset, derivative }) =>
      `${WebsocketChannelEnum.MARK_PRICE}:${asset}:${derivative}`
  );

const useMarkPriceWSS = (tickers: IMultiMarkPriceAssetDerivative) => {
  const { triggerSubscribe, lastMessages } = useContext(WebsocketContext);

  const [instrumentMark, setInstrumentMark] = useState<InstrumentToMark>({});

  useEffect(() => {
    if (!Object.values(tickers).length) {
      return;
    }

    const data = getMarkPriceChannel(tickers);
    triggerSubscribe("subscribe", data, jsonStringify(data));
  }, [tickers, triggerSubscribe]);

  // Receives messages and updates state
  useEffect(() => {
    if (lastMessages && Object.values(tickers).length) {
      lastMessages.forEach((lastMessage) => {
        setInstrumentMark((prev) => {
          const { data, channel }: IWSSMarkPriceResponse = jsonParse(
            lastMessage.data
          );

          const markPriceChannels = getMarkPriceChannel(tickers);

          if (
            markPriceChannels.includes(channel) &&
            data &&
            data.prices?.length
          ) {
            const prices = data.prices.reduce(
              (prevPrices, curr) => ({
                ...prevPrices,
                [curr.instrument_name]: curr,
              }),
              {} as any
            );

            if (data) {
              return {
                ...prev,
                ...prices,
              };
            }
          }
          return prev;
        });
      });
    }
  }, [lastMessages, tickers]);

  return {
    instrumentMark,
  };
};

export default useMarkPriceWSS;

import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { InstrumentTypeResponse } from "../../../codegen-api";
import { WebsocketContext } from "../../../contexts/WebsocketAuthContext";
import { nanosToSeconds } from "../../../utils/date";
import { getExpiryFromSymbol } from "../../../utils/instruments";
import { jsonParse } from "../../../utils/strings";
import { useGetOptionsChain } from "../../api/optionsChain/useGetOptionsChain";
import { WebsocketChannelEnum } from "../model/shared";
import {
  IWSSTicker,
  IWSSTickerResponse
} from "../model/ticker";
import { AssetResponse } from "../../../utils/asset";

export type InstrumentToTicker = {
  [key: string]: IWSSTicker;
};

export const getTickerChannel = (
  asset: AssetResponse,
  derivative: InstrumentTypeResponse
) => `${WebsocketChannelEnum.TICKER}:${asset}:${derivative}`;

const useTickersWSS = (
  asset?: AssetResponse,
  derivative?: InstrumentTypeResponse,
  expiry?: number
) => {
  const { triggerSubscribe, lastMessages } = useContext(WebsocketContext);
  const { data: optionsChainData } = useGetOptionsChain(asset, expiry);

  const [instrumentTicker, setInstrumentTicker] = useState<InstrumentToTicker>(
    {}
  );

  useEffect(() => {
    if (!asset || !derivative) {
      return;
    }

    const data = [getTickerChannel(asset, derivative)];
    triggerSubscribe(
      "subscribe",
      data,
      WebsocketChannelEnum.TICKER
    );
  }, [asset, derivative, triggerSubscribe]);

  // Receives messages and updates state
  useEffect(() => {
    if (lastMessages && asset && derivative) {
      lastMessages.forEach((lastMessage) => {
        setInstrumentTicker((prev) => {
          const { data, channel }: IWSSTickerResponse = jsonParse(lastMessage.data);

          const tickerChannel = getTickerChannel(asset, derivative);

          if (channel === tickerChannel && data && data.tickers?.length) {
            const tickers = data.tickers.reduce(
              (prevTickers, curr) => ({
                ...prevTickers,
                [curr.instrument_name]: curr,
              }),
              {} as any
            );

            if (data) {
              return {
                ...prev,
                ...tickers,
              };
            }
          }
          return prev;
        });
      });
    }
  }, [asset, derivative, lastMessages]);

  const filteredInstrumentTicker = useMemo(() => {
    const filteredTickers = Object.keys(instrumentTicker)
      .filter((k) => {
        if (expiry) {
          const instrumentExp = getExpiryFromSymbol(
            instrumentTicker[k].instrument_name
          );
          const exp = moment
            .unix(nanosToSeconds(expiry))
            .format("DD-MM-YYYY");
          return exp === instrumentExp;
        }
        return true;
      })
      .reduce(
        (prev, current) => ({
          ...prev,
          [current]: instrumentTicker[current],
        }),
          {} as InstrumentToTicker
      );

    // Transform options chain obj to instrumentTicker
    // Combine options chain data with filtered tickers
    if (optionsChainData && optionsChainData.asset === asset) {
      [
        ...(optionsChainData.options?.calls || []),
        ...(optionsChainData.options?.puts || []),
      ].forEach((opt) => {
        if (opt.expiry === String(expiry)) {
          filteredTickers[opt.instrument_name] = filteredTickers[
            opt.instrument_name
          ] || {
            instrument_id: opt.instrument_id,
            instrument_name: opt.instrument_name,
            open_interest: opt.open_interest,
            ask: {
              price: opt.ticker?.ask?.price,
              amount: opt.ticker?.ask?.volume,
              greeks: {
                iv: opt.ticker?.ask?.iv,
              },
            },
            bid: {
              price: opt.ticker?.bid?.price,
              amount: opt.ticker?.bid?.volume,
              greeks: {
                iv: opt.ticker?.bid?.iv,
              },
            },
            mark: {
              greeks: {
                delta: opt.delta,
                iv: opt.iv,
              },
              price: opt.mark_price,
            },
          };
        }
      });
      return filteredTickers;
    }
    return filteredTickers;
  }, [asset, expiry, instrumentTicker, optionsChainData]);

  return {
    instrumentTicker: filteredInstrumentTicker,
  };
};

export default useTickersWSS;

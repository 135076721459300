import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import {
  COLORS,
  ICON_COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import {
  FONT_CSS,
  FONT_SIZE,
  FONT_STYLE,
} from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";

export const PageContentContainer = styled(motion.div)`
  transition: all 0.2s ease-in-out;
`;

export const WalletButton = styled.button<{
  isActive?: boolean;
}>`
  width: 100%;
  height: 64px;
  color: ${TEXT_COLORS.one};
  padding: 0 ${SPACING.three}px;
  display: flex;
  align-items: center;

  font-size: ${FONT_SIZE.three};
  transition: all 0.4s ease-in-out;

  img {
    margin-right: ${SPACING.two}px;
    width: 40px;
    height: 40px;
  }

  span {
    margin: auto 0;
    font-size: ${FONT_SIZE.two};
  }

  background-color: ${({ isActive }) =>
    isActive ? PRIMARY_COLORS.six : "transparent"};
  ${({ isActive }) => {
    if (isActive) {
      return css`
        box-shadow: 0px 0px 0px 1px ${PRIMARY_COLORS.one} inset;
      `;
    }
    return "";
  }}
  border-radius: ${({ isActive }) => (isActive ? "8px" : "0")};

  &:disabled {
    opacity: 0.4;
  }
`;

export const RememberCheckbox = styled.div`
  display: flex;
  margin-top: ${SPACING.three}px;
`;

export const VideoView = styled.div`
  height: 200px;
  position: relative;

  > video {
    opacity: 0.25;
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;

    // Video width when height is 200px: 355px
    // Modal width: 311px
    left: ${-((355 - 311) / 2)}px;
    right: calc((355px - 311px) / 2);
  }
`;

export const ExtrasContainer = styled.div`
  padding: ${SPACING.three}px;
`;

export const ExtrasText = styled.span<{ type: "error" | "default" }>`
  ${FONT_CSS.body.five}
  color: ${({ type }) =>
    type === "error" ? COLORS.negative.one : TEXT_COLORS.one};
`;

export const AevoLogoContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    position: absolute;
  }
`;

export const Padding = styled.div`
  padding: 0 ${SPACING.three}px;
`;

export const SuccessMessage = styled.div`
  ${FONT_CSS.label.three}
  padding-top: ${SPACING.three}px;
  color: ${COLORS.highlight.one};
`;

export const RememberMeTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${SPACING.one}px;
  svg {
    stroke: ${ICON_COLORS.two};
  }
`;

export const WrongNetworkText = styled(motion.p)`
  padding: ${SPACING.three}px;
  color: ${TEXT_COLORS.two};
  ${FONT_STYLE.body.five};

  > span {
    color: ${TEXT_COLORS.one};
  }
`;

export const ToastLogoContainer = styled.div`
  display: flex;
  margin: auto;

  svg {
    margin: auto ${SPACING.two}px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
  }
`;

export const ToastSubheader = styled.div`
  text-transform: none;

  span {
    color: ${COLORS.highlight.one};
  }
`;

export const InputLabel = styled.div`
  ${FONT_CSS.label.three}
  color: ${TEXT_COLORS.three};
  margin-bottom: ${SPACING.one}px;
`;

export const InputContainer = styled.div`
  padding: ${SPACING.three}px;
  padding-bottom: 0px;
`;

export const InputError = styled.span`
  ${FONT_CSS.label.three}
  color: ${COLORS.negative.one};
`;

export const RightStepButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACING.three}px;
  width: 100%;
`;

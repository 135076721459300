import styled, { css } from "styled-components";
import {
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { TableWrapper } from "../../shared/Table/style";
import { frostedGlassBg } from "../../shared/style";

export const EmptyContentContainer = styled.div`
  ${FONT_CSS.body.three}
  color: ${TEXT_COLORS.three};
  padding-top: ${SPACING.three}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PortfolioTableWrapper = styled(TableWrapper)<{
  stickyHeader?: boolean;
  verticalSpacing?: number;
  isMobile?: boolean;
}>`
  overflow-x: scroll;

  table {
    margin-top: ${({ verticalSpacing }) =>
      verticalSpacing ? 0 : `${SPACING.two}px`};
    border-collapse: separate;
    border-spacing: 0 ${({ verticalSpacing }) => verticalSpacing || 0}px;

    thead {
      ${({ stickyHeader }) =>
        stickyHeader &&
        css`
          height: ${COMPONENTS.portfolioTableHeader}px;
          position: sticky;
          top: 0;
          ${frostedGlassBg("transparent", LAYER_COLORS.one, 12)}
        `};

      tr {
        height: 100%;
      }
    }

    th {
      ${({ stickyHeader, isMobile }) =>
        stickyHeader &&
        !isMobile &&
        css`
          border-bottom: 1px solid ${BORDER_COLORS.one};
        `};
      background-color: transparent;
    }

    tbody {
      tr {
        height: ${COMPONENTS.portfolioTableRow}px;
        border: none;
        background-color: transparent;
        cursor: ${({ disableHoverRow }) =>
          !disableHoverRow ? "pointer" : "default"};
      }
      td {
        ${({ isMobile }) =>
          !isMobile && `background-color:  ${LAYER_COLORS.one};`}
        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

export const Header = styled.div<{ isMobile?: boolean }>`
  color: ${TEXT_COLORS.one};
  margin: ${({ isMobile }) => (isMobile ? 0 : SPACING.five)}px !important;
  margin-bottom: 0 !important;
  position: relative;
`;

export const SpaceBetweenInnerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MarketCellContainer = styled.div`
  display: flex;
  text-transform: capitalize;
  align-items: center;
  color: ${TEXT_COLORS.one};
`;

export const Separator = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 2px;
  margin: 0 ${SPACING.one}px;
  background-color: ${TEXT_COLORS.one};
`;

export const MarketIconContainer = styled.div`
  width: 32px;
  height: 32px;
  margin-right: ${SPACING.two}px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const CSVLinkWrapper = styled.div`
  a {
    color: ${TEXT_COLORS.three} !important;
    text-decoration: none;
    font-size: ${FONT_SIZE.one};
    cursor: pointer;
    &:hover {
      color: ${COLORS.blue.one} !important;
    }
  }

  a[aria-disabled="true"] {
    &:hover {
      cursor: not-allowed;
      color: ${TEXT_COLORS.three} !important;
    }
  }
`;

export const ViewDetailsContent = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${SPACING.three}px;
  }
`;

export const ViewDetailsRow = styled.div<{ clickable?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-size: ${FONT_SIZE.one};
  text-transform: capitalize;

  span:first-child {
    color: ${TEXT_COLORS.three};
  }

  span:last-child {
    display: flex;
    align-items: center;
    color: ${TEXT_COLORS.one};
  }

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      &:hover {
        span:last-child {
          transition: opacity 0.2s ease-in-out;
          opacity: 0.6;
        }
      }
    `}
`;

export const MarketCellContent = styled.div`
  ${FONT_CSS.body.five}
  display: flex;
  align-items: center;
`;

export const SettlementInProgress = styled.div`
  ${FONT_CSS.body.six}
  color: ${COLORS.highlight.one};
  margin-top: ${SPACING.one}px;
`;

import styled, { css } from "styled-components";
import { OptionTypeResponse } from "../../../codegen-api";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";
import { fadeIn } from "../../shared/style";

export const TabsContainer = styled.div`
  display: flex;
  height: 32px;
  margin-bottom: ${SPACING.two}px;
`;

export const Spacer = styled.div`
  width: ${SPACING.three}px;
`;

export const OptionRowContainer = styled.button`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${LAYER_COLORS.two};
  margin: ${SPACING.two}px 0;
  font-size: ${FONT_SIZE.two};
  padding: ${SPACING.three}px;
  border-radius: ${BORDER_RADIUS.five}px;

  &:last-of-type {
    margin-bottom: 0;
  }

  > * {
    width: 100%;
  }
`;

export const OptionRowHeader = styled.div`
  display: flex;
`;

export const OptionTypeWrapper = styled.div<{
  type: OptionTypeResponse;
  highlighted?: boolean;
}>`
  display: flex;
  color: ${COLORS.blue.one};
  align-items: center;

  svg {
    background-color: ${COLORS.blue.three};
    stroke: ${COLORS.blue.one};
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-left: ${SPACING.two}px;
    margin-right: ${SPACING.two}px;

    transform: ${({ type }) =>
      type === OptionTypeResponse.Call ? "none" : "rotate(90deg)"};
  }

  span {
    color: ${({ highlighted }) =>
      highlighted ? COLORS.blue.one : TEXT_COLORS.one};
  }

  margin-bottom: ${SPACING.twoHalf}px;
`;

export const OptionRowValues = styled.div`
  display: flex;
`;

export const OptionRowValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  // Title
  span:first-child {
    color: ${TEXT_COLORS.three};
    margin-bottom: ${SPACING.one}px;
  }

  // Value
  span:last-child {
    color: ${TEXT_COLORS.two};
  }
`;

export const PositionMarker = styled.div`
  background-color: ${COLORS.blue.one};
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin-left: ${SPACING.two}px;
`;

export const TapToScroll = styled.button<{ reversed?: boolean }>`
  ${FONT_CSS.body.five}
  opacity: 0;
  animation: ${fadeIn} 0.4s ease-in forwards;
  display: flex;
  flex: 1;
  flex-direction: ${({ reversed }) => (reversed ? "column-reverse" : "column")};
  align-items: center;
  justify-content: center;
  color: ${TEXT_COLORS.one};

  svg {
    width: 16px;
    height: 16px;
    ${({ reversed }) =>
      reversed
        ? css`
            margin-bottom: ${SPACING.one}px;
            transform: rotate(180deg);
          `
        : css`
            margin-top: ${SPACING.one}px;
          `}
  }
`;

import { CSSProperties, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronThick } from "../../../assets/svg/chevron-thick.svg";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import FillBar from "../../FillMeter/FillBar";
import DropdownSimple from "../DropdownSimple";
import {
  PaginationContainer,
  ControlButton,
  ControlButtonContainer,
  GroupContainer,
} from "./style";
import { IHasMobileComponentProps } from "../../../pages/shared";

interface IPaginationProps extends IHasMobileComponentProps {
  // Starts from 0
  currentPage: number;
  totalPages: number;
  onPrevPage: () => void;
  onNextPage: () => void;
  paginationName?: string;
  containerStyle?: CSSProperties;

  // If provided, show "Showing 1-25 out of 82 items"
  itemCountConfig?: {
    totalItems: number;
    perPage: number;
    perPageDropdownOptions?: number[];
    onPerPageDropdownSelect?: (perPage: number) => void;
  };
}

function Pagination({
  currentPage,
  totalPages,
  onPrevPage,
  onNextPage,
  containerStyle,
  itemCountConfig,
  paginationName,
  isMobile,
}: IPaginationProps) {
  const { t } = useTranslation("app", { keyPrefix: "shared.Pagination" });
  const startPage = currentPage + 1;
  const canPrev = currentPage !== 0;
  const canNext = currentPage < totalPages - 1;

  const startItemIndex = itemCountConfig
    ? itemCountConfig.perPage * currentPage + 1
    : 0;
  const endItemIndex = itemCountConfig
    ? Math.min(
        startItemIndex + itemCountConfig.perPage - 1,
        itemCountConfig.totalItems
      )
    : 0;

  const [showDropdown, setShowDropdown] = useState(false);

  const onToggleDropdown = useCallback(() => {
    setShowDropdown((prev) => !prev);
  }, []);

  return (
    <PaginationContainer style={containerStyle}>
      {!isMobile && (
        <GroupContainer style={{ justifyContent: "flex-start" }}>
          {itemCountConfig && (
            <span>
              {t("showing")}{" "}
              <strong>
                {startItemIndex}-{endItemIndex}
              </strong>{" "}
              {t("out_of")} <strong>{itemCountConfig.totalItems}</strong>{" "}
              {paginationName || t("items")}
            </span>
          )}
        </GroupContainer>
      )}
      <ControlButtonContainer>
        <ControlButton
          disabled={!canPrev}
          direction="left"
          onClick={onPrevPage}
        >
          <ChevronThick />
        </ControlButton>
        <span>{startPage < 10 ? `0${startPage}` : startPage}</span>
        <FillBar
          fillColor={COLORS.highlight.one}
          percent={(startPage / totalPages) * 100}
          style={{
            width: 64,
            height: 2,
          }}
        />
        <span style={{ color: TEXT_COLORS.three }}>
          {totalPages < 10 ? `0${totalPages}` : totalPages}
        </span>
        <ControlButton
          disabled={!canNext}
          direction="right"
          onClick={onNextPage}
        >
          <ChevronThick />
        </ControlButton>
      </ControlButtonContainer>
      {!isMobile && (
        <GroupContainer style={{ justifyContent: "flex-end" }}>
          {itemCountConfig?.perPageDropdownOptions && (
            <>
              {t("items_per_page")}
              <DropdownSimple<string>
                dropDirection={"up"}
                show={showDropdown}
                onToggle={onToggleDropdown}
                title={String(itemCountConfig.perPage)}
                items={itemCountConfig.perPageDropdownOptions.map((v) =>
                  String(v)
                )}
                onSelectItem={(item) => {
                  itemCountConfig.onPerPageDropdownSelect?.(Number(item));
                }}
              />
            </>
          )}
        </GroupContainer>
      )}
    </PaginationContainer>
  );
}

export default Pagination;

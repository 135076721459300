import styled from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const CurrentSpotWrapper = styled.div<{ isMobileScreen?: boolean }>`
  ${FONT_CSS.data.three}
  background-color: ${({ isMobileScreen }) =>
    isMobileScreen ? "transparent" : LAYER_COLORS.two};
  height: ${COMPONENTS.tableHeader}px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Title = styled.span`
  color: ${TEXT_COLORS.three};
  padding-right: ${SPACING.two}px;
`;

/* eslint-disable no-await-in-loop */
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { ethers } from "ethers";
import moment from "moment";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { LocalStorageKeyEnum } from "../../../enums/localStorage";
import { ChainCodeErrorEnum } from "../../../enums/rpcErrorCodes";
import { IAuthInfo, IDecryptedAuthInfo } from "../../../interfaces/AuthInfo";
import { authApi } from "../../../services/api/apiFetcher";
import { nanosToMillis } from "../../../utils/date";
import { delay } from "../../../utils/delay";
import { IRegisterMessage, createSigningKey } from "../../../utils/signing";
import { ToastStatusEnum } from "../../../utils/toast";
import useWallet from "../../wallet/useWallet";

type IRegisterAuthInfoResponse =
  | {
      authInfo: undefined;
      error: string;
    }
  | {
      authInfo: IDecryptedAuthInfo;
      error: undefined;
    };

export const useRegister = () => {
  const { account, provider } = useWallet();
  const { enableTrading } = useContext(AuthContext);
  const fetcher = authApi();
  const referralCode = window.localStorage.getItem(
    LocalStorageKeyEnum.REFERRAL_CODE
  );

  const { apiKey, apiSecret } = useContext(AuthContext);
  const postNewSigningKey = useCallback(
    async (
      acc: string,
      registerMessage: IRegisterMessage,
      accountSignature: string,
      signingKeySignature: string,
      signingKey: string,
      refCode?: string,
      // Number of times to retry. If not provided, dont retry
      retryCount?: number
    ): Promise<IRegisterAuthInfoResponse> => {
      try {
        const response = (
          await (
            await fetcher.postRegister({
              account: acc,
              signing_key: registerMessage.key,
              expiry: registerMessage.expiry,
              account_signature: ethers.utils.joinSignature(
                ethers.utils.splitSignature(accountSignature)
              ),
              signing_key_signature: signingKeySignature,
              referral_code: refCode,
              no_api_key: Boolean(apiKey && apiSecret), // do not create new api key and secret if both are are defined
            })
          )()
        ).data;

        const api_key = response.api_key || apiKey;
        const api_secret = response.api_secret || apiSecret;

        if (api_key && api_secret) {
          const authInfo: IAuthInfo = {
            signingKey,
            account: acc,
            apiKey: api_key,
            apiSecret: api_secret,
            expiry: registerMessage.expiry,
            encrypted: false,
            passwordProtected: false,
          };

          // Before enable trading, we check with /auth endpoint and see if the signing key is valid
          // Check up to 10 times. If valid, enable trading
          const newFetcher = authApi(api_key, api_secret);
          let success = false;
          for (let i = 0; i < 10; i += 1) {
            try {
              await (
                await newFetcher.getAuth()
              )();
              success = true;
              // No error, break
              break;
            } catch (error) {
              // if error, delay 0.5s and try again
              // eslint-disable-next-line no-await-in-loop
              await delay(1000);
            }
          }

          if (success) {
            return {
              authInfo,
              error: undefined,
            };
          }
          // if for some reason api returns 200 but no api keys and signing keys available
          Sentry.captureException(
            Error(
              "POST register returned api keys, but /account is still returning 401"
            ),
            {
              extra: {
                response: JSON.stringify({
                  account: acc,
                  response,
                }),
              },
            }
          );
          return {
            authInfo: undefined,
            error: "Invalid signing key, please re-enable trading",
          };
        }
        // Delete auth info and show error
        // if for some reason api returns 200 but no api keys and signing keys available
        Sentry.captureException(Error("POST register not returning api keys"), {
          extra: {
            response: JSON.stringify(response),
          },
        });
        return {
          authInfo: undefined,
          error: "Invalid signing key, please re-enable trading",
        };
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error);
        if (error.code === ChainCodeErrorEnum.CANCELLED) {
          return {
            authInfo: undefined,
            error: "User denied message signature",
          };
        }

        // If rate limited, retry with the retry_after response, or retry 1 second later
        if ((error as AxiosError).response?.status === 429) {
          if (retryCount && retryCount > 0) {
            const retryAfter = error.response?.data?.retry_after;
            const retryAfterMillis = retryAfter
              ? nanosToMillis(retryAfter)
              : 1000;
            await delay(retryAfterMillis);

            return postNewSigningKey(
              acc,
              registerMessage,
              accountSignature,
              signingKeySignature,
              signingKey,
              refCode,
              retryCount - 1
            );
          }
        }
        return {
          authInfo: undefined,
          error: "Unable to register signing key. Please try again.",
        };
      }
    },
    [apiKey, apiSecret, fetcher]
  );

  const registerSigningKey = useCallback(
    async (
      isRememberMeChecked: boolean
    ): Promise<{
      status: ToastStatusEnum;
      message: string;
    }> => {
      try {
        if (!account || !provider) {
          throw Error("Unauthenticated");
        }

        const { chainId } = await provider.getNetwork();
        const expiry = isRememberMeChecked
          ? undefined
          : moment().add(1, "hour").unix().toString();

        const signingKeyData = await createSigningKey({
          provider,
          account,
          chainId,
          expiry,
        });

        const { authInfo, error } = await postNewSigningKey(
          account,
          signingKeyData.registerMessage,
          signingKeyData.accountSignature,
          signingKeyData.signingKeySignature,
          signingKeyData.privateKey,
          referralCode || undefined
        );

        if (error || !authInfo) {
          return {
            status: ToastStatusEnum.ERROR,
            message: error,
          };
        }
        enableTrading(authInfo, isRememberMeChecked);
        return {
          status: ToastStatusEnum.SUCCESS,
          message: "Signing key registered successfully",
        };
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log(error);
        if (error.code === ChainCodeErrorEnum.CANCELLED) {
          return {
            status: ToastStatusEnum.ERROR,
            message: "User denied message signature",
          };
        }
        return {
          status: ToastStatusEnum.ERROR,
          message: "Unable to register signing key. Please try again.",
        };
      }
    },
    [account, enableTrading, postNewSigningKey, provider, referralCode]
  );

  return {
    registerSigningKey,
    postNewSigningKey,
  };
};

import { useContext, useEffect, useMemo, useState } from "react";
import { GetAccount200ResponsePositionsInner } from "../../codegen-api";
import { WebsocketContext } from "../../contexts/WebsocketAuthContext";
import { jsonParse } from "../../utils/strings";
import { useGetAccount } from "../api/account/useGetAccount";
import { IWSSPositionResponse } from "./model/position";

const positionChannel = "positions";

type IPositionMap = {
  [instrumentName: string]: GetAccount200ResponsePositionsInner;
};

function transformPositionsToMap(
  positions?: GetAccount200ResponsePositionsInner[]
) {
  const posMap = positions?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.instrument_name]: curr,
    }),
    {} as IPositionMap
  );
  return posMap;
}

const usePositionsWSS = () => {
  const { data: accountData, isValidating } = useGetAccount();
  const { authenticated, triggerSubscribe, lastMessages } =
    useContext(WebsocketContext);

  const [positionsMap, setPositionsMap] = useState<IPositionMap>();

  const loading = isValidating && !positionsMap;

  // In case websocket is slow,
  // If account data positions is newer, update positionsMap
  useEffect(() => {
    setPositionsMap((prev) => {
      // If previously empty, or no positions from account, just set positions.
      if (!prev || !accountData?.positions) {
        return transformPositionsToMap(accountData?.positions);
      }

      // If accountData.positions is different than positionsMap (new positions), just set map to accountData
      // We do this by checking the list of instrument names, eg "MANTA-PERP,TRB-PERP"
      const isDifferentInstruments =
        Object.keys(prev).sort().join(",") !==
        accountData.positions.map((p) => p.instrument_name).join(",");
      if (isDifferentInstruments) {
        return transformPositionsToMap(accountData?.positions);
      }

      // Now we look for other notable props to update when positions from accounts api changes
      for (let i = 0; i < (accountData.positions.length || 0); i += 1) {
        const p = accountData.positions[i];
        const existingPositionFromMap = prev[p.instrument_name];

        // Check triggers
        const stopLossChanged =
          p.triggers?.stop_loss?.order_id !==
          existingPositionFromMap.triggers?.stop_loss?.order_id;
        const takeProfitChanged =
          p.triggers?.take_profit?.order_id !==
          existingPositionFromMap.triggers?.take_profit?.order_id;

        // Check isolated margin
        const isolatedMarginChanged =
          p.isolated_margin !== existingPositionFromMap.isolated_margin;

        if (stopLossChanged || takeProfitChanged || isolatedMarginChanged) {
          return transformPositionsToMap(accountData.positions);
        }
      }

      // No change
      return prev;
    });
  }, [accountData?.positions]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    const data = [positionChannel];
    triggerSubscribe("subscribe", data);
  }, [authenticated, triggerSubscribe]);

  // Receives messages and updates state
  useEffect(() => {
    if (lastMessages) {
      lastMessages.forEach((lastMessage) => {
        const { data, channel }: IWSSPositionResponse = jsonParse(
          lastMessage.data
        );

        if (channel === positionChannel && data?.positions) {
          setPositionsMap((prevPositions) => {
            const newPositions = { ...prevPositions } || {};

            data.positions.forEach((pos) => {
              const size = Number(pos.amount);
              if (!size) {
                delete newPositions[pos.instrument_name];
              } else {
                newPositions[pos.instrument_name] = {
                  ...pos,
                  amount: String(Math.abs(size)),
                };
              }
            });
            return newPositions;
          });
        }
      });
    }
  }, [lastMessages]);

  const positions = useMemo(
    () =>
      Object.values(positionsMap || {}).sort(
        (a, b) => Number(a.instrument_id) - Number(b.instrument_id)
      ),
    [positionsMap]
  );

  return {
    positionsMap: positionsMap || {},
    positions,
    loading,
  };
};

export default usePositionsWSS;

import styled from "styled-components";
import { TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const TradeFormContainer = styled.div`
  position: relative;
  width: calc(100dvw - ${SPACING.three * 2}px);
  height: calc(
    100dvh - ${SPACING.three * 2}px - ${COMPONENTS.modalHeaderHeight}px
  );
`;

export const ModalTitlePrice = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${FONT_CSS.body.five}

  > div > span {
    ${FONT_CSS.data.four}
  }

  > span:first-of-type {
    margin-right: ${SPACING.two}px;
    color: ${TEXT_COLORS.three};
  }
`;

import {
  PropsWithChildren,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  createChart,
  DeepPartial,
  ChartOptions,
  ColorType,
  LastPriceAnimationMode,
  IChartApi,
  ISeriesApi,
  AreaStyleOptions,
  SeriesOptionsCommon,
  SingleValueData,
  UTCTimestamp,
  MouseEventHandler,
  LineStyle,
  BarPrice,
  MouseEventParams,
  PriceScaleMode,
} from "lightweight-charts";
import moment from "moment";
import currency from "currency.js";
import { PriceChartContainer, Tooltip } from "./style";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";

interface IPriceChartProps {
  /**
   * Array of array with timestamp and price
   * [
   *  ["16726381723", "100"],
   *  ["16726382723", "101"],
   * ]
   */
  timestampPriceData: string[][];
  width: number;
  height: number;
  chartStyle?: "default" | "mini";
  hideAxes?: boolean;
  showTimeOnly?: boolean;
  hoverCallback?: MouseEventHandler;

  // Optional chart color
  overrideChartColor?: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
}

function PriceChart({
  overrideChartColor,
  timestampPriceData,
  chartStyle,
  hideAxes,
  width,
  height,
  showTimeOnly,
  hoverCallback,
}: PropsWithChildren<IPriceChartProps>) {
  const priceChartRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [, setPrevSeries] = useState<ISeriesApi<"Area"> | undefined>();
  const [, setPrevChart] = useState<IChartApi | undefined>();

  const changePercent = useMemo(() => {
    if (timestampPriceData.length) {
      const latestPrice = timestampPriceData[timestampPriceData.length - 1][1];
      const oldestPrice = timestampPriceData[0][1];
      const diff = Number(latestPrice) - Number(oldestPrice);
      const percentage = (diff / (Number(oldestPrice) || 1)) * 100;
      return percentage;
    }
    return 0;
  }, [timestampPriceData]);

  const chartOptions: DeepPartial<ChartOptions> = useMemo(
    () => ({
      width,
      height,
      layout: {
        textColor: TEXT_COLORS.two,
        background: {
          type: ColorType.Solid,
          color: "transparent",
        },
        fontFamily: "BaseFont",
      },
      handleScale: false,
      handleScroll: false,
      grid: {
        horzLines: {
          visible: false,
        },
        vertLines: {
          visible: false,
        },
      },
      timeScale: {
        borderVisible: false,
        visible: !hideAxes && chartStyle === "default",
        shiftVisibleRangeOnNewBar: true,
        tickMarkFormatter: (time: UTCTimestamp) => {
          if (showTimeOnly) {
            return moment.unix(time).format("HH:mm:ss");
          }
          return moment.unix(time).format("DD MMM");
        },
      },
      rightPriceScale: {
        borderVisible: false,
        visible: !hideAxes && chartStyle === "default",
        scaleMargins: {
          bottom: 0.15,
          top: 0.05,
        },
        autoScale: true,
        mode: PriceScaleMode.Normal,
      },
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false,
        },
        vertLine: {
          visible: chartStyle === "default",
          style: LineStyle.Solid,
          labelVisible: false,
        },
      },
    }),
    [width, height, hideAxes, chartStyle, showTimeOnly]
  );

  const color = useMemo(
    () => ({
      primary:
        overrideChartColor?.primary ||
        (changePercent >= 0 ? COLORS.positive.one : COLORS.negative.one),
      secondary:
        overrideChartColor?.secondary ||
        (changePercent >= 0 ? COLORS.positive.four : COLORS.negative.four),
      tertiary:
        overrideChartColor?.tertiary ||
        (changePercent >= 0 ? COLORS.positive.three : COLORS.negative.three),
    }),
    [changePercent, overrideChartColor]
  );

  const renderTooltipCallback = useCallback(
    (param: MouseEventParams) => {
      if (!tooltipRef.current) {
        return;
      }

      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.y < 0
      ) {
        tooltipRef.current.style.display = "none";
      } else {
        tooltipRef.current.style.display = "block";

        const display = showTimeOnly
          ? `${moment(Number(param.time || 1) * 1000).format("hh:mm a")}`
          : `${moment(Number(param.time || 1) * 1000).format(
              "hh:mm a, MMM Do"
            )}`;

        tooltipRef.current.innerText = display;
        tooltipRef.current.style.bottom = `${SPACING.four}px`;

        const labelHalfWidth = showTimeOnly ? SPACING.five : SPACING.seven;
        let left = param.point.x - labelHalfWidth;
        left = Math.max(left, 0);
        tooltipRef.current.style.left = `${left}px`;
      }
    },
    [showTimeOnly]
  );

  const areaSeriesOptions: DeepPartial<AreaStyleOptions & SeriesOptionsCommon> =
    useMemo(
      () => ({
        // Gradient
        topColor: color.secondary,
        bottomColor: "transparent",
        lineColor: color.primary,
        crosshairMarkerVisible: chartStyle === "default",
        crosshairMarkerRadius: 5,
        crosshairMarkerBorderColor: color.tertiary,
        crosshairMarkerBackgroundColor: color.primary,
        lineWidth: 1,
        lastPriceAnimation: LastPriceAnimationMode.Disabled,
        priceLineVisible: false,
        lastValueVisible: false,
        priceFormat: {
          type: "custom",
          formatter: (priceValue: BarPrice) =>
            currency(priceValue, { precision: 0, symbol: "" }).format(),
        },
      }),
      [chartStyle, color]
    );

  useEffect(() => {
    let chartData: SingleValueData[] = timestampPriceData.slice().map((d) => ({
      time: Number(d[0]) as UTCTimestamp,
      value: Number(d[1]),
    }));

    // Minimum 2 dots
    if (chartData.length === 1) {
      chartData = [
        {
          time: ((chartData[0].time as number) - 1) as UTCTimestamp,
          value: chartData[0].value,
        },
        ...chartData,
      ];
    }

    setPrevChart((prevChart) => {
      // If no prevChart, creates a new chart
      if (!prevChart && priceChartRef.current) {
        const newChart = createChart(priceChartRef.current, chartOptions);

        // Update tooltip
        if (chartStyle === "default") {
          newChart.subscribeCrosshairMove(renderTooltipCallback);
        }

        if (hoverCallback) {
          newChart.subscribeCrosshairMove(hoverCallback);
        }
        // Adds a series to chart, and then updates
        setPrevSeries(() => {
          const areaSeries = newChart.addAreaSeries(areaSeriesOptions);
          areaSeries.setData(chartData);
          newChart.timeScale().fitContent();
          return areaSeries;
        });
        return newChart;
      }

      // If prevChart already available, update the prevSeries (assume this will be available too)
      setPrevSeries((prevSeries) => {
        prevSeries?.applyOptions(areaSeriesOptions);
        prevSeries?.setData(chartData);
        prevChart?.timeScale().fitContent();
        prevChart?.applyOptions(chartOptions);
        return prevSeries;
      });
      return prevChart;
    });
  }, [
    timestampPriceData,
    chartOptions,
    areaSeriesOptions,
    hoverCallback,
    renderTooltipCallback,
    chartStyle,
  ]);

  // Responsive chart size
  useEffect(() => {
    setPrevChart((existingChart) => {
      if (existingChart) {
        existingChart.applyOptions({
          width,
          height,
        });
      }
      return existingChart;
    });
  }, [width, height]);

  return (
    <PriceChartContainer width={`${width}px`} height={`${height}px`}>
      <div style={{ width: "100%" }} ref={priceChartRef} />
      <Tooltip ref={tooltipRef} />
    </PriceChartContainer>
  );
}

export default PriceChart;

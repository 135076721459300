import styled, { css } from "styled-components";
import { SPACING } from "../../../constants/design/spacing";
import { TEXT_COLORS } from "../../../constants/design/colors";

import { FONT_CSS } from "../../../constants/design/fontSize";

export const BoostTitle = styled.div`
  color: ${TEXT_COLORS.three};
  ${FONT_CSS.label.two}
`;

export const BoostTitleWrapper = styled.div`
  display: flex;
  gap: ${SPACING.one}px;
`;

export const BoostNumber = styled.div`
  ${FONT_CSS.data.one}
`;

export const BoostedVolumeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.two}px;
`;

export const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.two}px;
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};
  width: 240px;
`;

export const Stat = styled.div<{ small?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ small }) =>
    small &&
    css`
      ${FONT_CSS.data.four}
      margin-left: ${SPACING.three}px;
    `}
`;

export const StatTitle = styled.div`
  color: ${TEXT_COLORS.three};
`;

export const StatNumber = styled.div`
  display: flex;
  gap: ${SPACING.one}px;
  color: ${TEXT_COLORS.one};
  align-items: center;
  justify-content: center;
`;

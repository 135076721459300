import { useCallback, useEffect, useMemo, useState } from "react";
import { WIDTHS } from "../../constants/design/spacing";

const useScreenSize = () => {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  // If large screen, isLargeScreen will be true
  // If medium screen, isMediumScreen will be true
  // If mobile screen, both isMediumScreen and isMobileScreen will be true
  const isLargeScreen = useMemo(() => width !== 0 && width > WIDTHS.L, [width]);
  const isMediumScreen = useMemo(
    () => width !== 0 && width <= WIDTHS.M,
    [width]
  );
  const isMobileScreen = useMemo(
    () => width !== 0 && width <= WIDTHS.S,
    [width]
  );

  const updateWidth = useCallback(() => {
    setWidth(Math.max(window.innerWidth, document.documentElement.clientWidth));
  }, []);

  const updateHeight = useCallback(() => {
    setHeight(
      Math.max(window.innerHeight, document.documentElement.clientHeight)
    );
  }, []);

  useEffect(() => {
    updateWidth();
    updateHeight();

    window.addEventListener(
      "resize",
      () => {
        updateWidth();
        updateHeight();
      },
      { passive: true }
    );
  }, [updateWidth, updateHeight]);

  const [videoWidth, videoHeight] = useMemo(() => {
    /**
     * Screen size exactly 16:9
     */
    if (width / height === 16 / 9) {
      return [width, height];
    }

    /**
     * If screen are longer than 16:9
     */
    if (width / height > 16 / 9) {
      return [width, width * (9 / 16)];
    }

    return [height * (16 / 9), height];
  }, [height, width]);

  return {
    height,
    width,
    video: { width: videoWidth, height: videoHeight },
    isLargeScreen,
    isMediumScreen,
    isMobileScreen,
  };
};

export default useScreenSize;

import moment from "moment";
import {
  GetMarkets200Response,
  OptionTypeResponse,
  SideResponse,
} from "../../codegen-api";
import { AssetResponse } from "../asset";

/**
 * Extract the expiry date in DD-MM-YYYY format from a given symbol
 * @param symbol eg. "ETH-27MAY22-2000-C" or "ETH-PERP"
 */
export const getExpiryFromSymbol = (symbol: string) => {
  const date = symbol.split("-")[1]; // eg. "27MAY22"
  const m = moment(date, "DDMMMYY");
  if (m.isValid()) {
    return m.format("DD-MM-YYYY");
  }
  return undefined;
};

export const getOptionsTypeFromSymbol = (symbol: string) => {
  const split = symbol.split("-");
  const letter = split[split.length - 1];
  if (letter.toUpperCase() === "C") {
    return OptionTypeResponse.Call;
  }
  if (letter.toUpperCase() === "P") {
    return OptionTypeResponse.Put;
  }
  return undefined;
};

// Symbol same format as above
export const getAssetFromSymbol = (
  symbol: string
): AssetResponse | undefined => {
  const symb = symbol.split("-")?.[0]?.toUpperCase();
  return symb as AssetResponse;
};

/**
 * Calculates the profit per option.
 * To get total profit, multiply this by your position size
 */
export const calculateProfitPerOption = (
  isPut: boolean,
  currentPrice: number,
  strikePrice: number,
  premium: number,
  direction: SideResponse = SideResponse.Sell
) => {
  if (direction === SideResponse.Sell) {
    if (isPut) {
      return currentPrice >= strikePrice
        ? premium
        : currentPrice - strikePrice + premium;
    }
    return currentPrice <= strikePrice
      ? premium
      : strikePrice - currentPrice + premium;
  }

  if (isPut) {
    return currentPrice <= strikePrice
      ? strikePrice - currentPrice - premium
      : -premium;
  }
  return currentPrice >= strikePrice
    ? currentPrice - strikePrice - premium
    : -premium;
};

export const calculateBreakevenForOption = (
  isPut: boolean,
  strikePrice: number,
  premium: number
) => (isPut ? strikePrice - premium : strikePrice + premium);

export const getMaxProfitLossPerOption = (
  premium: number,
  orderType: OptionTypeResponse,
  direction: SideResponse,
  strikePrice: number
): {
  maxLoss: number | "Unlimited";
  maxProfit: number | "Unlimited";
} => {
  if (direction === SideResponse.Sell) {
    return {
      maxLoss: orderType === OptionTypeResponse.Put ? strikePrice : "Unlimited",
      maxProfit: premium,
    };
  }

  // When buying PUT, max profit is when ETH => $0
  // So max profit is the strikePrice
  return {
    maxLoss: premium,
    maxProfit: orderType === OptionTypeResponse.Put ? strikePrice : "Unlimited",
  };
};

/**
 *
 * @param amountStep Amount step. Eg. "0.001"
 * @param priceStep Price step. Eg. "0.1"
 */
export const getContractPriceStepPrecision = (
  amountStep: string,
  priceStep: string
) => {
  const amountStepSplit = amountStep.split(".");
  const priceStepSplit = priceStep.split(".");
  const amountStepPrecision =
    amountStepSplit.length > 1
      ? amountStepSplit[amountStepSplit.length - 1]?.length
      : 0;
  const priceStepPrecision =
    priceStepSplit.length > 1
      ? priceStepSplit[priceStepSplit.length - 1]?.length
      : 0;

  const amount_precision =
    amountStepPrecision === undefined ? 2 : amountStepPrecision;
  const price_precision =
    priceStepPrecision === undefined ? 2 : priceStepPrecision;

  return {
    amount_step: Number(amountStep),
    price_step: Number(priceStep),
    amount_precision,
    price_precision,
  };
};

// Given an instrument, returns its step and precision
export interface IContractPriceStep {
  amount_step: number;
  price_step: number;
  amount_precision: number;
  price_precision: number;
}
export const getContractPriceStep = (
  marketInstrument?: GetMarkets200Response
): IContractPriceStep => {
  if (!marketInstrument) {
    return {
      amount_step: 0.01,
      price_step: 0.01,
      amount_precision: 2,
      price_precision: 2,
    };
  }

  const { amount_step, price_step } = marketInstrument;
  return getContractPriceStepPrecision(amount_step, price_step);
};

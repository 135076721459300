import axios from "axios";
import { BigNumber } from "ethers";
import { useContext, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import {
  AllCollaterals,
  DepositWithdrawCollaterals,
  MULTICHAIN_CONTRACT_ADDRESSES,
} from "../../../constants/addresses";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ChainIdEnum } from "../../../enums/chain";
import { authApi } from "../../../services/api/apiFetcher";
import { isProduction } from "../../../utils/env";

const baseURL = "https://prod.dlapi.socket.tech/v1/estimate-min-fees";

type ISocketFeesResponse = {
  status: "SUCCESS" | "INTERNAL_SERVER_ERROR";
  // Result is amount in eth. eg. "31500005355000"
  result?: string;
};

// https://6il289myzb.execute-api.us-east-1.amazonaws.com/dev/v1/estimate-min-fees?srcPlug=0x7e54F4c0aCafec318aF1f5d1dCD373CdACc32622&srcChainSlug=421613&dstChainSlug=11155112&msgGasLimit=1000000
// https://6il289myzb.execute-api.us-east-1.amazonaws.com/dev/v1/estimate-min-fees?srcPlug=0x7e54F4c0aCafec318aF1f5d1dCD373CdACc32622&srcChainSlug=421613&dstChainSlug=11155111&msgGasLimit=1000000

const l2ChainId = isProduction() ? 2999 : 11155112;

const unwrapSupportedChainId = (chainId?: ChainIdEnum) => {
  if (!chainId) {
    return undefined;
  }
  const contains = Object.keys(MULTICHAIN_CONTRACT_ADDRESSES).includes(
    String(chainId)
  );
  return contains
    ? (chainId as keyof typeof MULTICHAIN_CONTRACT_ADDRESSES)
    : undefined;
};

const useSocketApi = (chain?: ChainIdEnum, collateral?: AllCollaterals) => {
  const { accountApiKeyState } = useContext(AuthContext);

  const [startsQueryWithdrawal, setStartsQueryWithdrawal] = useState(false);

  const fetcher = useMemo(() => authApi(), []);

  // Wait before querying
  useEffect(() => {
    const t = setTimeout(() => {
      setStartsQueryWithdrawal(true);
    }, 1000);
    return () => clearTimeout(t);
  }, []);

  // Deposit from optimism chain
  const depositParams = useMemo(() => {
    const chainId = unwrapSupportedChainId(chain);

    if (!chainId) {
      return undefined;
    }

    const adds = MULTICHAIN_CONTRACT_ADDRESSES[chainId];
    switch (collateral) {
      case DepositWithdrawCollaterals.Usdc:
        return {
          srcPlug: adds.connector.usdc,
          srcChainSlug: chainId,
          dstChainSlug: l2ChainId,
          msgGasLimit: 1000000,
          dstValue: 1,
        };
      case DepositWithdrawCollaterals.NativeUsdc:
        return {
          srcPlug: adds.connector["native-usdc"],
          srcChainSlug: chainId,
          dstChainSlug: l2ChainId,
          msgGasLimit: 1000000,
          dstValue: 1,
        };
      case DepositWithdrawCollaterals.Weth:
        return {
          srcPlug: adds.connector.weth,
          srcChainSlug: chainId,
          dstChainSlug: l2ChainId,
          msgGasLimit: 1000000,
          dstValue: 1,
        };
      default:
        return undefined;
    }
  }, [chain, collateral]);

  const withdrawParams = useMemo(() => {
    const chainId = unwrapSupportedChainId(chain);

    if (!chainId) {
      return undefined;
    }

    const adds = MULTICHAIN_CONTRACT_ADDRESSES[chainId];
    let msgGasLimit = 500000;
    switch (collateral) {
      case DepositWithdrawCollaterals.Usdc:
        if (
          chainId === ChainIdEnum.ARBITRUM ||
          chainId === ChainIdEnum.ARBITRUM_TESTNET
        ) {
          msgGasLimit = 2000000;
        }

        return {
          srcPlug: adds.withdrawalConnector.usdc,
          srcChainSlug: l2ChainId,
          dstChainSlug: chainId,
          dstValue: 1,
          msgGasLimit,
        };
      case DepositWithdrawCollaterals.NativeUsdc:
        if (
          chainId === ChainIdEnum.ARBITRUM ||
          chainId === ChainIdEnum.ARBITRUM_TESTNET
        ) {
          msgGasLimit = 2000000;
        }

        return {
          srcPlug: adds.withdrawalConnector["native-usdc"],
          srcChainSlug: l2ChainId,
          dstChainSlug: chainId,
          dstValue: 1,
          msgGasLimit,
        };
      case DepositWithdrawCollaterals.Weth:
        if (
          chainId === ChainIdEnum.ARBITRUM ||
          chainId === ChainIdEnum.ARBITRUM_TESTNET
        ) {
          msgGasLimit = 2000000;
        }

        return {
          srcPlug: adds.withdrawalConnector.weth,
          srcChainSlug: l2ChainId,
          dstChainSlug: chainId,
          dstValue: 1,
          msgGasLimit,
        };
      default:
        return undefined;
    }
  }, [chain, collateral]);

  const depositFees = useSWRImmutable(
    depositParams ? [depositParams, baseURL] : undefined,
    async () => {
      const { status, result } = (
        await axios.get(baseURL, {
          params: depositParams,
          headers: {
            "x-api-key": "GIFHLiPLZU8CP0gE4tDSzaIonxWdT7vn1kn6k7Nc",
          },
        })
      ).data as ISocketFeesResponse;
      if (status === "SUCCESS") {
        return result;
      }
      return undefined;
    }
  );

  const withdrawalFees = useSWRImmutable(
    withdrawParams && startsQueryWithdrawal
      ? [withdrawParams, baseURL]
      : undefined,
    async () => {
      const { status, result } = (
        await axios.get(baseURL, {
          params: withdrawParams,
        })
      ).data as ISocketFeesResponse;
      if (status === "SUCCESS") {
        return result;
      }
      return undefined;
    }
  );

  const vaultLimitSWR = useSWR(
    accountApiKeyState === AccountStateEnum.OK
      ? ["GetSocketCapacity"]
      : undefined,
    async () => {
      // Only query if user account is authenticated
      const { data } = await (await fetcher.getSocketCapacity())();
      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: false,
    }
  );

  // We returned the fees * 2 to account for the rising gas fees
  const data = useMemo(
    () => ({
      depositFees: depositFees.data
        ? BigNumber.from((Number(depositFees.data) * 1.1).toFixed(0))
        : undefined,
      withdrawalFees: withdrawalFees.data
        ? BigNumber.from((Number(withdrawalFees.data) * 2).toFixed(0))
        : undefined,
      vaultLimitSWR,
    }),
    [depositFees.data, vaultLimitSWR, withdrawalFees.data]
  );

  return data;
};

export default useSocketApi;

import useSWR from "swr";
import { GetIndexHistories200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { TimePeriodEnum } from "../../../interfaces/TimePeriod";
import { publicApi } from "../../../services/api/apiFetcher";
import { AssetResponse } from "../../../utils/asset";
import { getStartTimeSeconds, secondsToNanos } from "../../../utils/date";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";

export const useGetIndexHistories = (
  assets: AssetResponse[],
  period: TimePeriodEnum = TimePeriodEnum.DAILY,
  resolution: number = 60 * 30
) => {
  const fetcher = publicApi();

  const swr = useSWR<GetIndexHistories200Response>(
    assets.length
      ? [APIEndpointEnum.PRICE_HISTORICAL, assets, period]
      : undefined,
    async () => {
      const start = getStartTimeSeconds(period);

      const { data } = await (
        await fetcher.getIndexHistories(
          assets,
          resolution,
          secondsToNanos(start),
          undefined
        )
      )();
      return data;
    },
    {
      use: [useLoggerMiddleware],
      revalidateOnFocus: false,
    }
  );

  return swr;
};

import styled, { css } from "styled-components";
import {
  COLORS,
  ICON_COLORS,
  LAYER_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../constants/design/spacing";
import { foregroundComponent } from "../../style";
import { glow } from "../Buttons/styles";
import { fadeInCSS } from "../shared/style";

export const ButtonWrapper = styled.button<{ isActive?: boolean }>`
  ${foregroundComponent}

  width: ${COMPONENTS.subHeaderButtonSize}px;
  aspect-ratio: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: ${BORDER_RADIUS.five}px;

  svg {
    width: 20px;
    height: 20px;
    transition: stroke 0.2s ease-in-out;
    stroke: ${ICON_COLORS.three};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      svg {
        stroke: ${COLORS.highlight.one};
      }
      box-shadow: 0px 0px 0px 1px ${COLORS.highlight.one} inset;
    `}

  &:hover {
    svg {
      stroke: ${COLORS.highlight.one};
    }
  }

  ${glow(COLORS.highlight.one)}
`;

export const TradingHeaderWrapper = styled.div<{ isMobileScreen?: boolean }>`
  ${fadeInCSS}
  z-index: 3;
  position: relative;
  display: flex;
  align-items: center;
  height: ${COMPONENTS.header.sub}px;
  margin-bottom: ${({ isMobileScreen }) =>
    isMobileScreen ? SPACING.two : 0}px;

  ${ButtonWrapper} {
    padding: initial;
  }

  > *:not(:last-child) {
    margin-right: ${SPACING.three}px;
  }
`;

export const PriceOnlyLabel = styled.span<{ color?: string }>`
  ${FONT_CSS.data.two}
  color: ${({ color }) => color};
`;

export const SpotChartWrapper = styled.div`
  display: flex;
  width: ${COMPONENTS.optionsSpotChartWrapperWidth}px;
`;

export const SpotWrapper = styled.div`
  flex: 1;

  > *:not(:last-child) {
    margin-bottom: ${SPACING.one}px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
`;

export const PriceChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const StatContainer = styled.div`
  padding: 0px ${SPACING.three}px;

  &:first-of-type {
    padding-left: 0;
  }
`;

export const OtherStatsWrapperParent = styled.div`
  height: 100%;
  flex: 1;
  overflow-x: scroll;
  position: relative;
  border-radius: ${BORDER_RADIUS.five}px;
`;

export const OtherStatsWrapper = styled.div<{ isMobileScreen?: boolean }>`
  background: ${LAYER_COLORS.one};
  height: 100%;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: scroll;
  min-width: max-content;

  ${({ isMobileScreen }) =>
    isMobileScreen
      ? css`
          > * {
            margin: 0 ${SPACING.one}px;

            &:first-of-type {
              margin-left: ${SPACING.three}px;
            }
          }
        `
      : css`
          > * {
            margin: 0 ${SPACING.three}px;
          }
        `}
`;

export const StatData = styled.div<{ color?: string }>`
  ${FONT_CSS.data.three}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

export const BuySellVolumeContainer = styled.div`
  ${FONT_CSS.data.four}
  display: flex;
  align-items: center;

  > span {
    &:first-child {
      margin-right: ${SPACING.two}px;
    }

    &:last-child {
      margin-left: ${SPACING.two}px;
    }
  }

  > div {
    width: 1px;
    height: 8px;
    border-radius: 8px;

    &:not(:last-child) {
      margin-right: 1px;
    }
  }
`;

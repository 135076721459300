import type { AddEthereumChainParameter } from "@web3-react/types";
import { ChainIdEnum } from "../../enums/chain";
import { CHAIN_EXPLORER_URLS } from "../chain";

interface IBasicChainInformation {
  urls: string[];
  name: string;
}

interface IExtendedChainInformation extends IBasicChainInformation {
  nativeCurrency: AddEthereumChainParameter["nativeCurrency"];
  blockExplorerUrls: AddEthereumChainParameter["blockExplorerUrls"];
}

type ChainConfig = {
  [chainId in ChainIdEnum]: IBasicChainInformation | IExtendedChainInformation;
};

const RPC_API_KEY = process.env.REACT_APP_RPC_KEY;

export const CHAINS: ChainConfig = {
  [ChainIdEnum.NONE]: {
    urls: [],
    name: "None",
  },
  [ChainIdEnum.ETH_MAINNET]: {
    urls: [`https://eth-mainnet.g.alchemy.com/v2/${RPC_API_KEY}`],
    name: "Mainnet",
  },
  [ChainIdEnum.SEPOLIA_TESTNET]: {
    urls: [`https://eth-sepolia.g.alchemy.com/v2/${RPC_API_KEY}`],
    name: "Aevo Testnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "SepoliaETH",
      decimals: 18,
    },
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainIdEnum.SEPOLIA_TESTNET]],
  },
  [ChainIdEnum.LOCAL_TESTNET]: {
    urls: ["http://127.0.0.1:8540"],
    name: "Local Testnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: ["http://127.0.0.1/"],
  },
  [ChainIdEnum.OPTIMISM]: {
    urls: ["https://mainnet.optimism.io"],
    name: "OP Mainnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainIdEnum.OPTIMISM]],
  },
  [ChainIdEnum.OPTIMISM_TESTNET]: {
    urls: ["https://sepolia.optimism.io"],
    name: "Optimism Sepolia Testnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainIdEnum.OPTIMISM_TESTNET]],
  },
  [ChainIdEnum.ARBITRUM]: {
    urls: [`https://arb-mainnet.g.alchemy.com/v2/${RPC_API_KEY}`],
    name: "Arbitrum One",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainIdEnum.ARBITRUM]],
  },
  [ChainIdEnum.ARBITRUM_TESTNET]: {
    urls: ["https://sepolia-rollup.arbitrum.io/rpc"],
    name: "Arbitrum Sepolia Testnet",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    blockExplorerUrls: [CHAIN_EXPLORER_URLS[ChainIdEnum.ARBITRUM_TESTNET]],
  },
};

function isExtendedChainInformation(
  chainInformation: IBasicChainInformation | IExtendedChainInformation
): chainInformation is IExtendedChainInformation {
  return !!(chainInformation as IExtendedChainInformation).nativeCurrency;
}

export function getAddChainParameters(
  chainId: number
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId as ChainIdEnum];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  }
  return chainId;
}

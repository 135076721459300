import React, { ReactElement, useEffect, useState } from "react";
import { InstrumentTypeResponse } from "../../codegen-api";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { AssetResponse } from "../../utils/asset";

export interface IMarket {
  asset: AssetResponse;
  derivative: InstrumentTypeResponse;
}

interface IMarketContextType {
  market: IMarket;
  setMarket: React.Dispatch<React.SetStateAction<IMarket>>;
  showCommandModal: boolean;
  setShowCommandModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IMarketContextProviderProps {
  children: ReactElement;
}

export const MarketContext = React.createContext<IMarketContextType>({
  market: {
    asset: "ETH",
    derivative: InstrumentTypeResponse.Perpetual,
  },
  setMarket: () => {},
  showCommandModal: false,
  setShowCommandModal: () => {},
});

const getLastViewedMarket = () => {
  const item = localStorage.getItem(LocalStorageKeyEnum.LAST_VIEWED_MARKET);
  return item ? (JSON.parse(item) as IMarket) : undefined;
};

const setLastViewedMarket = (market: IMarket) => {
  localStorage.setItem(
    LocalStorageKeyEnum.LAST_VIEWED_MARKET,
    JSON.stringify(market)
  );
};

export function MarketContextProvider({
  children,
}: IMarketContextProviderProps) {
  const [showCommandModal, setShowCommandModal] = useState(false);
  const [market, setMarket] = useState<IMarket>({
    asset: getLastViewedMarket()?.asset || "ETH",
    derivative:
      getLastViewedMarket()?.derivative || InstrumentTypeResponse.Perpetual,
  });

  useEffect(() => {
    setLastViewedMarket(market);
  }, [market]);

  return (
    <MarketContext.Provider
      value={{
        market,
        setMarket,
        showCommandModal,
        setShowCommandModal,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
}

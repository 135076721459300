import currency from "currency.js";
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronRight } from "../../../assets/svg/chevron-outline.svg";
import { COLORS, LAYER_COLORS } from "../../../constants/design/colors";
import { useWithdrawalTransfer } from "../../../hooks/api/withdrawTransfer/useWithdrawalTransfer";
import useWallet from "../../../hooks/wallet/useWallet";
import { getAssetLogo } from "../../../utils/asset/assets";
import { shortenAddress } from "../../../utils/strings";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { ButtonsContainer } from "../style";
import { ITransferData, TransferStateEnum } from "./model";
import {
  BackButton,
  ConfirmTransferContainer,
  TextContent,
  TransferArrow,
  TransferFailedIcon,
  TransferProgressContainer,
  TransferSuccessIcon,
} from "./style";
import { AllCollaterals } from "../../../constants/addresses";

interface IConfirmStateProps {
  asset: AllCollaterals;
  transferData: ITransferData;
  onBackClick: () => void;
  onCloseModal: () => void;
  transferState: TransferStateEnum;
  setTransferState: Dispatch<SetStateAction<TransferStateEnum>>;
}

function ConfirmTransferModal({
  asset,
  transferData,
  onBackClick,
  onCloseModal,
  transferState,
  setTransferState,
}: IConfirmStateProps) {
  const wallet = useWallet();
  const { createTransfer } = useWithdrawalTransfer(wallet, asset);
  const { t } = useTranslation("app", {
    keyPrefix: "DepositWithdrawModal.TransferModal.ConfirmTransferModal",
  });

  const onTransfer = useCallback(async () => {
    try {
      setTransferState(TransferStateEnum.PENDING_SIGNATURE);
      await createTransfer(transferData.amount, transferData.address, () => {
        setTransferState(TransferStateEnum.SUBMITTING);
      });
      setTransferState(TransferStateEnum.COMPLETED);
    } catch (e: any) {
      setTransferState(TransferStateEnum.ERROR);
    }
  }, [createTransfer, setTransferState, transferData]);

  const subtitle = useMemo(() => {
    switch (transferState) {
      case TransferStateEnum.COMPLETED:
        return t("you_sent");
      case TransferStateEnum.ERROR:
        return t("failed_to_send");
      default:
        return t("you_are_sending");
    }
  }, [transferState, t]);

  const transferIcon = useMemo(() => {
    switch (transferState) {
      case TransferStateEnum.COMPLETED:
        return <TransferSuccessIcon />;
      case TransferStateEnum.ERROR:
        return <TransferFailedIcon />;
      default:
        return <TransferArrow />;
    }
  }, [transferState]);

  const circleColor = useMemo(() => {
    switch (transferState) {
      case TransferStateEnum.COMPLETED:
        return COLORS.positive.one;
      case TransferStateEnum.ERROR:
        return COLORS.negative.one;
      default:
        return COLORS.neutral.one;
    }
  }, [transferState]);

  const buttons = useMemo(() => {
    const style: CSSProperties = { flex: 1 };
    if (transferState === TransferStateEnum.PENDING_SIGNATURE) {
      style.backgroundColor = LAYER_COLORS.two;
      style.color = COLORS.highlight.one;
    }

    let buttonTitle = t("transfer");
    if (transferState === TransferStateEnum.PENDING_SIGNATURE) {
      buttonTitle = t("sign_message");
    } else if (transferState === TransferStateEnum.SUBMITTING) {
      buttonTitle = t("signed");
    } else if (transferState === TransferStateEnum.COMPLETED) {
      buttonTitle = t("close");
    } else if (transferState === TransferStateEnum.ERROR) {
      buttonTitle = t("try_again");
    }

    return (
      <>
        {transferState !== TransferStateEnum.COMPLETED &&
          transferState !== TransferStateEnum.ERROR && (
            <BackButton
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              disabled={transferState !== TransferStateEnum.CONFIRMATION}
              onClick={onBackClick}
            >
              <ChevronRight />
            </BackButton>
          )}
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          disabled={
            transferState === TransferStateEnum.PENDING_SIGNATURE ||
            transferState === TransferStateEnum.SUBMITTING
          }
          style={style}
          onClick={() => {
            if (transferState === TransferStateEnum.COMPLETED) {
              onCloseModal();
            } else if (transferState === TransferStateEnum.ERROR) {
              setTransferState(TransferStateEnum.CONFIRMATION);
            } else {
              onTransfer();
            }
          }}
        >
          {buttonTitle}
        </Button>
      </>
    );
  }, [
    onBackClick,
    onCloseModal,
    onTransfer,
    setTransferState,
    transferState,
    t,
  ]);

  return (
    <div>
      <ConfirmTransferContainer>
        <TextContent>
          <span>{subtitle}</span>
          <span>
            <img src={getAssetLogo(asset) || ""} alt={asset} />
            {currency(transferData.amount, { symbol: "" }).format()}
          </span>
        </TextContent>
        <TransferProgressContainer>
          <CircularProgressbar
            value={
              transferState === TransferStateEnum.ERROR ||
              transferState === TransferStateEnum.COMPLETED
                ? 100
                : 0
            }
            strokeWidth={4}
            styles={buildStyles({
              strokeLinecap: "round",
              pathTransitionDuration: 0.5,
              trailColor: COLORS.neutral.six,
              pathColor: circleColor,
            })}
          />
          {transferIcon}
        </TransferProgressContainer>
        <TextContent>
          <span>{t("to")}</span>
          <span>{shortenAddress(transferData.address)}</span>
        </TextContent>
      </ConfirmTransferContainer>
      <ButtonsContainer>{buttons}</ButtonsContainer>
    </div>
  );
}

export default ConfirmTransferModal;

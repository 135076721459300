import { useContext, useEffect, useState } from "react";
import { GetTradeHistory200ResponseTradeHistoryInner } from "../../codegen-api";
import { WebsocketContext } from "../../contexts/WebsocketAuthContext";
import { jsonParse } from "../../utils/strings";
import { IWSSFillsResponse } from "./model/fills";

const fillsChannel = "fills:ext";

const useFillsWSS = () => {
  const { authenticated, triggerSubscribe, lastMessages } = useContext(WebsocketContext);

  const [trades, setTrades] = useState<GetTradeHistory200ResponseTradeHistoryInner[]>();

  useEffect(() => {
    if (!authenticated) {
      return;
    }

    const data = [fillsChannel];
    triggerSubscribe(
      "subscribe",
      data,
    );
  }, [authenticated, triggerSubscribe]);

  // Receives messages and updates state
  useEffect(() => {
    if (lastMessages) {
      lastMessages.forEach((lastMessage) => {
        const { data, channel }: IWSSFillsResponse = jsonParse(lastMessage.data);

        if (channel === fillsChannel && data) {
          setTrades((prev) => [
            ...(prev || []),
            data
          ]);
        }
      });
    }
  }, [lastMessages]);

  return {
    trades
  };
};

export default useFillsWSS;

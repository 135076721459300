import { motion } from "framer-motion";
import styled from "styled-components";
import {
  BORDER_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { foregroundComponent } from "../../../style";

export const ToastListWrapper = styled.div`
  z-index: 1000;
  position: absolute;
  top: ${SPACING.three + COMPONENTS.header.main}px;
  right: ${SPACING.three}px;
  max-height: calc(100vh - ${COMPONENTS.header.main}px - ${SPACING.three}px);
  overflow-y: auto;
`;

export const ToastWrapper = styled(motion.div)`
  ${foregroundComponent}
  background-color: ${LAYER_COLORS.two};
  border: 1px solid ${BORDER_COLORS.one}};
  margin-bottom: ${SPACING.three}px;
  width: 350px;
`;

export const ToastHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: ${COMPONENTS.tabs}px;

  > * {
    margin: auto 0;
    display: flex;
  }

  p {
    margin: auto;
  }

  img {
    margin-left: ${SPACING.twoHalf}px;
    margin-right: ${SPACING.two}px;
    width: 32px;
    height: 32px;
  }

  > div {
    align-items: center;
    &:first-child {
      margin: auto 0;
    }
  }
`;

export const ToastInfoWrapper = styled.div`
  padding-top: ${SPACING.three}px;
  border-top: 1px solid ${BORDER_COLORS.two};
  padding: ${SPACING.three}px;
  font-size: ${FONT_SIZE.one};

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto 0;

    &:not(:last-child) {
      margin-bottom: ${SPACING.two}px;
    }
  }
`;

export const ButtonDivider = styled.div`
  top: 0;
  bottom: 0;
  right: 56px;
  width: 1px;
  background-color: ${BORDER_COLORS.two}};
  position: absolute;
`;

export const CloseButton = styled.button`
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatLabel = styled.span`
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.one};
`;

export const Header = styled.div`
  display: block;
  margin: auto 0;
  font-size: ${FONT_SIZE.two};
  padding: ${SPACING.two}px 0;
`;

export const SubheaderWrapper = styled.div`
  font-size: ${FONT_SIZE.one};
  color: ${TEXT_COLORS.two};
  text-transform: capitalize;
  padding-right: ${SPACING.two}px;
`;

import { Suspense, lazy, useCallback, useMemo } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Meta from "../Meta";
import { InstrumentTypeResponse } from "../codegen-api";
import FillsToastAlerter from "../components/FillsToastAlerter";
import Footer from "../components/Footer";
import MobileTabs from "../components/MobileTabs";
import Announcement from "../components/shared/Announcement";
import { Header } from "../components/shared/Header";
import { MobileHeader } from "../components/shared/MobileHeader";
import { MarketInstrumentContextProvider } from "../contexts/MarketInstrumentContext";
import { PageEndpointEnum } from "../enums/endpoint";
import useScreenSize from "../hooks/screenSize/useScreenSize";
import { EarnRedirectPage } from "../pages/EarnRedirectPage";
import MobileHistoricalPage from "../pages/HistoricalPage/mobile/MobileHistoricalPage";
import MobileRFQPage from "../pages/RFQPage/mobile/MobileRFQPage";
import { ReferralRedirectPage } from "../pages/ReferralRedirectPage";
import MobileReferralsPage from "../pages/ReferralsPage/mobile";
import TradingPage from "../pages/TradingPage";
import MobileTradingPage from "../pages/TradingPage/MobileTradingPage";
import { AssetResponse } from "../utils/asset";
import { IRoute } from "./shared";
import {
  TradingRoutePathParamsEnum,
  getTradingRoutePath,
  validTradingRoutes,
} from "./tradingRoute";

const RFQPage = lazy(() => import("../pages/RFQPage"));
const HistoricalPage = lazy(() => import("../pages/HistoricalPage"));
const PortfolioPage = lazy(() => import("../pages/PortfolioPage"));
const ReferralsPage = lazy(() => import("../pages/ReferralsPage"));
const RewardsPage = lazy(() => import("../pages/RewardsPage"));
const MobilePortfolioPage = lazy(
  () => import("../pages/PortfolioPage/mobile/MobilePortfolioPage")
);
const SettingsPage = lazy(() => import("../pages/SettingsPage"));
const MobileSettingsPage = lazy(
  () => import("../pages/SettingsPage/mobile/MobileSettingsPage")
);

function OutletRoute() {
  // UPDATE CONTEXT WHEN ROUTES CHANGES
  const p = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobileScreen } = useScreenSize();

  const currentPage: PageEndpointEnum = useMemo(() => {
    const { pathname } = location;
    const allPages = Object.keys(PageEndpointEnum);
    const isTrading = pathname
      .split("/")
      .some((name) =>
        Object.values(InstrumentTypeResponse).some(
          (t) => t.toLowerCase() === name
        )
      );
    if (isTrading) {
      return PageEndpointEnum.TRADING;
    }

    const matchedRouteKeys: string[] = allPages.filter((k) => {
      const route = PageEndpointEnum[k as keyof typeof PageEndpointEnum];
      return pathname.includes(route);
    });

    // Get the last matched route
    if (matchedRouteKeys.length) {
      const lastMatchedKey = matchedRouteKeys[matchedRouteKeys.length - 1];
      return PageEndpointEnum[lastMatchedKey as keyof typeof PageEndpointEnum];
    }
    return PageEndpointEnum.TRADING;
  }, [location]);

  // This fixes params keep rerendering
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = useMemo(() => p, [JSON.stringify(p)]);

  // Derive the asset, derivative, and instrument from the params.
  // eg. if the URL is /option/eth/ETH-07JUL23-1850-C,
  // returns asset = ETH, derivative = option, instrument = ETH-07JUL23-1850-C
  const assetDerivativeParams = useMemo(() => {
    if (currentPage === PageEndpointEnum.TRADING) {
      const d = params[TradingRoutePathParamsEnum.DERIVATIVE];
      const validDerivative =
        d &&
        (Object.values(InstrumentTypeResponse) as string[]).includes(
          d?.toUpperCase() || ""
        );
      const a = params[TradingRoutePathParamsEnum.ASSET];
      const validAsset = a?.toUpperCase();

      return {
        asset: validAsset ? (a!.toUpperCase() as AssetResponse) : undefined,
        derivative: validDerivative
          ? (d!.toUpperCase() as InstrumentTypeResponse)
          : undefined,
        instrument: params[TradingRoutePathParamsEnum.INSTRUMENT_NAME],
      };
    }
    return undefined;
  }, [currentPage, params]);

  const onAssetDerivativeChanged = useCallback(
    (
      asset?: AssetResponse,
      derivative?: InstrumentTypeResponse,
      instrumentName?: string
    ) => {
      // Ignore if is not trading path
      if (currentPage !== PageEndpointEnum.TRADING) {
        return;
      }

      const newPath = getTradingRoutePath(
        (derivative || InstrumentTypeResponse.Option) as InstrumentTypeResponse,
        asset || "ETH",
        (derivative as InstrumentTypeResponse) === InstrumentTypeResponse.Option
          ? instrumentName
          : undefined
      );
      navigate(newPath, { replace: true });
    },
    [currentPage, navigate]
  );

  return (
    <MarketInstrumentContextProvider
      params={assetDerivativeParams}
      onAssetDerivativeChanged={onAssetDerivativeChanged}
    >
      <FillsToastAlerter />
      {!isMobileScreen ? (
        <Announcement key="alert" announcementType="alert" />
      ) : null}
      {!isMobileScreen ? (
        <Announcement key="feature" announcementType="feature" />
      ) : null}
      {!isMobileScreen ? <Header /> : <MobileHeader />}
      <Meta />
      <Outlet />
      {!isMobileScreen ? (
        <Footer />
      ) : (
        <MobileTabs navigate={navigate} currentPage={currentPage} />
      )}
    </MarketInstrumentContextProvider>
  );
}

function AevoRoutes() {
  const { isMobileScreen } = useScreenSize();

  const routes = useMemo(() => {
    const tradingPage = isMobileScreen ? (
      <MobileTradingPage />
    ) : (
      <TradingPage />
    );
    const tradingRoutes = validTradingRoutes.map((r) => ({
      path: r,
      page: tradingPage,
    }));
    const allRoutes: IRoute[] = [
      {
        path: `${PageEndpointEnum.PORTFOLIO}/*`,
        page: isMobileScreen ? (
          <Suspense>
            <MobilePortfolioPage />
          </Suspense>
        ) : (
          <Suspense>
            <PortfolioPage />
          </Suspense>
        ),
      },
      {
        path: `${PageEndpointEnum.REFERRALS}/*`,
        page: isMobileScreen ? (
          <Suspense>
            <MobileReferralsPage />
          </Suspense>
        ) : (
          <Suspense>
            <ReferralsPage />
          </Suspense>
        ),
      },
      {
        path: `${PageEndpointEnum.AEVO}/*`,
        page: isMobileScreen ? (
          <Suspense>
            <RewardsPage />
          </Suspense>
        ) : (
          <Suspense>
            <RewardsPage />
          </Suspense>
        ),
      },
      {
        path: `${PageEndpointEnum.HISTORICAL}/*`,
        page: isMobileScreen ? (
          <Suspense>
            <MobileHistoricalPage />
          </Suspense>
        ) : (
          <Suspense>
            <HistoricalPage />
          </Suspense>
        ),
      },
      {
        path: `${PageEndpointEnum.RFQ}/*`,
        page: isMobileScreen ? (
          <Suspense>
            <MobileRFQPage />
          </Suspense>
        ) : (
          <Suspense>
            <RFQPage />
          </Suspense>
        ),
      },
      // todo: readd later
      // {
      //   path: `${PageEndpointEnum.STRATEGIES}/*`,
      //   page: (
      //     <Suspense>
      //       <StrategiesPage isMobile={isMobileScreen} />
      //     </Suspense>
      //   ),
      // },
      {
        path: `${PageEndpointEnum.SETTINGS}/*`,
        page: isMobileScreen ? (
          <Suspense>
            <MobileSettingsPage />
          </Suspense>
        ) : (
          <Suspense>
            <SettingsPage />
          </Suspense>
        ),
      },
      {
        path: `${PageEndpointEnum.REF_LINK}/*`,
        page: <ReferralRedirectPage />,
      },
      {
        path: `${PageEndpointEnum.EARN}`,
        page: <EarnRedirectPage />,
      },
      {
        path: `${PageEndpointEnum.PWA}`,
        page: tradingPage,
      },
      // Trading routes.
      // Supports url like
      // aevo.xyz/option/eth, aevo.xyz/option/eth/ETH-21APR23-1800-P, aevo.xyz/perpetual/eth, aevo.xyz/
      ...tradingRoutes,
    ];
    return allRoutes;
  }, [isMobileScreen]);

  return (
    <Routes>
      <Route key={"/"} path={"/"} element={<OutletRoute />}>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.page} />
        ))}
      </Route>
    </Routes>
  );
}

export default AevoRoutes;

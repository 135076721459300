import { CSSProperties, useMemo } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import deltaIcon from "../../../assets/svg/delta.svg";
import gammaIcon from "../../../assets/svg/gamma.svg";
import rhoIcon from "../../../assets/svg/rho.svg";
import thetaIcon from "../../../assets/svg/theta.svg";
import vegaIcon from "../../../assets/svg/vega.svg";
import { GetPortfolio200ResponseGreeksInner } from "../../../codegen-api";
import { Label } from "../../../pages/PortfolioOverviewTab/style";
import { Spinner } from "../../shared/Spinner";
import { Greek, GreekWrapper, GreeksContent } from "./style";

interface IGreeksProps {
  loading?: boolean;
  greeks?: GetPortfolio200ResponseGreeksInner;
  wrapperStyle?: CSSProperties;
  contentStyle?: CSSProperties;
}

export function Greeks({
  loading,
  greeks,
  wrapperStyle,
  contentStyle,
}: IGreeksProps) {
  const { t } = useTranslation("app", { keyPrefix: "TicketDetails.Greeks" });
  const greeksData = useMemo(() => {
    if (loading) {
      return {
        delta: <Spinner />,
        vega: <Spinner />,
        gamma: <Spinner />,
        rho: <Spinner />,
        theta: <Spinner />,
      };
    }
    return {
      delta: greeks ? Number(greeks.delta).toFixed(2) : "-",
      vega: greeks ? Number(greeks.vega).toFixed(2) : "-",
      gamma: greeks ? Number(greeks.gamma).toFixed(6) : "-",
      rho: greeks ? Number(greeks.rho).toFixed(2) : "-",
      theta: greeks ? Number(greeks.theta).toFixed(2) : "-",
    };
  }, [greeks, loading]);

  return (
    <div style={wrapperStyle}>
      <GreeksContent style={contentStyle}>
        <Col xs={6}>
          <GreekWrapper>
            <Greek>
              <img src={deltaIcon} alt="delta" />
            </Greek>
            <div>
              <Label>{t("delta")}</Label>
              <p>{greeksData.delta}</p>
            </div>
          </GreekWrapper>
        </Col>
        <Col xs={6}>
          <GreekWrapper>
            <Greek>
              <img src={vegaIcon} alt="vega" />
            </Greek>
            <div>
              <Label>{t("vega")}</Label>
              <p>{greeksData.vega}</p>
            </div>
          </GreekWrapper>
        </Col>
        <Col xs={6}>
          <GreekWrapper>
            <Greek>
              <img src={gammaIcon} alt="gamma" />
            </Greek>
            <div>
              <Label>{t("gamma")}</Label>
              <p>{greeksData.gamma}</p>
            </div>
          </GreekWrapper>
        </Col>
        <Col xs={6}>
          <GreekWrapper>
            <Greek>
              <img src={rhoIcon} alt="rho" />
            </Greek>
            <div>
              <Label>{t("rho")}</Label>
              <p>{greeksData.rho}</p>
            </div>
          </GreekWrapper>
        </Col>
        <Col xs={6}>
          <GreekWrapper>
            <Greek>
              <img src={thetaIcon} alt="theta" />
            </Greek>
            <div>
              <Label>{t("theta")}</Label>
              <p>{greeksData.theta}</p>
            </div>
          </GreekWrapper>
        </Col>
      </GreeksContent>
    </div>
  );
}

import currency from "currency.js";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../constants/design/colors";
import { usePriceChangeColor } from "../../../hooks/usePriceChangeColor";
import { Spinner } from "../../shared/Spinner";
import { CurrentSpotWrapper, Title } from "./style";
import { AssetResponse } from "../../../utils/asset";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";

interface ICurrentSpotSectionProps {
  asset: AssetResponse;
  price?: number;
  loading?: boolean;
}

const CurrentSpotSection = forwardRef<any, ICurrentSpotSectionProps>(
  ({ asset, price, loading }, ref) => {
    const color = usePriceChangeColor(
      COLORS.negative.one,
      COLORS.positive.one,
      price
    );
    const { t } = useTranslation("app", {
      keyPrefix: "OptionsChain.CurrentSpotSection",
    });
    const { isMobileScreen } = useScreenSize();

    return (
      <CurrentSpotWrapper ref={ref} isMobileScreen={isMobileScreen}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Title>{t("current_spot", { asset })}:</Title>
            <span style={{ color }}>
              {price ? currency(price).format() : "---"}
            </span>
          </>
        )}
      </CurrentSpotWrapper>
    );
  }
);

export default CurrentSpotSection;

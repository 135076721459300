/* eslint-disable no-nested-ternary */
import currency from "currency.js";
import { useAnimation } from "framer-motion";
import { SubmitHandler, useWatch } from "react-hook-form";
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  GetAccount200ResponsePositionsInner,
  InstrumentTypeResponse,
  OrderTypeResponse,
  SideResponse,
  Stop,
  TimeInForce,
} from "../../../codegen-api";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { IPerpsMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import { useOrder } from "../../../hooks/api/order/useOrder";
import { useRFQ } from "../../../hooks/api/rfq/useRFQ";
import { useToast } from "../../../hooks/toast";
import usePersistentState from "../../../hooks/usePersistentState";
import usePositionsWSS from "../../../hooks/wss/usePositionsWSS";
import { ICreateOrderBody } from "../../../interfaces/Order";
import { IStat } from "../../../interfaces/Toast";
import { AssetResponse } from "../../../utils/asset";
import { getAssetLogo } from "../../../utils/asset/assets";
import { getContractPriceStep } from "../../../utils/instruments";
import { getStopOrderName } from "../../../utils/order";
import {
  ToastEnum,
  ToastStatusEnum,
  getToastTitleForInstrument,
} from "../../../utils/toast";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import ClosePositionModal from "../../ConfirmationModal/ClosePositionModal";
import { Divider } from "../../shared/Divider";
import SegmentedControl from "../../shared/SegmentedControl";
import {
  BoostTitle,
  BoostValueWrapper,
  ContentWrapper,
  DeselectReduceOnlyContainer,
  DetailsWrapper,
  FormContent,
  GapWrapper,
  InfoRow,
  InputsWrapper,
  MarginTypeWrapper,
  OrderTypeWrapper,
  Padding,
  PlaceOrderButton,
  PositionChip,
  PositionInfoWrapper,
  SubmitWrapper,
  Title,
  TradeExecutedBar,
  TradeForm,
  TradeInfoWrapper,
  TransactionTypeWrapper,
  Value,
  ReduceOnlyAndTIFContainer,
  TIFDropdownContainer,
} from "../style";
import PerformanceDetails from "./PerformanceDetails";
import {
  IPerpsFormFieldValues,
  LeverageSelectInput,
  MarginSelector,
  OrderSizeInput,
  PerpFormFieldKeyEnum,
  PriceInput,
  TriggerPriceInput,
  usePerpsForm,
} from "./form";
import useOrderbookWSS from "../../../hooks/wss/useOrderbookWSS";
import useMarkPriceWSS from "../../../hooks/wss/useMarkPriceWSS";
import { FormValidatorKeysEnum } from "../../../enums/form";
import { IPositionInfo, ITradeInfo } from "../../../interfaces/TradeInfo";
import {
  formatSizePrecision,
  roundToStepSize,
  roundUpToPrecision,
} from "../../../utils/format";
import { IPriceSize } from "../../../interfaces/Orderbook";
import { getValueOfContractsAmount } from "../../../utils/orderbook";
import { Spinner } from "../../shared/Spinner";
import FeeInfo from "../OptionsTradeForm/FeeInfo";
import { ReduceOnlyInput, TIFSelector } from "../form";
import { useFarm } from "../../../hooks/api/farm/useFarm";
import {
  calculateEstimatedTradeRewards,
  calculateFarmBoostMultiplier,
} from "../../../utils/math";
import TradeFormFarmingTooltip from "../../shared/TradeFormFarmingTooltip";
import { ExtraFarmBoostDetails } from "../ExtraFarmBoostDetails";
import { useEmissions } from "../../../hooks/api/emissions/useEmissions";
import { ReactComponent as AevoLogo } from "../../../assets/logo/logo_white.svg";
import { ReactComponent as Alert } from "../../../assets/svg/alert.svg";

export interface IPerpsFormDefaultValues {
  amount?: string;
  price?: string;
  side?: SideResponse;
}
export interface ITradeModalProps {
  perpInstrument?: IPerpsMarket;
  mobileMode?: boolean;
  onClose?: () => void;
  defaultValues?: IPerpsFormDefaultValues;
}

function PerpsTradeForm({
  perpInstrument,
  mobileMode,
  onClose,
  defaultValues,
}: PropsWithChildren<ITradeModalProps>) {
  const { t: apiError } = useTranslation("apiErrors");
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { accountSigningKeyState, account } = useContext(AuthContext);
  const { showOnboarding, data: accountData } = useGetAccount();
  const { data: farmData, mutate: mutateFarmData } = useFarm();
  const form = usePerpsForm();
  const { createOrder } = useOrder();
  const { deleteRFQsByInstrumentId } = useRFQ(undefined, false);
  const { data: emissionsData } = useEmissions();
  const { addToast, addErrorToast } = useToast();
  const animControls = useAnimation();
  const { t } = useTranslation();
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });
  const { t: perpTradeFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.PerpsTradeForm",
  });
  const { positionsMap } = usePositionsWSS();
  const [showClosePositionModal, setClosePositionModal] = useState(false);

  const { tradeUseUSDCTerms, setTradeUseUSDCTerms } = usePersistentState();

  const [orderDirection, setOrderDirection] = useState<SideResponse>(
    defaultValues?.side ?? SideResponse.Buy
  );
  const [orderType, setOrderType] = useState<OrderTypeResponse>(
    OrderTypeResponse.Market
  );
  const [stopType, setStopType] = useState<Stop>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Only for dropdown displaying purpose
  const [tempOrderType, setTempOrderType] = useState<OrderTypeResponse>(
    OrderTypeResponse.Market
  );
  const [tempStopType, setTempStopType] = useState<Stop>(Stop.StopLoss);
  const dropdownValue = stopType
    ? `${orderType}-${stopType}`
    : `${tempOrderType}-${tempStopType}`;

  const modalRef = useRef<HTMLDivElement>(null);

  const [isSticky, setIsSticky] = useState(false);

  const [tifType, setTifType] = useState<TimeInForce>(TimeInForce.Gtc);

  const [tifDropdown, setTifDropdown] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (modalRef.current) {
        const currentHeight = modalRef.current.clientHeight;
        setIsSticky(currentHeight < 900);
      }
    };

    // Perform an initial check after 2 seconds.
    const timeoutId = setTimeout(handleResize, 2000);

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Updates default values
  useEffect(() => {
    if (defaultValues?.amount || defaultValues?.price) {
      setOrderType(OrderTypeResponse.Limit);
      if (defaultValues.amount) {
        form.setValue(PerpFormFieldKeyEnum.AMOUNT, defaultValues.amount, {
          shouldValidate: true,
        });
      }
      if (defaultValues.price) {
        form.setValue(PerpFormFieldKeyEnum.PRICE, defaultValues.price, {
          shouldValidate: true,
        });
      }
    }
  }, [defaultValues, form]);

  const currentPosition: GetAccount200ResponsePositionsInner | undefined =
    positionsMap[perpInstrument?.instrument_name || ""];

  const contractPriceStep = useMemo(
    () => getContractPriceStep(perpInstrument),
    [perpInstrument]
  );

  const { t: formTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    control,
    unregister,
    trigger,
  } = form;

  // form fields

  const triggerPrice = useWatch({
    control,
    name: PerpFormFieldKeyEnum.TRIGGER_PRICE,
  });
  const leverage = useWatch({ control, name: PerpFormFieldKeyEnum.LEVERAGE });
  const amount = useWatch({ control, name: PerpFormFieldKeyEnum.AMOUNT });
  const price = useWatch({ control, name: PerpFormFieldKeyEnum.PRICE });

  // wss

  const { orderbook: orderbookData } = useOrderbookWSS(
    perpInstrument?.instrument_name,
    orderType
  );

  const uniqueTickers = useMemo(
    () =>
      perpInstrument
        ? {
            [perpInstrument.instrument_name]: {
              asset: perpInstrument.underlying_asset as AssetResponse,
              derivative: InstrumentTypeResponse.Perpetual,
            },
          }
        : {},
    [perpInstrument]
  );

  const { instrumentMark } = useMarkPriceWSS(uniqueTickers);

  const mark = instrumentMark[`${perpInstrument?.underlying_asset}-PERP`];
  const markPrice = mark?.mark_price || perpInstrument?.mark_price;

  const [reduceOnly, setReduceOnly] = useState(false);

  // On unmount, unregister from form
  useEffect(
    () => () => {
      Object.values(PerpFormFieldKeyEnum).forEach((k) => {
        unregister(k);
      });
    },
    [unregister]
  );

  const orderValueTooSmall =
    errors?.amount?.type === FormValidatorKeysEnum.orderValueTooSmall;

  const notEnoughBalanceError =
    errors?.amount?.type === FormValidatorKeysEnum.notEnoughBalance;

  // Updates default values
  useEffect(() => {
    if (defaultValues?.amount) {
      setValue(PerpFormFieldKeyEnum.AMOUNT, defaultValues.amount, {
        shouldValidate: true,
      });
    }
  }, [defaultValues, setValue]);

  // Market triggers

  // Update whenever reduce only checkbox is changed
  useEffect(() => {
    if (isDirty && amount) {
      trigger(PerpFormFieldKeyEnum.AMOUNT);
    }
  }, [reduceOnly, orderDirection, trigger, isDirty, amount]);

  useEffect(() => {
    if (isDirty) {
      trigger(PerpFormFieldKeyEnum.LEVERAGE);
    }
  }, [amount, isDirty, trigger]);

  // Limit triggers
  useEffect(() => {
    if (amount && price) {
      trigger("price");
      trigger("amount");
    }
  }, [amount, price, trigger]);

  useEffect(() => {
    if (price) {
      trigger("price");
    }
  }, [orderDirection, price, trigger]);

  // Reduce only is disabled if theres no position, or position === orderDirection
  const reduceOnlyNotAllowed = useMemo(
    () => !currentPosition || currentPosition.side === orderDirection,
    [currentPosition, orderDirection]
  );

  const accountLeverage = useMemo(
    () =>
      accountData?.leverages?.find(
        (lev) => lev.instrument_id === perpInstrument?.instrument_id
      )?.leverage,
    [accountData?.leverages, perpInstrument?.instrument_id]
  );

  // Account max leverage takes precedence before asset max leverage
  const maxLeverage = useMemo(() => {
    const lev = accountLeverage
      ? Number(accountLeverage)
      : Number(perpInstrument?.max_leverage);
    return lev || 0;
  }, [accountLeverage, perpInstrument?.max_leverage]);

  const buyingPower = useMemo(() => {
    if (accountData) {
      return Number(accountData.available_balance) * maxLeverage;
    }
    return 0;
  }, [accountData, maxLeverage]);

  const replacePlaceOrderButton = useMemo(() => {
    if (accountSigningKeyState !== AccountStateEnum.OK || showOnboarding) {
      return (
        <Button
          fullWidth
          type="button"
          buttonTheme={ButtonThemeEnum.HIGHLIGHT}
          onClick={() => setShowConnectModal(true)}
        >
          {accountSigningKeyState === AccountStateEnum.REQUIRE_PASSWORD
            ? commonFormTranslations("unlock_trading")
            : accountSigningKeyState === AccountStateEnum.OK
            ? commonFormTranslations("continue_onboarding")
            : account
            ? commonFormTranslations("complete_sign_in")
            : commonFormTranslations("connect_wallet")}
        </Button>
      );
    }
    return undefined;
  }, [
    accountSigningKeyState,
    account,
    commonFormTranslations,
    setShowConnectModal,
    showOnboarding,
  ]);

  const onCloseModalHide = useCallback(() => {
    if (currentPosition)
      deleteRFQsByInstrumentId(currentPosition.instrument_id);
    setClosePositionModal(false);
  }, [currentPosition, deleteRFQsByInstrumentId]);

  const showOrderCreatedAnimation = useCallback(async () => {
    await animControls.start({
      opacity: 1,
      scale: 1,
      transition: { duration: 0.6 },
    });
    await animControls.start({
      opacity: 0,
      scale: 0,
      transition: {
        delay: 3,
        duration: 0.5,
      },
    });
  }, [animControls]);

  const onCreateOrder = useCallback(
    async (order: ICreateOrderBody) => {
      if (!perpInstrument) {
        return;
      }

      try {
        setIsLoading(true);
        const response = await createOrder(order);

        if (response.order_id) {
          const stats: IStat[] = response.stop
            ? [
                {
                  label: commonFormTranslations("contracts"),
                  value: Number(response.amount).toFixed(
                    contractPriceStep.amount_precision
                  ),
                },
                {
                  label: perpTradeFormTranslations("trigger_price"),
                  value: currency(response.trigger || 0, {
                    precision:
                      getContractPriceStep(perpInstrument).price_precision,
                  }).format(),
                },
              ]
            : [
                {
                  label:
                    orderDirection === SideResponse.Buy
                      ? commonFormTranslations("buy_amount")
                      : commonFormTranslations("sell_amount"),
                  value: (
                    <span
                      style={{
                        color:
                          orderDirection === SideResponse.Buy
                            ? COLORS.positive.one
                            : COLORS.negative.one,
                      }}
                    >
                      {Number(order.amount).toFixed(
                        contractPriceStep.amount_precision
                      ) || (0).toFixed(contractPriceStep.amount_precision)}
                    </span>
                  ),
                },
              ];

          // Add limit price for limit orders,
          // Add reduce only for market orders
          if (order.orderType === OrderTypeResponse.Limit) {
            stats.push({
              label: commonFormTranslations("limit_price"),
              value: currency(order.price || 0, {
                precision: getContractPriceStep(perpInstrument).price_precision,
              }).format(),
            });
          } else {
            stats.push({
              label: perpTradeFormTranslations("reduce_only"),
              value: order.reduceOnly ? "Yes" : "No",
            });
          }

          // Only show immediate toast if is market order.
          // Else just show order placed
          addToast(
            {
              type: ToastEnum.INFO,
              icon: getAssetLogo(
                perpInstrument.underlying_asset as AssetResponse
              ) as string,
              header: (
                <p>
                  {getToastTitleForInstrument(
                    InstrumentTypeResponse.Perpetual,
                    perpInstrument.instrument_name
                  )}
                </p>
              ),
              subheader: (
                <span
                  style={{
                    color:
                      orderDirection === SideResponse.Buy
                        ? COLORS.positive.one
                        : COLORS.negative.one,
                  }}
                >
                  {response.stop
                    ? `${response.side} ${getStopOrderName(
                        t,
                        response.order_type,
                        response.stop
                      )} - Placed`
                    : `${response.order_type} ${response.side}`}
                </span>
              ),
              stats,
              status: ToastStatusEnum.SUCCESS,
            },
            4000
          );
          showOrderCreatedAnimation();
          mutateFarmData();
          form.reset();
          onClose?.();
        }
      } catch (error: any) {
        let errorTitle = "";
        if (order.orderType === OrderTypeResponse.Market) {
          errorTitle =
            orderDirection === SideResponse.Buy
              ? commonFormTranslations("market_buy_failed")
              : commonFormTranslations("market_sell_failed");
        } else {
          errorTitle =
            orderDirection === SideResponse.Buy
              ? commonFormTranslations("limit_buy_failed")
              : commonFormTranslations("limit_sell_failed");
        }
        addErrorToast(
          <p>{errorTitle}</p>,
          apiError(error.message) || commonFormTranslations("place_order_again")
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      perpInstrument,
      createOrder,
      commonFormTranslations,
      contractPriceStep.amount_precision,
      perpTradeFormTranslations,
      orderDirection,
      addToast,
      t,
      showOrderCreatedAnimation,
      mutateFarmData,
      form,
      onClose,
      addErrorToast,
      apiError,
    ]
  );

  const positionInfo = useMemo<IPositionInfo[]>(() => {
    const posInfo: IPositionInfo[] = [
      {
        title: commonFormTranslations("margin_balance"),
        value: accountData?.available_balance
          ? currency(accountData.available_balance).format()
          : "---",
        side: undefined,
      },
      {
        title: commonFormTranslations("buying_power"),
        value: buyingPower ? currency(buyingPower).format() : "---",
        side: undefined,
        warningOrError: notEnoughBalanceError ? "error" : undefined,
        showErrorIcon: true,
      },
      {
        title: `${formTranslations("position")} (${
          perpInstrument?.underlying_asset as AssetResponse
        })`,
        value: currentPosition
          ? formatSizePrecision(
              Number(currentPosition.amount),
              contractPriceStep.amount_precision
            )
          : "---",
        side: currentPosition?.side,
      },
    ];
    return posInfo;
  }, [
    commonFormTranslations,
    formTranslations,
    contractPriceStep.amount_precision,
    accountData?.available_balance,
    buyingPower,
    notEnoughBalanceError,
    perpInstrument?.underlying_asset,
    currentPosition,
  ]);

  const minimumUSDCValueForValidContractSize = useMemo(() => {
    const { amount_step, amount_precision } = contractPriceStep;
    if (orderbookData) {
      // Market order
      const bestBidOrAskPrice = (
        orderDirection === SideResponse.Buy
          ? orderbookData.asks
          : orderbookData.bids
      )?.[0]?.[0];
      // Round UP to precision
      return roundUpToPrecision(
        amount_step * Number(bestBidOrAskPrice || 0),
        amount_precision
      );
    }
    if (price) {
      // Round UP to precision
      return roundUpToPrecision(amount_step * Number(price), amount_precision);
    }
    return 0;
  }, [contractPriceStep, orderDirection, orderbookData, price]);

  const limitPriceValidateFn = useMemo(() => {
    if (stopType === Stop.StopLoss) {
      // If stop limit and buy, limit price >= trigger price
      if (orderDirection === SideResponse.Buy) {
        return {
          [FormValidatorKeysEnum.limitPriceAboveTriggerPrice]: (v: string) =>
            parseFloat(v) >= Number(triggerPrice),
          [FormValidatorKeysEnum.limitPriceBelowTriggerPrice]: () => true,
        };
      }
      return {
        [FormValidatorKeysEnum.limitPriceAboveTriggerPrice]: () => true,
        [FormValidatorKeysEnum.limitPriceBelowTriggerPrice]: (v: string) =>
          parseFloat(v) <= Number(triggerPrice),
      };
    }
    if (stopType === Stop.TakeProfit) {
      if (orderDirection === SideResponse.Buy) {
        // If take profit and buy, limit price >= trigger price
        return {
          [FormValidatorKeysEnum.limitPriceAboveTriggerPrice]: () => true,
          [FormValidatorKeysEnum.limitPriceBelowTriggerPrice]: (v: string) =>
            parseFloat(v) <= Number(triggerPrice),
        };
      }
      return {
        [FormValidatorKeysEnum.limitPriceAboveTriggerPrice]: (v: string) =>
          parseFloat(v) >= Number(triggerPrice),
        [FormValidatorKeysEnum.limitPriceBelowTriggerPrice]: () => true,
      };
    }

    // if not stops, dont do anything
    return {
      [FormValidatorKeysEnum.limitPriceAboveTriggerPrice]: () => true,
      [FormValidatorKeysEnum.limitPriceBelowTriggerPrice]: () => true,
    };
  }, [orderDirection, stopType, triggerPrice]);

  const triggerPriceValidateFn = useMemo(() => {
    if (!stopType) {
      return {
        [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
        [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
      };
    }

    // If BUY STOP LOSS or SELL TAKE PROFIT, trigger price must be higher than mark
    if (
      (orderDirection === SideResponse.Buy && stopType === Stop.StopLoss) ||
      (orderDirection === SideResponse.Sell && stopType === Stop.TakeProfit)
    ) {
      return {
        [FormValidatorKeysEnum.triggerPriceBelowMark]: () => true,
        [FormValidatorKeysEnum.triggerPriceAboveMark]: (v: string) =>
          parseFloat(v) >= Number(markPrice),
      };
    }
    // If SELL STOP LOSS or BUY TAKE PROFIT, trigger price must be lower than mark
    return {
      [FormValidatorKeysEnum.triggerPriceBelowMark]: (v: string) =>
        parseFloat(v) <= Number(markPrice),
      [FormValidatorKeysEnum.triggerPriceAboveMark]: () => true,
    };
  }, [markPrice, orderDirection, stopType]);

  const customError = useMemo(
    () =>
      tradeUseUSDCTerms &&
      amount &&
      Number(amount) < minimumUSDCValueForValidContractSize
        ? formTranslations("minimum_size_error", {
            size: minimumUSDCValueForValidContractSize,
            asset: "USDC",
          })
        : undefined,
    [
      amount,
      formTranslations,
      minimumUSDCValueForValidContractSize,
      tradeUseUSDCTerms,
    ]
  );

  const getRealContractSize = useCallback(
    (amt: string, isUSD?: boolean) => {
      // if asset is USD and market order,
      // go through OB to figure out how many contracts we can buy
      if (isUSD) {
        const stepSize = contractPriceStep.amount_step;
        const precision = contractPriceStep.amount_precision;

        if (orderbookData) {
          let amtInUSD = Number(amt || 0);
          const bidsAsks =
            orderDirection === SideResponse.Buy
              ? orderbookData.asks
              : orderbookData.bids;
          const totalSize = bidsAsks?.reduce((prev, ask) => {
            if (!amtInUSD) {
              return prev;
            }

            const [askPrice, size] = ask;

            // Size per USD
            const totalCost = Number(askPrice) * Number(size);

            // Buying more than this level, go to next level
            if (amtInUSD > totalCost) {
              amtInUSD -= totalCost;
              return prev + Number(size);
            }

            // Not enough to buy the entire level, calculate contract
            const sizeAbleToBuy = amtInUSD / Number(askPrice);
            amtInUSD = 0;
            return prev + sizeAbleToBuy;
          }, 0);

          return roundToStepSize(totalSize || 0, stepSize, precision);
        }
      }

      // if asset is underlying, just use size
      return Number(amt || 0);
    },
    [contractPriceStep, orderDirection, orderbookData]
  );

  const calculateTotalValueWithSize = useCallback(
    (amountStr: string, isUSDC?: boolean) => {
      let total = 0;

      const isUSD = isUSDC || tradeUseUSDCTerms;

      if (isUSD) {
        return Number(amountStr);
      }

      const realSize = getRealContractSize(amountStr, false);

      // Limit order
      if (orderType === OrderTypeResponse.Limit) {
        return realSize * Number(price);
      }

      if (stopType) {
        // If is a stop order, calculate total using trigger price instead
        total = realSize * (Number(triggerPrice) || 0);
      } else {
        total = getValueOfContractsAmount(
          orderDirection,
          orderDirection === SideResponse.Buy
            ? (orderbookData?.asks as IPriceSize[]) || []
            : (orderbookData?.bids as IPriceSize[]) || [],
          realSize
        );
      }

      return total;
    },
    [
      price,
      orderType,
      tradeUseUSDCTerms,
      getRealContractSize,
      stopType,
      triggerPrice,
      orderDirection,
      orderbookData?.asks,
      orderbookData?.bids,
    ]
  );

  const realContractSize = getRealContractSize(amount, tradeUseUSDCTerms);

  const tradeInfo = useMemo<ITradeInfo[]>(() => {
    // ================ MARKET ORDERS ================
    // MARKET BUY/SELL ORDER
    const totalValue = calculateTotalValueWithSize(amount);
    return [
      {
        title: commonFormTranslations("required_margin"),
        value:
          totalValue && accountLeverage
            ? currency(totalValue / Number(accountLeverage), {
                precision: getContractPriceStep(perpInstrument).price_precision,
              }).format()
            : "---",
        warningOrError: notEnoughBalanceError ? "error" : undefined,
      },
      {
        title: commonFormTranslations("order_value"),
        value: totalValue
          ? currency(totalValue, {
              precision: getContractPriceStep(perpInstrument).price_precision,
            }).format()
          : "---",
        warningOrError:
          notEnoughBalanceError || orderValueTooSmall ? "error" : undefined,
      },
    ];
  }, [
    calculateTotalValueWithSize,
    amount,
    commonFormTranslations,
    accountLeverage,
    perpInstrument,
    notEnoughBalanceError,
    orderValueTooSmall,
  ]);

  const bottomTradeInfo = useMemo<ITradeInfo[]>(() => {
    const totalValue = calculateTotalValueWithSize(amount);
    const totalVolume =
      farmData && totalValue
        ? Number(farmData.trailing_volume || 0) + totalValue
        : totalValue;
    const farmBoostAfterTrade = calculateFarmBoostMultiplier(totalVolume);
    const boostedVolume = totalValue * farmBoostAfterTrade;
    const estimatedRewards = calculateEstimatedTradeRewards(
      boostedVolume,
      emissionsData
    );
    const farmBoost = farmData?.farm_boost;
    const boostIncrease =
      farmBoost && totalValue && totalVolume
        ? calculateFarmBoostMultiplier(totalVolume) - Number(farmBoost)
        : 0;

    const boostIncreasePercentage =
      farmBoost && boostIncrease
        ? String((boostIncrease / Number(farmBoost)) * 100)
        : undefined;
    return [
      {
        key: "Est. Rewards",
        title: (
          <BoostTitle>
            <div>{perpTradeFormTranslations("est_rewards")}</div>
            <TradeFormFarmingTooltip
              farmData={farmData}
              farmBoostAfterTrade={farmBoostAfterTrade}
              boostIncreasePercentage={boostIncreasePercentage}
              estimatedRewards={estimatedRewards}
            />
          </BoostTitle>
        ),
        value: (
          <BoostValueWrapper>
            {estimatedRewards > 0 && <AevoLogo width={16} height={16} />}
            {estimatedRewards
              ? currency(estimatedRewards, {
                  symbol: "",
                }).format()
              : "---"}
          </BoostValueWrapper>
        ),
      },
    ];
  }, [
    amount,
    calculateTotalValueWithSize,
    emissionsData,
    farmData,
    perpTradeFormTranslations,
  ]);

  const verifyNotEnoughBalance = useCallback(
    (amt: string) => {
      const size = getRealContractSize(amount, tradeUseUSDCTerms);
      if (
        currentPosition &&
        currentPosition.side !== orderDirection &&
        Number(currentPosition.amount) >= size
      ) {
        return true;
      }
      return buyingPower >= calculateTotalValueWithSize(amt);
    },
    [
      amount,
      buyingPower,
      calculateTotalValueWithSize,
      currentPosition,
      getRealContractSize,
      tradeUseUSDCTerms,
      orderDirection,
    ]
  );

  /**
   * If priceValue is not provided, use totalValue instead.
   * If is market order, pass in undefined for priceValue
   */
  const updateLeverage = useCallback(
    (orderSize: number, isUSDC: boolean, priceValue?: number) => {
      if (accountData?.available_balance) {
        if (priceValue) {
          const value = isUSDC ? orderSize : priceValue * orderSize;
          const lvrg = Math.min(
            value / Number(accountData.available_balance),
            maxLeverage
          );
          setValue(PerpFormFieldKeyEnum.LEVERAGE, lvrg.toFixed(2));
        }
      }
    },
    [accountData, maxLeverage, setValue]
  );

  const updateOrderSize = useCallback(
    (leverageValue: number, disableLeverageUpdate?: boolean) => {
      if (accountData?.available_balance) {
        const amountUSDC =
          Number(accountData.available_balance) * leverageValue;

        // Round to allowed order size, and update leverage value
        const value = tradeUseUSDCTerms
          ? amountUSDC
          : getRealContractSize(String(amountUSDC), true);

        const stepSize = tradeUseUSDCTerms
          ? contractPriceStep.price_step
          : contractPriceStep.amount_step;
        const precision = tradeUseUSDCTerms
          ? contractPriceStep.price_precision
          : contractPriceStep.amount_precision;
        const newValue = roundToStepSize(value, stepSize, precision);
        setValue(PerpFormFieldKeyEnum.AMOUNT, newValue.toFixed(2));

        if (!disableLeverageUpdate) {
          updateLeverage(
            newValue,
            tradeUseUSDCTerms,
            price ? Number(price) : undefined
          );
        }
      }
    },
    [
      accountData?.available_balance,
      tradeUseUSDCTerms,
      getRealContractSize,
      contractPriceStep.price_step,
      contractPriceStep.amount_step,
      contractPriceStep.price_precision,
      contractPriceStep.amount_precision,
      setValue,
      updateLeverage,
      price,
    ]
  );

  const submitOrder = useCallback<SubmitHandler<IPerpsFormFieldValues>>(
    async (values) => {
      const size = getRealContractSize(values.amount, tradeUseUSDCTerms);
      if (perpInstrument && size) {
        const order: ICreateOrderBody =
          orderType === OrderTypeResponse.Market
            ? {
                instrument: Number(perpInstrument.instrument_id),
                amount: String(size),
                side: orderDirection,
                orderType: OrderTypeResponse.Market,
                stop: stopType,
                trigger: stopType ? values.triggerPrice : undefined,
                reduceOnly,
                isolatedMargin:
                  values.marginType === "isolated"
                    ? `${(
                        (calculateTotalValueWithSize(values.amount) /
                          Number(accountLeverage)) *
                        1e6
                      ).toFixed(0)}`
                    : undefined,
              }
            : {
                instrument: Number(perpInstrument.instrument_id),
                amount: String(size),
                side: orderDirection,
                price: values.price,
                orderType: OrderTypeResponse.Limit,
                stop: stopType,
                trigger: stopType ? values.triggerPrice : undefined,
                reduceOnly,
                timeInForce: tifType,
                isolatedMargin:
                  values.marginType === "isolated"
                    ? `${(
                        (calculateTotalValueWithSize(values.amount) /
                          Number(accountLeverage)) *
                        1000000
                      ).toFixed(0)}`
                    : undefined,
              };
        onCreateOrder(order);
      }
    },
    [
      getRealContractSize,
      tradeUseUSDCTerms,
      perpInstrument,
      orderType,
      orderDirection,
      stopType,
      reduceOnly,
      calculateTotalValueWithSize,
      accountLeverage,
      onCreateOrder,
      tifType,
    ]
  );

  // if buy, check asks liquidity
  let insufficientLiquidity = false;

  if (orderDirection === SideResponse.Buy) {
    const totalAsksSize =
      orderbookData?.asks?.reduce((prev, ask) => {
        const askSize = Number(ask[1] || 0);
        return prev + askSize;
      }, 0) || 0;
    if (!totalAsksSize || totalAsksSize < realContractSize) {
      insufficientLiquidity = true;
    }
  }

  // if sell, check bids liquidity
  if (orderDirection === SideResponse.Sell) {
    const totalBidsSize =
      orderbookData?.bids?.reduce((prev, ask) => {
        const bidSize = Number(ask[1] || 0);
        return prev + bidSize;
      }, 0) || 0;
    if (!totalBidsSize || totalBidsSize < realContractSize) {
      insufficientLiquidity = true;
    }
  }

  const feeStructure = accountData?.fee_structures?.find(
    (f) =>
      f.asset === perpInstrument?.underlying_asset &&
      f.instrument_type === InstrumentTypeResponse.Perpetual
  );

  const showExtraFarmBoostDetails = useMemo(() => {
    if (!farmData?.last_rare_farm_boost) {
      return false;
    }

    // Adding 1 hour to last rare boost in milliseconds
    const oneHourAfterLastRare =
      Number(farmData?.last_rare_farm_boost) * 1e3 + 3600000;

    return Date.now() > oneHourAfterLastRare;
  }, [farmData?.last_rare_farm_boost]);

  return (
    <ContentWrapper ref={modalRef} isMobile={mobileMode} style={{ bottom: 0 }}>
      <TradeForm onSubmit={handleSubmit(submitOrder)}>
        {showClosePositionModal && (
          <ClosePositionModal
            position={currentPosition}
            onHide={onCloseModalHide}
            show={showClosePositionModal}
          />
        )}
        <FormContent>
          <Padding>
            <TransactionTypeWrapper>
              <SegmentedControl
                segments={[
                  {
                    value: String(SideResponse.Buy),
                    display: commonFormTranslations("buy"),
                    textColor:
                      orderDirection === SideResponse.Buy
                        ? COLORS.positive.one
                        : TEXT_COLORS.three,
                  },
                  {
                    value: String(SideResponse.Sell),
                    display: commonFormTranslations("sell"),
                    textColor:
                      orderDirection === SideResponse.Sell
                        ? COLORS.negative.one
                        : TEXT_COLORS.three,
                  },
                ]}
                value={String(orderDirection)}
                onSelect={(value) => setOrderDirection(value as SideResponse)}
                config={{
                  theme: "outline",
                  color:
                    orderDirection === SideResponse.Sell
                      ? COLORS.negative.one
                      : COLORS.positive.one,
                  widthType: "fullWidth",
                  backgroundColor: LAYER_COLORS.two,
                  activeBackgroundColor:
                    orderDirection === SideResponse.Sell
                      ? COLORS.negative.five
                      : COLORS.positive.five,
                  borderRadius: "10px",
                  button: {
                    height: 40,
                    fontSize: FONT_SIZE.two,
                  },
                }}
              />
            </TransactionTypeWrapper>
            <OrderTypeWrapper>
              <SegmentedControl
                config={{
                  widthType: "fullWidth",
                  backgroundColor: LAYER_COLORS.two,
                  activeBackgroundColor: LAYER_COLORS.three,
                  borderRadius: "10px",
                  button: {
                    height: 32,
                    fontSize: FONT_SIZE.one,
                    px: SPACING.two,
                  },
                }}
                segments={[
                  {
                    value: OrderTypeResponse.Market,
                    display: commonFormTranslations("market"),
                    textColor:
                      !stopType && orderType === OrderTypeResponse.Market
                        ? TEXT_COLORS.one
                        : TEXT_COLORS.three,
                  },
                  {
                    value: OrderTypeResponse.Limit,
                    display: commonFormTranslations("limit"),
                    textColor:
                      !stopType && orderType === OrderTypeResponse.Limit
                        ? TEXT_COLORS.one
                        : TEXT_COLORS.three,
                  },
                  {
                    type: "dropdown",
                    value: dropdownValue,
                    display: stopType
                      ? getStopOrderName(t, orderType, stopType, true)
                      : getStopOrderName(t, tempOrderType, tempStopType, true),
                    textColor: stopType ? TEXT_COLORS.one : TEXT_COLORS.three,
                    items: [
                      {
                        title: getStopOrderName(
                          t,
                          OrderTypeResponse.Market,
                          Stop.StopLoss
                        ),
                        value: `${OrderTypeResponse.Market}-${Stop.StopLoss}`,
                        onSelectItem: () => {
                          setOrderType(OrderTypeResponse.Market);
                          setStopType(Stop.StopLoss);
                          setTempOrderType(OrderTypeResponse.Market);
                          setTempStopType(Stop.StopLoss);
                        },
                      },
                      {
                        title: getStopOrderName(
                          t,
                          OrderTypeResponse.Limit,
                          Stop.StopLoss
                        ),
                        value: `${OrderTypeResponse.Limit}-${Stop.StopLoss}`,
                        onSelectItem: () => {
                          setOrderType(OrderTypeResponse.Limit);
                          setStopType(Stop.StopLoss);
                          setTempOrderType(OrderTypeResponse.Limit);
                          setTempStopType(Stop.StopLoss);
                        },
                      },
                      {
                        title: getStopOrderName(
                          t,
                          OrderTypeResponse.Market,
                          Stop.TakeProfit
                        ),
                        value: `${OrderTypeResponse.Market}-${Stop.TakeProfit}`,
                        onSelectItem: () => {
                          setOrderType(OrderTypeResponse.Market);
                          setStopType(Stop.TakeProfit);
                          setTempOrderType(OrderTypeResponse.Market);
                          setTempStopType(Stop.TakeProfit);
                        },
                      },
                      {
                        title: getStopOrderName(
                          t,
                          OrderTypeResponse.Limit,
                          Stop.TakeProfit
                        ),
                        value: `${OrderTypeResponse.Limit}-${Stop.TakeProfit}`,
                        onSelectItem: () => {
                          setOrderType(OrderTypeResponse.Limit);
                          setStopType(Stop.TakeProfit);
                          setTempOrderType(OrderTypeResponse.Limit);
                          setTempStopType(Stop.TakeProfit);
                        },
                      },
                    ],
                  },
                ]}
                value={stopType ? dropdownValue : orderType}
                onSelect={(value) => {
                  setStopType(undefined);
                  setOrderType(value as OrderTypeResponse);
                }}
              />
            </OrderTypeWrapper>
            <MarginTypeWrapper>
              <MarginSelector
                instrument={perpInstrument}
                form={form}
                side={orderDirection}
              />
            </MarginTypeWrapper>
          </Padding>
          <DetailsWrapper>
            <InputsWrapper>
              <GapWrapper>
                {!!stopType && (
                  <TriggerPriceInput
                    placeholder={
                      markPrice
                        ? Number(markPrice).toFixed(
                            contractPriceStep.price_precision
                          )
                        : ""
                    }
                    markPrice={
                      markPrice
                        ? Number(markPrice).toFixed(
                            contractPriceStep.price_precision
                          )
                        : undefined
                    }
                    minPrice={contractPriceStep.price_step}
                    register={register(PerpFormFieldKeyEnum.TRIGGER_PRICE, {
                      disabled: isLoading,
                      required: true,
                      validate: {
                        ...triggerPriceValidateFn,
                        [FormValidatorKeysEnum.moreThanZero]: (v) =>
                          parseFloat(v) > 0,
                        [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                          roundToStepSize(
                            Number(v),
                            contractPriceStep.price_step,
                            contractPriceStep.price_precision
                          ) >=
                          1 / 10 ** contractPriceStep.price_precision,
                      },
                      onBlur(event) {
                        setValue(
                          PerpFormFieldKeyEnum.TRIGGER_PRICE,
                          event.target.value
                            ? roundToStepSize(
                                parseFloat(event.target.value),
                                contractPriceStep.price_step,
                                contractPriceStep.price_precision
                              ).toString()
                            : event.target.value
                        );
                      },
                    })}
                    errors={errors}
                  />
                )}
                {orderType === OrderTypeResponse.Limit && (
                  <PriceInput
                    isStopOrder={!!stopType}
                    placeholder={
                      markPrice
                        ? Number(markPrice).toFixed(
                            contractPriceStep.price_precision
                          )
                        : ""
                    }
                    minPrice={contractPriceStep.price_step}
                    register={register(PerpFormFieldKeyEnum.PRICE, {
                      disabled: isLoading,
                      required: true,
                      validate: {
                        ...limitPriceValidateFn,
                        [FormValidatorKeysEnum.moreThanZero]: (v) =>
                          parseFloat(v) > 0,
                        [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                          roundToStepSize(
                            Number(v),
                            contractPriceStep.price_step,
                            contractPriceStep.price_precision
                          ) >=
                          1 / 10 ** contractPriceStep.price_precision,
                      },
                      onBlur(event) {
                        setValue(
                          PerpFormFieldKeyEnum.PRICE,
                          roundToStepSize(
                            parseFloat(event.target.value),
                            contractPriceStep.price_step,
                            contractPriceStep.price_precision
                          ).toString()
                        );
                      },
                    })}
                    errors={errors}
                  />
                )}
                <OrderSizeInput
                  customError={customError}
                  minAmountSize={
                    tradeUseUSDCTerms
                      ? contractPriceStep.price_step
                      : contractPriceStep.amount_step
                  }
                  existingPositionSize={currentPosition?.amount}
                  underlyingAsset={
                    perpInstrument?.underlying_asset as AssetResponse
                  }
                  selectedAsset={
                    tradeUseUSDCTerms
                      ? undefined
                      : perpInstrument?.underlying_asset
                  }
                  onChangeAsset={(asset) => {
                    const stepSize = asset
                      ? contractPriceStep.amount_step
                      : contractPriceStep.price_step;
                    const precision = asset
                      ? contractPriceStep.amount_precision
                      : contractPriceStep.price_precision;
                    let newAmount = 0;

                    // change from underlying -> USDC
                    // update amount to show TOTAL VALUE
                    const noAsset = !asset;
                    if (!tradeUseUSDCTerms && noAsset) {
                      newAmount = roundToStepSize(
                        calculateTotalValueWithSize(amount, false),
                        stepSize,
                        precision
                      );
                    } else if (tradeUseUSDCTerms && asset) {
                      // change from USDC -> underlying
                      // update amount to show CONTRACTS
                      newAmount = roundToStepSize(
                        getRealContractSize(amount, true),
                        stepSize,
                        precision
                      );
                    }

                    if (newAmount) {
                      setValue(PerpFormFieldKeyEnum.AMOUNT, String(newAmount));
                      updateLeverage(
                        Number(amount),
                        !asset,
                        price ? Number(price) : undefined
                      );
                    }
                    setTradeUseUSDCTerms?.(!asset);
                  }}
                  register={register(PerpFormFieldKeyEnum.AMOUNT, {
                    disabled: isLoading,
                    required: true,
                    validate: {
                      [FormValidatorKeysEnum.moreThanZero]: (v) =>
                        parseFloat(v) > 0,
                      // [FormValidatorKeysEnum.notEnoughBalance]:
                      //   verifyNotEnoughBalance,
                      [FormValidatorKeysEnum.decimalsTooSmall]: (v) => {
                        const stepSize = tradeUseUSDCTerms
                          ? contractPriceStep.price_step
                          : contractPriceStep.amount_step;
                        const precision = tradeUseUSDCTerms
                          ? contractPriceStep.price_precision
                          : contractPriceStep.amount_precision;
                        return (
                          roundToStepSize(Number(v), stepSize, precision) >=
                          1 / 10 ** contractPriceStep.amount_precision
                        );
                      },
                      [FormValidatorKeysEnum.reduceOnlyOrderSizeValid]: (v) => {
                        if (!reduceOnly) {
                          return true;
                        }
                        const size = getRealContractSize(v, tradeUseUSDCTerms);
                        return currentPosition
                          ? size <= Number(currentPosition.amount)
                          : true;
                      },
                    },
                    onChange(event) {
                      const { value } = event.target;
                      updateLeverage(
                        Number(value),
                        !!tradeUseUSDCTerms,
                        price ? Number(price) : undefined
                      );
                    },
                    onBlur(event) {
                      const { value } = event.target;
                      const stepSize = tradeUseUSDCTerms
                        ? contractPriceStep.price_step
                        : contractPriceStep.amount_step;
                      const precision = tradeUseUSDCTerms
                        ? contractPriceStep.price_precision
                        : contractPriceStep.amount_precision;
                      setValue(
                        PerpFormFieldKeyEnum.AMOUNT,
                        roundToStepSize(
                          Number(value),
                          stepSize,
                          precision
                        ).toString()
                      );
                      updateLeverage(
                        Number(value),
                        !!tradeUseUSDCTerms,
                        price ? Number(price) : undefined
                      );
                    },
                  })}
                  style={{ paddingBottom: 0 }}
                  errors={errors}
                  warning={
                    insufficientLiquidity &&
                    orderType === OrderTypeResponse.Market
                      ? formTranslations("insufficient_market_liquidity")
                      : undefined
                  }
                />
                <LeverageSelectInput
                  key={maxLeverage}
                  leverage={Number(leverage)}
                  maxLeverage={maxLeverage}
                  onChangeLeverage={(value) => {
                    setValue(PerpFormFieldKeyEnum.LEVERAGE, value.toFixed(2));
                    updateOrderSize(value, true);
                  }}
                  leverageColor={
                    orderDirection === SideResponse.Buy
                      ? COLORS.positive.one
                      : COLORS.negative.one
                  }
                  errors={errors}
                />
              </GapWrapper>
            </InputsWrapper>
            <DeselectReduceOnlyContainer
              show={reduceOnlyNotAllowed && reduceOnly}
            >
              <div>{commonFormTranslations("deselect_reduce_only")}</div>
              <div>
                {commonFormTranslations("reduce_only_error_desc_1", {
                  orderDirection: commonFormTranslations(
                    orderDirection.toLowerCase()
                  ),
                  orderType: getStopOrderName(t, orderType, stopType),
                })}
                <strong>
                  {currentPosition
                    ? currentPosition?.side === SideResponse.Buy
                      ? commonFormTranslations(
                          "reduce_only_error_desc_2_open_position_long"
                        )
                      : commonFormTranslations(
                          "reduce_only_error_desc_2_open_position_short"
                        )
                    : commonFormTranslations(
                        "reduce_only_error_desc_2_no_position"
                      )}
                </strong>
                {commonFormTranslations("reduce_only_error_desc_3_and")}
                <strong>
                  {commonFormTranslations(
                    "reduce_only_error_desc_4_reduce_only"
                  )}
                </strong>
                {commonFormTranslations("reduce_only_error_desc_5")}
              </div>
            </DeselectReduceOnlyContainer>
            <ReduceOnlyAndTIFContainer>
              <ReduceOnlyInput
                reduceOnly={reduceOnly}
                onToggle={() => {
                  setReduceOnly(!reduceOnly);
                  setTifType(TimeInForce.Ioc);
                }}
              />
              {orderType === OrderTypeResponse.Limit && (
                <TIFDropdownContainer>
                  <TIFSelector
                    tifDropdown={tifDropdown}
                    setTifDropdown={setTifDropdown}
                    reduceOnly={reduceOnly}
                    tifType={tifType}
                    setTIFType={setTifType}
                  />
                </TIFDropdownContainer>
              )}
            </ReduceOnlyAndTIFContainer>
            <TradeInfoWrapper>
              {tradeInfo.map((info) => (
                <InfoRow
                  key={`${info.title}-${info.value}}`}
                  warningOrError={info.warningOrError}
                >
                  <Title>
                    {info.title}
                    {Boolean(info.warningOrError && info.showErrorIcon) && (
                      <Alert
                        style={{
                          stroke:
                            info.warningOrError === "warning"
                              ? COLORS.system.one
                              : COLORS.negative.one,
                          marginLeft: `${SPACING.one}px`,
                          marginTop: `-${SPACING.one / 2}px`,
                        }}
                      />
                    )}
                  </Title>
                  <Value>{info.value}</Value>
                </InfoRow>
              ))}
              <FeeInfo
                asset={perpInstrument?.underlying_asset as AssetResponse}
                size={realContractSize}
                feeStructure={Number(feeStructure?.taker_fee || 0)}
              />
            </TradeInfoWrapper>

            <PositionInfoWrapper>
              {positionInfo.map((info) => (
                <InfoRow
                  key={`${info.title}-${info.value}}`}
                  warningOrError={info.warningOrError}
                >
                  <Title>
                    {info.title}
                    {Boolean(info.warningOrError && info.showErrorIcon) && (
                      <Alert
                        style={{
                          stroke:
                            info.warningOrError === "warning"
                              ? COLORS.system.one
                              : COLORS.negative.one,
                          marginLeft: `${SPACING.one}px`,
                          marginTop: `-${SPACING.one / 2}px`,
                        }}
                      />
                    )}
                    {info.side && (
                      <PositionChip type={info.side}>
                        {info.side === SideResponse.Buy
                          ? formTranslations("long")
                          : formTranslations("short")}
                      </PositionChip>
                    )}
                  </Title>
                  <Value>{info.value}</Value>
                </InfoRow>
              ))}
            </PositionInfoWrapper>
            <TradeInfoWrapper style={{ marginTop: SPACING.three }}>
              {bottomTradeInfo.map((info) => (
                <InfoRow
                  key={info.key ?? `${info.title}-${info.value}`}
                  warningOrError={info.warningOrError}
                  marginBottom={SPACING.two}
                >
                  <Title>{info.title}</Title>
                  <Value>{info.value}</Value>
                </InfoRow>
              ))}
            </TradeInfoWrapper>
            {showExtraFarmBoostDetails && (
              <ExtraFarmBoostDetails staked={!!farmData?.staked} />
            )}
          </DetailsWrapper>
        </FormContent>
        <SubmitWrapper isSticky={isSticky}>
          {replacePlaceOrderButton || (
            <PlaceOrderButton
              disabled={
                Object.values(errors).length > 0 ||
                !!customError ||
                accountData?.in_liquidation ||
                isLoading ||
                (reduceOnlyNotAllowed && reduceOnly)
              }
              type={"submit"}
              side={orderDirection}
            >
              {!isLoading ? (
                commonFormTranslations("place_order", {
                  side: commonFormTranslations(orderDirection.toLowerCase()),
                })
              ) : (
                <Spinner
                  color={
                    orderDirection === SideResponse.Buy
                      ? COLORS.positive.one
                      : COLORS.negative.one
                  }
                />
              )}
            </PlaceOrderButton>
          )}
          <TradeExecutedBar
            type={orderDirection}
            animate={animControls}
            initial={{
              opacity: 0,
              scale: 0,
            }}
          />
        </SubmitWrapper>
        {!isSticky && (
          <>
            <Divider direction="horizontal" size="100%" />
            <PerformanceDetails perpInstrument={perpInstrument} />
          </>
        )}
      </TradeForm>
    </ContentWrapper>
  );
}

export default PerpsTradeForm;

import { useContext, useEffect, useMemo, useState } from "react";
import { GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner } from "../../codegen-api";
import { WebsocketContext } from "../../contexts/WebsocketAuthContext";
import { TimePeriodEnum } from "../../interfaces/TimePeriod";
import { jsonParse } from "../../utils/strings";
import { useInstrumentTradeHistory } from "../api/tradeHistory/useInstrumentTradeHistory";
import { IWSSTradesResponse } from "./model/trades";

const tradesChannel = "trades";

const useTradesWSS = (
  instrumentName?: string,
  period: TimePeriodEnum = TimePeriodEnum.WEEKLY,
  populatePastData: boolean = false,
  maxTrades: number = 50
) => {
  const { triggerSubscribe, lastMessages } = useContext(WebsocketContext);
  const { data: instrumentTradeHistoryData, isValidating } =
    useInstrumentTradeHistory(
      populatePastData ? instrumentName : undefined,
      period
    );
  const [tradeHistoriesKey, setTradeHistoryKey] = useState<{
    [
      instrumentName: string
    ]: GetInstrumentInstrumentNameTradeHistory200ResponseTradeHistoryInner[];
  }>({});

  const tradeHistories = useMemo(
    () => (instrumentName ? tradeHistoriesKey[instrumentName] || [] : []),
    [instrumentName, tradeHistoriesKey]
  );

  useEffect(() => {
    if (!instrumentName) {
      return;
    }

    const data = [`${tradesChannel}:${instrumentName}`];
    triggerSubscribe("subscribe", data, tradesChannel);
  }, [instrumentName, triggerSubscribe]);

  // Receives messages and updates state
  useEffect(() => {
    if (lastMessages) {
      lastMessages.forEach((lastMessage) => {
        const { data, channel }: IWSSTradesResponse = jsonParse(
          lastMessage.data
        );

        if (channel === `${tradesChannel}:${instrumentName}` && data) {
          // Mutate update if not already exist
          setTradeHistoryKey((ths) => {
            const newTH = {
              ...ths,
              [data.instrument_name]: [
                // Newest trades is first in array
                data,
                ...(ths[data.instrument_name] || []),
                // Dont store array of over 50 trades, to prevent memory issues
              ].slice(0, maxTrades),
            };
            return newTH;
          });
        }
      });
    }
  }, [instrumentName, lastMessages, maxTrades]);

  const aggregatedTradeHistories = useMemo(() => {
    const histories = [...(instrumentTradeHistoryData || [])];

    tradeHistories.forEach((h) => {
      const exists = histories.some((hist) => hist.trade_id === h.trade_id);
      if (!exists) {
        histories.push(h);
      }
    });

    return histories
      .sort((a, b) => Number(b.created_timestamp) - Number(a.created_timestamp))
      .slice(0, maxTrades);
  }, [instrumentTradeHistoryData, maxTrades, tradeHistories]);

  return {
    data: aggregatedTradeHistories,
    loading: !instrumentTradeHistoryData && isValidating,
  };
};

export default useTradesWSS;

import { Row } from "react-bootstrap";
import styled from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";

export const GreeksContent = styled(Row)`
  ${FONT_CSS.data.three}
  display: flex;
  align-items: center;
  margin: 0;
`;

export const Greek = styled.div`
  background-color: ${LAYER_COLORS.three};
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: ${BORDER_RADIUS.five}px;
  display: flex;
  vertical-align: middle;
  color: ${TEXT_COLORS.two};
  justify-content: center;
  margin: auto 0;
  margin-right: ${SPACING.three}px;

  img {
    margin: auto;
  }
`;

export const GreekWrapper = styled.div`
  display: flex;
  margin: ${SPACING.two}px 0;

  p {
    margin-bottom: 0;
  }
`;

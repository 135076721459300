/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../../../assets/styledSvg/logo";
import { ReactComponent as Api } from "../../../assets/svg/api.svg";
import { ReactComponent as Arrow } from "../../../assets/svg/arrow-up-right.svg";
import { ReactComponent as Dashboard } from "../../../assets/svg/bar-chart.svg";
import { ReactComponent as Bell } from "../../../assets/svg/bell.svg";
import { ReactComponent as Blog } from "../../../assets/svg/blog.svg";
import { ReactComponent as Doc } from "../../../assets/svg/book-open.svg";
import { ReactComponent as Clock } from "../../../assets/svg/clock.svg";
import { ReactComponent as Discord } from "../../../assets/svg/discord.svg";
import { ReactComponent as Github } from "../../../assets/svg/github.svg";
import { ReactComponent as Home } from "../../../assets/svg/home.svg";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import { ReactComponent as MediaKit } from "../../../assets/svg/mediakit.svg";
import { ReactComponent as RFQsLogo } from "../../../assets/svg/orders.svg";
import { ReactComponent as Settings } from "../../../assets/svg/settings.svg";
import { ReactComponent as Phone } from "../../../assets/svg/smartphone.svg";
import { ReactComponent as Twitter } from "../../../assets/svg/twitter.svg";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { LINKS } from "../../../constants/links";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { PageEndpointEnum } from "../../../enums/endpoint";
import useOutsideAlerter from "../../../hooks/outsideAlerter/useOutsideAlerter";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import useKeydownHandler, {
  KeydownHandlerKeyEnum,
} from "../../../hooks/useKeydownHandler";
import ExternalLinkText from "../../ExternalLinkText";
import { SettingsModal } from "../../SettingsModal";
import AccountDropdown from "../AccountDropdown";
import BalanceMarginUtilization from "../BalanceMarginUtilization";
import { Chevron } from "../Chevron/style";
import Dropdown from "../Dropdown";
import { NotificationPanel } from "../NotificationPanel";
import AboutAevoModal from "./AboutAevoModal";
import {
  BellWrapper,
  CommunityRow,
  CommunityRowLabel,
  DropdownWrapper,
  HeaderButton,
  LeftHeaderWrapper,
  LinkText,
  MainHeaderWrapper,
  NotificationButton,
  RightHeaderComponent,
  RightHeaderWrapper,
} from "./style";
import DesktopPWAModal from "../PWAFlowModal/DesktopPWAModal";
import { LocalStorageKeyEnum } from "../../../enums/localStorage";

export function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const activePageEndpointEnum = useMemo(() => {
    const activePage = location.pathname;
    if (activePage.includes(PageEndpointEnum.PORTFOLIO)) {
      return PageEndpointEnum.PORTFOLIO;
    }
    if (activePage.includes(PageEndpointEnum.AEVO)) {
      return PageEndpointEnum.AEVO;
    }
    if (activePage.includes(PageEndpointEnum.REFERRALS)) {
      return PageEndpointEnum.REFERRALS;
    }
    if (activePage.includes(PageEndpointEnum.HISTORICAL)) {
      return PageEndpointEnum.HISTORICAL;
    }
    if (activePage.includes(PageEndpointEnum.RFQ)) {
      return PageEndpointEnum.RFQ;
    }
    // todo: readd
    // if (activePage.includes(PageEndpointEnum.STRATEGIES)) {
    //   return PageEndpointEnum.STRATEGIES;
    // }
    return PageEndpointEnum.TRADING;
  }, [location.pathname]);

  // NOTIFICATIONS
  const { showPanel, setShowPanel } = useContext(NotificationContext);
  const { accountApiKeyState } = useContext(AuthContext);
  const modalRef = useRef(null);
  const notifButtonRef = useRef(null);

  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showPWAFlow, setShowPWAFlow] = useState<boolean>(false);
  const [showCommunityDropdown, setShowCommunityDropdown] = useState(false);
  const [showAboutAevoModal, setShowAboutAevoModal] = useState(false);

  const { t } = useTranslation("app", { keyPrefix: "Header.Header" });
  const { isMobileScreen } = useScreenSize();

  const onHide = useCallback(() => {
    setShowPanel(false);
  }, [setShowPanel]);

  useOutsideAlerter([modalRef, notifButtonRef], onHide);
  useKeydownHandler(KeydownHandlerKeyEnum.ESC, onHide);

  const onTriggerPanel = useCallback(() => {
    setShowPanel(!showPanel);
  }, [setShowPanel, showPanel]);

  const onSettingsClick = useCallback(() => {
    setShowSettingsModal(true);
  }, []);

  const onShowPWAFlow = useCallback(() => {
    setShowPWAFlow(true);
  }, []);

  return (
    <MainHeaderWrapper>
      <SettingsModal
        show={showSettingsModal}
        onHide={() => setShowSettingsModal(false)}
        showOptionsChainSettings={!isMobileScreen}
      />
      <NotificationPanel modalRef={modalRef} />
      <AboutAevoModal
        show={showAboutAevoModal}
        onHide={() => setShowAboutAevoModal(false)}
      />
      <DesktopPWAModal
        show={showPWAFlow}
        onHide={() => setShowPWAFlow(false)}
      />
      <LeftHeaderWrapper>
        <Link to={PageEndpointEnum.TRADING}>
          <Logo height={32} width={32} />
        </Link>
        <Link to={PageEndpointEnum.TRADING}>
          <LinkText
            isActive={activePageEndpointEnum === PageEndpointEnum.TRADING}
          >
            {t("trading")}
          </LinkText>
        </Link>
        <Link to={PageEndpointEnum.PORTFOLIO}>
          <LinkText
            isActive={activePageEndpointEnum === PageEndpointEnum.PORTFOLIO}
          >
            {t("portfolio")}
          </LinkText>
        </Link>
        {/* todo: readd */}
        {/* <Link to={PageEndpointEnum.STRATEGIES}>
          <StrategiesWrapper>
            <LinkText
              isActive={activePageEndpointEnum === PageEndpointEnum.STRATEGIES}
            >
              {t("strategies")}
            </LinkText>
          </StrategiesWrapper>
        </Link> */}
        <Link to={PageEndpointEnum.AEVO}>
          <LinkText
            isActive={activePageEndpointEnum === PageEndpointEnum.AEVO}
            isAevoLogo
            showBlueAevoLogo={
              !localStorage.getItem(LocalStorageKeyEnum.HIDE_BLUE_AEVO_HEADER)
            }
            onClick={() =>
              localStorage.setItem(
                LocalStorageKeyEnum.HIDE_BLUE_AEVO_HEADER,
                "true"
              )
            }
          >
            <Logo height={16} width={16} />
            AEVO
          </LinkText>
        </Link>
        <Link to={PageEndpointEnum.REFERRALS}>
          <LinkText
            isActive={activePageEndpointEnum === PageEndpointEnum.REFERRALS}
          >
            {t("referrals")}
          </LinkText>
        </Link>
        <DropdownWrapper>
          <Dropdown
            onToggle={setShowCommunityDropdown}
            toggleStyles={{
              border: "none",
              backgroundColor: "transparent",
              padding: "0px !important",
            }}
            dropdownMenuContainerStyles={{
              overflow: "hidden",
              borderRadius: "8px",
              background: LAYER_COLORS.three,
            }}
            title={
              <LinkText isActive={showCommunityDropdown}>
                {t("more")}
                <Chevron
                  style={{
                    marginLeft: SPACING.one,
                    opacity: 1,
                  }}
                  direction={showCommunityDropdown ? "up" : "down"}
                />
              </LinkText>
            }
            items={[
              {
                label: (
                  <CommunityRow>
                    <RFQsLogo />
                    <CommunityRowLabel>{t("rfq")}</CommunityRowLabel>
                  </CommunityRow>
                ),
                onSelect: () => navigate(PageEndpointEnum.RFQ),
              },
              {
                label: (
                  <CommunityRow>
                    <Clock />
                    <CommunityRowLabel>
                      {t("historical_data")}
                    </CommunityRowLabel>
                  </CommunityRow>
                ),
                onSelect: () => navigate(PageEndpointEnum.HISTORICAL),
              },
              {
                label: (
                  <CommunityRow>
                    <Info />
                    <CommunityRowLabel>{t("about")}</CommunityRowLabel>
                  </CommunityRow>
                ),
                onSelect: () => setShowAboutAevoModal(true),
              },
              {
                label: (
                  <CommunityRow href={LINKS.home}>
                    <Home />
                    <CommunityRowLabel>
                      {t("home")}
                      <Arrow />
                    </CommunityRowLabel>
                  </CommunityRow>
                ),
              },
              {
                label: (
                  <CommunityRow href={LINKS.twitter}>
                    <Twitter />
                    <CommunityRowLabel>
                      {t("twitter")}
                      <Arrow />
                    </CommunityRowLabel>
                  </CommunityRow>
                ),
              },
              {
                label: (
                  <CommunityRow svgIsFill href={LINKS.discord}>
                    <Discord />
                    <CommunityRowLabel>
                      {t("discord")}
                      <Arrow />
                    </CommunityRowLabel>
                  </CommunityRow>
                ),
              },
              {
                label: (
                  <CommunityRow href={LINKS.github}>
                    <Github />
                    <CommunityRowLabel>
                      {t("github")}
                      <Arrow />
                    </CommunityRowLabel>
                  </CommunityRow>
                ),
              },
              {
                label: (
                  <CommunityRow href={LINKS.docs}>
                    <Doc />
                    <CommunityRowLabel>
                      {t("docs")}
                      <Arrow />
                    </CommunityRowLabel>
                  </CommunityRow>
                ),
              },
              {
                label: (
                  <CommunityRow href={LINKS.apidocs}>
                    <Api />
                    <CommunityRowLabel>
                      {t("api_reference")}
                      <Arrow />
                    </CommunityRowLabel>
                  </CommunityRow>
                ),
              },
              {
                label: (
                  <CommunityRow svgIsFill href={LINKS.blog}>
                    <Blog />
                    <CommunityRowLabel>
                      {t("blog")}
                      <Arrow />
                    </CommunityRowLabel>
                  </CommunityRow>
                ),
              },
              {
                label: (
                  <CommunityRow href={LINKS.dashboard}>
                    <Dashboard />
                    <CommunityRowLabel>
                      {t("dashboard")}
                      <Arrow />
                    </CommunityRowLabel>
                  </CommunityRow>
                ),
              },
              {
                label: (
                  <CommunityRow noBorder href={LINKS.mediakit}>
                    <MediaKit />
                    <CommunityRowLabel>{t("media_kit")}</CommunityRowLabel>
                  </CommunityRow>
                ),
              },
            ]}
          />
        </DropdownWrapper>
        <ExternalLinkText
          color={TEXT_COLORS.three}
          hoverColor={TEXT_COLORS.one}
          href={LINKS.otc}
          target="_blank"
          rel="noreferrer"
        >
          {t("otc")}
        </ExternalLinkText>
      </LeftHeaderWrapper>
      <RightHeaderWrapper>
        {accountApiKeyState === AccountStateEnum.OK && (
          <NotificationButton
            onClick={() => onTriggerPanel()}
            ref={notifButtonRef}
          >
            <BellWrapper>
              <Bell />
            </BellWrapper>
          </NotificationButton>
        )}
        <RightHeaderComponent>
          <BalanceMarginUtilization />
          <AccountDropdown onSyncPWA={onShowPWAFlow} />
          <HeaderButton onClick={onShowPWAFlow}>
            <Phone />
          </HeaderButton>
          <HeaderButton onClick={onSettingsClick}>
            <Settings />
          </HeaderButton>
        </RightHeaderComponent>
      </RightHeaderWrapper>
    </MainHeaderWrapper>
  );
}

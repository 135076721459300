import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import moment from "moment";
import apiErrors_en from "./en/apiErrors.json";
import tooltips_en from "./en/tooltips.json";
import formErrors_en from "./en/formErrors.json";
import app_en from "./en/app.json";

import apiErrors_cn from "./cn/apiErrors.json";
import tooltips_cn from "./cn/tooltips.json";
import formErrors_cn from "./cn/formErrors.json";
import app_cn from "./cn/app.json";

import apiErrors_ru from "./ru/apiErrors.json";
import tooltips_ru from "./ru/tooltips.json";
import formErrors_ru from "./ru/formErrors.json";
import app_ru from "./ru/app.json";

import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import "moment/locale/ru";

export type Language = "en" | "cn" | "ru";

export const resources = {
  en: {
    apiErrors: apiErrors_en,
    tooltips: tooltips_en,
    app: app_en,
    formErrors: formErrors_en,
  },
  cn: {
    apiErrors: apiErrors_cn,
    tooltips: tooltips_cn,
    app: app_cn,
    formErrors: formErrors_cn,
  },
  ru: {
    apiErrors: apiErrors_ru,
    tooltips: tooltips_ru,
    app: app_ru,
    formErrors: formErrors_ru,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export const onChangeLanguage = (language: Language) => {
  i18n.changeLanguage(language).then(() => {
    console.log("Language changed to: ", language);
    switch (language) {
      case "cn":
        return moment.locale("zh-cn");
      case "ru":
        return moment.locale("ru");
      default:
        return moment.locale("en-gb");
    }
  });
};

export enum WebsocketChannelEnum {
  ORDER_BOOK = "orderbook",
  ORDERS = "orders",
  TICKER = "ticker-frontend",
  MARK_PRICE = "markprice",
  RFQ = "rfqs",
}

export interface IWebsocketAuth {
  timestamp: string;
  signature: string;
  key: string;
}

export type IWebsocketRequestOp = "subscribe" | "unsubscribe" | "ping" | "auth";

export interface IWebsocketRequest {
  op: IWebsocketRequestOp;
  data?: any;
  auth?: IWebsocketAuth;
  id?: number;
}

export interface IWebsocketResponse {
  id?: number;
  channel?: string;
  data?: any;
  error?: string;
}

export interface IWebsocketRateLimitedResponse extends IWebsocketResponse {
  data: {
    // Nanoseconds
    retry_after: string;
  };
}

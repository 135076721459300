import { MouseEventHandler, PropsWithChildren } from "react";
import styled, { CSSProperties, css, keyframes } from "styled-components";
import { SideResponse } from "../../../codegen-api";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { Button } from "../../Buttons/styles";
import { PaginationContainer } from "../Pagination/style";

export const TableContainerWrapper = styled.div<{ $isMobile?: boolean }>`
  position: relative;
  flex: 1;

  > *:not(${PaginationContainer}) {
    margin: 0 ${({ $isMobile }) => ($isMobile ? 0 : SPACING.five)}px;
  }

  > ${PaginationContainer} {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;
  }
`;

export const TableHeaderWrapper = styled.div<{ height?: string }>`
  line-height: 1;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 0;

  > div {
    background-color: ${BACKGROUND_COLORS.eight};
    height: ${({ height }) => height || `${COMPONENTS.tableHeader}px`};
    text-align: center;
    width: 100%;
    display: flex;
  }

  span {
    text-align: center;
    align-self: center;
    color: ${TEXT_COLORS.three};
    padding: ${SPACING.two}px;
  }
`;

export const StrikeColumn = styled.div<{
  width: number;
  activeBorderSide?: "left" | "right";
}>`
  ${FONT_CSS.data.three}
  width: ${(props) => props.width}px;
  background-color: ${LAYER_COLORS.two};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  ${({ activeBorderSide }) => {
    if (activeBorderSide === "right") {
      return `
        border: 1px solid ${COLORS.blue.one};
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 1px solid transparent;
      `;
    }

    if (activeBorderSide === "left") {
      return `
        border: 1px solid ${COLORS.blue.one};
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: 1px solid transparent;
      `;
    }

    return "border: 1px solid transparent";
  }};
`;

export const StrikeHeader = styled(StrikeColumn)``;

const RowBackground = styled.div.attrs<{
  fillColor?: string;
  fillPercent?: number;
  fillTo?: "left" | "right";
}>(({ fillColor, fillPercent, fillTo }) => {
  // Background
  if (fillColor && fillPercent) {
    // minimum of 1%
    const roundedFillPercent = Math.max(1, fillPercent);

    return {
      style: {
        background: `linear-gradient(
          to ${fillTo || "right"},
          ${fillColor} ${roundedFillPercent || 100}%,
          transparent ${roundedFillPercent || 100}%,
          transparent 100%
        )`,
      },
    };
  }
  return {};
})<{
  fillColor?: string;
  fillPercent?: number;
  fillTo?: "left" | "right";
}>`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  pointer-events: none;
`;

export const Row = styled.div<{
  showBorderBottom?: boolean;
  width?: string;
  highlightOnHover?: boolean;
  isActive?: boolean;
}>`
  position: relative;
  display: flex;
  width: ${({ width }) => width || "100%"};

  ${({ isActive }) =>
    isActive && `border: 1px solid ${COLORS.blue.one} !important;`}

  ${({ highlightOnHover }) => {
    if (highlightOnHover) {
      return css`
        &:hover {
          background-color: ${BACKGROUND_COLORS.four};
        }
      `;
    }
    return "";
  }}
  
  border-bottom: ${({ showBorderBottom }) =>
    showBorderBottom ? `1px solid ${BORDER_COLORS.one}` : "none"};

  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const flashAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const RowFlashAnimation = styled.div<{
  fillColor?: string;
  needsAnimation?: boolean;
}>`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ fillColor, needsAnimation }) => {
    if (fillColor) {
      if (needsAnimation) {
        return css`
          background: ${fillColor};
          &:before {
            content: " ";
            display: block;
            background: ${fillColor};
            height: 100%;
            width: 100%;
          }
          opacity: 0;
          animation: ${flashAnimation} 0.6s ease-in-out forwards;
        `;
      }
    }
    return "";
  }}
`;

/**
 * A wrapper around Row.
 */
interface IRowWithSizeIndicatorProps {
  showBorderBottom?: boolean;
  fillColor?: string;
  fillPercent?: number;
  fillTo?: "left" | "right";
  width?: string;
  highlightOnHover?: boolean;
  needsAnimation?: boolean;
  style?: CSSProperties;
  onRowHover?: (hover: boolean) => void;
}

export function RowWithSizeIndicator({
  fillColor,
  fillPercent,
  fillTo,
  width,
  highlightOnHover,
  showBorderBottom,
  children,
  needsAnimation,
  onRowHover,
  ...others
}: PropsWithChildren<IRowWithSizeIndicatorProps>) {
  return (
    <Row
      showBorderBottom={showBorderBottom}
      width={width}
      highlightOnHover={highlightOnHover}
      onMouseEnter={() => onRowHover?.(true)}
      onMouseLeave={() => onRowHover?.(false)}
      {...others}
    >
      <RowBackground
        fillColor={fillColor}
        fillPercent={fillPercent}
        fillTo={fillTo}
      />
      {children}
      <RowFlashAnimation
        fillColor={fillColor}
        needsAnimation={needsAnimation}
      />
    </Row>
  );
}

export const TableHeaderCell = styled.th<{
  onClick?: () => void;
}>`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
`;

// Used for react table library
export const TableWrapper = styled.div<{ disableHoverRow?: boolean }>`
  position: relative;
  > table {
    ${FONT_CSS.data.three}
    min-width: 100%;
    color: ${TEXT_COLORS.two};

    thead {
      top: 0;
      z-index: 2;
      height: ${COMPONENTS.tableHeader}px;
    }

    tbody {
      tr {
        transition: 0.2s all ease-in-out;
        height: ${COMPONENTS.tableRow}px;
        border-bottom: 1px solid ${BORDER_COLORS.one};
        :last-child {
          border-bottom: none;
        }

        &:hover {
          td {
            background-color: ${({ disableHoverRow }) =>
              !disableHoverRow ? LAYER_COLORS.two : undefined};
          }
        }
      }
    }

    th {
      font-weight: normal;
      color: ${TEXT_COLORS.three};
      background-color: ${LAYER_COLORS.one};
    }

    th,
    td {
      margin: 0;
      padding: 0 ${SPACING.three}px;
      text-transform: capitalize;
      white-space: nowrap;
    }
  }
`;

export const Align = styled.div<{
  align?: "left" | "right" | "center";
  onClick?: MouseEventHandler<HTMLElement> | undefined;
}>`
  text-align: ${({ align }) => align || "left"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  }};
`;

export const Direction = styled.div<{ side: SideResponse }>`
  color: ${({ side }) =>
    side === SideResponse.Buy ? COLORS.positive.one : COLORS.negative.one};
`;

export const GroupedCells = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonGroup = styled.div`
  display: flex;
  > ${Button} {
    width: 100%;
    max-height: 32px;
    min-height: 32px;
    border-radius: 8px;
  }
`;

export const SizeFillCell = styled.div`
  ${FONT_CSS.data.three}
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${TEXT_COLORS.one};
  span:last-child {
    ${FONT_CSS.data.four}
    color: ${TEXT_COLORS.three};
  }
`;

export const TPSLContainer = styled.div`
  display: flex;
`;

export const TPSLCell = styled.div`
  ${FONT_CSS.data.three}
  display: flex;
  flex-direction: column;
  color: ${TEXT_COLORS.two};
`;

import useSWR from "swr";
import { GetOrderbook200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { unwrapApiError } from "../../../utils/errors";

export const useGetOrderbook = (symbol?: string) => {
  const fetcher = publicApi();
  return useSWR<GetOrderbook200Response | undefined, Error>(
    symbol ? [APIEndpointEnum.ORDERBOOK, symbol] : undefined,
    async () => {
      try {
        const { data } = (await (await fetcher.getOrderbook(symbol!))());
        return data;
      } catch (error: any) {
        // Ignore instrument_id_invalid errors
        const errMsg = unwrapApiError(error);
        if (errMsg !== "INSTRUMENT_ID_INVALID") {
          throw error;
        }
      }
      return undefined;
    },
    {
      use: [useLoggerMiddleware],
    },
  );
};

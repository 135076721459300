import styled, { css } from "styled-components";
import { TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";

export const Section = styled.div`
  &:not(:last-of-type) {
    margin-bottom: ${SPACING.five}px;
  }
`;

export const PortfolioOverviewWrapper = styled.div<{
  $isMobileScreen?: boolean;
}>`
  ${({ $isMobileScreen }) =>
    $isMobileScreen
      ? css`
          ${Section} {
            margin: ${SPACING.three}px 0;
          }
          > *:not(:first-child) {
            padding: ${SPACING.two}px;
          }
        `
      : css`
          padding: ${SPACING.five}px;
        `}

  > ${Section}:last-child {
    margin-bottom: 0;
  }
`;
export const Label = styled.label`
  ${FONT_CSS.label.three}
  color: ${TEXT_COLORS.three};
`;

export const Value = styled.div<{ color?: string }>`
  ${FONT_CSS.data.three}
  color: ${(props) => props.color ?? TEXT_COLORS.one};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${SPACING.seven}px;
`;

export const GridRow = styled.div<{ isMobileScreen?: boolean }>`
  display: ${({ isMobileScreen }) => (isMobileScreen ? "block" : "grid")};
  grid-template-columns: 1fr 1fr;
  gap: ${SPACING.four}px;

  > div {
    grid-column: span 1;
    width: 100%;
  }
`;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CollateralAssetResponse,
  GetAccount200Response,
} from "../../../codegen-api";
import ConfirmTransferModal from "./ConfirmTransferModal";
import InitialStateModal from "./InitialStateModal";
import { ITransferData, TransferStateEnum } from "./model";
import { BaseModal } from "../../BaseModal";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { getAssetShortName } from "../../../utils/asset/assets";
import ConfirmAddressModal from "./ConfirmAddressModal";
import { AllCollaterals } from "../../../constants/addresses";

interface IDepositWithdrawModalProps {
  show?: boolean;
  onHide?: () => void;
  accountData?: GetAccount200Response;
  defaultAsset?: AllCollaterals;
}

function TransferModal({
  show,
  onHide,
  accountData,
  defaultAsset,
}: IDepositWithdrawModalProps) {
  const [transferState, setTransferState] = useState(TransferStateEnum.INITIAL);
  const [transferData, setTransferData] = useState<ITransferData>();
  const [asset, setAsset] = useState<AllCollaterals>(
    CollateralAssetResponse.Usdc
  );
  const { t } = useTranslation("app", {
    keyPrefix: "DepositWithdrawModal.TransferModal.TransferModal",
  });

  // When default asset changed, update state
  useEffect(() => {
    if (defaultAsset) {
      setAsset(defaultAsset);
    }
  }, [defaultAsset]);

  // reset to initial state whenever modal is closed
  useEffect(() => {
    if (!show) {
      setTransferData(undefined);
      setTransferState(TransferStateEnum.INITIAL);
    }
  }, [show]);

  const handleSetTransferData = useCallback(async (data: ITransferData) => {
    setTransferData(data);
    setTransferState(TransferStateEnum.ADDRESS_CONFIRMATION);
  }, []);

  const handleConfirmAddress = useCallback(async () => {
    setTransferState(TransferStateEnum.CONFIRMATION);
  }, []);

  const onConfirmTransferBack = useCallback(() => {
    setTransferState(TransferStateEnum.INITIAL);
  }, []);

  const modalProps = useMemo(() => {
    let title = t("transfer_asset", { asset: getAssetShortName(asset) });
    switch (transferState) {
      case TransferStateEnum.INITIAL:
        title = t("transfer_asset", { asset: getAssetShortName(asset) });
        break;
      case TransferStateEnum.ADDRESS_CONFIRMATION:
        title = t("address_confirmation");
        break;
      case TransferStateEnum.COMPLETED:
        title = t("transfer_completed");
        break;
      case TransferStateEnum.ERROR:
        title = t("transfer_failed");
        break;
      default:
        title = t("confirm_transfer");
        break;
    }

    const canGoBack =
      transferState === TransferStateEnum.INITIAL ||
      transferState === TransferStateEnum.ADDRESS_CONFIRMATION;

    let titleStyle = {};
    if (!canGoBack) {
      titleStyle = {
        textAlign: "center",
        flex: 1,
        padding: 0,
        color:
          transferState === TransferStateEnum.ERROR
            ? COLORS.negative.one
            : TEXT_COLORS.one,
      };
    }

    return {
      title,
      titleStyle,
      hideCloseButton: !canGoBack,
    };
  }, [asset, transferState, t]);

  const modalContent = useMemo(() => {
    if (transferState === TransferStateEnum.INITIAL || !transferData) {
      return (
        <InitialStateModal
          defaultTransferData={transferData}
          asset={asset}
          setAsset={setAsset}
          handleTransfer={handleSetTransferData}
          accountData={accountData}
        />
      );
    }
    if (transferState === TransferStateEnum.ADDRESS_CONFIRMATION) {
      return (
        <ConfirmAddressModal
          defaultTransferData={transferData}
          handleNext={handleConfirmAddress}
          handleBack={onConfirmTransferBack}
        />
      );
    }
    return (
      <ConfirmTransferModal
        asset={asset}
        transferState={transferState}
        setTransferState={setTransferState}
        onBackClick={onConfirmTransferBack}
        onCloseModal={onHide ?? (() => {})}
        transferData={transferData}
      />
    );
  }, [
    accountData,
    asset,
    handleConfirmAddress,
    handleSetTransferData,
    onConfirmTransferBack,
    onHide,
    transferData,
    transferState,
  ]);

  return (
    <BaseModal
      style={{
        width: 310,
      }}
      show={show}
      onHide={onHide}
      {...modalProps}
    >
      {modalContent}
    </BaseModal>
  );
}

export default TransferModal;

import styled, { css, keyframes } from "styled-components";
import {
  BACKGROUND_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import { BORDER_RADIUS, SPACING } from "../../../constants/design/spacing";
import { formatAmount } from "../../../utils/format";
import { frostedGlassBg } from "../../shared/style";
import { Content } from "../style";

export const FilterPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-bottom: ${SPACING.three}px;
`;

export const TitleDropdown = styled.div`
  display: flex;
  align-items: center;
`;

export const ChartWrapper = styled.div`
  height: 232px;
  position: relative;
`;

export const TooltipInnerContainer = styled.div`
  ${frostedGlassBg(BACKGROUND_COLORS.six)};
  border-radius: ${BORDER_RADIUS.five}px;
  color: ${TEXT_COLORS.one};
  padding: ${SPACING.three}px;
  font-size: ${FONT_SIZE.one};
  font-weight: ${FONT_WEIGHT.medium};
  outline: 1px solid ${COLORS.white.two};
`;

export const DeltaContainer = styled.div`
  background-color: ${COLORS.white.five};
  padding: ${SPACING.one}px ${SPACING.two}px;
  border-radius: 4px;

  > span:first-child {
    color: ${TEXT_COLORS.three};
    margin-right: ${SPACING.one}px;
  }
`;

const DataSection = styled.div<{ color?: string }>`
  margin-bottom: ${SPACING.twoHalf}px;

  > div:first-child {
    color: ${({ color }) => color};
    margin-bottom: ${SPACING.one}px;
  }
`;

const DataAmount = styled.div`
  display: flex;

  > div {
    margin-right: ${SPACING.one}px;
  }
  > div:last-child {
    color: ${TEXT_COLORS.three};
  }
  margin-bottom: ${SPACING.one}px;
`;

interface ITooltipDataSectionProps {
  isShort?: boolean;
  calls?: number;
  puts?: number;
}
export function TooltipDataSection({
  isShort,
  calls,
  puts,
}: ITooltipDataSectionProps) {
  return (
    <DataSection color={isShort ? COLORS.negative.one : COLORS.positive.one}>
      <div>{isShort ? "Short" : "Long"}</div>
      {Boolean(calls && calls > 0) && (
        <DataAmount>
          <div>{formatAmount(calls!, true)}</div>
          <div>calls</div>
        </DataAmount>
      )}
      {Boolean(puts && puts > 0) && (
        <DataAmount>
          <div>{formatAmount(puts!, true)}</div>
          <div>puts</div>
        </DataAmount>
      )}
    </DataSection>
  );
}

export const ExposureContent = styled(Content)`
  padding: ${SPACING.three}px 0;
`;

export const CollateralContainer = styled.div`
  flex: 1;
`;

export const AssetCell = styled.div`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.one};
  display: flex;
  align-items: center;
  img {
    margin-right: ${SPACING.three}px;
  }
`;

export const MobileCollateralRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING.three}px;
  background-color: ${LAYER_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;
  margin-bottom: ${SPACING.two}px;

  > span {
    ${FONT_CSS.data.four}
  }
`;

export const MobileValueWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    ${FONT_CSS.data.four}
  }
`;

export const MobileCollateralLogo = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 32px;
    height: 32px;
    margin-right: ${SPACING.two}px;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  span:nth-child(1) {
    ${FONT_CSS.header.eight}
  }
  span:nth-child(2) {
    ${FONT_CSS.data.five}
    color: ${TEXT_COLORS.two};
  }
`;

export const MobileActionButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: ${SPACING.two}px;
  button {
    border-radius: 8px;
    padding: 0;
    min-height: 32px;
  }
`;

export const EarnMobileActionButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${SPACING.two}px;
  button {
    border-radius: 8px;
    padding: 0;
    min-height: 32px;
  }
`;

export const CollateralRow = styled.tr`
  background: ${LAYER_COLORS.two} !important;

  td {
    background: none !important;
  }
`;

export const CollateralsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.five}px;
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const blinkAnimation = keyframes`
  0% {
    color: ${COLORS.positive.one};
  }
  99% {
    color: ${COLORS.positive.one};
  }
  100% {
    color: ${COLORS.white.one};
  }
`;

export const AnimationContainer = styled.div<{ animate: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white.one};
  ${({ animate }) => {
    if (animate) {
      return css`
        animation: ${blinkAnimation} 0.5s linear;
      `;
    }
    return "";
  }}
`;

export const ApyWrapper = styled.div`
  color: ${COLORS.positive.one};
  background: ${COLORS.positive.five};
  border-radius: ${BORDER_RADIUS.one}px;
  padding: 2px 4px;
`;

export const PnlSpan = styled.div<{ color: string }>`
  ${FONT_CSS.data.four}
  color: ${({ color }) => color};
`;

import { createRef, useEffect, useMemo, useRef, useState } from "react";

import { SPACING } from "../../../constants/design/spacing";
import { Chevron } from "../Chevron/style";
import Dropdown from "../Dropdown";
import { DropdownItem } from "../DropdownSimple/style";
import {
  ActiveBackground,
  SegmentControlButton,
  SegmentControlButtonText,
  SegmentControlContainer,
  WidthType,
} from "./style";

export interface ISegmentedControlConfig {
  theme?: "outline";
  color?: string;
  backgroundColor?: string;
  activeBackgroundColor?: string;
  widthType?: WidthType;
  button?: {
    px?: number;
    py?: number;
    height?: number;
    fontSize: string;
  };
  borderRadius?: string;
  margin?: string;
}

export type ISegmentType = {
  type?: string;
  textColor?: string;
  disabled?: boolean;
} & (
  | {
      type?: "default";
      value: string;
      display?: string | JSX.Element;
    }
  | {
      type: "dropdown";
      value: string;
      display: string;
      items: {
        title: string;
        value: string;
        onSelectItem: () => void;
      }[];
    }
);

interface ISegmentControlProps {
  segments: ISegmentType[];
  value: string;
  onSelect?: (value: string) => void;
  config?: ISegmentedControlConfig;
}

function SegmentControl({
  segments,
  value,
  onSelect,
  config: {
    theme,
    color,
    backgroundColor,
    activeBackgroundColor,
    widthType = "maxContent",
    button = { px: 16, py: 12, fontSize: "14px" },
    borderRadius,
    margin,
  } = {},
}: ISegmentControlProps) {
  const controlRefs = useMemo(
    () =>
      segments.reduce<any>((acc, curr) => {
        acc[curr.value] = createRef();
        return acc;
      }, {}),
    [segments]
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeBackgroundState, setActiveBackgroundState] = useState<
    object | boolean
  >(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentRef = controlRefs[value]?.current;

    if (!currentRef) {
      return () => {};
    }

    const handleResize = () => {
      setActiveBackgroundState({
        left: currentRef.offsetLeft,
        top: currentRef.offsetTop,
        height: currentRef.clientHeight,
        width: currentRef.clientWidth - 1,
      });
    };
    handleResize();

    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [value, controlRefs, theme]);

  return (
    <SegmentControlContainer
      ref={containerRef}
      color={color}
      backgroundColor={backgroundColor}
      widthType={widthType}
      style={{ borderRadius, margin }}
    >
      {segments.map((segment) => {
        if (segment.type === "dropdown") {
          return (
            <SegmentControlButton
              key={segment.value}
              role="button"
              onClick={() => {
                if (segment.disabled) {
                  return;
                }
                // Selects the default option
                const defaultOption = segment.items.find(
                  ({ value: v }) => v === segment.value
                );
                defaultOption?.onSelectItem();
              }}
              ref={controlRefs[segment.value]}
              widthType={widthType}
              px={button.px}
              py={button.py}
              height={button.height}
              backgroundColor={
                theme === "outline" && value === segment.value
                  ? activeBackgroundColor
                  : "transparent"
              }
              disabled={segment.disabled}
              style={{ borderRadius }}
            >
              <Dropdown
                width={"100%"}
                onToggle={(open) => setDropdownOpen(open)}
                dropdownMenuContainerStyles={{
                  width: 115,
                  outline: "none",
                  marginRight: button.px ? -button.px : undefined,
                }}
                toggleStyles={{
                  padding: 0,
                  height: button.height,
                  backgroundColor: "transparent",
                  border: "none",
                }}
                title={
                  <SegmentControlButtonText
                    color={segment.textColor ?? color}
                    style={{
                      fontSize: button.fontSize,
                    }}
                  >
                    {segment.display}
                    <Chevron
                      style={{ marginLeft: SPACING.one }}
                      direction={dropdownOpen ? "up" : "down"}
                    />
                  </SegmentControlButtonText>
                }
                items={segment.items.map(
                  ({ title, value: v, onSelectItem }) => ({
                    label: (
                      <DropdownItem
                        key={title}
                        isSelected={segment.value === v}
                      >
                        {title}
                      </DropdownItem>
                    ),
                    onSelect: (e) => {
                      e.stopPropagation();
                      onSelectItem();
                    },
                  })
                )}
              />
            </SegmentControlButton>
          );
        }
        return (
          <SegmentControlButton
            key={segment.value}
            role="button"
            onClick={(e) => {
              e.stopPropagation();
              if (!segment.disabled) {
                onSelect?.(segment.value);
              }
            }}
            ref={controlRefs[segment.value]}
            widthType={widthType}
            px={button.px}
            py={button.py}
            height={button.height}
            backgroundColor={
              theme === "outline" && value === segment.value
                ? activeBackgroundColor
                : "transparent"
            }
            disabled={segment.disabled}
            style={{ borderRadius }}
          >
            <SegmentControlButtonText
              color={segment.textColor ?? color}
              style={{
                fontSize: button.fontSize,
              }}
            >
              {segment.display}
            </SegmentControlButtonText>
          </SegmentControlButton>
        );
      })}
      <ActiveBackground
        transition={{
          type: "keyframes",
          ease: "easeOut",
        }}
        initial={{
          height: "100%",
          width: `calc(100% / ${segments.length})`,
        }}
        animate={activeBackgroundState}
        theme={theme}
        color={theme === "outline" ? color : activeBackgroundColor}
        style={{ borderRadius }}
      />
    </SegmentControlContainer>
  );
}

export default SegmentControl;

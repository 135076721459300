// Custom dropdown implementation with reference from
// https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
import { AnimatePresence, motion } from "framer-motion";
import {
  CSSProperties,
  Children,
  MouseEventHandler,
  PropsWithChildren,
  forwardRef,
  useMemo,
} from "react";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import { DropdownMenuProps } from "react-bootstrap/esm/DropdownMenu";
import { DropDirection } from "react-bootstrap/esm/DropdownContext";
import {
  ChevronWrapper,
  DropdownButton,
  DropdownHeaderContainer,
  DropdownItemButton,
  DropdownMenuWrapper,
} from "./style";
import { Chevron } from "../Chevron/style";

const props = {
  transition: {
    duration: 0.25,
    ease: "easeInOut",
  },
  initial: {
    opacity: 0,
    translateY: 8,
  },
  animate: {
    opacity: 1,
    translateY: 0,
  },
  exit: {
    opacity: 0,
    translateY: 8,
  },
};

// THE TOGGLE
interface IDropdownToggleProps {
  onClick: () => void;
  style: CSSProperties;
}
const DropdownToggle = forwardRef<any, PropsWithChildren<IDropdownToggleProps>>(
  ({ children, onClick, style }, ref) => (
    <DropdownButton ref={ref} onClick={onClick} style={style}>
      {children}
    </DropdownButton>
  )
);

// THE MENU
interface IDropdownMenuProps {
  style: CSSProperties;
  className: string;
  "aria-labelledby": string;
  hasAnnouncement: boolean;
}
const DropdownMenu = forwardRef<any, PropsWithChildren<IDropdownMenuProps>>(
  (
    {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
      hasAnnouncement,
    },
    ref
  ) => (
    <DropdownMenuWrapper
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
      hasAnnouncement={hasAnnouncement}
    >
      {/* <ul className="list-unstyled"> */}
      {Children.toArray(children)}
      {/* </ul> */}
    </DropdownMenuWrapper>
  )
);

// THE ITEMS
interface IDropdownItemProps {
  active: boolean;
  onClick?: () => void;
}
const DropdownItem = forwardRef<any, PropsWithChildren<IDropdownItemProps>>(
  ({ children, onClick }, ref) => (
    <DropdownItemButton ref={ref} type="button" onClick={onClick} {...props}>
      {children}
    </DropdownItemButton>
  )
);

export interface IDropdownItemsItem {
  label: string | JSX.Element;
  isSeparator?: boolean;
  active?: boolean;
  onSelect?: MouseEventHandler<HTMLElement>;
}

// THE COMPONENT
interface IDropdownProps {
  title: string | JSX.Element;
  items: IDropdownItemsItem[];
  width?: number | string;
  height?: number | string;
  toggleStyles?: CSSProperties;
  dropdownMenuProps?: DropdownMenuProps;
  dropdownMenuContainerStyles?: CSSProperties;
  dropdownHeader?: JSX.Element;
  dropdownFooter?: JSX.Element;
  dropDirection?: DropDirection;

  // Optional callback on dropdown open
  isToggled?: boolean;
  onToggle?: (open: boolean) => void;
  preventHideOnSelect?: boolean;
  showArrow?: boolean;
}
const Dropdown = forwardRef<any, PropsWithChildren<IDropdownProps>>(
  (
    {
      title,
      items,
      width,
      height,
      dropdownHeader,
      dropdownFooter,
      dropDirection = "down",
      isToggled,
      onToggle,
      toggleStyles,
      dropdownMenuProps,
      dropdownMenuContainerStyles,
      preventHideOnSelect,
      showArrow = false,
    },
    ref
  ) => {
    const hasAnnouncement = useMemo(() => {
      const key = process.env.REACT_APP_ANNOUNCEMENT_KEY;
      return Boolean(key && !localStorage.getItem(key));
    }, []);

    return (
      <BootstrapDropdown
        drop={dropDirection}
        ref={ref}
        style={{
          width,
          height: height ?? "100%",
        }}
        onToggle={onToggle}
      >
        <BootstrapDropdown.Toggle
          as={DropdownToggle}
          style={{
            width,
            overflow: "hidden",
            ...toggleStyles,
          }}
        >
          {title}
          {showArrow ? (
            <ChevronWrapper>
              <Chevron direction={isToggled ? "up" : "down"} />
            </ChevronWrapper>
          ) : null}
        </BootstrapDropdown.Toggle>

        <BootstrapDropdown.Menu
          as={DropdownMenu}
          style={{
            width,
            ...dropdownMenuContainerStyles,
          }}
          {...dropdownMenuProps}
          hasAnnouncement={hasAnnouncement}
        >
          <DropdownHeaderContainer>{dropdownHeader}</DropdownHeaderContainer>
          <AnimatePresence>
            {items.map((item, i) =>
              item.isSeparator ? (
                <motion.div
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  {...props}
                >
                  {item.label}
                </motion.div>
              ) : (
                <BootstrapDropdown.Item
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  as={DropdownItem}
                  active={item.active}
                  onClick={(e) => {
                    if (preventHideOnSelect) {
                      e.stopPropagation();
                    }
                    item.onSelect?.(e);
                  }}
                >
                  {item.label}
                </BootstrapDropdown.Item>
              )
            )}
          </AnimatePresence>
          {dropdownFooter}
        </BootstrapDropdown.Menu>
      </BootstrapDropdown>
    );
  }
);

export default Dropdown;

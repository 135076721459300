import styled from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../constants/design/spacing";
import { Button } from "../Buttons/styles";

export const EmptyContent = styled.div<{ fillHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ fillHeight }) => (fillHeight ? "100%" : "65%")};
  transition: all 0.2s ease-in-out;
  font-size: ${FONT_SIZE.two};
  color: ${TEXT_COLORS.two};
`;

export const SelectedMarketToggleContainer = styled.div`
  ${FONT_CSS.data.three}
  color: ${TEXT_COLORS.two};
  height: 100%;
  display: flex;
  align-items: center;

  > span {
    margin-right: ${SPACING.two}px;
  }

  > ${Button} {
    min-height: 100%;
    min-width: 0;
    aspect-ratio: 1;
    border-radius: 8px;
    margin-left: ${SPACING.two}px;
    padding: 0;

    svg {
      opacity: 1;
      stroke: ${COLORS.highlight.one};
    }
  }
`;

export const PositionsPnlLabel = styled.span`
  ${FONT_CSS.data.three}
  margin-left: ${SPACING.two}px;
`;

export const MobileTradingBottomBarContainer = styled.div`
  margin-top: ${SPACING.two}px;
  padding: ${SPACING.two}px;
  background: ${LAYER_COLORS.one};
  border-radius: ${BORDER_RADIUS.five}px;
  overflow: hidden;
`;

export const MobileTradingBottomBarContent = styled.div`
  min-height: ${COMPONENTS.mobileTradeBottomBarMinHeight}px;
`;

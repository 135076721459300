/* eslint-disable no-unreachable */
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { AxiosError } from "axios";
import { Suspense, lazy, useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { AppContainer } from "./App.style";
import CommandModal from "./components/CommandModal";
import InvalidReferralModal from "./components/InvalidReferralModal";
import LiquidationModal from "./components/LiquidationModal";
import LoadingScreen from "./components/LoadingScreen";
import MMPTriggeredModal from "./components/MMPTriggeredModal";
import OFACModal from "./components/OFACModal";
import { Toast } from "./components/shared/Toast";
import { AuthContext, AuthContextProvider } from "./contexts/AuthContext";
import {
  ConnectWalletContext,
  ConnectWalletContextProvider,
} from "./contexts/ConnectWalletContext";
import {
  IntercomContext,
  IntercomContextProvider,
} from "./contexts/IntercomContext";
import { MarketContextProvider } from "./contexts/MarketContext";
import { NotificationContextProvider } from "./contexts/NotificationContext";
import { SettingsContextProvider } from "./contexts/SettingsContext";
import { ToastContextProvider } from "./contexts/ToastContext";
import { WebsocketContextProvider } from "./contexts/WebsocketAuthContext";
import { LocalStorageKeyEnum } from "./enums/localStorage";
import { useAnalytics } from "./hooks/api/analytics/useAnalytics";
import useScreenSize from "./hooks/screenSize/useScreenSize";
import useEagerConnect from "./hooks/wallet/useEagerConnect";
import AevoRoutes from "./routes";
import { nanosToMillis } from "./utils/date";
import { allConnectors } from "./utils/wallet/connectors";
import { Web3ContextProvider } from "./contexts/Web3Context";

const LazyIntercomSetupModal = lazy(
  () => import("./components/IntercomSetupModal")
);

export function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider, "any");
  library.pollingInterval = 15000;
  return library;
}

function Root() {
  useEagerConnect();
  useAnalytics();

  const { account } = useContext(AuthContext);
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { showIntercomSetupModal } = useContext(IntercomContext);
  const { height } = useScreenSize();
  useEffect(() => {
    if (
      !account &&
      window.localStorage.getItem(LocalStorageKeyEnum.REFERRAL_CODE)
    ) {
      setShowConnectModal(true);
    }
  }, [account, setShowConnectModal]);

  return (
    <AppContainer style={{ height }}>
      <Toast />
      <BrowserRouter>
        {account && (
          <div>
            <InvalidReferralModal />
            <LiquidationModal />
            <MMPTriggeredModal />
          </div>
        )}
        {showIntercomSetupModal && (
          <Suspense>
            <LazyIntercomSetupModal />
          </Suspense>
        )}
        <OFACModal />
        <CommandModal />
        <AevoRoutes />
        <LoadingScreen autoHideAfterMs={100} />
      </BrowserRouter>
    </AppContainer>
  );
}

function App() {
  return (
    <SettingsContextProvider>
      <ConnectWalletContextProvider>
        <IntercomContextProvider>
          <ToastContextProvider>
            <NotificationContextProvider>
              <MarketContextProvider>
                <Web3ContextProvider>
                  <Web3ReactProvider connectors={allConnectors}>
                    <AuthContextProvider>
                      <WebsocketContextProvider>
                        <SWRConfig
                          value={{
                            shouldRetryOnError: true,
                            onErrorRetry: (
                              err,
                              _,
                              __,
                              revalidate,
                              { retryCount }
                            ) => {
                              const axiosErr = err as AxiosError | undefined;
                              if (axiosErr?.response?.status === 429) {
                                // Retry mechanism on rate limited
                                const retryAfterNanos: string | undefined =
                                  axiosErr?.response?.data?.retry_after;
                                const retryAfterMillis = retryAfterNanos
                                  ? nanosToMillis(retryAfterNanos)
                                  : 1000;
                                setTimeout(
                                  () => revalidate({ retryCount }),
                                  retryAfterMillis
                                );
                              }
                            },
                            onError: (error) => {
                              if (
                                error.status !== 429 &&
                                error.status !== 401
                              ) {
                                // do nothing
                              }
                            },
                          }}
                        >
                          <Root />
                        </SWRConfig>
                      </WebsocketContextProvider>
                    </AuthContextProvider>
                  </Web3ReactProvider>
                </Web3ContextProvider>
              </MarketContextProvider>
            </NotificationContextProvider>
          </ToastContextProvider>
        </IntercomContextProvider>
      </ConnectWalletContextProvider>
    </SettingsContextProvider>
  );
}

export default App;

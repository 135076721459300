import { AnchorHTMLAttributes, CSSProperties, PropsWithChildren } from "react";
import { TextWrapper } from "./styles";
import { arrowUpRight as Arrow } from "../../assets/styledSvg/arrowUpRight";

interface IExternalLinkTextProps extends PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>> {
  hideArrow?: boolean;
  wrapperStyle?: CSSProperties;
  color?: string;
  hoverColor?: string;
  disableHoverAnimation?: boolean;
}

function ExternalLinkText({
  children,
  hideArrow,
  wrapperStyle,
  color,
  hoverColor,
  disableHoverAnimation,
  ...others
}: IExternalLinkTextProps) {
  return (
    <TextWrapper disableHoverAnimation={disableHoverAnimation} style={wrapperStyle} color={color} hoverColor={hoverColor}>
      <a {...others}>
        {children}
        {!hideArrow && <Arrow color={color} />}
      </a>
    </TextWrapper>
  );
}

export default ExternalLinkText;

import { AssetResponse, assets } from "../../utils/asset";

type AssetResponseToEnabled = {
  [key in AssetResponse]?: boolean;
};

export const optionsEnabledAssets: AssetResponseToEnabled = {
  ETH: true,
  BTC: true,
};

export const disabledAssets: AssetResponse[] = (() => {
  if (process.env.REACT_APP_DISABLED_ASSETS) {
    try {
      return JSON.parse(
        process.env.REACT_APP_DISABLED_ASSETS
      ) as AssetResponse[];
    } catch (error) {
      console.log("Error parsing disabled assets", error);
    }
  }
  return [];
})();

export const allAssets: AssetResponse[] = Object.keys(assets).filter(
  (a) => !disabledAssets.includes(a)
);

export const volatileAssets: AssetResponse[] = [
  "BLAST",
  "SHFL",
  "PARCL",
  "W",
  "AEVO",
];

import { APIEndpointEnum } from "../../enums/endpoint";

type PollingInterval = {
  [key in APIEndpointEnum]: number | undefined;
};

// Keeps track of the polling interval for each endpoint
// in milliseconds
export const pollingInterval: Partial<PollingInterval> = {
  [APIEndpointEnum.FUNDING]: 60000 * 5,
  [APIEndpointEnum.MARKET_SUMMARY]: 60000,
  [APIEndpointEnum.STATISTICS]: 60000,
  [APIEndpointEnum.MMP]: 60000,
  [APIEndpointEnum.INSTRUMENT]: 7000,
  [APIEndpointEnum.ACCOUNT]: 3000,
  [APIEndpointEnum.OPTIONS_HISTORY]: 6000,
  [APIEndpointEnum.TRADES]: 10000,
  [APIEndpointEnum.TRANSACTION_HISTORY]: 6500,
  [APIEndpointEnum.EMAIL_PREFERENCES]: 30000,
  [APIEndpointEnum.EMAIL_ADDRESS]: 10000,
  [APIEndpointEnum.ACCUMULATED_FUNDINGS]: 60000,
  [APIEndpointEnum.RFQS]: 5000,
  [APIEndpointEnum.QUOTES]: 5000,
};

// Delay before querying
export const pollingDelay: Partial<PollingInterval> = {
  [APIEndpointEnum.STATISTICS]: 1000,
  [APIEndpointEnum.YIELD_VAULT]: 3000,
  [APIEndpointEnum.FUNDING]: 4000,
  [APIEndpointEnum.INDEX]: 5000,
  // [APIEndpointEnum.MARKET_SUMMARY]: 60000,
  // [APIEndpointEnum.STATISTICS]: 60000,
  // [APIEndpointEnum.MMP]: 60000,
  // [APIEndpointEnum.INSTRUMENT]: 7000,
  // [APIEndpointEnum.ACCOUNT]: 3000,
  // [APIEndpointEnum.OPTIONS_HISTORY]: 6000,
  // [APIEndpointEnum.TRADES]: 10000,
  // [APIEndpointEnum.TRANSACTION_HISTORY]: 6500,
  // [APIEndpointEnum.NOTIFICATIONS]: 8000,
  // [APIEndpointEnum.EMAIL_PREFERENCES]: 30000,
  // [APIEndpointEnum.EMAIL_ADDRESS]: 10000,
  // [APIEndpointEnum.RFQS]: 5000,
  // [APIEndpointEnum.QUOTES]: 5000,
};

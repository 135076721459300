import base58 from "bs58";
import { TFunction } from "react-i18next";
import { GetTradeHistory200ResponseTradeHistoryInner, SideResponse } from "../codegen-api";

export const getTradeSide = (side: SideResponse, isClosing: boolean, t: TFunction) => {
  if (isClosing) {
    const type = side === SideResponse.Buy
      ? SideResponse.Sell
      : SideResponse.Buy;

    const text = side === SideResponse.Buy
      ? t("close_short")
      : t("close_long");
    return { type, text };
  }

  const type = side;
  const text = side === SideResponse.Buy
    ? t("open_long")
    : t("open_short");

  return { type, text };
};

/**
 * Trade ID has an embedded match index in its last 4 byte elements
 * Within a single trade, there can be multiple matches e.g. a single taker order is matched with multiple maker orders
 * The match index informs the ordering of the matches and hence informs us how to sort the trade entries
 * @param tradeId eg. "DwmDn5XnEyiqx5AB5CM4W8bgD137ASX4Lz1XWBWDYHn8"
 * @returns the match index embedded in the trade id
 */
export const decodeMatchIndex = (tradeId: string) => {
  // Decode the trade ID
  const bytes = base58.decode(tradeId);
  // Extract the last 4 byte elements
  const arr = bytes.slice(bytes.length - 4, bytes.length);
  // Decode to convert bytes into string
  const int = new TextDecoder("utf-8").decode(arr);

  // Parse the string into a number
  return parseInt(int, 10);
};

export const sortTrades = (a: GetTradeHistory200ResponseTradeHistoryInner, b: GetTradeHistory200ResponseTradeHistoryInner) => {
  // Try to sort based on timestamp
  const diffTimestamp = Number(b?.created_timestamp || 0) - Number(a?.created_timestamp || 0);

  // If timestamp is the same, sort based on match index
  if (diffTimestamp === 0) {
    const diffMatchIndex = decodeMatchIndex(b.trade_id) - decodeMatchIndex(a.trade_id);

    // If match index is the same, check for is_closing flag
    // Prioritize is_closing flag = true
    if (diffMatchIndex === 0) {
      return Number(a?.is_closing || 0) - Number(b?.is_closing || 0);
    }
    return diffMatchIndex;
  }

  return diffTimestamp;
};

import { useContext, useEffect, useMemo, useState } from "react";
import { WebsocketContext } from "../../contexts/WebsocketAuthContext";
import { AssetResponse } from "../../utils/asset";
import { jsonParse } from "../../utils/strings";
import { IWSSIndexResponse, IWSSIndexResponseData } from "./model";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { secondsToNanos } from "../../utils/date";

const indexChannel = "index";

const useIndexWSS = (asset?: AssetResponse) => {
  const { triggerSubscribe, lastMessages } = useContext(WebsocketContext);
  const { activePerpMarkets } = useContext(MarketInstrumentContext);

  const [index, setIndex] = useState<IWSSIndexResponseData | undefined>();

  const indexOrFallback: IWSSIndexResponseData | undefined = useMemo(() => {
    if (!index) {
      const market = activePerpMarkets?.find(
        (m) => m.underlying_asset === asset
      );
      if (market?.index_price) {
        return {
          timestamp: String(secondsToNanos(Date.now() / 1000)),
          price: market?.index_price,
        };
      }
    }
    return index;
  }, [activePerpMarkets, asset, index]);

  // Whenever asset changed, reset stale ws index price
  useEffect(() => {
    setIndex(undefined);
  }, [asset]);

  useEffect(() => {
    if (asset) {
      const data = [`${indexChannel}:${asset}`];
      triggerSubscribe("subscribe", data);
    }
  }, [asset, triggerSubscribe]);

  // Receives messages and updates state
  useEffect(() => {
    if (lastMessages && asset) {
      lastMessages.forEach((lastMessage) => {
        const { data, channel }: IWSSIndexResponse = jsonParse(
          lastMessage.data
        );
        if (channel === `${indexChannel}:${asset}` && data) {
          setIndex(data);
        }
      });
    }
  }, [asset, lastMessages]);

  return {
    index: indexOrFallback,
  };
};

export default useIndexWSS;

import styled, { css, keyframes } from "styled-components";
import {
  BORDER_COLORS,
  COLORS,
  ICON_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../constants/design/spacing";
import { Button } from "../Buttons/styles";
import { TableWrapper } from "../shared/Table/style";
import { fadeIn, frostedGlassBg } from "../shared/style";

const contentPadding = css`
  padding: ${SPACING.three}px;
`;

export const HeaderContainer = styled.div`
  ${contentPadding}
  padding-bottom: ${SPACING.three}px;
  border-bottom: 1px solid ${BORDER_COLORS.two};
`;

export const InputContainer = styled.div`
  display: flex;

  ${Button} {
    aspect-ratio: 1;
    margin-left: ${SPACING.two}px;
  }
`;

export const MarketFilterContainer = styled.div`
  padding-top: ${SPACING.three}px;
  overflow-x: scroll;
`;

export const Section = styled.div`
  padding-bottom: ${SPACING.three}px;
  margin-top: ${SPACING.three}px;

  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in-out forwards;
`;

export const Title = styled.div<{ noResults?: boolean }>`
  ${({ noResults }) => (noResults ? FONT_CSS.body.five : FONT_CSS.header.seven)}

  color: ${({ noResults }) => (noResults ? TEXT_COLORS.two : TEXT_COLORS.one)};
  padding: ${SPACING.two}px ${SPACING.three}px;
`;

export const ModalContentWrapper = styled.div<{ isMobile?: boolean }>`
  position: relative;
  height: 100%;
  min-height: ${({ isMobile }) =>
    isMobile ? `calc(100dvh - ${SPACING.three * 2}px)` : "70vh"};
  display: flex;
  flex-direction: column;
`;

export const CommandWrapper = styled.div`
  flex: 1;
  position: relative;
`;

export const CommandContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;

  > ${Section}:not(:last-child) {
    border-bottom: 1px solid ${BORDER_COLORS.two};
  }
`;

export const MarketsTableWrapper = styled(TableWrapper)`
  padding: 0 ${SPACING.two}px;
  overflow-x: scroll;

  tbody {
    tr {
      border-bottom: none !important;
    }
  }
`;

export const MarketsTableTable = styled.table`
  th {
    padding: 0 ${SPACING.two}px !important;
    background-color: transparent !important;
  }
  tbody {
    tr {
      cursor: pointer;
      border: none;
    }
  }
`;

export const MarketsTableCol = styled.td`
  padding: 0 ${SPACING.two}px !important;
  width: 20%;

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 140px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 48px;
  }
`;

const rowAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const MarketTableBody = styled.tbody``;

export const MarketRow = styled.tr<{ isSelected?: boolean; delay: number }>`
  color: ${({ isSelected }) =>
    isSelected ? TEXT_COLORS.one : TEXT_COLORS.two};
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${LAYER_COLORS.two};
    `}
  opacity: 0;
  animation: ${rowAnimation} 0.2s ease-in-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

export const AssetCellContainer = styled.div`
  color: ${TEXT_COLORS.one};
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    margin-right: ${SPACING.two}px;
  }
`;

export const ChangeTag = styled.div<{ value: number }>`
  border-radius: 4px;
  padding: ${SPACING.one}px;
  color: ${({ value }) =>
    value < 0 ? COLORS.negative.one : COLORS.positive.one};
  background-color: ${({ value }) =>
    value < 0 ? COLORS.negative.five : COLORS.positive.five};
`;

export const WatchlistButton = styled.button`
  > svg {
    width: 16px;
    height: 16px;
  }
`;

export const CommandFooterContainer = styled.div`
  ${FONT_CSS.body.five}
  color: ${TEXT_COLORS.two};
  padding: ${SPACING.twoHalf}px ${SPACING.three}px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${COMPONENTS.cmdKModalFooter}px;
  border-top: 1px solid ${BORDER_COLORS.two};
  ${frostedGlassBg("rgba(18, 18, 26, 0.64)", LAYER_COLORS.one, 8)}
`;

export const FooterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }

  div {
    background-color: ${LAYER_COLORS.three};
    padding: ${SPACING.one}px ${SPACING.two}px;
    border-radius: ${BORDER_RADIUS.two}px;

    &:nth-child(2) {
      margin-left: ${SPACING.one}px;
    }

    svg {
      width: 16px;
      height: 16px;
      stroke: ${ICON_COLORS.two};
    }
  }

  span {
    margin-left: ${SPACING.two}px;
  }
`;

export const PriceCell = styled.div<{
  isNegative?: boolean;
}>`
  > div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    margin-top: ${SPACING.one}px;
    margin-bottom: ${SPACING.two}px;
  }
`;

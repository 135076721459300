import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const close = ({
  color,
  ...props
}: SVGProps & { color?: string }): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    stroke={color || "white"}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 4L4 12" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 4L12 12" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

import { MutableRefObject, useCallback, useEffect, useState } from "react";

const useElementSize = (
  ref: MutableRefObject<HTMLElement | null>,
  config = { mutationObserver: true }
) => {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [scrollWidth, setScrollWidth] = useState<number>(0);

  const updateDimension = useCallback(() => {
    const element = ref.current;
    if (!element) return;

    setHeight(element.clientHeight);
    setWidth(element.clientWidth);
    setScrollHeight(element.scrollHeight);
    setScrollWidth(element.scrollWidth);
  }, [ref]);

  useEffect(() => {
    if (!config.mutationObserver) return;

    const element = ref.current;
    if (!element) return;

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(() => {
      updateDimension();
    });

    // Start observing the target node for configured mutations
    observer.observe(element, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });

    // Call the updateDimension once to set the initial size
    updateDimension();

    // Return a cleanup function to disconnect the observer
    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [ref, updateDimension, config.mutationObserver]);

  useEffect(() => {
    const resizeCallback = () => {
      updateDimension();
    };

    window.addEventListener("resize", resizeCallback);

    return () => window.removeEventListener("resize", resizeCallback);
  }, [updateDimension]);

  return { height, width, scrollHeight, scrollWidth };
};

export default useElementSize;

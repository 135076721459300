import currency from "currency.js";
import moment from "moment";
import { useCallback, useContext, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  GetAccount200ResponsePositionsInner,
  InstrumentTypeResponse,
  MarginTypeResponse,
  SideResponse,
} from "../../../../codegen-api";
import ClosePositionModal from "../../../../components/ConfirmationModal/ClosePositionModal";
import { Content } from "../../../../components/PortfolioSettings/style";
import RowActionButton from "../../../../components/shared/RowActionButton";
import { Spinner } from "../../../../components/shared/Spinner";
import { SpinnerContainerWrapper } from "../../../../components/shared/Spinner/style";
import { COLORS, TEXT_COLORS } from "../../../../constants/design/colors";
import usePositionsWSS from "../../../../hooks/wss/usePositionsWSS";
import { getAssetLogo } from "../../../../utils/asset/assets";
import { nanosToSeconds } from "../../../../utils/date";
import { Label } from "../../../PortfolioOverviewTab/style";
import {
  Actions,
  Footer,
  Header,
  MobilePortfolioRow,
  Pill,
  PositionContent,
  PortfolioFilterContainer,
  Separator,
  SettlementInProgress,
  Stat,
  Stats,
  StatValue,
  MobileWrapper,
} from "../style";
import { PortfolioMarketFilter } from "../../../../components/PortfolioSettings/PortfolioMarketFilter";
import { MarketInstrumentContext } from "../../../../contexts/MarketInstrumentContext";
import { useRFQ } from "../../../../hooks/api/rfq/useRFQ";
import { SPACING } from "../../../../constants/design/spacing";
import PositionDetailsModal from "../../../../components/shared/ViewDetailsModal/PositionDetailsModal";
import { PnLCardModal } from "../../../../components/PnLCardModal";
import AdjustIsolatedMarginModal from "../../../../components/ConfirmationModal/AdjustIsolatedMarginModal";
import { useGetAccount } from "../../../../hooks/api/account/useGetAccount";
import TPSLForPositionModal from "../../../../components/ConfirmationModal/TPSLForPositionModal";
import {
  TPSLCell,
  TPSLContainer,
} from "../../../../components/shared/Table/style";

const pnlColor = (pnl: string) => {
  if (pnl && Number(pnl) > 0) {
    return COLORS.positive.one;
  }
  if (pnl && Number(pnl) < 0) {
    return COLORS.negative.one;
  }
  return TEXT_COLORS.one;
};

interface IMobilePositionsProps {
  positions: GetAccount200ResponsePositionsInner[];
  t: TFunction;
}

function MobilePositions({ positions, t }: IMobilePositionsProps) {
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const { data: accountData } = useGetAccount();

  // NOTE: IDs here are referring the position's instrument_id
  const [shareModalPositionId, setShareModalPositionId] = useState<string>();
  const [closingPositionId, setClosingPositionId] = useState<string>();
  const [viewDetailsForPositionId, setViewDetailsForPositionId] =
    useState<string>();
  const [adjustingMarginPositionId, setAdjustingMarginPositionId] =
    useState<string>();
  const [showClosePositionModal, setShowClosePositionModal] =
    useState<boolean>(false);
  const [tpslModalPositionId, setTpslModalPositionId] = useState<string>();

  const { deleteRFQsByInstrumentId } = useRFQ(undefined, false);

  const onClosePosition = useCallback(
    (pos: GetAccount200ResponsePositionsInner) => {
      setClosingPositionId(pos.instrument_id);
      setShowClosePositionModal(true);
    },
    []
  );

  const onClosePositionModalHide = useCallback(() => {
    if (closingPositionId) {
      deleteRFQsByInstrumentId(closingPositionId);
    }

    setShowClosePositionModal(false);
  }, [closingPositionId, deleteRFQsByInstrumentId]);

  const positionByInstrumentId = useCallback(
    (instrumentId?: string) => {
      if (!instrumentId) {
        return undefined;
      }

      return positions.find(
        (position) => position.instrument_id === instrumentId
      );
    },
    [positions]
  );

  const onAdjustMarginModalHide = useCallback(() => {
    setAdjustingMarginPositionId(undefined);
  }, []);

  const onPositionDetailsModalHide = useCallback(() => {
    setViewDetailsForPositionId(undefined);
  }, []);

  const onSharePositionModalHide = useCallback(() => {
    setShareModalPositionId(undefined);
  }, []);

  const onTPSLForPositionModalHide = useCallback(() => {
    setTpslModalPositionId(undefined);
  }, []);

  return (
    <>
      <AdjustIsolatedMarginModal
        position={positionByInstrumentId(adjustingMarginPositionId)}
        onHide={onAdjustMarginModalHide}
      />
      <ClosePositionModal
        position={positionByInstrumentId(closingPositionId)}
        onHide={onClosePositionModalHide}
        show={showClosePositionModal}
      />
      <PositionDetailsModal
        position={positionByInstrumentId(viewDetailsForPositionId)}
        onHide={onPositionDetailsModalHide}
      />
      <PnLCardModal
        pnlType="position"
        pnlData={positionByInstrumentId(shareModalPositionId)}
        onHideModal={onSharePositionModalHide}
        show={!!shareModalPositionId}
        onHidePnLCard={onSharePositionModalHide}
        hideBackButton
      />
      <TPSLForPositionModal
        position={positionByInstrumentId(tpslModalPositionId)}
        onHide={onTPSLForPositionModalHide}
        show={!!tpslModalPositionId}
      />
      <div>
        {positions.map((position, i) => {
          const { price_precision, amount_precision } = getMarketPrecision(
            position.asset,
            position.instrument_type
          );
          return (
            <MobilePortfolioRow
              key={position.instrument_id}
              transition={{
                delay: positions.length > 10 ? 0 : i * 0.1,
                duration: 0.5,
                ease: "easeInOut",
              }}
              initial={{
                transform: "translateY(-8px)",
                opacity: 0,
              }}
              animate={{
                transform: "translateY(0px)",
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <Header>
                <img
                  src={getAssetLogo(position.asset)}
                  alt={position.asset}
                  width={32}
                  height={32}
                />
                <PositionContent>
                  <div>
                    <div>
                      {position.option ? (
                        <>
                          {position.option.option_type}
                          <Separator>{"⸱"}</Separator>
                          {moment
                            .unix(nanosToSeconds(position.option.expiry))
                            .format("DD MMM YY")}
                          <Separator>{"⸱"}</Separator>
                          {currency(position.option.strike, {
                            precision: 0,
                          }).format()}
                        </>
                      ) : (
                        position.instrument_name
                      )}
                    </div>
                    {position.option &&
                      moment
                        .unix(nanosToSeconds(position.option.expiry))
                        .isSameOrBefore(moment()) && (
                        <SettlementInProgress>
                          {t("settlement_in_progress")}
                        </SettlementInProgress>
                      )}
                  </div>
                  <Pill
                    borderRadius="8px"
                    isPositive={position.side === SideResponse.Buy}
                  >
                    {position.side === SideResponse.Buy
                      ? t("long")
                      : t("short")}
                  </Pill>
                </PositionContent>
              </Header>
              <Footer>
                <Stats>
                  <Stat>
                    <Label>{t("size")}</Label>
                    <StatValue>
                      {Number(position.amount).toFixed(amount_precision)}
                    </StatValue>
                  </Stat>
                  <Stat>
                    <Label>{t("position_value")}</Label>
                    <StatValue>
                      {currency(
                        Number(position.amount) * Number(position.mark_price),
                        { precision: price_precision }
                      ).format()}
                    </StatValue>
                  </Stat>
                </Stats>
                <Stats>
                  <Stat>
                    <Label>{t("pnl")}</Label>
                    {position.unrealized_pnl ? (
                      <StatValue color={pnlColor(position.unrealized_pnl)}>
                        {currency(position.unrealized_pnl, {
                          precision: 2,
                        }).format()}
                      </StatValue>
                    ) : (
                      <StatValue color={TEXT_COLORS.one}>-</StatValue>
                    )}
                  </Stat>
                  <Stat>
                    <Label>
                      {t("margin")}{" "}
                      {position.margin_type === MarginTypeResponse.Isolated ? (
                        <>
                          -{" "}
                          <span style={{ color: COLORS.blue.one }}>
                            {t("isolated")}
                          </span>
                        </>
                      ) : null}
                    </Label>
                    <StatValue>
                      {currency(
                        position.margin_type === MarginTypeResponse.Isolated
                          ? position.isolated_margin || 0
                          : position.maintenance_margin || 0,
                        {
                          precision: price_precision,
                        }
                      ).format()}
                    </StatValue>
                  </Stat>
                </Stats>
                <Stats>
                  <Stat>
                    <Label>{t("tpsl_for_position")}</Label>
                    <StatValue>
                      <TPSLContainer>
                        <TPSLCell>
                          <span>
                            {currency(
                              position.triggers?.take_profit?.trigger || 0,
                              {
                                precision: price_precision,
                              }
                            ).format()}
                          </span>
                          <span>
                            {currency(
                              position.triggers?.stop_loss?.trigger || 0,
                              {
                                precision: price_precision,
                              }
                            ).format()}
                          </span>
                        </TPSLCell>
                        <RowActionButton
                          variant="edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            setTpslModalPositionId(position.instrument_id);
                          }}
                          disabled={
                            position.instrument_type !==
                            InstrumentTypeResponse.Perpetual
                          }
                          style={{ marginLeft: SPACING.two }}
                        />
                      </TPSLContainer>
                    </StatValue>
                  </Stat>
                </Stats>
              </Footer>
              <Actions>
                <RowActionButton
                  variant={"edit"}
                  disabled={!position.isolated_margin}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAdjustingMarginPositionId(position.instrument_id);
                  }}
                />
                {!accountData?.in_liquidation && (
                  <RowActionButton
                    variant={"close"}
                    onClick={(e) => {
                      e.stopPropagation();
                      onClosePosition(position);
                    }}
                  />
                )}
                <RowActionButton
                  variant={"details"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setViewDetailsForPositionId(position.instrument_id);
                  }}
                />
                <RowActionButton
                  variant="share"
                  svgColor={
                    Number(position.unrealized_pnl) >= 0
                      ? COLORS.positive.one
                      : COLORS.negative.one
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setShareModalPositionId(
                      (position as GetAccount200ResponsePositionsInner)
                        .instrument_id
                    );
                  }}
                />
              </Actions>
            </MobilePortfolioRow>
          );
        })}
      </div>
    </>
  );
}

interface IMobilePositionsTabProps {
  showFilter?: boolean;
  noPadding?: boolean;
}

export function MobilePositionsTab({
  showFilter = true,
  noPadding = false,
}: IMobilePositionsTabProps) {
  const { loading, positions } = usePositionsWSS();
  const [filter, setFilter] = useState<InstrumentTypeResponse>();
  const { t } = useTranslation("app", {
    keyPrefix: "pages.PortfolioPage.mobile.MobilePositionsTab",
  });

  const filteredPositions = useMemo(() => {
    if (filter === InstrumentTypeResponse.Option) {
      return positions.filter((p) => !!p.option);
    }
    if (filter === InstrumentTypeResponse.Perpetual) {
      return positions.filter((p) => !p.option);
    }
    return positions;
  }, [filter, positions]);

  if (loading) {
    return (
      <SpinnerContainerWrapper>
        <Spinner />
      </SpinnerContainerWrapper>
    );
  }

  return (
    <MobileWrapper noPadding={noPadding}>
      {showFilter ? (
        <PortfolioFilterContainer>
          <PortfolioMarketFilter
            title={t("positions")}
            instrumentType={filter}
            onSetFilter={setFilter}
          />
        </PortfolioFilterContainer>
      ) : null}
      {!loading && filteredPositions.length < 1 ? (
        <Content>{t("no_history_desc")}</Content>
      ) : (
        <MobilePositions positions={filteredPositions} t={t} />
      )}
    </MobileWrapper>
  );
}

import currency from "currency.js";
import { useCallback, useContext, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
  AccountTypeResponse,
  GetOrders200Response,
  InstrumentTypeResponse,
  SideResponse,
} from "../../../../codegen-api";
import { CancelAllOrdersModal } from "../../../../components/ConfirmationModal/CancelAllOrdersModal";
import EditOrderModal from "../../../../components/ConfirmationModal/EditOrderModal";
import RemoveOrderModal from "../../../../components/ConfirmationModal/RemoveOrderModal";
import { PortfolioMarketFilter } from "../../../../components/PortfolioSettings/PortfolioMarketFilter";
import MarketCell from "../../../../components/PortfolioSettings/Table/MarketCell";
import { Content } from "../../../../components/PortfolioSettings/style";
import RowActionButton from "../../../../components/shared/RowActionButton";
import { Spinner } from "../../../../components/shared/Spinner";
import { SpinnerContainerWrapper } from "../../../../components/shared/Spinner/style";
import OrderDetailsModal from "../../../../components/shared/ViewDetailsModal/OrderDetailsModal";
import { TEXT_COLORS } from "../../../../constants/design/colors";
import { SPACING } from "../../../../constants/design/spacing";
import { MarketInstrumentContext } from "../../../../contexts/MarketInstrumentContext";
import { useGetAccount } from "../../../../hooks/api/account/useGetAccount";
import { useOrder } from "../../../../hooks/api/order/useOrder";
import { getAssetFromSymbol } from "../../../../utils/instruments";
import { getStopOrderName } from "../../../../utils/order";
import { CancelButton } from "../../../OrdersTab/style";
import { Label } from "../../../PortfolioOverviewTab/style";
import {
  Actions,
  Footer,
  Header,
  MobilePortfolioRow,
  MobileWrapper,
  Pill,
  PortfolioFilterContainer,
  Stat,
  StatValue,
  Stats,
} from "../style";

interface IMobileOrdersProps {
  orders?: GetOrders200Response[];
  t: TFunction;
}

function MobileOrders({ orders, t }: IMobileOrdersProps) {
  const { t: translateAll } = useTranslation();
  const { getMarketPrecision } = useContext(MarketInstrumentContext);

  const [removingOrder, setRemovingOrder] = useState<GetOrders200Response>();
  const [showRemoveOrderModal, setShowRemoveOrderModal] = useState(false);

  const [editingOrder, setEditingOrder] = useState<GetOrders200Response>();
  const [showEditOrderModal, setShowEditOrderModal] = useState(false);

  const [viewDetailsForOrder, setViewDetailsForOrder] =
    useState<GetOrders200Response>();

  const onCancelOrder = useCallback((order?: GetOrders200Response) => {
    if (!order) {
      return;
    }
    setRemovingOrder(order);
    setViewDetailsForOrder(undefined);
    setShowRemoveOrderModal(true);
  }, []);

  const onEditOrder = useCallback((order?: GetOrders200Response) => {
    if (!order) {
      return;
    }
    setEditingOrder(order);
    setViewDetailsForOrder(undefined);
    setShowEditOrderModal(true);
  }, []);

  return (
    <>
      <RemoveOrderModal
        order={removingOrder}
        onHide={() => setShowRemoveOrderModal(false)}
        show={showRemoveOrderModal}
      />
      <EditOrderModal
        order={editingOrder}
        onHide={() => setShowEditOrderModal(false)}
        show={showEditOrderModal}
      />
      <OrderDetailsModal
        order={viewDetailsForOrder}
        onHide={() => setViewDetailsForOrder(undefined)}
        onEditOrder={() => onEditOrder(viewDetailsForOrder)}
        onRemoveOrder={() => onCancelOrder(viewDetailsForOrder)}
      />
      <div>
        {orders?.map((order, i) => {
          const asset = getAssetFromSymbol(order.instrument_name);
          const { price_precision, amount_precision } = getMarketPrecision(
            asset,
            order.instrument_type
          );
          return (
            <MobilePortfolioRow
              key={order.order_id}
              transition={{
                delay: orders.length > 10 ? 0 : i * 0.1,
                duration: 0.5,
                ease: "easeInOut",
              }}
              initial={{
                transform: "translateY(-8px)",
                opacity: 0,
              }}
              animate={{
                transform: "translateY(0px)",
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <Header>
                <MarketCell
                  instrumentName={order.instrument_name}
                  optionType={order.option_type}
                  expiry={order.expiry}
                  strike={order.strike}
                />
                <Pill isPositive={order.side === SideResponse.Buy}>
                  {order.side === SideResponse.Buy ? t("bid") : t("offer")}
                </Pill>
              </Header>
              <Footer>
                <Stats>
                  <Stat>
                    <Label>{t("price")}</Label>
                    <StatValue>
                      {currency(order.price, {
                        precision: price_precision,
                      }).format()}
                    </StatValue>
                  </Stat>
                  <Stat>
                    <Label>{t("size_fill")}</Label>
                    <StatValue>
                      <span style={{ color: TEXT_COLORS.one }}>
                        {Number(order.amount).toFixed(amount_precision)}
                      </span>
                      <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                      {Number(order.filled).toFixed(2)}
                    </StatValue>
                  </Stat>
                </Stats>
                <Stats>
                  <Stat>
                    <Label>{t("order_type")}</Label>
                    <StatValue>
                      {order.stop
                        ? getStopOrderName(
                            translateAll,
                            order.order_type,
                            order.stop,
                            true
                          )
                        : order.order_type}
                    </StatValue>
                  </Stat>
                  <Stat>
                    <Label>{t("margin")}</Label>
                    <StatValue>
                      {currency(order.initial_margin || 0, {
                        precision: price_precision,
                      }).format()}
                    </StatValue>
                  </Stat>
                </Stats>
              </Footer>
              <Actions>
                <RowActionButton
                  variant={"close"}
                  onClick={() => onCancelOrder(order)}
                  style={{ marginRight: `${SPACING.two}px` }}
                />
                {!order.close_position && (
                  <RowActionButton
                    variant={"edit"}
                    onClick={() => onEditOrder(order)}
                    style={{ marginRight: `${SPACING.two}px` }}
                  />
                )}
                <RowActionButton
                  variant={"details"}
                  onClick={() => setViewDetailsForOrder(order)}
                />
              </Actions>
            </MobilePortfolioRow>
          );
        })}
      </div>
    </>
  );
}
interface IMobilePositionsTabProps {
  showFilter?: boolean;
  noPadding?: boolean;
}

export function MobileOrdersTab({
  showFilter = true,
  noPadding = false,
}: IMobilePositionsTabProps) {
  const { data: ordersData, isValidating: ordersDataValidating } = useOrder();
  const ordersLoading = !ordersData && ordersDataValidating;
  const [filter, setFilter] = useState<InstrumentTypeResponse>();
  const { data: accountData } = useGetAccount();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const { t } = useTranslation("app", {
    keyPrefix: "pages.PortfolioPage.mobile.MobileOrdersTab",
  });

  const canCancelAllOrders = useMemo(
    () =>
      accountData?.account_type === AccountTypeResponse.MarketMaker &&
      (ordersData || []).length > 0,
    [accountData?.account_type, ordersData]
  );

  const filteredOrders = useMemo(() => {
    if (filter === InstrumentTypeResponse.Option) {
      return ordersData?.filter((p) => !!p.option_type);
    }
    if (filter === InstrumentTypeResponse.Perpetual) {
      return ordersData?.filter((p) => !p.option_type);
    }
    return ordersData;
  }, [filter, ordersData]);

  if (ordersLoading) {
    return (
      <SpinnerContainerWrapper>
        <Spinner />
      </SpinnerContainerWrapper>
    );
  }

  return (
    <MobileWrapper noPadding={noPadding}>
      {showFilter ? (
        <PortfolioFilterContainer style={{ display: "flex" }}>
          <PortfolioMarketFilter
            title={t("orders")}
            instrumentType={filter}
            onSetFilter={setFilter}
          />
          {!canCancelAllOrders && (
            <CancelButton
              type="button"
              onClick={() => setShowCancelModal(true)}
            >
              {t("cancel_all_orders")}
            </CancelButton>
          )}
        </PortfolioFilterContainer>
      ) : null}
      <CancelAllOrdersModal
        instrumentType={filter}
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
      />
      {!filteredOrders || filteredOrders?.length === 0 ? (
        <Content>{t("no_history_desc")}</Content>
      ) : (
        <MobileOrders orders={filteredOrders} t={t} />
      )}
    </MobileWrapper>
  );
}

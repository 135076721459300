/* eslint-disable react/prop-types */
import currency from "currency.js";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useSortBy, useTable } from "react-table";
import { ReactComponent as Star } from "../../assets/svg/star.svg";
import { InstrumentTypeResponse } from "../../codegen-api";
import { COLORS, ICON_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { AssetResponse } from "../../utils/asset";
import { getAssetLogo, getAssetShortName } from "../../utils/asset/assets";
import PriceChart from "../PriceChart";
import { DefaultCellForColumn } from "../shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../shared/Table/DefaultHeaderForColumn";
import { Align } from "../shared/Table/style";
import {
  AssetCellContainer,
  ChangeTag,
  MarketRow,
  MarketTableBody,
  MarketsTableCol,
  MarketsTableTable,
  MarketsTableWrapper,
  PriceCell,
  WatchlistButton,
} from "./style";

export type IMarketData = {
  id: string;
  asset: AssetResponse;
  indexHistory: string[][];
  dailyVolume: number;
  isWatchlist: boolean;
  isCurrentMarket: boolean;

  // Number of decimal places, optional.
  pricePrecision?: number;
};

export type IOptionMarketData = IMarketData & {
  putCallRatio: number;
  oi: number;
};

export type IPerpetualMarketData = IMarketData & {
  // eg. BANANA-USD
  market: string;
  price: string;
  dailyChange: number;
  isPrelaunch: boolean;
};

type IMarketsTableProps = {
  isMobile?: boolean;
  highlightedIndex?: number;
} & (
  | {
      type: typeof InstrumentTypeResponse.Option;
      marketsData: IOptionMarketData[];
      onRowClick: (row: IOptionMarketData) => void;
      onWatchlist: (row: IOptionMarketData) => void;
    }
  | {
      type: typeof InstrumentTypeResponse.Perpetual;
      marketsData: IPerpetualMarketData[];
      onRowClick: (row: IPerpetualMarketData) => void;
      onWatchlist: (row: IPerpetualMarketData) => void;
    }
);

function AssetCell({
  asset,
  children,
}: PropsWithChildren<{ asset: AssetResponse }>) {
  return (
    <AssetCellContainer>
      <img src={getAssetLogo(asset)} width={24} height={24} alt={asset} />
      {children || <span>{getAssetShortName(asset)}</span>}
    </AssetCellContainer>
  );
}

const defaultPriceTimestampValue: string[][] = [];
const chartDimensions = {
  width: 40,
  height: 40,
};

function MarketsTable({
  type,
  marketsData,
  onRowClick,
  onWatchlist,
  highlightedIndex,
  isMobile,
}: IMarketsTableProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "CommandModal.MarketsTable",
  });
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);

  const columns = useMemo(() => {
    if (type === InstrumentTypeResponse.Perpetual) {
      const mobileCol: ITableColumn<IPerpetualMarketData>[] = [
        {
          title: t("market"),
          accessor: "market",
          Cell: ({ value, row }) => (
            <AssetCell asset={row.original.asset}>{value}</AssetCell>
          ),
        },
        {
          title: " ",
          accessor: "indexHistory",
          Header: () => <div />,
          Cell: ({ value }) => (
            <PriceChart
              timestampPriceData={(value?.length
                ? value
                : defaultPriceTimestampValue
              )
                .slice()
                .reverse()}
              chartStyle={"mini"}
              height={chartDimensions.height}
              width={chartDimensions.width}
            />
          ),
        },
        {
          title: t("price"),
          accessor: "price",
          align: "right",
          Cell: ({ value, row }) => {
            const { dailyChange } = row.original;
            return (
              <Align align="right">
                <PriceCell>
                  <div>
                    {currency(value, {
                      precision: Math.max(row.original.pricePrecision || 2, 2),
                    }).format()}
                  </div>
                  {isMobile && (
                    <div>
                      <ChangeTag value={dailyChange}>
                        {(dailyChange * 100).toFixed(2)}%
                      </ChangeTag>
                    </div>
                  )}
                </PriceCell>
              </Align>
            );
          },
        },
      ];

      const col: ITableColumn<IPerpetualMarketData>[] = [
        ...mobileCol,
        {
          title: t("daily_change"),
          accessor: "dailyChange",
          align: "right",
          sortType: "basic",
          Cell: ({ value }) => (
            <Align align="right">
              <ChangeTag value={value}>
                {value > 0 ? "+" : ""}
                {(value * 100).toFixed(2)}%
              </ChangeTag>
            </Align>
          ),
        },
        {
          title: t("daily_volume"),
          accessor: "dailyVolume",
          align: "right",
          Cell: ({ value }) => (
            <Align align="right">
              {value ? currency(value).format() : "-"}
            </Align>
          ),
        },
      ];

      const watchlistCol: ITableColumn<IPerpetualMarketData> = {
        title: " ",
        accessor: "id",
        Header: () => <div />,
        Cell: ({ row }) => (
          <Align
            align="right"
            style={{
              marginRight: SPACING.two,
            }}
          >
            <WatchlistButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onWatchlist(row.original);
              }}
            >
              <Star
                fill={
                  row.original.isWatchlist
                    ? COLORS.highlight.one
                    : ICON_COLORS.three
                }
              />
            </WatchlistButton>
          </Align>
        ),
      };

      return isMobile ? [...mobileCol, watchlistCol] : [...col, watchlistCol];
    }

    const mobileCol: ITableColumn<IOptionMarketData>[] = [
      {
        title: t("underlying_asset"),
        accessor: "asset",
        Cell: ({ value }) => <AssetCell asset={value} />,
      },
      {
        title: t("oi"),
        accessor: "oi",
        align: "right",
        Cell: ({ value }) => (
          <Align align="right">{currency(value).format()}</Align>
        ),
      },
    ];

    const col: ITableColumn<IOptionMarketData>[] = [
      mobileCol[0],
      {
        title: " ",
        accessor: "indexHistory",
        Header: () => <div />,
        Cell: ({ value }) => (
          <PriceChart
            timestampPriceData={(value?.length
              ? value
              : defaultPriceTimestampValue
            )
              .slice()
              .reverse()}
            chartStyle={"mini"}
            height={chartDimensions.height}
            width={chartDimensions.width}
          />
        ),
      },
      {
        title: t("put_call_ratio"),
        accessor: "putCallRatio",
        align: "right",
        Cell: ({ value }) => <Align align="right">{value.toFixed(2)}</Align>,
      },
      mobileCol[1],
      {
        title: t("daily_volume"),
        accessor: "dailyVolume",
        align: "right",
        Cell: ({ value }) => (
          <Align align="right">{value ? currency(value).format() : "-"}</Align>
        ),
      },
    ];

    const watchlistCol: ITableColumn<IOptionMarketData> = {
      title: " ",
      accessor: "id",
      Header: () => <div />,
      Cell: ({ row }) => (
        <Align
          align="right"
          style={{
            marginRight: SPACING.two,
          }}
        >
          <WatchlistButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onWatchlist(row.original);
            }}
          >
            <Star
              fill={
                row.original.isWatchlist
                  ? COLORS.highlight.one
                  : ICON_COLORS.three
              }
            />
          </WatchlistButton>
        </Align>
      ),
    };

    return isMobile ? [...mobileCol, watchlistCol] : [...col, watchlistCol];
  }, [isMobile, onWatchlist, t, type]);

  const assignRef = useCallback(
    (ref: HTMLTableRowElement | null, index: number) => {
      // eslint-disable-next-line no-param-reassign
      rowRefs.current[index] = ref;
    },
    [rowRefs]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: marketsData,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  // Scroll to selected row
  useEffect(() => {
    if (highlightedIndex !== undefined) {
      const ref = rowRefs.current[highlightedIndex];
      if (ref) {
        ref.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [highlightedIndex]);

  return (
    <MarketsTableWrapper>
      <MarketsTableTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                // <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <MarketTableBody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const { ...rowProps } = row.getRowProps();
            return (
              // eslint-disable-next-line react/jsx-key
              <MarketRow
                {...rowProps}
                ref={(ref) => assignRef(ref, index)}
                isSelected={
                  isMobile
                    ? false
                    : (row.original as IMarketData).isCurrentMarket ||
                      index === highlightedIndex
                }
                onClick={() => onRowClick(row.original as any)}
                delay={0.04 * index}
              >
                {row.cells.map((cell) => (
                  // eslint-disable-next-line react/jsx-key
                  <MarketsTableCol
                    {...cell.getCellProps()}
                    style={
                      cell.column.id === "indexHistory"
                        ? {
                            width: chartDimensions.width,
                          }
                        : undefined
                    }
                  >
                    {cell.render("Cell")}
                  </MarketsTableCol>
                ))}
              </MarketRow>
            );
          })}
        </MarketTableBody>
      </MarketsTableTable>
    </MarketsTableWrapper>
  );
}

export default MarketsTable;

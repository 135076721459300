import { useCallback, useEffect, useRef, useState } from "react";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { resetGlobalState } from "react-use-websocket";
import { WSS_URL } from "../../constants/api/wss";

// Shared websocket.
// When `share` config is true, any messages sent to the same `WSS_URL`
// will be sent through the same websocket connection.

const flushMessagesIntervalMs = 250;

const useSharedWebsocket = () => {
  const data = useRef(new Set<MessageEvent<any>>());

  const { sendMessage, readyState } = useWebSocket(WSS_URL, {
    shouldReconnect: () => true,
    reconnectAttempts: 50,
    // Attempt to reconnect every 2 seconds
    reconnectInterval: 2000,
    retryOnError: true,
    share: true,
    onMessage: (msg: any) => {
      data.current.add(msg);
    },
    // Never upate lastMessage, since we're processing onMessage ourselves
    filter: () => false,
  });

  const resetState = useCallback(() => {
    resetGlobalState(WSS_URL);
  }, []);

  const [messages, setMessages] = useState<MessageEvent<any>[] | null>(null);

  useEffect(() => {
    const flushMessages = () => {
      const msgs = Array.from(data.current.values());
      if (msgs.length) {
        data.current.clear();
        setMessages(msgs);
      }
    };
    const timer = setInterval(flushMessages, flushMessagesIntervalMs);

    return () => {
      clearInterval(timer);
      flushMessages();
    };
  }, []);

  return {
    readyState,
    sendMessage,
    lastMessages: messages,
    resetGlobalState: resetState,
  };
};

export default useSharedWebsocket;

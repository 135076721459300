/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import currency from "currency.js";
import moment from "moment";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSortBy, useTable } from "react-table";
import {
  GetOrders200Response,
  InstrumentTypeResponse,
  MarginTypeResponse,
  OrderTypeResponse,
  SideResponse,
} from "../../codegen-api";
import { SPACING } from "../../constants/design/spacing";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { getTimeAgo, nanosToSeconds } from "../../utils/date";
import { formatSizePrecision } from "../../utils/format";
import {
  getAssetFromSymbol,
  getContractPriceStep,
  getOptionsTypeFromSymbol,
} from "../../utils/instruments";
import { getStopOrderName } from "../../utils/order";
import { CancelAllOrdersModal } from "../ConfirmationModal/CancelAllOrdersModal";
import EditOrderModal from "../ConfirmationModal/EditOrderModal";
import RemoveOrderModal from "../ConfirmationModal/RemoveOrderModal";
import MarketCell from "../PortfolioSettings/Table/MarketCell";
import RowActionButton from "../shared/RowActionButton";
import { Spinner } from "../shared/Spinner";
import { SpinnerContainerWrapper } from "../shared/Spinner/style";
import { DefaultCellForColumn } from "../shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../shared/Table/DefaultHeaderForColumn";
import {
  Align,
  ButtonGroup,
  Direction,
  SizeFillCell,
  TableHeaderCell,
} from "../shared/Table/style";
import OrderDetailsModal from "../shared/ViewDetailsModal/OrderDetailsModal";
import {
  CancelAllButton,
  OrderGroupedCells,
  OrdersTableWrapper,
} from "./style";
import { AssetResponse } from "../../utils/asset";
import { MarketContext } from "../../contexts/MarketContext";
import { COLORS } from "../../constants/design/colors";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";

interface IOrdersProps {
  orders: GetOrders200Response[];
  loading?: boolean;
  selectedMarketType?: InstrumentTypeResponse;
  selectedAssetType?: AssetResponse;
}

// A modified version of Order that includes calculated values
interface IModifiedOrder extends GetOrders200Response {
  fillPercent: string;
  remaining: string;
  side: SideResponse;
  type: string;
  amountPrecision: number;
}

function Orders({
  orders,
  loading,
  selectedMarketType,
  selectedAssetType,
}: IOrdersProps) {
  const { t } = useTranslation("app", { keyPrefix: "Orders" });
  const { t: translateAll } = useTranslation();
  const { t: tooltip } = useTranslation("tooltips");
  const {
    activeOptionMarkets,
    activePerpMarkets,
    getMarketPrecision,
    setOrderType,
    setExpiry,
    setStrike,
  } = useContext(MarketInstrumentContext);
  const { data: accountData } = useGetAccount();

  const [removingOrder, setRemovingOrder] = useState<GetOrders200Response>();
  const [viewDetailsForOrder, setViewDetailsForOrder] =
    useState<GetOrders200Response>();
  const [showRemoveOrderModal, setShowRemoveOrderModal] = useState(false);

  const [editingOrder, setEditingOrder] = useState<GetOrders200Response>();
  const [showEditOrderModal, setShowEditOrderModal] = useState(false);
  const [showCancelAllOrderModal, setShowCancelAllOrderModal] = useState(false);

  const onClickCancelAll = useCallback(() => {
    setShowCancelAllOrderModal(true);
  }, []);

  const onCancelOrder = useCallback((order?: GetOrders200Response) => {
    setRemovingOrder(order);
    setShowRemoveOrderModal(!!order);
  }, []);

  const onEditOrder = useCallback((order?: GetOrders200Response) => {
    setEditingOrder(order);
    setShowEditOrderModal(!!order);
  }, []);

  const { setMarket } = useContext(MarketContext);

  const onSelectOrder = (order: GetOrders200Response) => {
    if (order) {
      setMarket({
        derivative: order.instrument_type,
        asset: getAssetFromSymbol(order.instrument_name) as string,
      });
      setOrderType(order.option_type);
      setExpiry(Number(order.expiry));
      setStrike(order.strike);
    }
  };

  const getInstrumentLeverage = useCallback(
    (instrumentId: string) =>
      accountData?.leverages?.find((l) => l.instrument_id === instrumentId),
    [accountData?.leverages]
  );

  const modifiedOrders: IModifiedOrder[] = useMemo(
    () =>
      orders.map((order) => {
        const orderAsset = getAssetFromSymbol(order.instrument_name);
        const markets =
          order.instrument_type === InstrumentTypeResponse.Option
            ? activeOptionMarkets
            : activePerpMarkets;
        const instrument = orderAsset
          ? markets?.find((m) => m.underlying_asset === orderAsset)
          : undefined;
        return {
          ...order,
          fillPercent: (
            (Number(order.filled) / Number(order.amount)) *
            100
          ).toFixed(1),
          remaining: (Number(order.amount) - Number(order.filled)).toFixed(2),
          tradeDirection: order.side,
          amountPrecision: getContractPriceStep(instrument).amount_precision,
          type: order.stop
            ? `${getStopOrderName(
                translateAll,
                order.order_type,
                order.stop,
                true
              )}`
            : order.order_type,
        };
      }),
    [activeOptionMarkets, activePerpMarkets, orders, translateAll]
  );

  const columns: ITableColumn<IModifiedOrder>[] = useMemo(() => {
    const ivCols: ITableColumn<IModifiedOrder>[] = [
      {
        title: t("iv"),
        accessor: "iv",
        align: "right",
        tooltip: tooltip("iv"),
        valueExtractor: (value) =>
          value ? `${(Number(value) * 100).toFixed(2)}%` : "-",
      },
    ];
    const cols: ITableColumn<IModifiedOrder>[] = [
      {
        title: t("market"),
        accessor: "instrument_name",
        tooltip: tooltip("market"),
        Cell: ({ row }) => {
          const { expiry, strike, instrument_name, instrument_id } =
            row.original;
          const optionType = getOptionsTypeFromSymbol(instrument_name);
          const leverage = getInstrumentLeverage(instrument_id)?.leverage;

          if (optionType) {
            return (
              <MarketCell
                instrumentName={instrument_name}
                optionType={optionType}
                expiry={expiry}
                strike={strike}
              />
            );
          }

          return (
            <MarketCell
              leverage={Number(leverage)}
              instrumentName={instrument_name}
            />
          );
        },
      },
      {
        title: t("side"),
        align: "left",
        accessor: "side",
        tooltip: tooltip("side"),
        Cell: ({ value }) => <Direction side={value}>{t(value)}</Direction>,
      },
      {
        title: t("type"),
        align: "left",
        accessor: "type",
        tooltip: tooltip("orderType"),
        Cell: ({ value }) => <span>{value}</span>,
      },
      {
        title: t("size_fill"),
        align: "right",
        accessor: "amount",
        tooltip: tooltip("orderSize"),
        Cell: ({ row }) => (
          <Align align="right">
            <SizeFillCell>
              <span>
                {row.original.close_position === true
                  ? t("close_position")
                  : formatSizePrecision(
                      row.original.amount,
                      row.original.amountPrecision
                    )}
              </span>
              <span>
                {formatSizePrecision(
                  row.original.filled,
                  row.original.amountPrecision
                )}
              </span>
            </SizeFillCell>
          </Align>
        ),
      },
      {
        title: t("price_trigger"),
        accessor: "price",
        align: "right",
        tooltip: tooltip("priceTrigger"),
        Cell: ({ value, row }) => {
          const { trigger, order_type, instrument_name, instrument_type } =
            row.original;
          const precision = getMarketPrecision(
            getAssetFromSymbol(instrument_name),
            instrument_type
          ).price_precision;
          return (
            <SizeFillCell>
              <span>
                {order_type === OrderTypeResponse.Market
                  ? "-"
                  : currency(value, { precision }).format()}
              </span>
              <span>
                {trigger ? currency(trigger, { precision }).format() : "-"}
              </span>
            </SizeFillCell>
          );
        },
      },
      ...(selectedMarketType === InstrumentTypeResponse.Option ? ivCols : []),
      {
        title: t("initial_margin"),
        accessor: "initial_margin",
        align: "right",
        tooltip: tooltip("initialMargin"),
        Cell: ({ value, row }) => {
          if (
            getInstrumentLeverage(row.original.instrument_id)?.margin_type ===
            MarginTypeResponse.Isolated
          ) {
            return (
              <Align align="right">
                <div style={{ flexDirection: "column" }}>
                  <div>{currency(Number(value || 0)).format()}</div>
                  <div style={{ color: COLORS.blue.one }}>{t("isolated")}</div>
                </div>
              </Align>
            );
          }

          return (
            <Align align="right">{currency(Number(value || 0)).format()}</Align>
          );
        },
      },
      {
        title: t("time"),
        accessor: "created_timestamp",
        align: "right",
        tooltip: tooltip("orderTimestamp"),
        valueExtractor: (value) =>
          getTimeAgo(moment.unix(nanosToSeconds(value)), translateAll),
      },
      {
        id: "rowActionsSideTypExpiry",
        Header: () => (
          <OrderGroupedCells>
            <CancelAllButton onClick={onClickCancelAll}>
              Cancel All
            </CancelAllButton>
          </OrderGroupedCells>
        ),
        Cell: ({ row }: any) => (
          <OrderGroupedCells>
            <ButtonGroup>
              <RowActionButton
                variant={"close"}
                onClick={(e) => {
                  e.stopPropagation();
                  onCancelOrder(row.original as GetOrders200Response);
                }}
                style={{ marginRight: `${SPACING.two}px` }}
              />
              {!row.original.close_position && (
                <RowActionButton
                  variant={"edit"}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditOrder(row.original as GetOrders200Response);
                  }}
                  style={{ marginRight: `${SPACING.two}px` }}
                />
              )}
              <RowActionButton
                variant={"details"}
                onClick={(e) => {
                  e.stopPropagation();
                  setViewDetailsForOrder(row.original as GetOrders200Response);
                }}
              />
            </ButtonGroup>
          </OrderGroupedCells>
        ),
      },
    ];
    return cols;
  }, [
    t,
    tooltip,
    selectedMarketType,
    getMarketPrecision,
    getInstrumentLeverage,
    translateAll,
    onClickCancelAll,
    onCancelOrder,
    onEditOrder,
  ]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: modifiedOrders,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  if (loading) {
    return (
      <SpinnerContainerWrapper>
        <Spinner />
      </SpinnerContainerWrapper>
    );
  }

  return (
    <>
      <RemoveOrderModal
        order={removingOrder}
        onHide={() => setShowRemoveOrderModal(false)}
        show={showRemoveOrderModal}
      />
      <CancelAllOrdersModal
        instrumentType={selectedMarketType}
        asset={selectedAssetType}
        show={showCancelAllOrderModal}
        onHide={() => setShowCancelAllOrderModal(false)}
      />
      <EditOrderModal
        order={editingOrder}
        onHide={() => setShowEditOrderModal(false)}
        show={showEditOrderModal}
      />
      <OrderDetailsModal
        order={viewDetailsForOrder}
        onHide={() => setViewDetailsForOrder(undefined)}
        onEditOrder={() => {
          onEditOrder(viewDetailsForOrder);
          setViewDetailsForOrder(undefined);
        }}
        onRemoveOrder={() => {
          onCancelOrder(viewDetailsForOrder);
          setViewDetailsForOrder(undefined);
        }}
      />
      <OrdersTableWrapper>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    // style={{ width: column.width }}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { ...rowProps } = row.getRowProps();
              return (
                // eslint-disable-next-line react/jsx-key
                <tr
                  {...rowProps}
                  onClick={() =>
                    onSelectOrder(row.original as GetOrders200Response)
                  }
                >
                  {row.cells.map((cell) => (
                    // eslint-disable-next-line react/jsx-key
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </OrdersTableWrapper>
    </>
  );
}

export default memo(Orders);

import { forwardRef, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MarketPortfolioOptionEnum } from ".";
import { LAYER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { MobileOrdersTab } from "../../pages/PortfolioPage/mobile/MobileOrdersTab";
import { MobilePositionsTab } from "../../pages/PortfolioPage/mobile/MobilePositionsTab";
import { MobileTradeHistoryTab } from "../../pages/PortfolioPage/mobile/MobileTradeHistoryTab";
import { ChartTab } from "../Perps/PerpsOrderbookAndChart/style";
import SegmentedControl from "../shared/SegmentedControl";
import {
  MobileTradingBottomBarContainer,
  MobileTradingBottomBarContent,
} from "./style";

const MobileTradingBottomBar = forwardRef<any>((_, ref) => {
  const { t } = useTranslation("app", {
    keyPrefix: "TradingBottomBar.TradingBottomBar",
  });

  const [portfolioTab, setPortfolioTab] = useState<MarketPortfolioOptionEnum>(
    MarketPortfolioOptionEnum.Positions
  );

  const getTabTitle = useCallback(
    (tab: MarketPortfolioOptionEnum) => {
      switch (tab) {
        case MarketPortfolioOptionEnum.Positions:
          return `${t("positions")}`;
        case MarketPortfolioOptionEnum.OpenOrders:
          return `${t("orders")}`;
        case MarketPortfolioOptionEnum.History:
          return `${t("history")}`;
        default:
          return "";
      }
    },
    [t]
  );

  const portfolioControls = useMemo(
    () =>
      Object.keys(MarketPortfolioOptionEnum).map((k) => ({
        display: (
          <ChartTab>
            {getTabTitle(
              MarketPortfolioOptionEnum[
                k as keyof typeof MarketPortfolioOptionEnum
              ]
            )}
          </ChartTab>
        ),
        value: MarketPortfolioOptionEnum[
          k as keyof typeof MarketPortfolioOptionEnum
        ] as MarketPortfolioOptionEnum,
        textColor:
          MarketPortfolioOptionEnum[
            k as keyof typeof MarketPortfolioOptionEnum
          ] === portfolioTab
            ? TEXT_COLORS.one
            : TEXT_COLORS.three,
      })),
    [getTabTitle, portfolioTab]
  );

  return (
    <MobileTradingBottomBarContainer ref={ref}>
      <SegmentedControl
        segments={portfolioControls}
        value={portfolioTab}
        onSelect={(e) => setPortfolioTab(e as MarketPortfolioOptionEnum)}
        config={{
          widthType: "fullWidth",
          backgroundColor: LAYER_COLORS.two,
          activeBackgroundColor: LAYER_COLORS.three,
          borderRadius: "10px",
          button: {
            fontSize: FONT_SIZE.one,
          },
        }}
      />
      <MobileTradingBottomBarContent>
        <div hidden={portfolioTab !== MarketPortfolioOptionEnum.Positions}>
          <MobilePositionsTab showFilter={false} noPadding />
        </div>
        <div hidden={portfolioTab !== MarketPortfolioOptionEnum.OpenOrders}>
          <MobileOrdersTab showFilter={false} noPadding />
        </div>
        <div hidden={portfolioTab !== MarketPortfolioOptionEnum.History}>
          <MobileTradeHistoryTab showFilter={false} noPadding />
        </div>
      </MobileTradingBottomBarContent>
    </MobileTradingBottomBarContainer>
  );
});

export default MobileTradingBottomBar;

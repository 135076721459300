// 8-point grid system
export const SPACING = {
  one: 4,
  two: 8,
  twoHalf: 12,
  three: 16,
  four: 24,
  five: 32,
  six: 48,
  seven: 64,
  eight: 72,
  nine: 80,
} as const;

// Screen widths
export const WIDTHS = {
  S: 980,
  SM: 1441,
  M: 1280,
  L: 1728,
} as const;

export const BORDER_RADIUS = {
  one: 4,
  two: 6,
  three: 8,
  four: 10,
  five: 12,
  six: 64,
  max: 100,
} as const;

// Might be worth including browser height into the mix
export const COMPONENTS = {
  header: {
    main: 56,
    sub: 48,
    buttons: 40,
  },
  toggleTabs: 32,
  marketSelectionDropdown: 271,
  footer: {
    desktop: 32,
    // Account + Nav
    mobileSubNav: 56,
    mobileNav: 56,
    mobileFooterTotal: 56 + 64,
  },
  portfolioNavigation: 40,
  portfolioFilter: 48,
  mobileTvChart: 360,
  mobileCustomChart: 320,
  banner: 24,
  notificationPanel: 343,
  tradeTicketOrderbookHeight: 420,
  emptyContent: 236,
  tableRow: 40,
  portfolioTableRow: 56,
  portfolioRewardsTableRow: 48,
  tableRowSmall: 40,
  perpsTableRowSmall: 24,
  tableHeader: 32,
  portfolioTableHeader: 48,
  tableActionButtonsCellWidth: 112,
  selectHeight: 32,
  chart: {
    tradingViewSpotPrice: 290,
    spotPrice: 48,
    dateFilter: 48,
    portfolio: 240,
  },
  overview: {
    section: 288,
  },
  slideOutModalContentMaxWidth: 400,
  modalHeaderHeight: 56,
  tabs: 56,
  secondaryTabs: 48,
  pageTabsHeight: 40,
  tradeTicketTab: 56,
  dateSelection: 64,
  tradeModalFilterHeight: 437,
  showHideTradeModalButtonSize: 64,
  subHeaderButtonSize: 56,
  accountModalRowHeight: 48,
  tradeModalMinHeight: 890,
  tradeSectionWidth: 304,
  perpsOrderbookWidth: 304,
  perpsOrderbookSpreadRowHeight: 24,
  tradeBottomBar: {
    header: 48,
    height: {
      large: 320,
      small: 200,
    },
  },
  mobileTradeBottomBarMinHeight: 300,
  portfolioSideTab: 320,
  modalButton: 48,
  cmdKModalFooter: 48,
  dateSelectionHeight: 32,
  optionsSpotChartWrapperWidth: 160,
  mobilePerpPositionBarHeight: 80,
  paginationHeight: 64,
  strikeHeader: 40,
  announcementHeight: 32,
  mobileTabBar: 56,
  slider: 40,
} as const;

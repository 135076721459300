import styled from "styled-components";
import {
  ICON_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../constants/design/spacing";

export const HeaderText = styled.p`
  margin: 0;
  ${FONT_CSS.header.seven};
  color: ${TEXT_COLORS.one};
`;

export const SubText = styled.p`
  ${FONT_CSS.label.two}
  margin: 0;
  color: ${TEXT_COLORS.three};
`;

export const ClearButton = styled.button`
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommandKeyChevronContainer = styled.div`
  display: flex;

  > div {
    min-width: 24px;
    min-height: 24px;
    border-radius: ${BORDER_RADIUS.two}px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${SPACING.one}px;

    ${FONT_CSS.body.five}
    background-color: ${LAYER_COLORS.two};
    color: ${TEXT_COLORS.three};

    span {
      padding: ${SPACING.one}px ${SPACING.two}px;
    }

    svg {
      stroke: ${ICON_COLORS.three};
    }
  }
`;

export const MarketsDropdownWrapper = styled.button`
  border-radius: ${BORDER_RADIUS.five}px;
  text-align: left;
  display: flex;
  width: 100%;
  height: ${COMPONENTS.header.sub}px;
  background-color: ${LAYER_COLORS.one};
  justify-content: space-between;

  > * {
    margin: auto ${SPACING.one}px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: ${SPACING.two}px;
    }
  }

  > div > img {
    margin-right: ${SPACING.two}px;
  }

  &:hover {
    ${CommandKeyChevronContainer} {
      > * {
        transition: all 0.2s ease-out;
        color: ${TEXT_COLORS.one};
      }
      svg {
        transition: all 0.2s ease-out;
        stroke: ${TEXT_COLORS.one};
        opacity: 1;
      }
    }
  }
`;

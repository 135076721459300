/* eslint-disable no-underscore-dangle */
import { AssetResponse, getAssetInfo } from ".";
import BTCLogo, {
  ReactComponent as BTCLogoComponent,
} from "../../assets/svg/assets/BTC.svg";
import ETHLogo, {
  ReactComponent as ETHLogoComponent,
} from "../../assets/svg/assets/ETH.svg";
import USDTLogo, {
  ReactComponent as USDTLogoComponent,
} from "../../assets/svg/assets/usdt.svg";
import USDCLogo, {
  ReactComponent as USDCLogoComponent,
} from "../../assets/svg/assets/usdc.svg";
import AUSDCLogo, {
  ReactComponent as AUSDCLogoComponent,
} from "../../assets/svg/assets/ausdc.svg";
import RBNLogo from "../../assets/logo/rbn_logo_white.svg";
import AEVOLogo from "../../assets/logo/logo_white.svg";

import { CollateralAssetResponse } from "../../codegen-api";
import {
  AllCollaterals,
  AllDepositWithdrawAssets,
  DepositWithdrawCollaterals,
  addressToDepositCollateral,
} from "../../constants/addresses";
import { ChainIdEnum, socketDepositChains } from "../../enums/chain";
import { isProduction } from "../env";

export function getAssetLogo(
  asset?: AssetResponse | AllCollaterals | "RBN" | "AEVO"
): string | undefined {
  switch (asset) {
    case CollateralAssetResponse.Weth:
      return ETHLogo;
    case CollateralAssetResponse.Usdt:
      return USDTLogo;
    case CollateralAssetResponse.Usdc:
      return USDCLogo;
    case DepositWithdrawCollaterals.NativeUsdc:
      return USDCLogo;
    case CollateralAssetResponse.AeUsd:
      return AUSDCLogo;
    case CollateralAssetResponse.Wbtc:
      return BTCLogo;
    case "RBN":
      return RBNLogo;
    case "AEVO":
      return AEVOLogo;
    default: {
      const a = getAssetInfo(asset);
      return a.logo;
    }
  }
}

export function getCollateralAssetLogo(asset?: AllCollaterals) {
  switch (asset) {
    case CollateralAssetResponse.Weth:
      return ETHLogoComponent;
    case CollateralAssetResponse.AeUsd:
      return AUSDCLogoComponent;
    case CollateralAssetResponse.Usdt:
      return USDTLogoComponent;
    case CollateralAssetResponse.Usdc:
      return USDCLogoComponent;
    case DepositWithdrawCollaterals.NativeUsdc:
      return USDCLogoComponent;
    case CollateralAssetResponse.Wbtc:
      return BTCLogoComponent;
    case undefined:
      return undefined;
  }
  return undefined;
}

export function getCollateralAssetByAddress(
  collateralAddress: string
): AllCollaterals | undefined {
  const asset = collateralAddress
    ? (Object.keys(addressToDepositCollateral).find((key) =>
        addressToDepositCollateral[key as AllCollaterals].includes(
          collateralAddress
        )
      ) as AllCollaterals | undefined)
    : undefined;
  return asset;
}

export function getAssetToShow(
  asset?: AllCollaterals,
  label?: string
): AllCollaterals | undefined {
  if (label === "YV_DEPOSIT" || label === "YV_WITHDRAW") {
    return CollateralAssetResponse.AeUsd;
  }
  return asset;
}

export function getAssetName(asset: AssetResponse): string | undefined {
  return getAssetInfo(asset).name;
}

export function getAssetShortName(
  asset?: AssetResponse | CollateralAssetResponse,
  chainId?: ChainIdEnum
): string | undefined {
  if (asset === "WETH") {
    return "ETH";
  }
  if (asset === "WBTC") {
    return "BTC";
  }
  if (
    asset === CollateralAssetResponse.Usdc &&
    (chainId === ChainIdEnum.ARBITRUM ||
      chainId === ChainIdEnum.ARBITRUM_TESTNET ||
      chainId === ChainIdEnum.OPTIMISM ||
      chainId === ChainIdEnum.OPTIMISM_TESTNET)
  ) {
    return "USDC.e";
  }
  if (
    asset === DepositWithdrawCollaterals.NativeUsdc &&
    chainId === undefined
  ) {
    return "USDC";
  }

  return asset;
}

// eslint-disable-next-line consistent-return
export function getCollateralPrecision(collateral: AllCollaterals) {
  switch (collateral) {
    case CollateralAssetResponse.Usdc:
    case CollateralAssetResponse.Usdt:
    case CollateralAssetResponse.AeUsd:
    case DepositWithdrawCollaterals.NativeUsdc:
      return 2;
    case CollateralAssetResponse.Sdai:
    case CollateralAssetResponse.Weth:
      return 4;
    case CollateralAssetResponse.Wbtc:
      return 5;
  }
}

const testnetAllowedDeposits = [...Object.values(DepositWithdrawCollaterals)];
const mainnetAllowedDeposits = [...Object.values(DepositWithdrawCollaterals)];

// TODO: - Allow other collateral deposits when UI is ready
const allDepositAssets = isProduction()
  ? mainnetAllowedDeposits
  : testnetAllowedDeposits;

export const getCollateralAssets = (
  chainId?: ChainIdEnum,
  type?: "deposit" | "withdraw" | "transfer",
  yieldAsset: boolean = false
) => {
  let collaterals: AllCollaterals[] = Object.values(
    AllCollaterals
  ) as AllCollaterals[];
  // todo: remove this if statement once bridged usdc withdraw is available again
  if (
    (chainId === ChainIdEnum.ARBITRUM || chainId === ChainIdEnum.OPTIMISM) &&
    type === "withdraw"
  ) {
    collaterals = [
      DepositWithdrawCollaterals.NativeUsdc,
      // DepositWithdrawCollaterals.Usdc,
      DepositWithdrawCollaterals.Weth,
    ] as DepositWithdrawCollaterals[];
  } else if (chainId && socketDepositChains.includes(chainId)) {
    collaterals = [
      DepositWithdrawCollaterals.Usdc,
      DepositWithdrawCollaterals.NativeUsdc,
      DepositWithdrawCollaterals.Weth,
    ] as DepositWithdrawCollaterals[];
  }

  collaterals = collaterals.filter((c) => c !== CollateralAssetResponse.Sdai);
  if (!yieldAsset) {
    collaterals = collaterals.filter(
      (c) => c !== CollateralAssetResponse.AeUsd
    );
  }
  if (chainId && !socketDepositChains.includes(chainId)) {
    collaterals = collaterals.filter(
      (c) => c !== DepositWithdrawCollaterals.NativeUsdc
    );
  }

  // Filter type
  switch (type) {
    case "deposit":
      collaterals = collaterals.filter((c) =>
        allDepositAssets.includes(c as DepositWithdrawCollaterals)
      );
      break;
    default:
      break;
  }

  return collaterals;
};

export const getYieldAssets = () =>
  [CollateralAssetResponse.AeUsd] as CollateralAssetResponse[];

export const collateralAssetDecimals: {
  [collateral in AllDepositWithdrawAssets]: number;
} = {
  [CollateralAssetResponse.Usdc]: 6,
  [DepositWithdrawCollaterals.NativeUsdc]: 6,
  [CollateralAssetResponse.Usdt]: 6,
  [CollateralAssetResponse.AeUsd]: 6,
  [CollateralAssetResponse.Weth]: 18,
  [CollateralAssetResponse.Wbtc]: 8,
  [CollateralAssetResponse.Sdai]: 6,
  AEVO: 18,
  RBN: 18,
};

import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { KeyedMutator } from "swr";
import { BaseModal } from "../BaseModal";
import {
  ActiveNumberButton,
  AnimationWrapper,
  ButtonWrapper,
  ButtonsRow,
  ButtonsWrapper,
  CalculateWrapper,
  ModalWrapper,
  NumberButton,
  NumberText,
  NumberWrapper,
  VideoContainer,
  Text,
  SubText,
  BaseModalWrapper,
  CountdownWrapper,
} from "./style";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { SPACING } from "../../constants/design/spacing";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import {
  COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import BackgroundVideoFX from "../shared/BackgroundVideoFX/BackgroundVideoFX";
import ExternalLinkText from "../ExternalLinkText";
import { useToast } from "../../hooks/toast";
import { GetFarmBoost200Response } from "../../codegen-api";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useSFX } from "../../hooks/useSFX";

interface IFarmBoostBonusModalProps {
  show: boolean;
  onHide: () => void;
  mutate: KeyedMutator<GetFarmBoost200Response>;
  setFarmBoost: () => Promise<string>;
  setFarmBoostClaimed?: (claimed: boolean) => void;
  level?: number;
}

const farmBoostBonusEndDate = moment.utc("2024-03-20T08:00:00");

function FarmBoostBonusModal({
  show,
  onHide,
  mutate,
  setFarmBoost,
  setFarmBoostClaimed,
  level,
}: IFarmBoostBonusModalProps) {
  const { t } = useTranslation("app", { keyPrefix: "FarmBoostBonusModal" });
  const [generatedFarmBoost, setGeneratedFarmBoost] = useState<string>();
  const firstRowButtons = [0, 1, 2, 3, 4];
  const secondRowButtons = [5, 6, 7, 8, 9];
  const { addErrorToast } = useToast();
  const { playSound } = useSFX();
  const [loading, setLoading] = useState<boolean>(false);
  const { data: accountData } = useGetAccount();
  const [confirmedNumber, setConfirmedNumber] = useState<number | undefined>();
  const [completeAnimation, setCompleteAnimation] = useState<boolean>(false);
  const [showCompletedState, setShowCompletedState] = useState<boolean>(false);
  const [displayedNumbers, setDisplayedNumbers] = useState<string[]>([
    "-",
    "-",
    "-",
  ]);
  const [activeButtonIndex, setActiveButtonIndex] = useState<number | null>(
    null
  );

  const [timeTillEnd, setTimeTillEnd] = useState("");

  useEffect(() => {
    // Function to update the time till the boost end date
    const updateTimeTillEnd = () => {
      const now = moment.utc();
      const duration = moment.duration(farmBoostBonusEndDate.diff(now));

      if (duration.asSeconds() <= 0) {
        // If the duration is negative, set time till end to "0D 0H 0M 0S"
        setTimeTillEnd("0D 0H 0M 0S");
      } else {
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        const timeUntilEnd = `${days}D ${hours}H ${minutes}M ${seconds}S`;
        setTimeTillEnd(timeUntilEnd);
      }
    };

    updateTimeTillEnd();

    const intervalId = setInterval(updateTimeTillEnd, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const updateNumber = useCallback((index: number, number: number) => {
    setDisplayedNumbers((prev) => {
      const updatedNumbers = [...prev];
      updatedNumbers[index] = number.toString();
      return updatedNumbers;
    });
  }, []);

  const animateGenerateFarmBoost = useCallback(
    (firstNumber: number, secondNumber: number, thirdNumber: number) => {
      const sequenceNumbers = [firstNumber, secondNumber, thirdNumber];
      let sequenceIndex = 0;
      let count = 0;
      let lastRandomIndex = -1;
      const updateSequence = () => {
        let availableNumbers = Array.from({ length: 10 }, (_, i) => i); // Create an array [0-9]

        const interval = setInterval(() => {
          // Exclude the last selected number and the actual number (if it's not the 5th iteration)
          availableNumbers = availableNumbers.filter(
            (n) => n !== lastRandomIndex
          );
          if (count < 9) {
            availableNumbers = availableNumbers.filter(
              (n) => n !== sequenceNumbers[sequenceIndex]
            );
          }

          // Select a random number from the remaining available numbers
          const randomIndex =
            availableNumbers[
              Math.floor(Math.random() * availableNumbers.length)
            ];
          lastRandomIndex = randomIndex; // Update the last selected number

          setActiveButtonIndex(randomIndex);

          count += 1;
          if (count === 10) {
            clearInterval(interval);
            setConfirmedNumber(sequenceNumbers[sequenceIndex]);
            setActiveButtonIndex(sequenceNumbers[sequenceIndex]); // Set to the actual sequence number
            updateNumber(sequenceIndex, sequenceNumbers[sequenceIndex]);

            lastRandomIndex = -1; // Reset for the next sequence
            count = 0;
            sequenceIndex += 1;

            if (sequenceIndex < sequenceNumbers.length) {
              setTimeout(() => {
                updateSequence();
                setConfirmedNumber(undefined);
              }, 1000); // Wait before starting the next sequence
            }
            if (sequenceIndex === sequenceNumbers.length) {
              setCompleteAnimation(true);
              setTimeout(() => {
                setConfirmedNumber(undefined);
                setLoading(false);
              }, 1000); // Wait before starting the next sequence
            }
          }
        }, 100);
      };

      updateSequence();
    },
    [updateNumber]
  );

  const generateFarmBoost = useCallback(async () => {
    try {
      setLoading(true);
      const farmBoost = await setFarmBoost();
      const roundedNumber = Number(farmBoost).toFixed(2);
      const digits = roundedNumber.replace(".", ""); // Remove the decimal point to extract digits
      mutate();

      if (digits.length >= 3) {
        setGeneratedFarmBoost(roundedNumber);
        animateGenerateFarmBoost(
          parseInt(digits.charAt(0), 10),
          parseInt(digits.charAt(1), 10),
          parseInt(digits.charAt(2), 10)
        );
      } else {
        addErrorToast("Invalid Farm Boost Data", "Please Try Again");
      }
    } catch (error: any) {
      setLoading(false);
      addErrorToast("Error Claiming Farm Boost Bonus", "Please Try Again");
    }
  }, [addErrorToast, animateGenerateFarmBoost, mutate, setFarmBoost]);

  useEffect(() => {
    if (completeAnimation) {
      setTimeout(() => {
        setShowCompletedState(true);
        playSound("social_media_like");
      }, 1500);
    }
  }, [completeAnimation, playSound]);
  return (
    <BaseModal
      title={showCompletedState ? t("title_1") : t("title_2")}
      titleStyle={{
        width: "100%",
        textAlign: "center",
        marginRight: SPACING.three,
      }}
      show={show}
      onHide={onHide}
      style={{ width: 311, overflow: "hidden" }}
      showHeader
      hideCloseButton
      noContentPadding
    >
      <BaseModalWrapper>
        <CountdownWrapper>
          {!generatedFarmBoost ? timeTillEnd : ""}
        </CountdownWrapper>
        {completeAnimation && (
          <AnimationWrapper>
            <AnimatePresence>
              <motion.div
                initial={{
                  y: "100%",
                  height: "100%",
                  width: "100%",
                  backgroundColor: PRIMARY_COLORS.one,
                  overflow: "hidden",
                }}
                animate={{ y: ["100%", "0%", "0%", "-100%"] }}
                transition={{
                  duration: 1.2,
                  times: [0, 0.4, 0.6, 1.0],
                  ease: "linear",
                  delay: 1,
                }}
                style={{ position: "absolute", bottom: 0 }}
                onAnimationComplete={() => {
                  setCompleteAnimation(false);
                  if (setFarmBoostClaimed) {
                    setFarmBoostClaimed(true);
                  }
                }}
              />
            </AnimatePresence>
          </AnimationWrapper>
        )}
        {showCompletedState && (
          <ModalWrapper>
            <VideoContainer>
              <BackgroundVideoFX />
              <SubText>{generatedFarmBoost}x</SubText>
            </VideoContainer>
            <Text style={{ textAlign: "center" }}>
              {accountData?.has_been_referred ? (
                t("end_text_1", { generatedFarmBoost })
              ) : level ? (
                <span>
                  {t("end_text_2.1", { generatedFarmBoost })}
                  <span style={{ color: TEXT_COLORS.one }}>{level}</span>
                  {t("end_text_2.2", { generatedFarmBoost })}
                </span>
              ) : (
                t("end_text_3", { generatedFarmBoost })
              )}
            </Text>
            <Text style={{ textAlign: "center" }}>
              <ExternalLinkText
                href="https://docs.aevo.xyz/trading-and-staking-incentives/earning-usdaevo/trade-on-aevo"
                target="_blank"
                rel="noreferrer"
                color={TEXT_COLORS.two}
              >
                {t("learn_more")}
              </ExternalLinkText>
            </Text>
            <Button
              fullWidth
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              onClick={() => {
                onHide();
              }}
              style={{ marginTop: "auto" }}
            >
              <Close />
            </Button>
          </ModalWrapper>
        )}
        {!showCompletedState && (
          <>
            <NumberWrapper completeAnimation={completeAnimation}>
              <NumberText>{displayedNumbers[0]}</NumberText>
              <NumberText>{displayedNumbers[1]}</NumberText>
              <NumberText>{displayedNumbers[2]}</NumberText>
            </NumberWrapper>
            <CalculateWrapper completeAnimation={completeAnimation}>
              <ButtonsRow>
                {firstRowButtons.map((number) => (
                  <ButtonsWrapper key={number}>
                    <ActiveNumberButton
                      key={number === confirmedNumber ? "active" : "inactive"}
                      color={COLORS.highlight.one}
                      isActive={number === confirmedNumber}
                    >
                      {number}
                    </ActiveNumberButton>
                    <NumberButton
                      key={number}
                      color={
                        activeButtonIndex === number
                          ? TEXT_COLORS.one
                          : TEXT_COLORS.four
                      }
                    >
                      {number}
                    </NumberButton>
                  </ButtonsWrapper>
                ))}
              </ButtonsRow>
              <ButtonsRow>
                {secondRowButtons.map((number) => (
                  <ButtonsWrapper key={number}>
                    <ActiveNumberButton
                      key={number === confirmedNumber ? "active" : "inactive"}
                      color={COLORS.highlight.one}
                      isActive={number === confirmedNumber}
                    >
                      {number}
                    </ActiveNumberButton>
                    <NumberButton
                      key={number}
                      color={
                        activeButtonIndex === number
                          ? TEXT_COLORS.one
                          : TEXT_COLORS.four
                      }
                    >
                      {number}
                    </NumberButton>
                  </ButtonsWrapper>
                ))}
              </ButtonsRow>
            </CalculateWrapper>
            <ButtonWrapper>
              <Button
                fullWidth
                buttonTheme={ButtonThemeEnum.PRIMARY}
                onClick={generateFarmBoost}
                disabled={loading}
              >
                {t("generate_farm_boost")}
              </Button>
            </ButtonWrapper>
          </>
        )}
      </BaseModalWrapper>
    </BaseModal>
  );
}

export default FarmBoostBonusModal;

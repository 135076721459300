import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import {
  GetOrders200Response,
  InstrumentTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { FUNDING_RATE_PRECISION } from "../../../constants/precision/form";
import { IPerpsMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { useOrder } from "../../../hooks/api/order/useOrder";
import { useGetStatistics } from "../../../hooks/api/statistics/useGetStatistics";
import usePositionsWSS from "../../../hooks/wss/usePositionsWSS";
import { AssetResponse, getAssetInfo } from "../../../utils/asset";
import { getAssetLogo } from "../../../utils/asset/assets";
import { getContractPriceStep } from "../../../utils/instruments";
import { BaseModal } from "../../BaseModal";
import { ButtonThemeEnum } from "../../Buttons/styles";
import RemoveOrderModal from "../../ConfirmationModal/RemoveOrderModal";
import FundingChart from "../../DepthFundingChart/FundingChart";
import { InfoRow, Title, Value } from "../../TradeForm/style";
import TradingViewChart from "../../TradingViewChart";
import SegmentedControl from "../../shared/SegmentedControl";
import DepthSection from "../DepthSection";
import TradeOrderbookSection from "../TradeOrderbookSection";
import {
  ChartArea,
  ChartContainer,
  ChartTab,
  ChartTabWrapper,
  ChartsContainer,
  Header,
  MarketDetailsButton,
  MarketDetailsDescription,
  MarketDetailsHeader,
  MarketDetailsInfo,
  OrderbookTradeHistoryArea,
} from "./style";

interface IPerpsOrderbookAndChartProps {
  onOrderbookRowClick: (
    price: string,
    size: string,
    side: SideResponse
  ) => void;
  perpInstrument?: IPerpsMarket;
  height?: string;
}

export enum ChartTypeEnum {
  PRICE = "price_chart",
  DEPTH = "depth_chart",
  FUNDING = "funding_chart",
}

function PerpsOrderbookAndChart({
  onOrderbookRowClick,
  height,
  perpInstrument,
}: IPerpsOrderbookAndChartProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "Perps.PerpsOrderbookAndChart",
  });
  const [currentChartType, setCurrentChartType] = useState<ChartTypeEnum>(
    ChartTypeEnum.PRICE
  );

  const prelaunch = perpInstrument?.pre_launch;

  const { positionsMap } = usePositionsWSS();
  const { data: orderData } = useOrder();
  const { data: statsData } = useGetStatistics(
    perpInstrument?.underlying_asset as AssetResponse,
    InstrumentTypeResponse.Perpetual
  );

  const [removingOrder, setRemovingOrder] = useState<GetOrders200Response>();
  const [showMarketDetails, setShowMarketDetails] = useState(false);

  const onCancelOrder = useCallback((order: GetOrders200Response) => {
    setRemovingOrder(order);
  }, []);

  const onClickMarketDetails = useCallback(() => {
    setShowMarketDetails(true);
  }, []);

  const controls = useMemo(() => {
    const HIGH_LOAD_MODE = process.env.REACT_APP_HIGH_LOAD_MODE === "true";

    let keys = Object.keys(ChartTypeEnum);
    if (HIGH_LOAD_MODE) keys = keys.filter((k) => k !== "FUNDING");

    const values = keys.map((k) => {
      const key = k as keyof typeof ChartTypeEnum;

      return {
        value: ChartTypeEnum[key],
        display: <ChartTab>{t(ChartTypeEnum[key])}</ChartTab>,
        textColor:
          currentChartType === ChartTypeEnum[key]
            ? TEXT_COLORS.one
            : TEXT_COLORS.three,
      };
    });
    if (prelaunch) {
      return values.slice(0, values.length - 1);
    }
    return values;
  }, [prelaunch, t, currentChartType]);

  return (
    <>
      <RemoveOrderModal
        order={removingOrder}
        onHide={() => setRemovingOrder(undefined)}
        show={!!removingOrder}
      />
      {perpInstrument && (
        <BaseModal
          style={{ width: 311 }}
          show={showMarketDetails}
          title={
            <MarketDetailsHeader>
              <img
                src={getAssetLogo(perpInstrument.underlying_asset)}
                alt={perpInstrument.underlying_asset}
                style={{
                  width: 32,
                  height: 32,
                }}
              />
              {perpInstrument?.instrument_name}
            </MarketDetailsHeader>
          }
          onHide={() => setShowMarketDetails(false)}
        >
          <MarketDetailsDescription>
            {getAssetInfo(perpInstrument.underlying_asset).description}
            <MarketDetailsInfo>
              <InfoRow>
                <Title>{t("min_contract_size")}</Title>
                <Value>
                  {perpInstrument?.quote_asset
                    ? `${perpInstrument.amount_step} ${perpInstrument?.underlying_asset}`
                    : "---"}
                </Value>
              </InfoRow>
              <InfoRow>
                <Title>{t("min_notional_value")}</Title>
                <Value>
                  {perpInstrument?.quote_asset
                    ? `10 ${perpInstrument?.quote_asset}`
                    : "---"}
                </Value>
              </InfoRow>
              <InfoRow>
                <Title>{t("tick_size")}</Title>
                <Value>{perpInstrument?.price_step}</Value>
              </InfoRow>
              <InfoRow>
                <Title>{t("settlement_asset")}</Title>
                <Value>{perpInstrument?.quote_asset ?? "---"}</Value>
              </InfoRow>
              <InfoRow>
                <Title>{t("avg_1h_funding")}</Title>
                {!statsData ? (
                  <Value>-</Value>
                ) : (
                  <Value
                    style={{
                      color:
                        Number(statsData.funding_daily_avg) >= 0
                          ? COLORS.positive.one
                          : COLORS.negative.one,
                    }}
                  >
                    {Number(statsData.funding_daily_avg) >= 0 ? "+" : ""}
                    {(Number(statsData.funding_daily_avg) * 100).toFixed(
                      FUNDING_RATE_PRECISION
                    )}
                    %
                  </Value>
                )}
              </InfoRow>
            </MarketDetailsInfo>
          </MarketDetailsDescription>
        </BaseModal>
      )}
      <ChartArea style={{ height }}>
        <Header>
          <ChartTabWrapper>
            <SegmentedControl
              segments={controls}
              value={currentChartType}
              onSelect={(e) => setCurrentChartType(e as ChartTypeEnum)}
              config={{
                widthType: "fullWidth",
                backgroundColor: LAYER_COLORS.two,
                activeBackgroundColor: LAYER_COLORS.three,
                borderRadius: "10px",
                button: {
                  fontSize: FONT_SIZE.one,
                },
              }}
            />
            <MarketDetailsButton
              buttonTheme={ButtonThemeEnum.NEUTRAL2}
              onClick={onClickMarketDetails}
            >
              {t("market_details")}
              <Info />
            </MarketDetailsButton>
          </ChartTabWrapper>
        </Header>
        {perpInstrument && (
          <ChartsContainer>
            <TradingViewChart
              hidden={currentChartType !== ChartTypeEnum.PRICE}
              amountDecimals={
                getContractPriceStep(perpInstrument).amount_precision
              }
              priceDecimals={
                getContractPriceStep(perpInstrument).price_precision
              }
              symbol={perpInstrument?.instrument_name}
              position={positionsMap[perpInstrument.instrument_name]}
              orders={orderData?.filter(
                (o) => o.instrument_id === perpInstrument.instrument_id
              )}
              onCancelOrder={onCancelOrder}
            />
            <ChartContainer hidden={currentChartType !== ChartTypeEnum.DEPTH}>
              <DepthSection perpInstrument={perpInstrument} />
            </ChartContainer>
            {currentChartType === ChartTypeEnum.FUNDING && (
              <ChartContainer
                hidden={currentChartType !== ChartTypeEnum.FUNDING}
              >
                <FundingChart instrument={perpInstrument.instrument_name} />
              </ChartContainer>
            )}
          </ChartsContainer>
        )}
      </ChartArea>
      <OrderbookTradeHistoryArea style={{ height }}>
        <TradeOrderbookSection
          perpInstrument={perpInstrument}
          onOrderbookRowClick={onOrderbookRowClick}
        />
      </OrderbookTradeHistoryArea>
    </>
  );
}

export default PerpsOrderbookAndChart;

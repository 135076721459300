import { ReactComponent as Back } from "../../assets/svg/arrow-down.svg";
import { Button, ButtonThemeEnum, IButtonComponentProps } from "./styles";

type IBackButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>
  & Omit<IButtonComponentProps, "buttonTheme">

function BackButton(props: IBackButtonProps) {
  return (
    <Button
      buttonTheme={ButtonThemeEnum.NEUTRAL2}
      style={{
        height: "48px",
      }}
      {...props}
    >
      <Back style={{ transform: "rotate(90deg)" }} />
    </Button>
  );
}
export default BackButton;

import { motion } from "framer-motion";
import ReactSlider from "react-slider";
import styled, { css } from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  FIELD_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import {
  FONT_CSS,
  FONT_SIZE,
  FONT_STYLE,
} from "../../../constants/design/fontSize";
import {
  BORDER_RADIUS,
  COMPONENTS,
  SPACING,
} from "../../../constants/design/spacing";
import { Button } from "../../Buttons/styles";
import { SideResponse } from "../../../codegen-api";

export const DropdownTitle = styled.div`
  font-size: ${FONT_SIZE.one};
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    margin-right: ${SPACING.one}px;
  }
`;

export const DropdownItem = styled.div<{ isSelected?: boolean }>`
  padding: ${SPACING.two}px;
  font-size: ${FONT_SIZE.one};
  color: ${({ isSelected }) =>
    isSelected ? TEXT_COLORS.one : TEXT_COLORS.three};
  background-color: ${BACKGROUND_COLORS.four};

  &:hover {
    color: ${TEXT_COLORS.one};
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: ${SPACING.three}px;
  }

  display: flex;
  align-items: center;
`;

export const X = styled.span`
  padding-left: ${SPACING.one}px;
  padding-right: ${SPACING.two}px;
  color: ${TEXT_COLORS.three};
  font-size: ${FONT_SIZE.one};
`;

export const InputButtonsWrapper = styled.div`
  display: flex;
  height: 48px;
`;

export const LeverageButton = styled(motion.button).attrs({
  type: "button",
})<{ $selected?: boolean; $hidden?: boolean }>`
  ${FONT_CSS.header.seven}
  margin-left: ${SPACING.one}px;
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${FIELD_COLORS.one};
  border-radius: 4px;
  transition: all 0.25s ease-in-out;
  overflow: hidden;

  ${({ $hidden }) =>
    $hidden &&
    css`
      width: 0;
      margin-left: 0;
      opacity: 0;
    `}

  ${({ $selected }) =>
    !$selected &&
    css`
      color: ${TEXT_COLORS.three} !important;
      outline: 1px solid transparent !important;

      &:hover {
        color: ${TEXT_COLORS.one} !important;
      }
    `}
`;

export const LeverageButtonsWrapper = styled.div<{ selectedColor?: string }>`
  position: relative;
  display: flex;
  flex-shrink: 1;

  ${LeverageButton} {
    color: ${({ selectedColor }) => selectedColor};
    outline: 1px solid ${({ selectedColor }) => selectedColor};
    &:focus:not(:focus-visible) {
      outline: 1px solid ${({ selectedColor }) => selectedColor};
    }
  }
`;

export const SliderWrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  margin-right: ${SPACING.one}px;
  height: ${COMPONENTS.slider}px;
`;

export const SliderPatternWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SliderPattern = styled.div<{
  activeColor?: string;
  active?: boolean;
}>`
  height: 8px;
  width: 1px;
  border-radius: ${BORDER_RADIUS.five}px;
  background-color: ${COLORS.white.three};

  ${({ active, activeColor }) =>
    active &&
    activeColor &&
    css`
      background-color: ${activeColor};
      height: 1px;
    `}

  &:last-child {
    margin-right: 0;
  }
`;

export const SliderBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

export const Slider = styled(ReactSlider)`
  flex: 1;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`;

export const SliderInputWrapper = styled.div<{ showSlider?: boolean }>`
  flex: 1;
  display: flex;
  overflow: hidden;
  background-color: ${FIELD_COLORS.one};
  transition: all 0.25s ease-in-out;
  border-radius: 10px;

  ${({ showSlider }) =>
    showSlider &&
    css`
      padding: ${SPACING.one}px;
    `}

  ${Slider} {
    ${({ showSlider }) =>
      !showSlider &&
      css`
        width: 0;
        opacity: 0;
      `}
  }

  ${SliderWrapper} {
    margin: 0;
  }
`;

export const StyledThumb = styled.div<{ color?: string; width: string }>`
  width: ${({ width }) => width};
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    height: 8px;
    width: 1px;
    background-color: ${({ color }) => color};
  }

  &:focus-visible {
    outline: none;
  }
`;

export const StyledTrack = styled.div<{ index: number; color?: string }>`
  top: 0;
  bottom: 0;
  border-radius: ${BORDER_RADIUS.five}px;
  background: ${({ index, color }) => {
    if (index === 0) {
      return `linear-gradient(to right, transparent -30%, ${color} 900%)`;
    }
    return "none";
  }};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExternalLinks = styled.div`
  display: flex;
  opacity: 0.64;

  > * {
    padding: ${SPACING.two}px;
    border-radius: 100px;
    margin-right: ${SPACING.two}px;
    border: 1px solid ${COLORS.white.one};
  }
`;

export const VitalsSection = styled.div`
  margin-top: ${SPACING.five}px;
`;

export const LabelFlexWrapper = styled.div`
  display: flex;
`;

export const FormDisabledExplanationContainer = styled.div`
  ${FONT_CSS.label.three}
  color: ${TEXT_COLORS.two};
  padding: ${SPACING.two}px;
  margin-bottom: ${SPACING.three}px;
  background-color: ${LAYER_COLORS.two};
  border-radius: 4px;

  > div {
    ${FONT_CSS.label.two}
    color: ${COLORS.highlight.one};
    margin-bottom: ${SPACING.two}px;
  }

  strong {
    color: ${TEXT_COLORS.one};
  }
`;

export const MarginSelectorWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 100px 100px 56px;
  grid-gap: ${SPACING.two}px;
`;

export const LearnMoreText = styled.div.attrs(() => ({ role: "button" }))`
  transition: 0.2s all ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${TEXT_COLORS.one};
  }

  height: 0px;
  opacity: 0;
  ${FONT_CSS.body.six}
  color: ${TEXT_COLORS.three};
`;

export const MarginButton = styled(Button)<{
  isSelected?: boolean;
  side: SideResponse;
}>`
  justify-content: flex-start;
  height: ${COMPONENTS.slider}px;
  padding: ${SPACING.two}px;
  text-align: left;

  &:disabled {
    opacity: 0.4;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  &:hover {
    ${LearnMoreText} {
      transition: 0.2s all ease-in-out;
      height: 12px;
      opacity: 1;
    }
  }

  ${({ isSelected, side }) =>
    isSelected
      ? css`
          color: ${TEXT_COLORS.one};
          box-shadow: 0px 0px 0px 1px
            ${side === SideResponse.Buy
              ? COLORS.positive.one
              : COLORS.negative.one}
            inset;

          &:hover {
            box-shadow: 0px 0px 0px 1px
              ${side === SideResponse.Buy
                ? COLORS.positive.one
                : COLORS.negative.one}
              inset;
          }
        `
      : css`
          color: ${TEXT_COLORS.three};
          box-shadow: 0px 0px 0px 1px ${BORDER_COLORS.one} inset;

          &:hover {
            box-shadow: 0px 0px 0px 1px ${BORDER_COLORS.one} inset;
          }
        `}
`;

export const MarginLabel = styled.div`
  ${FONT_STYLE.label.three};
  color: ${TEXT_COLORS.three};
`;

export const CoreRing = styled.div<{ side: SideResponse }>`
  border-radius: 50%;
  display: grid;
  align-self: center;
  margin: auto;
  width: 8px;
  height: 8px;
  background: ${COLORS.neutral.three}
  transition: 0.2s ease-in-out;
`;

export const InnerRing = styled.div<{ isIsolated?: boolean }>`
  border-radius: 50%;
  display: grid;
  align-self: center;
  margin: auto;
  width: 16px;
  height: 16px;
  transition: 0.2s ease-in-out;
  ${({ isIsolated }) =>
    isIsolated
      ? css`
          background: transparent !important;
        `
      : undefined};
`;

export const OuterRing = styled.div<{
  isSelected?: boolean;
  side: SideResponse;
}>`
  border-radius: 50%;
  display: grid;
  align-self: center;
  width: 24px;
  height: 24px;
  background: ${COLORS.neutral.six};
  transition: 0.2s ease-in-out;

  ${({ isSelected, side }) =>
    isSelected
      ? css`
          background: ${side === SideResponse.Buy
            ? COLORS.positive.four
            : COLORS.negative.four};

          ${InnerRing} {
            background: ${side === SideResponse.Buy
              ? COLORS.positive.three
              : COLORS.negative.three};
          }

          ${CoreRing} {
            background: ${side === SideResponse.Buy
              ? COLORS.positive.one
              : COLORS.negative.one};
          }
        `
      : css`
          background: ${COLORS.neutral.four};

          ${InnerRing} {
            background: ${COLORS.neutral.three};
          }

          ${CoreRing} {
            background: ${COLORS.neutral.one};
          }
        `};
`;

export const LeverageInputWrapper = styled.div`
  display: flex;
`;

export const LeverageDescription = styled.div`
  color: ${TEXT_COLORS.two};
  margin-bottom: ${SPACING.three}px;
  ${FONT_STYLE.label.one}
`;

export const LeverageLabel = styled.div`
  color: ${TEXT_COLORS.three};
  ${FONT_STYLE.label.three}
  margin-bottom: ${SPACING.one}px;
`;

export const PerformanceDetailsContainer = styled.div`
  ${FONT_CSS.header.seven}
  padding: ${SPACING.three}px;
`;

export const PerformanceDetailCard = styled.div`
  margin-top: ${SPACING.three}px;
  padding: ${SPACING.three}px;
  background-color: ${LAYER_COLORS.two};
  color: ${TEXT_COLORS.two};
  border-radius: ${BORDER_RADIUS.five}px;

  > div:first-child {
    span:first-child {
      ${FONT_CSS.body.five}
      color: ${TEXT_COLORS.one};
    }
  }

  > div {
    ${FONT_CSS.data.four}
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PerformanceBarContainer = styled.div`
  position: relative;
`;

export const BarTrack = styled.div`
  background-color: ${LAYER_COLORS.three};
  height: 2px;
  border-radius: ${BORDER_RADIUS.five}px;
  width: 100%;
  margin: ${SPACING.three}px 0;
`;
export const OpenCloseTrack = styled.div<{ isNegative?: boolean }>`
  background-color: ${({ isNegative }) =>
    isNegative ? COLORS.negative.three : COLORS.positive.three};
  position: absolute;
  height: 2px;
  display: flex;
  align-items: center;
  justify-content: ${({ isNegative }) =>
    isNegative ? "flex-start" : "flex-end"};

  > div {
    height: 8px;
    width: 1px;
    background-color: ${({ isNegative }) =>
      isNegative ? COLORS.negative.one : COLORS.positive.one};
  }
`;

export const MarginTitle = styled.div`
  display: flex;

  > * {
    margin: auto 0;

    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const MarginDesc = styled.div`
  ${FONT_STYLE.body.three};
  color: ${TEXT_COLORS.two};
`;

export const MarginTypeText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: ${SPACING.two}px;
`;

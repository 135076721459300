import { useContext, useMemo } from "react";
import currency from "currency.js";
import { useGetPriceChart } from "../../hooks/api/priceChart/useGetPriceChart";
import useIndexWSS from "../../hooks/wss/useIndexWSS";
import { MarketContext } from "../../contexts/MarketContext";
import { calculateChange } from "../../utils/math";
import { ChangePercent, CurrentPrice, CurrentSpotRow, FilterRow } from "./style";

interface IStrikeRowProps {
  strikes: string[];
  selectedStrike?: string;
  onRowClick?: (strike: string) => void;
}

function StrikeRow({ strikes, selectedStrike, onRowClick }: IStrikeRowProps) {
  const { market } = useContext(MarketContext);
  const { index } = useIndexWSS(market.asset);
  const { data: indexData } = useGetPriceChart(market.asset);

  const changePercent = useMemo(() => {
    if (index?.price && indexData?.history) {
      const oldestPrice = indexData.history[indexData.history.length - 1];
      if (oldestPrice?.[1]) {
        const { percentageChange } = calculateChange(Number(oldestPrice[1]), Number(index.price));
        return percentageChange;
      }
    }
    return 0;
  }, [index?.price, indexData?.history]);

  const strikeWithCurrent: {
    strike: string;
    isCurrent?: boolean;
  }[] = index?.price
    ? [
      ...strikes
        .map((s) => ({
          strike: s,
          isCurrent: false,
        })),
      {
        strike: String(index.price),
        isCurrent: true,
      },
    ].sort((a, b) => Number(a.strike) - Number(b.strike))
    : strikes.map((s) => ({
      strike: s,
      isCurrent: false,
    }));

  return (
    <>
      {
        strikeWithCurrent.map(({ strike, isCurrent }) => (isCurrent
          ? (
            <CurrentSpotRow key={strike}>
              <span>Current {market.asset} Spot:</span>
              <CurrentPrice>{currency(strike).format()}</CurrentPrice>
              {
                changePercent && (
                  <ChangePercent>
                    {changePercent > 0 ? "+" : ""}{(changePercent * 100).toFixed(2)}%
                  </ChangePercent>
                )
              }
            </CurrentSpotRow>
          )
          : (
            <FilterRow
              key={strike}
              selected={selectedStrike === String(strike)}
              onClick={() => {
                onRowClick?.(strike);
              }}
            >
              <span>
                {currency(strike, { precision: 0 }).format()}
              </span>
            </FilterRow>
          )))
      }
    </>
  );
}

export default StrikeRow;

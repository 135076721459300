import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { ReactComponent as Close } from "../../../../assets/svg/close.svg";
import { LINKS } from "../../../../constants/links";
import { pwaURLPrefix } from "../../../../hooks/usePWAFlow";
import { BaseModal } from "../../../BaseModal";
import { Button, ButtonThemeEnum } from "../../../Buttons/styles";
import {
  CenteredContainer,
  QRCodeScannerContainer,
  ScannerFrame,
} from "./style";

interface IQRCodeScannerProps {
  onHide?: () => void;
  show?: boolean;
  onReceiveURL: (url: string) => void;
}

function QRCodeScannerModal({
  onHide,
  show,
  onReceiveURL,
}: IQRCodeScannerProps) {
  const qrRef = useRef<any>(null);
  const { t } = useTranslation("app", {
    keyPrefix: "Header.PWAFlowModal.MobilePWAModal.QRCodeScannerModal",
  });
  const [facingMode, setFacingMode] = useState<"environment" | "user">(
    "environment"
  );
  const [invalidQR, setInvalidQR] = useState(false);

  // Auto stop camera
  if (qrRef.current && !show) {
    try {
      qrRef.current.stopCamera();
    } catch (error) {
      // Nothing
    }
  }

  const handleScan = useCallback(
    async (scanData: string | null) => {
      if (scanData) {
        // ONLY ALLOW if the URL matches the PWA URL
        const mustMatchURL = `${LINKS.app}${pwaURLPrefix}`;
        if (scanData.startsWith(mustMatchURL)) {
          // Do something
          setInvalidQR(false);
          onReceiveURL(scanData);
        } else {
          setInvalidQR(true);
        }
      }
    },
    [onReceiveURL]
  );

  const handleError = useCallback((err: any) => {
    if (String(err).includes("Requested device not found")) {
      // Only toggle from environment -> user if not available. Prevents repeated rerendering
      setFacingMode((prev) => (prev === "environment" ? "user" : prev));
    }
  }, []);

  return (
    <BaseModal
      isFullscreen
      hideCloseButton
      noContentPadding
      onHide={onHide}
      show={show}
    >
      <QRCodeScannerContainer>
        <div>
          <span>{t("scan_qr_code")}</span>
          <span>{t("scan_qr_code_desc", { url: LINKS.app })}</span>
        </div>
        <CenteredContainer>
          <ScannerFrame>
            {show && (
              <QrReader
                ref={qrRef}
                facingMode={facingMode}
                delay={0}
                onError={handleError}
                onScan={handleScan}
              />
            )}
          </ScannerFrame>
          <p>{invalidQR ? t("invalid_qr") : ""}</p>
        </CenteredContainer>
        <Button buttonTheme={ButtonThemeEnum.NEUTRAL1} onClick={onHide}>
          <Close />
        </Button>
      </QRCodeScannerContainer>
    </BaseModal>
  );
}

export default QRCodeScannerModal;

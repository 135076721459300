import styled, { css } from "styled-components";
import {
  BACKGROUND_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { Row } from "../shared/Table/style";

export const OptionsChainContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Padding = styled.div`
  padding: ${SPACING.two}px;
`;

export const SideTableWrapper = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  display: inline-flex;
  justify-content: space-between;
  background-color: ${LAYER_COLORS.two};

  > span {
    width: 100%;
    text-align: center;
  }
`;

export const OptionsRowWrapper = styled.div`
  display: flex;
`;

export const OptionRow = styled(Row)<{
  width: string;
  disabled?: boolean;
  isActive: boolean;
  side: "left" | "right";
}>`
  border: 1px solid
    ${({ isActive }) => (isActive ? COLORS.blue.one : LAYER_COLORS.one)};
  border-radius: ${({ isActive }) => (isActive ? "10px" : "0")};

  ${({ side }) => {
    if (side === "left") {
      return `
        border-right: none !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      `;
    }
    return `
      border-left: none !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    `;
  }};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-bottom: 1px solid ${LAYER_COLORS.one};
  height: ${COMPONENTS.tableRow}px;
  transition: 0.2s;

  > * {
    padding: 0 ${SPACING.two}px;
  }

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? undefined : BACKGROUND_COLORS.four};
  }
`;

export const Cell = styled.span<{
  compact?: boolean;
  fontSize?: "default" | "small";
  align?: "left" | "center" | "right";
  dim?: boolean;
}>`
  ${({ fontSize }) => {
    switch (fontSize) {
      case "small":
        return FONT_CSS.data.four;
      default:
        return FONT_CSS.data.three;
    }
  }}

  flex-direction: column;
  align-items: ${({ align }) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  }} !important;

  // Shows "-" if no content
  &:empty {
    &:before {
      content: "-";
      color: ${TEXT_COLORS.three};
    }
  }

  ${({ compact }) => {
    if (compact) {
      return css`
        padding: 0px;
      `;
    }
    return "";
  }}

  color: ${({ dim }) => (dim ? TEXT_COLORS.two : TEXT_COLORS.one)}
`;

export const OptionsTypeDateSelectionWrapper = styled.div`
  display: flex;
  margin: ${SPACING.two}px;
  margin-top: 0;
`;

export const OptionsTypeSelectWrapper = styled.div`
  display: flex;
  height: 32px;
  width: 128px;

  > div:first-child {
    height: 100%;
    margin-right: ${SPACING.three}px;
  }
  margin-right: ${SPACING.three}px;
`;

export const TableContainer = styled.div`
  position: relative;
  flex: 1;
`;

export const ScrollableContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

export const ScrollableSection = styled.div`
  overflow: scroll;
  height: 100%;
`;

import { AxiosError } from "axios";
import { useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import {
  GetMarkets200Response,
  InstrumentTypeResponse,
} from "../../codegen-api";
import { APIEndpointEnum } from "../../enums/endpoint";
import { useLoggerMiddleware } from "../../hooks/api/middleware/useLoggerMiddleware";
import { publicApi } from "../../services/api/apiFetcher";
import { AssetResponse } from "../../utils/asset";

export interface IPerpsMarket
  extends Omit<GetMarkets200Response, "expiry" | "strike"> {}

// In option market, strike and expiry is required
export interface IOptionMarket extends GetMarkets200Response {
  strike: string;
  /**
   * Only for option instruments. Expiry of the option contract in seconds. Eg. \"1680249600\"
   * @type {string}
   * @memberof IOptionMarket
   */
  expiry: string;
}

export const useGetMarkets = (
  asset?: AssetResponse,
  instrumentType?: InstrumentTypeResponse,
  disabled?: boolean
) => {
  const fetcher = publicApi();
  const swr = useSWRImmutable<GetMarkets200Response[], AxiosError>(
    !disabled ? [APIEndpointEnum.MARKETS, asset, instrumentType] : undefined,
    async () =>
      (await (await fetcher.getMarkets(asset, instrumentType))()).data.map(
        (d) => ({
          ...d,
          expiry: d.expiry,
        })
      ),
    {
      use: [useLoggerMiddleware],
    }
  );

  const activeMarkets: GetMarkets200Response[] | undefined = useMemo(
    () => swr.data?.filter((m) => m.is_active),
    [swr.data]
  );

  return {
    ...swr,
    activeMarkets,
  };
};

import { UseFormRegisterReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  CheckboxContainer,
  TIFContainer,
  DropdownItem,
  DropdownTitle,
  StyledInfo,
} from "./style";
import Checkbox from "../shared/Checkbox";
import Dropdown from "../shared/Dropdown";
import { LAYER_COLORS } from "../../constants/design/colors";
import { TimeInForce } from "../../codegen-api";
import TooltipExplanation from "../shared/Tooltip";
import { TooltipWrapper } from "../shared/Header/AccountModal/style";
import { Chevron } from "../shared/Chevron/style";

export interface IInputProps {
  register: UseFormRegisterReturn<string>;
  errors: any;
  warning?: string;
  customError?: string;
}

export interface ITIFSelectorProps {
  reduceOnly: boolean;
  tifDropdown: boolean;
  setTifDropdown: (value: boolean) => void;
  tifType: TimeInForce;
  setTIFType: (value: TimeInForce) => void;
}

export type TIFType = (typeof TimeInForce)[keyof typeof TimeInForce];

interface IReduceOnlyInputProps {
  reduceOnly: boolean;
  onToggle: () => void;
}
export function ReduceOnlyInput({
  reduceOnly,
  onToggle,
}: IReduceOnlyInputProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });

  return (
    <CheckboxContainer>
      <Checkbox
        checked={reduceOnly}
        onToggle={onToggle}
        title={t("reduce_only")}
      />
      <TooltipExplanation
        title={t("reduce_only")}
        explanation={
          <>
            {t("reduce_only_text_line1")}
            <br />
            <br />
            {t("reduce_only_text_line2")}
          </>
        }
        renderContent={({ ref, ...triggerHandler }) => (
          <TooltipWrapper ref={ref} {...triggerHandler}>
            <StyledInfo reduceOnly={reduceOnly} />
          </TooltipWrapper>
        )}
      />
    </CheckboxContainer>
  );
}

export function TIFSelector({
  reduceOnly,
  tifDropdown,
  setTifDropdown,
  tifType,
  setTIFType,
}: ITIFSelectorProps) {
  const itemsSelector = reduceOnly
    ? Object.values(TimeInForce).filter((x) => x === TimeInForce.Ioc)
    : (Object.values(TimeInForce) as TIFType[]);

  const { t } = useTranslation("app", {
    keyPrefix: "TradeForm.PerpsTradeForm.Form",
  });

  const tifTitle = "TIF";

  return (
    <TIFContainer>
      <TooltipExplanation
        title={t("tif_title")}
        explanation={
          <>
            {t("tif_explanation_line1")}
            <br />
            <br />
            {t("tif_explanation_line2")}
          </>
        }
        renderContent={({ ref, ...triggerHandler }) => (
          <TooltipWrapper ref={ref} {...triggerHandler}>
            <p>{tifTitle}</p>
          </TooltipWrapper>
        )}
      />
      <Dropdown
        onToggle={setTifDropdown}
        width={"70px"}
        dropdownMenuContainerStyles={{
          overflow: "hidden",
          backgroundColor: LAYER_COLORS.three,
          textAlign: "center",
        }}
        title={
          <DropdownTitle>
            <span>{tifType}</span>
            <Chevron size="small" direction={tifDropdown ? "up" : "down"} />
          </DropdownTitle>
        }
        items={itemsSelector.map((v) => ({
          label: (
            <DropdownItem selected={v === tifType}>
              <span>{v}</span>
            </DropdownItem>
          ),
          onSelect: () => setTIFType(v as TimeInForce),
        }))}
      />
    </TIFContainer>
  );
}

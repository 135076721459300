import { motion } from "framer-motion";
import styled from "styled-components";
import { TEXT_COLORS } from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";
import { Button } from "../Buttons/styles";

export const SettingsTitle = styled(motion.div)<{ $isOn?: boolean }>`
  display: flex;

  > * {
    transition: 0.2s ease-in-out;
    margin: auto 0;
  }

  p {
    color: ${({ $isOn }) => ($isOn ? TEXT_COLORS.one : TEXT_COLORS.three)};
  }
`;

export const SFXTitle = styled(SettingsTitle)`
  > button {
    cursor: pointer;
    margin-right: ${SPACING.two}px;
    opacity: ${({ $isOn }) => ($isOn ? 1 : 0.4)};
  }
`;

export const SettingRow = styled(motion.div)`
  display: flex;
  justify-content: space-between;

  > * {
    font-size: ${FONT_SIZE.two};
  }

  // Toggler
  > div:not(${SettingsTitle}) {
    height: 32px;
    width: 64px;
    cursor: pointer;
  }

  &:not(:last-of-type) {
    margin-bottom: ${SPACING.two}px;
  }
`;

export const SettingHeader = styled.p`
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.two};
`;

export const LanguageButton = styled(Button)<{ isActive?: boolean }>``;

export const LanguageButtonWrapper = styled.div`
  > ${LanguageButton} {
    &:not(:last-of-type) {
      margin-bottom: ${SPACING.two}px;
    }
  }
`;

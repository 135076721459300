/* eslint-disable no-console */
import { Web3Provider } from "@ethersproject/providers";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { useWeb3React as useEVMWallet } from "@web3-react/core";
import { Network } from "@web3-react/network";
import { Connector } from "@web3-react/types";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import { useCallback, useMemo } from "react";
import { ChainEnum, ChainIdEnum } from "../../enums/chain";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { CHAIN_EXPLORER_URLS, L2_CHAIN_EXPLORER_URLS } from "../../utils/chain";
import { isDevelopment } from "../../utils/env";
import { getAddChainParameters } from "../../utils/wallet/chains";
import {
  allConnectors,
  supportedChainId,
  supportedSigningChainIds,
  walletToConnector,
} from "../../utils/wallet/connectors";
import { WalletEnum } from "../../utils/wallet/types";

export const allBrowserWallets = [
  WalletEnum.METAMASK,
  WalletEnum.BRAVE,
] as const;
export type IBrowserWalletType = typeof allBrowserWallets[number];

export interface IWallet {
  chainId: number | undefined;
  active: boolean;
  activate: (wallet: WalletEnum) => Promise<void>;
  deactivate: () => Promise<void>;
  account: string | null | undefined;
  connectedWallet: WalletEnum | undefined;
  provider: Web3Provider | undefined;
  hasBrowserWallet: boolean;
  connector?: Connector;
  isWrongNetwork: boolean;
  supportedChainId: ChainIdEnum;
  setChainToSwitch: (id: ChainIdEnum) => Promise<void>; // Dispatch<SetStateAction<ChainIdEnum | null>>;
  explorerURL: string;
  l2ExplorerURL: string;
}

type ChainToId = {
  [key in ChainEnum]: ChainIdEnum;
};
export const CHAINS_TO_ID: ChainToId = {
  [ChainEnum.NotSelected]: ChainIdEnum.NONE,
  [ChainEnum.Ethereum]: ChainIdEnum.ETH_MAINNET,
  [ChainEnum.SepoliaTestnet]: ChainIdEnum.SEPOLIA_TESTNET,
  [ChainEnum.AevoLocalnet]: ChainIdEnum.LOCAL_TESTNET,
  [ChainEnum.Optimism]: ChainIdEnum.OPTIMISM,
  [ChainEnum.OptimismTestnet]: ChainIdEnum.OPTIMISM_TESTNET,
  [ChainEnum.Arbitrum]: ChainIdEnum.ARBITRUM,
  [ChainEnum.ArbitrumTestnet]: ChainIdEnum.ARBITRUM_TESTNET,
};

type IdToChain = {
  [key in ChainIdEnum]: ChainEnum;
};
export const ID_TO_CHAINS: IdToChain = {
  [ChainIdEnum.NONE]: ChainEnum.NotSelected,
  [ChainIdEnum.ETH_MAINNET]: ChainEnum.Ethereum,
  [ChainIdEnum.SEPOLIA_TESTNET]: ChainEnum.SepoliaTestnet,
  [ChainIdEnum.LOCAL_TESTNET]: ChainEnum.AevoLocalnet,
  [ChainIdEnum.OPTIMISM]: ChainEnum.Optimism,
  [ChainIdEnum.OPTIMISM_TESTNET]: ChainEnum.OptimismTestnet,
  [ChainIdEnum.ARBITRUM]: ChainEnum.Arbitrum,
  [ChainIdEnum.ARBITRUM_TESTNET]: ChainEnum.ArbitrumTestnet,
};

const impersonateAddress = "";

export const useWallet = (): IWallet => {
  const {
    chainId: chainIdEth,
    account: accountEth,
    connector: connectorEth,
    isActive: isActiveEth,
    chainId: activeChainId,
    provider,
  } = useEVMWallet();

  // Returns the window's connector
  const windowEthereumObj = useMemo(() => {
    const windowObj: any | undefined = window as any;
    return windowObj?.ethereum;
  }, []);

  const connectedWallet = useMemo(() => {
    if (!connectorEth || !isActiveEth) {
      return undefined;
    }

    if (connectorEth instanceof WalletConnectV2) {
      return WalletEnum.WALLETCONNECT;
    }
    if (connectorEth instanceof CoinbaseWallet) {
      return WalletEnum.WALLETLINK;
    }

    const isBraveWallet = !!windowEthereumObj?.isBraveWallet;
    if (isBraveWallet) {
      return WalletEnum.BRAVE;
    }
    return WalletEnum.METAMASK;
  }, [connectorEth, isActiveEth, windowEthereumObj?.isBraveWallet]);

  const setChainToSwitch = useCallback(
    async (id?: ChainIdEnum) => {
      if (!id || id === activeChainId || !connectorEth) {
        return;
      }

      try {
        if (id === -1) {
          await connectorEth.activate();
        } else if (
          connectorEth instanceof WalletConnectV2 ||
          connectorEth instanceof Network
        ) {
          await connectorEth.activate(id);
        } else {
          await connectorEth.activate(getAddChainParameters(id));
        }
      } catch (switchError: any) {
        // Do nothing
      }
    },
    [activeChainId, connectorEth]
  );

  const deactivateEth = useCallback(async () => {
    localStorage.removeItem(LocalStorageKeyEnum.LAST_CONNECTED_WALLET);
    try {
      // Deactivate all connectors
      const promises = allConnectors.map(async (c) => {
        const connector = c[0];
        await connector.deactivate?.();
        await connector.resetState();
      });
      await Promise.all(promises);
    } catch (error) {
      console.log("Error deactivating", error);
    }
  }, []);

  const activate = useCallback(
    async (wallet: WalletEnum) => {
      try {
        if (!isActiveEth) {
          // Connect to the selected connector
          const connector = walletToConnector[wallet as WalletEnum]();

          // If is wallet connect, we try to connect eagerly first
          // This is because activate() will do nothing if it
          // already has an active session
          if (connector instanceof WalletConnectV2) {
            // If theres an error here, we ignore and try to activate()
            await connector?.connectEagerly().catch(() => {});
          }
          await connector?.activate(chainIdEth);
          localStorage.setItem(
            LocalStorageKeyEnum.LAST_CONNECTED_WALLET,
            String(wallet)
          );
        }
      } catch (error) {
        console.log("ERROR ACTIVATING", error);
      }
    },
    [chainIdEth, isActiveEth]
  );

  return {
    chainId: chainIdEth,
    active: isActiveEth,
    activate,
    deactivate: deactivateEth,
    account:
      isDevelopment() && impersonateAddress ? impersonateAddress : accountEth,
    connectedWallet,
    hasBrowserWallet: !!windowEthereumObj,
    provider: isActiveEth ? provider : undefined,
    connector: connectorEth,
    // If true, user is connected to an unsupported chain
    // Only enforces wrong network if not in development
    isWrongNetwork:
      Boolean(chainIdEth) &&
      !supportedSigningChainIds.includes(chainIdEth as ChainIdEnum),
    supportedChainId,
    setChainToSwitch,
    explorerURL: CHAIN_EXPLORER_URLS[supportedChainId],
    l2ExplorerURL: L2_CHAIN_EXPLORER_URLS[supportedChainId],
  };
};

export default useWallet;

import { useEffect, useState } from "react";
import { LocalStorageKeyEnum } from "../../enums/localStorage";

// For any state that needs to be persisted between refreshes

export enum StateKeyEnum {
  SELECTED_MARKET_ONLY = "showSelectedMarketOnly",
  ORDERBOOK_VIEW_IN_USD = "orderbookViewInUSD",
  PREFERRED_MARKET_FILTER = "preferredMarketFilter",
  SHOW_BOTTOM_TRAY = "showBottomTray",

  // Trade form uses underlying asset as the order size.
  // if unset, default is "USDC"
  TRADE_USE_USDC_TERMS = "tradeUseUSDCTerms",
}

type StorageType = {
  [key in StateKeyEnum]: string | number;
};

export const setPersistentState = (
  key: StateKeyEnum,
  value?: string | number
) => {
  const storage = localStorage.getItem(LocalStorageKeyEnum.PERSISTENT_STATE);

  if (storage) {
    const obj = JSON.parse(storage) as StorageType;
    if (value) {
      obj[key] = value;
    } else {
      delete obj[key];
    }
    localStorage.setItem(
      LocalStorageKeyEnum.PERSISTENT_STATE,
      JSON.stringify(obj)
    );
  } else if (value) {
    localStorage.setItem(
      LocalStorageKeyEnum.PERSISTENT_STATE,
      JSON.stringify({
        [key]: value,
      })
    );
  }
};

export const getPersistentState = (key: StateKeyEnum) => {
  const storage = localStorage.getItem(LocalStorageKeyEnum.PERSISTENT_STATE);
  if (storage) {
    const obj = JSON.parse(storage) as StorageType;
    return obj[key];
  }
  return undefined;
};

const usePersistentState = () => {
  const [showOnlyCurrentMarket, setShowOnlyCurrentMarket] = useState(
    Boolean(getPersistentState(StateKeyEnum.SELECTED_MARKET_ONLY))
  );
  const [orderbookViewInUSD, setOrderbookViewInUSD] = useState(
    Boolean(getPersistentState(StateKeyEnum.ORDERBOOK_VIEW_IN_USD))
  );
  const [preferredMarketFilter, setPreferredMarketFilter] = useState(
    getPersistentState(StateKeyEnum.PREFERRED_MARKET_FILTER)?.toString()
  );
  const [showBottomTray, setShowBottomTray] = useState(
    Boolean(getPersistentState(StateKeyEnum.SHOW_BOTTOM_TRAY))
  );
  const [tradeUseUSDCTerms, setTradeUseUSDCTerms] = useState(
    Boolean(getPersistentState(StateKeyEnum.TRADE_USE_USDC_TERMS))
  );

  // Store to local storage whenever its updated
  useEffect(() => {
    setPersistentState(
      StateKeyEnum.SELECTED_MARKET_ONLY,
      showOnlyCurrentMarket ? 1 : 0
    );
  }, [showOnlyCurrentMarket]);

  useEffect(() => {
    setPersistentState(
      StateKeyEnum.ORDERBOOK_VIEW_IN_USD,
      orderbookViewInUSD ? 1 : 0
    );
  }, [orderbookViewInUSD]);

  useEffect(() => {
    setPersistentState(
      StateKeyEnum.PREFERRED_MARKET_FILTER,
      preferredMarketFilter
    );
  }, [preferredMarketFilter]);

  useEffect(() => {
    setPersistentState(StateKeyEnum.SHOW_BOTTOM_TRAY, showBottomTray ? 1 : 0);
  }, [showBottomTray]);

  useEffect(() => {
    setPersistentState(
      StateKeyEnum.TRADE_USE_USDC_TERMS,
      tradeUseUSDCTerms ? 1 : 0
    );
  }, [tradeUseUSDCTerms]);

  return {
    showOnlyCurrentMarket,
    setShowOnlyCurrentMarket,
    orderbookViewInUSD,
    setOrderbookViewInUSD,
    preferredMarketFilter,
    setPreferredMarketFilter,
    showBottomTray,
    setShowBottomTray,
    tradeUseUSDCTerms,
    setTradeUseUSDCTerms,
  };
};

export default usePersistentState;

import currency from "currency.js";
import { useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "../../../assets/svg/arrow-up-right.svg";
import { OptionTypeResponse, SideResponse } from "../../../codegen-api";
import { COLORS } from "../../../constants/design/colors";
import { startCase } from "../../../utils/strings";
import { ITableContentRow } from "../OptionsTable";
import { OptionRowContainer, OptionRowHeader, OptionRowValue, OptionRowValues, OptionTypeWrapper } from "./style";

interface IOptionRowProps {
  type: OptionTypeResponse;
  side: SideResponse;
  data: ITableContentRow;
  onClick: () => void;
  highlighted?: boolean;
}

function OptionRow({
  type,
  side,
  data,
  onClick,
  highlighted,
}: IOptionRowProps) {
  const { t } = useTranslation("app", { keyPrefix: "OptionsChain.MobileOptionsChain.OptionRow" });

  // If buying, show asks. If selling, show bids
  const tickerData = side === SideResponse.Buy
    ? data.ticker.ask
    : data.ticker.bid;

  return (
    <OptionRowContainer onClick={onClick}>
      <OptionRowHeader>
        <OptionTypeWrapper highlighted={highlighted} type={type}>
          {startCase(type)}
          <Arrow />
          <span>{currency(data.strike).format()}</span>
        </OptionTypeWrapper>
      </OptionRowHeader>
      <OptionRowValues>
        <OptionRowValue>
          <span>{t("iv")}</span>
          <span>{Number(tickerData.iv) > 0 ? `${(Number(tickerData.iv) * 100).toFixed(2)}%` : "-"}</span>
        </OptionRowValue>
        <OptionRowValue>
          <span>{t("size")}</span>
          <span>{Number(tickerData.volume) > 0 ? Number(tickerData.volume).toFixed(2) : "-"}</span>
        </OptionRowValue>
        <OptionRowValue>
          <span>{t("delta")}</span>
          <span>{Number(data.ticker.delta).toFixed(2)}</span>
        </OptionRowValue>
        <OptionRowValue>
          <span>{t("price")}</span>
          <span style={{
            color: side === SideResponse.Buy
              ? COLORS.positive.one
              : COLORS.negative.one,
          }}
          >
            {
              (tickerData.price && Number(tickerData.price) > 0)
                ? currency(tickerData.price).format()
                : "-"
            }
          </span>
        </OptionRowValue>
      </OptionRowValues>
    </OptionRowContainer>
  );
}

export default OptionRow;

import styled, { css, keyframes } from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { BORDER_RADIUS, SPACING } from "../../constants/design/spacing";

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const NumberWrapper = styled.div<{ completeAnimation: boolean }>`
  padding: ${SPACING.five}px ${SPACING.three}px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: ${SPACING.three}px;
  transition: transform 0.5s ease-out 0.5s; // Smooth transition for the transform
  transform: ${({ completeAnimation }) =>
    completeAnimation ? "translateY(88px)" : "translateY(0)"};
`;

export const NumberText = styled.div`
  display: flex;
  align-items: center;
  height: 120px;
  font-size: 64px;
  line-height: 64px;
  justify-content: center;
  width: 33.33%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 80px;
  width: 20%;
  text-align: center;
  ${FONT_CSS.data.two}
`;

export const NumberButton = styled.div<{ color: string }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  ${({ color }) => `color: ${color};`}
`;

export const ActiveNumberButton = styled(NumberButton)<{ isActive: boolean }>`
  background: ${COLORS.highlight.four};
  border-radius: 12px;
  border: 1px solid ${COLORS.highlight.one};
  color: ${COLORS.highlight.one};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  ${({ isActive }) =>
    isActive &&
    css`
      animation: ${fadeOutAnimation} 0.5s ease-out 0.5s forwards;
    `}
  z-index: 1;
`;

export const ButtonsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const CalculateWrapper = styled.div<{ completeAnimation: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 64px;
  line-height: 64px;
  margin-bottom: ${SPACING.three}px;

  ${({ completeAnimation }) =>
    completeAnimation &&
    css`
      animation: ${fadeOutAnimation} 0.5s ease-out 0.5s forwards;
    `}
`;

export const ButtonWrapper = styled.div`
  padding: ${SPACING.three}px;
`;

export const AnimationWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  border-radius: 0 0 ${BORDER_RADIUS.five}px ${BORDER_RADIUS.five}px;
  z-index: 2;
`;

export const ModalWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: ${SPACING.three}px;
  padding: ${SPACING.three}px;
  z-index: 1;
`;

export const BaseModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 472px;
`;

export const Text = styled.div`
  color: ${TEXT_COLORS.two};
  ${FONT_CSS.body.three}
`;

export const LogoWrapper = styled.div`
  display: flex;
  padding: 32px 0;
  align-items: center;
  justify-content: center;
`;

export const VideoContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${SPACING.three}px;
  padding: ${SPACING.three}px;
  border-radius: 12px;
  overflow: hidden;
  background: ${LAYER_COLORS.two};
  width: 100%;
  height: 200px;

  video {
    position: absolute;
    opacity: 0.12;
    width: 550px;
    height: 255px;
    object-fit: cover;
    overflow: hidden;
  }
`;

export const Padding = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.three}px;
  width: 100%;
  padding: ${SPACING.three}px;
`;

export const SubText = styled.div`
  font-size: 64px;
  line-height: 72px;
`;

export const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  font-size: 11px;
`;

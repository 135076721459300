import styled from "styled-components";
import { TradeTypeResponse } from "../../codegen-api";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { Direction } from "../shared/Table/style";

export const PnL = styled.span<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`}
`;

export const TradeHistoryRow = styled.tr<{ tradeType?: TradeTypeResponse }>`
  ${({ tradeType }) => tradeType === TradeTypeResponse.Liquidation
    && `
        > td {
            background-color: ${COLORS.negative.five} !important;

            > ${Direction}, ${PnL} {
                color: ${TEXT_COLORS.two};
            }
        }
    `}
`;

import moment from "moment";
import { BigNumber, ethers } from "ethers";
import {
  GetEmissions200Response,
  GetFarmBoost200Response,
  SideResponse,
} from "../codegen-api";
import { IEpochStakeData } from "../hooks/api/subgraph/useSubgraphData";

export const getRange = (start: number, stop: number, step: number = 1) => {
  const a = [start];
  let b = start;
  while (b < stop) {
    a.push((b += step));
  }
  return a;
};

// initialMargin in percentage. 100 = 100%, 1 = 1%
export const initialMarginUtilization = (
  equity: number,
  initialMargin: number,
  maintenanceMargin: number
) => {
  if (!equity) {
    return 0;
  }
  const utilization = ((initialMargin + maintenanceMargin) / equity) * 100;
  return utilization;
};

// maintenanceMargin in percentage. 100 = 100%, 1 = 1%
export const maintenanceMarginUtilization = (
  equity: number,
  maintenanceMargin: number
) => {
  if (!equity) {
    return 0;
  }
  const utilization = (maintenanceMargin / equity) * 100;
  return utilization;
};

// Returns the change from num1 -> num2
export const calculateChange = (num1: number, num2: number) => {
  let diff = 0;
  let percentageChange = 0;

  if (num1 && num2) {
    diff = num2 - num1;
    percentageChange = diff / num1;
  }

  return {
    diff,
    percentageChange,
  };
};

export const formatCompactCurrency = (number: number) => {
  const opt: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    notation: "compact",
    compactDisplay: "short",
    currencyDisplay: "narrowSymbol",
    style: "currency",
    currency: "USD",
  };
  return Intl.NumberFormat("en-US", opt).format(number);
};

export const calculatePnl = (
  totalEntryValue: number,
  totalExitValue: number,
  side: SideResponse
) =>
  side === SideResponse.Buy
    ? totalExitValue - totalEntryValue
    : totalEntryValue - totalExitValue;

export const calculatePnlPercent = (
  pnl: number,
  totalEntryValue: number,
  maintenanceMargin: number
) => (maintenanceMargin ? pnl / maintenanceMargin : pnl / totalEntryValue);

export const calculateExitPriceFromRoi = (
  totalEntryValue: number,
  roi: number, // 50% = 50
  maintenanceMargin: number,
  side: SideResponse,
  amount: number
): number => {
  if (amount === 0) {
    return 0;
  }
  const pnl = maintenanceMargin
    ? roi * maintenanceMargin
    : roi * totalEntryValue;

  if (side === SideResponse.Buy) {
    return Number((pnl / amount + totalEntryValue).toFixed(6));
  }
  return Number((totalEntryValue - pnl / amount).toFixed(6));
};

export const calculatePositionLeverage = (
  avgEntryPrice: number,
  amount: number,
  accountEquity: number
) => (accountEquity ? (avgEntryPrice * amount) / accountEquity : 0);

export const getPercent = (num: number, total: number) => {
  const percent = num / total;
  return Math.max(0, Math.min(percent, 1));
};

export const countDecimalPlaces = (number: number) => {
  const match = `${number}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) return 0;

  // Extract the decimal part and the exponent part
  const [, decimalPart, exponentPart] = match;

  // Calculate the total decimal places by adding the decimal part length
  // and subtracting the exponent (if it exists and is negative)
  return Math.max(
    0,
    (decimalPart || "").length - (exponentPart ? +exponentPart : 0)
  );
};

export const nextSmallestDivisibleDecimal = (
  number: number,
  divisor: number
) => {
  if (divisor === 0) {
    return number;
  }

  let quotient = number / divisor;

  // if result ends with 0.999, ceil() instead. Caused by js accuracy errors
  // else, floor()
  quotient = String(quotient).split(".")[1]?.startsWith("999")
    ? Math.ceil(quotient)
    : Math.floor(quotient);

  const nextSmallestDivisible = quotient * divisor;

  // Return the rounded result to the desired decimal places
  const decimalPlaces = Math.max(
    countDecimalPlaces(number),
    countDecimalPlaces(divisor)
  );
  const roundedResult = Number(nextSmallestDivisible.toFixed(decimalPlaces));
  return roundedResult;
};

/**
 * Returns the number rounded to the nearest interval.
 * Example:
 *
 *   roundToNearest(1000.5, 1); // 1000
 *   roundToNearest(1000.5, 0.5);  // 1000.5
 */
export const roundToNearest = (
  value: number,
  interval: number,
  roundUp?: boolean
) =>
  (roundUp ? Math.ceil(value / interval) : Math.floor(value / interval)) *
  interval;

export const xScalarMultiplier = 500000;

export function calculateFarmBoostMultiplier(volume: number): number {
  // Constants
  const t = 0.936;
  const x0 = 3.2;
  const D = 1.2;
  const z = 0.092123;
  const p = 3.0796;
  const x = volume / xScalarMultiplier;

  if (x <= 0) {
    return 1;
  }
  // Check the range of x and apply the corresponding formula
  if (x > 0 && x < 4.562) {
    // Calculate the value based on the first equation
    const exponent = -D * (x - x0);
    const formulaValue = t + (4 - t) / (1 + Math.exp(exponent));
    return formulaValue;
  }
  if (x >= 4.562 && x < 10) {
    // Apply the second equation directly
    return Math.min(4, z * x + p);
  }

  // x is outside the defined ranges
  return 4;
}

export function getRoundedBoost(farmBoost: number) {
  let roundedFarmBoost = 10;

  if (farmBoost >= 100) {
    roundedFarmBoost = 100;
  } else if (farmBoost >= 50) {
    roundedFarmBoost = 50;
  }
  return roundedFarmBoost;
}

export function getNextEpochEndDate() {
  let nextWednesday = moment
    .utc()
    .startOf("week")
    .add(2, "days")
    .hour(8)
    .minute(0)
    .second(0);

  if (moment.utc().isAfter(nextWednesday)) {
    nextWednesday = nextWednesday.add(1, "week");
  }

  return nextWednesday;
}

export const totalFarmingEmissions = 110000000;
export const totalStakingEmissions = 20000000;
export const epoch0BoostedVolume = 3000000000;
export const farmingRewardsStartTimeNano = "1710316800000000000";

export const getCurrentEpoch = (timestampNano?: number): number => {
  // Define the start date (1 March 2024 00:00 UTC) in milliseconds
  const startDate = new Date("2024-03-13T08:00:00Z").getTime();

  const startNano = startDate * 1e6;

  const epochDurationNano = 7 * 24 * 60 * 60 * 1e9;

  const elapsedNano = (timestampNano ?? Date.now() * 1e6) - startNano;

  // Add one to start the count from 1 instead of 0
  const currentEpoch = Math.floor(elapsedNano / epochDurationNano) + 1;

  if (currentEpoch < 1) {
    return 1;
  }
  return currentEpoch;
};

export const calculatePercentageOfWeekDone = (): number => {
  // A function to find the start of the current week, assuming weeks start on Wednesday at 08:00 UTC
  const findStartOfCurrentWeek = (): Date => {
    const now = new Date();
    const dayOfWeek = now.getUTCDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6
    const wednesday = 3; // Wednesday is the 3rd day of the week in this context (starting from Sunday)
    let daysToSubtract = (dayOfWeek - wednesday + 7) % 7 || 7; // Calculate the difference in days

    // Adjust if we are past Wednesday 8:00 UTC or exactly at Wednesday
    if (dayOfWeek === wednesday && now.getUTCHours() >= 8) {
      daysToSubtract = 0;
    }

    // Calculate the start of the week by subtracting the days
    const startOfWeek = new Date(now);
    startOfWeek.setUTCDate(now.getUTCDate() - daysToSubtract);
    startOfWeek.setUTCHours(8, 0, 0, 0); // Set to Wednesday at 08:00 UTC

    return startOfWeek;
  };

  // Get the current date and time
  const now = new Date();

  // Get the start of the current week
  const startOfWeek = findStartOfCurrentWeek();

  // Calculate the duration of one week in milliseconds
  const oneWeekMilliseconds = 7 * 24 * 60 * 60 * 1000;

  // Calculate the elapsed time in milliseconds from the start of the week
  const elapsedMilliseconds = now.getTime() - startOfWeek.getTime();

  // If the current time is before the start of the week, return 0%
  if (elapsedMilliseconds < 0) {
    return 0;
  }

  // Calculate the percentage of the week completed
  const percentageOfWeekDone = elapsedMilliseconds / oneWeekMilliseconds;

  // Return the percentage of the week completed, rounded to two decimal places
  return percentageOfWeekDone;
};

export function getTotalStakedAmountAtEpoch(
  epochStakeAmounts: IEpochStakeData[],
  targetEpoch: number
): number {
  // Filter the array to include only the epochs in the range [targetEpoch-8, targetEpoch]
  const filteredEpochs = epochStakeAmounts.filter(
    (item) =>
      item.epoch <= targetEpoch && item.epoch >= Math.max(1, targetEpoch - 8)
  );

  // Sum the stakedAmounts of the filtered epochs
  const totalStakedAmount = filteredEpochs.reduce(
    (sum, current) => sum + parseInt(current.stakedAmount, 10),
    0
  );

  return totalStakedAmount;
}

export const calculateStakingRewardsEstimate = (
  accountStakingData: IEpochStakeData[],
  vaultStakingData: IEpochStakeData[],
  emissions: GetEmissions200Response[],
  epoch?: number,
  weekComplete?: boolean
): { confirmedRewards: number; estimatedRewards: number } => {
  const currentEpoch = epoch ?? getCurrentEpoch();
  const totalEpochs = currentEpoch;
  let confirmedRewards = 0;
  let estimatedRewards = 0;

  emissions.forEach((emission, index) => {
    const epochFromIndex = index + 1; // since emissions[0] is epoch 1

    const accountEpochStakedAmount = getTotalStakedAmountAtEpoch(
      accountStakingData,
      epochFromIndex
    ); // total compounded staked amount for account
    const vaultEpochStakedAmount = getTotalStakedAmountAtEpoch(
      vaultStakingData,
      epochFromIndex
    ); // total compounded staked amount for vault
    if (vaultEpochStakedAmount > 0 && epochFromIndex <= currentEpoch) {
      const stakedPercentage =
        accountEpochStakedAmount / vaultEpochStakedAmount;
      const epochProgressPercentage =
        epochFromIndex === currentEpoch && !weekComplete
          ? calculatePercentageOfWeekDone()
          : 1;
      const epochTotalStakingEmission =
        (Number(emission.staking_emission) / 100) * totalStakingEmissions;
      const reward =
        epochProgressPercentage * stakedPercentage * epochTotalStakingEmission;

      // Add to estimatedRewards for all epochs
      estimatedRewards += reward;

      // Add to confirmedRewards for all but the last (current) epoch
      if (index < totalEpochs - 1) {
        confirmedRewards += reward;
      }
    }
  });

  return { confirmedRewards, estimatedRewards };
};

export const calculateFarmingRewardsEstimate = (
  farmData: GetFarmBoost200Response,
  emissions: GetEmissions200Response[]
): number => {
  const accountBoostedVolume = farmData.boosted_volume;
  const latestEpochEmission = emissions[emissions.length - 1];

  const emissionPercentage = latestEpochEmission.farming_emission;
  const totalBoostedVolume = latestEpochEmission.total_boosted_volume;

  if (Number(totalBoostedVolume) === 0) {
    return 0;
  }
  return (
    (Number(accountBoostedVolume) / Number(totalBoostedVolume)) *
    (Number(emissionPercentage) / 100) *
    totalFarmingEmissions
  );
};

export const calculateEstimatedTradeRewards = (
  boostedVolume: number,
  emissions?: GetEmissions200Response[],
  timestampNano?: number
) => {
  if (!emissions) {
    return 0;
  }

  const timestampInNanoSeconds = timestampNano ?? Date.now() * 1e6;
  if (timestampInNanoSeconds < Number(farmingRewardsStartTimeNano)) {
    return 0;
  }
  const epoch = getCurrentEpoch(timestampInNanoSeconds);

  const epochFarmingEmissions =
    emissions.length >= epoch - 1
      ? Number(emissions[epoch - 1].farming_emission)
      : 1;
  const epochTotalBoostedVolume =
    emissions.length >= epoch - 1 && epoch !== 1
      ? Number(emissions[epoch - 2].total_boosted_volume) // use the previous epoch totalboostedvolume as estimate
      : epoch0BoostedVolume; // hardcode 1 billion for epoch 0 previous epoch totalboostedvolume

  return (
    (boostedVolume * totalFarmingEmissions * (epochFarmingEmissions / 100)) /
    epochTotalBoostedVolume
  );
};

export const calculateStakingApr = (
  emissions: GetEmissions200Response[],
  totalStakedAmount: BigNumber,
  currentEpoch?: number // for testing purposes
): number => {
  const epoch = currentEpoch ?? getCurrentEpoch();
  const epochIndex = epoch - 1;
  const latestEpochEmission =
    emissions.length >= epochIndex
      ? Number(emissions[epochIndex].staking_emission)
      : 0;

  const totalStakedAmountNumber = Number(
    ethers.utils.formatUnits(totalStakedAmount, 18)
  );

  return Math.min(
    (totalStakingEmissions * (Number(latestEpochEmission) / 100) * 52) /
      totalStakedAmountNumber,
    249.6427
  );
};

export const getUnlockDate = (): string => {
  const futureDate = moment().add(9, "weeks");

  // Adjust to next Wednesday
  if (futureDate.day() <= 3) {
    futureDate.day(3); // If it's before Wednesday, set to this week's Wednesday
  } else {
    futureDate.add(1, "weeks").day(3); // Otherwise, set to next week's Wednesday
  }

  // Set time to 00:00 UTC and format
  futureDate.utc().startOf("day");

  return futureDate.format("MMMM Do, YYYY");
};

export const getUnlockDateOfEpoch = (epoch: number): string => {
  // Start date: March 13, 2024, at 09:00 UTC
  const startDate = moment.utc("2024-03-13T09:00:00");

  // Calculate the unlock date by adding (8 + epoch) weeks to the start date
  // Epoch 1 will be 9 weeks from the start date, epoch 2 will be 10 weeks, etc.
  const unlockDate = startDate.add(8 + epoch, "weeks");

  // The time is already set to 09:00 UTC, so we only format the date
  return unlockDate.format("MMMM Do, YYYY");
};

export const formatNumber = (number: number) => {
  if (Math.abs(number) < 0.01) {
    return "0.00";
  }
  if (number < 1e3) return number;
  if (number >= 1e3 && number < 1e6) return `${+(number / 1e3).toFixed(1)}K`;
  if (number >= 1e6 && number < 1e9) return `${+(number / 1e6).toFixed(1)}M`;
  if (number >= 1e9 && number < 1e12) return `${+(number / 1e9).toFixed(1)}B`;
  if (number >= 1e12) return `${+(number / 1e12).toFixed(1)}T`;
  return number;
};

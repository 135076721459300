import { useEffect, useState } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { SocketVault, SocketVault__factory } from "../../codegen";
import { ChainIdEnum } from "../../enums/chain";
import {
  DepositWithdrawCollaterals,
  MULTICHAIN_CONTRACT_ADDRESSES,
} from "../../constants/addresses";

export const getVaultContract = (
  provider?: any,
  chainId?:
    | ChainIdEnum.OPTIMISM
    | ChainIdEnum.OPTIMISM_TESTNET
    | ChainIdEnum.ARBITRUM
    | ChainIdEnum.ARBITRUM_TESTNET,
  collateral?: DepositWithdrawCollaterals
): SocketVault | undefined => {
  const signerOrProvider = provider?.getSigner() || provider;
  if (signerOrProvider && chainId) {
    const chainAddresses = MULTICHAIN_CONTRACT_ADDRESSES[chainId];
    if (chainAddresses?.vault) {
      switch (collateral) {
        case DepositWithdrawCollaterals.Usdc:
          return SocketVault__factory.connect(
            chainAddresses.vault.usdc,
            signerOrProvider
          );
        case DepositWithdrawCollaterals.NativeUsdc:
          return SocketVault__factory.connect(
            chainAddresses.vault["native-usdc"],
            signerOrProvider
          );
        case DepositWithdrawCollaterals.Weth:
          return SocketVault__factory.connect(
            chainAddresses.vault.weth,
            signerOrProvider
          );
        default:
          return undefined;
      }
    }
  }

  return undefined;
};

interface ISocketVaultProps {
  provider?: Web3Provider;
  chainId?: number;
  collateral?: DepositWithdrawCollaterals;
}

const useSocketVault = ({
  provider,
  chainId,
  collateral,
}: ISocketVaultProps) => {
  const [vault, setVault] = useState<SocketVault>();

  useEffect(() => {
    setVault(getVaultContract(provider, chainId, collateral));
  }, [chainId, provider, collateral]);

  return {
    vault,
  };
};

export default useSocketVault;

import { motion } from "framer-motion";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation } from "react-router-dom";
import { ReactComponent as ReferralsLogo } from "../../../assets/svg/referrals.svg";
import { PageEndpointEnum } from "../../../enums/endpoint";
import { IPage } from "../../shared";
import { ReferralsTab } from "../../ReferralsTab";
import {
  ContentsWrapper,
  InnerContentsWrapper,
  MobilePageWrapper,
  MobileTabActionsWrapper,
  MobileTabLinkWrapper,
} from "../../style";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

function MobileReferralsPage() {
  const location = useLocation();
  const activePage = useMemo(
    () => location.pathname.split(`${PageEndpointEnum.REFERRALS}/`)[1],
    [location]
  );
  const { t } = useTranslation("app", { keyPrefix: "pages.ReferralsPage" });

  const pages = useMemo(() => {
    const initPages: IPage[] = [
      {
        title: t("referrals"),
        path: "/",
        redirect: "/referrals",
        Logo: ReferralsLogo,
        element: <ReferralsTab isMobileScreen />,
        isActive: !activePage,
      },
    ];

    return initPages;
  }, [activePage, t]);

  return (
    <MobilePageWrapper>
      <MobileTabActionsWrapper>
        <div>
          {pages.map((page, i) => (
            <motion.div
              key={page.title}
              transition={{
                delay: i * 0.1,
                duration: 0.5,
                ease: "easeInOut",
              }}
              initial={{
                transform: "translateY(-8px)",
                opacity: 0,
              }}
              animate={{
                transform: "translateY(0px)",
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <MobileTabLinkWrapper
                to={page.redirect}
                $isActive={page.isActive}
              >
                {page.title}
              </MobileTabLinkWrapper>
            </motion.div>
          ))}
        </div>
      </MobileTabActionsWrapper>
      <ContentsWrapper isMobileScreen>
        <InnerContentsWrapper
          isMobileScreen
          style={{
            paddingTop: COMPONENTS.portfolioNavigation,
            paddingLeft: SPACING.three,
            paddingRight: SPACING.three,
          }}
        >
          <Routes>
            {pages.map((page) => (
              <Route key={page.path} path={page.path} element={page.element} />
            ))}
          </Routes>
        </InnerContentsWrapper>
      </ContentsWrapper>
    </MobilePageWrapper>
  );
}

export default MobileReferralsPage;

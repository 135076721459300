import { useContext, useEffect, useMemo, useState } from "react";
import { WebsocketContext } from "../../contexts/WebsocketAuthContext";
import { jsonParse } from "../../utils/strings";
import { useGetOrderbook } from "../api/orderbook/useGetOrderbook";
import { IWSSOrderbookResponse } from "./model/orderbook";
import { WebsocketChannelEnum } from "./model/shared";
import { GetOrderbook200Response, OrderTypeResponse } from "../../codegen-api";

const getOrderbookChannel = (instrument: string) =>
  `${WebsocketChannelEnum.ORDER_BOOK}:${instrument}`;

const useOrderbookWSS = (
  instrument?: string,
  orderType: OrderTypeResponse = OrderTypeResponse.Market
) => {
  const { triggerSubscribe, lastMessages } = useContext(WebsocketContext);
  const { data: orderbookData } = useGetOrderbook(instrument);
  const [obData, setObData] = useState<GetOrderbook200Response | undefined>(
    orderbookData
  );

  useEffect(() => {
    if (!instrument || orderType === OrderTypeResponse.Limit) {
      return;
    }

    const data = [getOrderbookChannel(instrument)];
    triggerSubscribe("subscribe", data, WebsocketChannelEnum.ORDER_BOOK);
  }, [instrument, orderType, triggerSubscribe]);

  useEffect(() => {
    if (lastMessages && instrument && orderType === OrderTypeResponse.Market) {
      lastMessages.forEach((lastMessage) => {
        const { data, channel }: IWSSOrderbookResponse = jsonParse(
          lastMessage.data
        );

        if (
          data &&
          channel === getOrderbookChannel(instrument) &&
          Number(data.last_updated) > Number(obData?.last_updated || 0)
        ) {
          // If update, update the prev state
          if (data.type === "snapshot") {
            setObData(data);
          } else {
            // Process asks and bids, and update the diff
            const newAsks: string[][] =
              obData?.instrument_name === instrument ? obData?.asks || [] : [];
            const newBids: string[][] =
              obData?.instrument_name === instrument ? obData?.bids || [] : [];

            if (data) {
              data.asks?.forEach(([price, size]) => {
                const priceIndex = newAsks.findIndex(([p]) => p === price);
                if (priceIndex >= 0) {
                  newAsks[priceIndex] = [price, size];
                } else {
                  newAsks.push([price, size]);
                }
              });

              data.bids?.forEach(([price, size]) => {
                const priceIndex = newBids.findIndex(([p]) => p === price);
                if (priceIndex >= 0) {
                  newBids[priceIndex] = [price, size];
                } else {
                  newBids.push([price, size]);
                }
              });

              // Mutate OB
              setObData({
                ...data,
                // Filters to only bids/asks with size
                bids: (
                  newBids.filter(([, size]) => Number(size) > 0) || []
                ).sort((a, b) => Number(b[0]) - Number(a[0])),
                asks: (
                  newAsks.filter(([, size]) => Number(size) > 0) || []
                ).sort((a, b) => Number(a[0]) - Number(b[0])),
              });
            }
          }
        }
      });
    }
  }, [instrument, lastMessages, obData, orderType]);

  const fallbackOrderbookIfNeeded = useMemo(() => {
    if (!obData && orderbookData?.instrument_name === instrument) {
      return orderbookData;
    }

    if (obData?.instrument_name === instrument) {
      return obData;
    }
    return undefined;
  }, [instrument, obData, orderbookData]);

  return {
    orderbook: fallbackOrderbookIfNeeded,
  };
};

export default useOrderbookWSS;

import styled from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";

export const PriceChartContainer = styled.div<{
  width: string;
  height: string;
}>`
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

export const Tooltip = styled.div`
  pointer-events: none;
  display: none;
  ${FONT_CSS.data.four}
  position: absolute;
  color: ${TEXT_COLORS.two};
  background-color: ${LAYER_COLORS.four};
  padding: ${SPACING.one}px ${SPACING.two}px;
  border-radius: 4px;
  z-index: 999;
`;

import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InstrumentTypeResponse, SideResponse } from "../../../codegen-api";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS } from "../../../constants/design/spacing";
import { IPerpsMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { getContractPriceStep } from "../../../utils/instruments";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import MobileFooter from "../../MobileFooter";
import MobileTradeFormModal from "../../MobileTradeFormModal";
import { IPerpsFormDefaultValues } from "../../TradeForm/PerpsTradeForm";
import MobileTradingBottomBar from "../../TradingBottomBar/MobileTradingBottomBar";
import TradingViewChart from "../../TradingViewChart";
import SegmentedControl from "../../shared/SegmentedControl";
import DepthSection from "../DepthSection";
import { ChartTab } from "../PerpsOrderbookAndChart/style";
import OrderbookSection from "../TradeOrderbookSection/OrderbookSection";
import TradeHistorySection from "../TradeOrderbookSection/TradeHistorySection";
import { Container, SectionContainer, SegmentWrapper } from "./styles";

interface IMobilePerpsSectionProps {
  perpInstrument?: IPerpsMarket;
}

enum PerpTabEnum {
  PRICE = "price",
  DEPTH = "depth",
  ORDERBOOK = "orderbook",
  HISTORY = "history",
}

function MobilePerpsSection({ perpInstrument }: IMobilePerpsSectionProps) {
  const { t } = useTranslation("app", {
    keyPrefix: "Perps.MobilePerpsSection.MobilePerpsSection",
  });

  const [selectedTab, setSelectedTab] = useState<PerpTabEnum>(
    PerpTabEnum.PRICE
  );
  const [showTradeForm, setShowTradeForm] = useState(false);
  const [perpDefaultValues, setPerpDefaultValues] =
    useState<IPerpsFormDefaultValues>();

  const onPerpOrderbookRowClick = useCallback(
    (price: string, _: string, side: SideResponse) => {
      setShowTradeForm(true);
      setPerpDefaultValues({ price, side });
    },
    []
  );

  const chartControls = useMemo(
    () =>
      Object.keys(PerpTabEnum).map((k) => {
        const key = k as keyof typeof PerpTabEnum;
        return {
          value: PerpTabEnum[key],
          display: <ChartTab>{t(PerpTabEnum[key])}</ChartTab>,
          textColor:
            selectedTab === PerpTabEnum[key]
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        };
      }),
    [selectedTab, t]
  );

  const onToggleForm = useCallback((side: SideResponse) => {
    setPerpDefaultValues({ side });
    setShowTradeForm(true);
  }, []);

  return (
    <Container>
      <MobileTradeFormModal
        defaultValues={perpDefaultValues}
        instrumentType={InstrumentTypeResponse.Perpetual}
        show={showTradeForm}
        onHide={() => setShowTradeForm(false)}
        selectedInstrument={perpInstrument}
      />

      <div
        style={{
          background: LAYER_COLORS.one,
          height: "auto",
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        <SegmentWrapper>
          <SegmentedControl
            segments={chartControls}
            value={selectedTab}
            onSelect={(e) => setSelectedTab(e as PerpTabEnum)}
            config={{
              widthType: "overflow",
              backgroundColor: LAYER_COLORS.two,
              activeBackgroundColor: LAYER_COLORS.three,
              borderRadius: "10px",
              button: {
                fontSize: FONT_SIZE.one,
              },
            }}
          />
        </SegmentWrapper>

        {perpInstrument && (
          <>
            <SectionContainer hidden={selectedTab !== PerpTabEnum.PRICE}>
              <TradingViewChart
                isMobileScreen
                key={String(
                  getContractPriceStep(perpInstrument).price_precision
                )}
                symbol={perpInstrument.instrument_name}
                amountDecimals={
                  getContractPriceStep(perpInstrument).amount_precision
                }
                priceDecimals={
                  getContractPriceStep(perpInstrument).price_precision
                }
              />
            </SectionContainer>
            <SectionContainer hidden={selectedTab !== PerpTabEnum.DEPTH}>
              <DepthSection perpInstrument={perpInstrument} />
            </SectionContainer>
            <SectionContainer
              style={{ height: COMPONENTS.mobileTvChart }}
              hidden={selectedTab !== PerpTabEnum.ORDERBOOK}
            >
              <OrderbookSection
                sizeDecimals={
                  getContractPriceStep(perpInstrument).amount_precision
                }
                priceDecimals={
                  getContractPriceStep(perpInstrument).price_precision
                }
                onOrderbookRowClick={onPerpOrderbookRowClick}
                instrumentName={perpInstrument.instrument_name}
                showValueInUSD={false}
                orderbookTickSize={undefined}
              />
            </SectionContainer>
            <SectionContainer
              style={{ height: COMPONENTS.mobileTvChart }}
              hidden={selectedTab !== PerpTabEnum.HISTORY}
            >
              <TradeHistorySection
                instrumentName={perpInstrument.instrument_name}
                sizeDecimals={
                  getContractPriceStep(perpInstrument).amount_precision
                }
                priceDecimals={
                  getContractPriceStep(perpInstrument).price_precision
                }
              />
            </SectionContainer>
          </>
        )}
      </div>
      <MobileTradingBottomBar />
      <MobileFooter>
        <Button
          style={{ borderRadius: 8 }}
          onClick={() => onToggleForm(SideResponse.Buy)}
          buttonTheme={ButtonThemeEnum.POSITIVE}
        >
          {t("buy")}
        </Button>
        <Button
          style={{ borderRadius: 8 }}
          onClick={() => onToggleForm(SideResponse.Sell)}
          buttonTheme={ButtonThemeEnum.NEGATIVE}
        >
          {t("sell")}
        </Button>
      </MobileFooter>
    </Container>
  );
}

export default MobilePerpsSection;

import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import {
  OptionsChainSettings,
  SettingsContext,
  SettingsEnum,
  settingsEnumTitle,
  SFXSettings,
} from "../../contexts/SettingsContext";
import { SettingsAudioPlayer } from "../SettingsAudioPlayer";
import { BaseModal, IBaseModalProps } from "../BaseModal";
import SegmentedControl from "../shared/SegmentedControl";
import Toggle from "../shared/Toggle";
import {
  LanguageButton,
  LanguageButtonWrapper,
  SettingHeader,
  SettingRow,
  SettingsTitle,
  SFXTitle,
} from "./style";
import { ButtonThemeEnum } from "../Buttons/styles";
import { Language } from "../../i18n/config";

interface ISetting {
  title: string;
  setting: OptionsChainSettings | SFXSettings;
  isOn: boolean;
  onClick: () => void;
}

interface ISettingsModalProps extends IBaseModalProps {
  showOptionsChainSettings?: boolean;
}

export function SettingsModal({
  show,
  onHide,
  showOptionsChainSettings,
}: ISettingsModalProps) {
  const { t } = useTranslation("app", { keyPrefix: "SettingsModal" });

  const OPTIONS_CHAIN_LIST: Array<{
    key: string;
    value: OptionsChainSettings;
  }> = useMemo(
    () => [
      { key: t("your_position"), value: "position" },
      { key: t("bid_iv"), value: "bid_iv" },
      { key: t("ask_iv"), value: "ask_iv" },
      { key: t("delta"), value: "delta" },
      { key: t("mark_price"), value: "mark_price" },
    ],
    [t]
  );

  const SFX_LIST: Array<{ key: string; value: SFXSettings }> = useMemo(
    () => [
      { key: t("order_placed"), value: "order_placed" },
      { key: t("order_filled"), value: "order_filled" },
      { key: t("order_cancelled"), value: "order_cancelled" },
      { key: t("errors"), value: "error" },
    ],
    [t]
  );

  const {
    optionsChain,
    addOptionsChain,
    removeOptionsChain,
    sfx,
    addSfx,
    removeSfx,
    language,
    changeLanguage,
  } = useContext(SettingsContext);

  const settingOptions = useMemo(() => {
    if (showOptionsChainSettings) return Object.values(SettingsEnum);
    const settings = Object.values(SettingsEnum).filter(
      (v) => v !== SettingsEnum.OPTIONS_CHAIN
    ) as SettingsEnum[];
    return settings;
  }, [showOptionsChainSettings]);

  const [selectedSetting, setSetting] = useState<SettingsEnum>(
    settingOptions[0]
  );

  const optionsChainSettings: ISetting[] = useMemo(
    () =>
      OPTIONS_CHAIN_LIST.map((o) => ({
        title: o.key,
        setting: o.value,
        isOn: optionsChain.has(o.value),
        onClick: () => {
          if (optionsChain.has(o.value)) {
            removeOptionsChain(o.value);
          } else {
            addOptionsChain(o.value);
          }
        },
      })),
    [OPTIONS_CHAIN_LIST, optionsChain, removeOptionsChain, addOptionsChain]
  );

  const sfxSettings: ISetting[] = useMemo(
    () =>
      SFX_LIST.map((o) => ({
        title: o.key,
        setting: o.value,
        isOn: sfx.has(o.value),
        onClick: () => {
          if (sfx.has(o.value)) {
            removeSfx(o.value);
          } else {
            addSfx(o.value);
          }
        },
      })),
    [SFX_LIST, sfx, removeSfx, addSfx]
  );

  const settingsTitle = useMemo(() => {
    if (selectedSetting === SettingsEnum.OPTIONS_CHAIN) {
      return t("options_chain_desc");
    }

    if (selectedSetting === SettingsEnum.SFX) {
      return t("sfx_desc");
    }

    if (selectedSetting === SettingsEnum.LANGUAGE) {
      return t("language_desc");
    }

    return undefined;
  }, [selectedSetting, t]);

  const settingsList = useMemo(() => {
    switch (selectedSetting) {
      case SettingsEnum.OPTIONS_CHAIN:
        return optionsChainSettings;
      case SettingsEnum.SFX:
        return sfxSettings;
      default:
        return [];
    }
  }, [optionsChainSettings, selectedSetting, sfxSettings]);

  return (
    <BaseModal
      title={t(settingsEnumTitle[selectedSetting])}
      show={show}
      onHide={onHide}
      style={{
        width: 340,
      }}
    >
      <SettingHeader>{settingsTitle}</SettingHeader>
      {selectedSetting === SettingsEnum.LANGUAGE ? (
        <LanguageButtonWrapper>
          <LanguageButton
            onClick={() => changeLanguage("en" as Language)}
            fullWidth
            buttonTheme={
              language === "en"
                ? ButtonThemeEnum.HIGHLIGHT
                : ButtonThemeEnum.NEUTRAL1
            }
          >
            English
          </LanguageButton>
          <LanguageButton
            onClick={() => changeLanguage("cn" as Language)}
            fullWidth
            buttonTheme={
              language === "cn"
                ? ButtonThemeEnum.HIGHLIGHT
                : ButtonThemeEnum.NEUTRAL1
            }
          >
            中文
          </LanguageButton>
          <LanguageButton
            onClick={() => changeLanguage("ru" as Language)}
            fullWidth
            buttonTheme={
              language === "ru"
                ? ButtonThemeEnum.HIGHLIGHT
                : ButtonThemeEnum.NEUTRAL1
            }
          >
            русский
          </LanguageButton>
        </LanguageButtonWrapper>
      ) : null}
      {selectedSetting === SettingsEnum.OPTIONS_CHAIN ||
      selectedSetting === SettingsEnum.SFX
        ? settingsList.map((o, i) => (
            <SettingRow
              key={o.setting}
              transition={{
                duration: 0.4,
                delay: (i + 1) * 0.05,
                ease: "easeInOut",
              }}
              initial={{
                opacity: 0,
                transform: "translateY(8px)",
              }}
              animate={{
                opacity: 1,
                transform: "translateY(0px)",
              }}
            >
              {selectedSetting === SettingsEnum.OPTIONS_CHAIN ? (
                <SettingsTitle $isOn={o.isOn}>
                  <p>{o.title}</p>
                </SettingsTitle>
              ) : (
                <SFXTitle $isOn={o.isOn}>
                  <SettingsAudioPlayer
                    sfx={o.setting as SFXSettings}
                    isOn={o.isOn}
                  />
                  <p>{o.title}</p>
                </SFXTitle>
              )}
              <Toggle isOn={o.isOn} onToggle={o.onClick} />
            </SettingRow>
          ))
        : null}
      {settingOptions.length > 1 && (
        <SegmentedControl
          segments={settingOptions.map((opt) => ({
            value: opt,
            display: t(settingsEnumTitle[opt]),
            textColor:
              selectedSetting === opt ? COLORS.blue.one : TEXT_COLORS.three,
          }))}
          value={selectedSetting}
          onSelect={(value) => setSetting(value as SettingsEnum)}
          config={{
            theme: "outline",
            color: COLORS.blue.one,
            widthType: "overflow",
            backgroundColor: COLORS.white.five,
            activeBackgroundColor: COLORS.blue.five,
            borderRadius: "10px",
            margin: `${SPACING.three}px 0 0 0`,
          }}
        />
      )}
    </BaseModal>
  );
}

import { useCallback, useState } from "react";
import { IMarket } from "../../contexts/MarketContext";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { jsonParse } from "../../utils/strings";

const useWatchlist = () => {
  const loadWatchlist = useCallback(() => {
    const localStorageItem = localStorage.getItem(
      LocalStorageKeyEnum.WATCHLIST
    );
    if (localStorageItem) {
      try {
        return jsonParse(localStorageItem) as IMarket[];
      } catch (error) {
        // Do nothing
      }
    }
    return [];
  }, []);

  const [watchlist, setWatchList] = useState<IMarket[]>(loadWatchlist());

  const refreshWatchlist = useCallback(() => {
    setWatchList(loadWatchlist);
  }, [loadWatchlist]);

  const updateWatchlist = useCallback((list: IMarket[]) => {
    setWatchList(list);
    try {
      localStorage.setItem(LocalStorageKeyEnum.WATCHLIST, JSON.stringify(list));
    } catch (error) {
      // Do nothing
    }
  }, []);

  return {
    watchlist,
    updateWatchlist,
    refreshWatchlist,
  };
};

export default useWatchlist;

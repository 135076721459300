/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import eyeIconHighlight from "../../assets/svg/eye-highlight.svg";
import eyeIcon from "../../assets/svg/eye.svg";
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  IOrderLineAdapter,
  LibrarySymbolInfo,
  ResolutionString,
  Timezone,
} from "../../charting_library/charting_library";
import { widget } from "../../charting_library/charting_library.esm";
import {
  GetAccount200ResponsePositionsInner,
  GetOrders200Response,
  SideResponse,
} from "../../codegen-api";
import {
  BORDER_COLORS,
  COLORS,
  ICON_COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { getStopOrderName } from "../../utils/order";
import { startCase } from "../../utils/strings";
import { Container } from "./style";
import { useDatafeed } from "./useDatafeed";
import useSaveloadAdapter from "./useSaveloadAdapter";

interface ITradingViewChartProps {
  symbol: string;
  orders?: GetOrders200Response[];
  position?: GetAccount200ResponsePositionsInner;
  onCancelOrder?: (order: GetOrders200Response) => void;
  load?: boolean;
  isMobileScreen?: boolean;
  amountDecimals?: number;
  priceDecimals?: number;
  hidden?: boolean;
}

interface ITVPreferences {
  interval: ResolutionString;
  timezone: Timezone;
}

const defaultPreferences: ITVPreferences = {
  interval: "5" as ResolutionString,
  timezone: "Etc/UTC",
};

const getOrderHighlightingHTML = (orderHighlightingOn: boolean) => `
  <div data-role="button" style="display: flex; align-items: center; color: ${
    orderHighlightingOn ? COLORS.highlight.one : TEXT_COLORS.three
  };">
    <img src=${
      orderHighlightingOn ? eyeIconHighlight : eyeIcon
    } style="margin-right: ${SPACING.one}px;"/>
    Order Highlighting
  </div>
`;

const charts_storage_api_version = "1.0";
const client_id = "aevo.xyz";
const defaultChartName = "default";

function TradingViewChart({
  symbol,
  orders,
  position,
  onCancelOrder,
  isMobileScreen,
  hidden,
  load = true,
  amountDecimals = 2,
  priceDecimals = 2,
}: ITradingViewChartProps) {
  const { t } = useTranslation();
  const { datafeed } = useDatafeed(amountDecimals, priceDecimals);

  const tvWidget = useRef<IChartingLibraryWidget | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const orderLines = useRef<IOrderLineAdapter[]>([]);
  const chartByTradingViewButton = useRef<HTMLElement>();
  const orderHighlightingButton = useRef<HTMLElement>();

  const { adapter } = useSaveloadAdapter();

  const [orderHighlightingOn, setOrderHighlightingOn] = useState(true);
  const [chartReady, setChartReady] = useState(false);

  // load preferences
  const getStoredPreferences = useCallback(() => {
    const prefStr = localStorage.getItem(LocalStorageKeyEnum.TV_PREFERENCES);
    let pref = defaultPreferences;
    if (prefStr) {
      try {
        pref = JSON.parse(prefStr) as ITVPreferences;
      } catch (error) {
        // Do nothing
      }
    }
    // Fill missing prefs
    pref = Object.keys(pref).reduce((acc, k) => {
      const key = k as keyof ITVPreferences;
      return {
        ...acc,
        [key]: pref[key] || defaultPreferences[key],
      };
    }, pref);

    return pref;
  }, []);

  const prefChangedSub = useCallback(
    (key: keyof ITVPreferences, value: any) => {
      // Store
      const pref = getStoredPreferences();
      pref[key] = value;
      localStorage.setItem(
        LocalStorageKeyEnum.TV_PREFERENCES,
        JSON.stringify(pref)
      );
    },
    [getStoredPreferences]
  );

  useEffect(() => {
    if (!ref.current || !load) {
      return;
    }

    const pref: ITVPreferences = getStoredPreferences();
    const path = `${window.origin}/charting_library/`;

    const disabled_features = [
      "header_symbol_search",
      "save_chart_properties_to_local_storage",
      "compare_symbol",
      "header_compare",
      "timeframes_toolbar",
    ];

    if (isMobileScreen) {
      disabled_features.push(...["left_toolbar", "vert_touch_drag_scroll"]);
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol,
      // BEWARE: no trailing slash is expected in feed URL
      // tslint:disable-next-line:no-any
      // datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(
      //   TRADINGVIEW_DATAFEED_URL
      // ),
      datafeed,
      interval: pref.interval,
      loading_screen: {
        backgroundColor: LAYER_COLORS.one,
      },
      container: ref.current,
      debug: false,
      library_path: path,
      locale: "en",
      disabled_features,
      fullscreen: false,
      autosize: true,
      theme: "Dark",
      custom_css_url: "../tradingViewChart.css",
      charts_storage_api_version,
      client_id,
      load_last_chart: true,
      auto_save_delay: 1,
      save_load_adapter: adapter,

      // @ts-ignore
      custom_formatters: {
        priceFormatterFactory: (
          symbolInfo: LibrarySymbolInfo | null,
          minTick: string
        ) => ({
          format: (price, signPositive) => price.toFixed(priceDecimals),
        }),
      },
      overrides: {
        "paneProperties.vertGridProperties.color": BORDER_COLORS.one,
        "paneProperties.horzGridProperties.color": BORDER_COLORS.one,
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": LAYER_COLORS.one,

        "scalesProperties.textColor": TEXT_COLORS.two,
        "scalesProperties.fontSize": 13,

        // CANDLE
        "mainSeriesProperties.candleStyle.borderUpColor": COLORS.positive.one,
        "mainSeriesProperties.candleStyle.borderDownColor": COLORS.negative.one,
        "mainSeriesProperties.candleStyle.upColor": COLORS.positive.one,
        "mainSeriesProperties.candleStyle.downColor": COLORS.negative.one,
        "mainSeriesProperties.candleStyle.drawWick": true,
        "mainSeriesProperties.candleStyle.drawBorder": false,
        "mainSeriesProperties.candleStyle.wickUpColor": COLORS.positive.one,
        "mainSeriesProperties.candleStyle.wickDownColor": COLORS.negative.one,
        "mainSeriesProperties.candleStyle.wickColor": COLORS.blue.one,
        "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
        "mainSeriesProperties.candleStyle.drawBody": true,

        // HOLLOW CANDLE
        "mainSeriesProperties.hollowCandleStyle.borderUpColor":
          COLORS.positive.one,
        "mainSeriesProperties.hollowCandleStyle.borderDownColor":
          COLORS.negative.one,
        "mainSeriesProperties.hollowCandleStyle.upColor": COLORS.positive.one,
        "mainSeriesProperties.hollowCandleStyle.downColor": COLORS.negative.one,
        "mainSeriesProperties.hollowCandleStyle.drawWick": true,
        "mainSeriesProperties.hollowCandleStyle.drawBorder": false,
        "mainSeriesProperties.hollowCandleStyle.wickUpColor":
          COLORS.positive.one,
        "mainSeriesProperties.hollowCandleStyle.wickDownColor":
          COLORS.negative.one,
        "mainSeriesProperties.hollowCandleStyle.wickColor": COLORS.blue.one,
        "mainSeriesProperties.hollowCandleStyle.drawBody": true,
      },
      studies_overrides: {
        "volume.volume.color.0": COLORS.negative.two,
        "volume.volume.color.1": COLORS.positive.two,
      },
    };

    // eslint-disable-next-line new-cap
    const w = new widget(widgetOptions) as IChartingLibraryWidget;
    tvWidget.current = w;

    tvWidget.current?.onChartReady(async () => {
      setChartReady(true);

      // Subscriptions
      w.activeChart()
        .onIntervalChanged()
        .subscribe(null, (v) => prefChangedSub("interval", v));

      w.subscribe("onAutoSaveNeeded", () => {
        w.saveChartToServer(undefined, undefined, { defaultChartName });
      });

      if (!isMobileScreen) {
        tvWidget.current!.headerReady().then(() => {
          orderHighlightingButton.current?.remove();
          orderHighlightingButton.current = tvWidget.current!.createButton();
          tvWidget.current!.setDebugMode(true);
          orderHighlightingButton.current.setAttribute(
            "title",
            "Show or hide order lines"
          );
          orderHighlightingButton.current.classList.add("apply-common-tooltip");
          orderHighlightingButton.current.addEventListener(
            "click",
            () => setOrderHighlightingOn((on) => !on),
            { passive: true }
          );
          orderHighlightingButton.current.innerHTML =
            getOrderHighlightingHTML(true);
        });
      }

      // Chart by Trading view tooltip
      tvWidget.current!.headerReady().then(() => {
        chartByTradingViewButton.current?.remove();
        chartByTradingViewButton.current = tvWidget.current!.createButton();

        tvWidget.current!.setDebugMode(true);
        chartByTradingViewButton.current.setAttribute(
          "title",
          "Aevo uses TradingView technology to display price data on charts. TradingView is globally recognized as a leading platform for charting and trading, offering an extensive array of technical, drawing, and analytical tools. Powered by robust technologies available on web browsers, desktop applications, and mobile apps, this platform delivers unparalleled access to real-time data e.g. Dow Futures and  BTC USD Chart, the latest financial news, Stock screener and Economic calendar."
        );
        chartByTradingViewButton.current.classList.add("apply-common-tooltip");
        chartByTradingViewButton.current.innerHTML = `
          <a 
            href="https://www.tradingview.com/" 
            data-role="button"
            target="_blank"
            rel="noreferrer"
            style="color: ${TEXT_COLORS.three};"
          >
            Trading View
          </div>
        `;
      });
    });
  }, [
    adapter,
    datafeed,
    getStoredPreferences,
    isMobileScreen,
    load,
    prefChangedSub,
    priceDecimals,
    symbol,
  ]);

  // update toggle button state
  useEffect(() => {
    if (orderHighlightingButton.current?.innerHTML) {
      orderHighlightingButton.current.innerHTML =
        getOrderHighlightingHTML(orderHighlightingOn);
    }
  }, [orderHighlightingOn]);

  // Update orderlines
  useEffect(() => {
    tvWidget.current?.onChartReady(() => {
      if (orders) {
        const chart = tvWidget.current!.activeChart();
        // Remove all previous orderlines
        for (let i = 0; i < orderLines.current.length; i += 1) {
          const l = orderLines.current[i];
          l.remove();
        }

        if (!orderHighlightingOn || isMobileScreen) {
          orderLines.current = [];
          return;
        }

        // Create new orderlines
        const lines = orders.map((o) => {
          const primaryColor =
            o.side === SideResponse.Buy
              ? COLORS.positive.one
              : COLORS.negative.one;
          const secondaryColor =
            o.side === SideResponse.Buy
              ? COLORS.positive.two
              : COLORS.negative.two;
          const bgColor = o.side === SideResponse.Buy ? "#05200B" : "#230615";
          const borderColor =
            o.side === SideResponse.Buy
              ? COLORS.positive.four
              : COLORS.negative.four;

          const orderAmount = Number(o.amount || 0) - Number(o.filled || 0);

          try {
            const line = chart
              .createOrderLine()
              .setExtendLeft(false)
              .setTooltip("Additional order information")
              .onMove(() => {
                // this.setText("onMove called");
              })
              .onModify("onModify called", (text) => {
                // this.setText(text);
              })
              .onCancel("onCancel called", (text) => {
                // this.setText(text);
                onCancelOrder?.(o);
              })
              .setEditable(false)
              .setCancellable(true)
              .setLineLength(50)
              .setLineColor(secondaryColor)
              .setLineWidth(2)
              .setLineStyle(1)
              .setBodyTextColor(primaryColor)
              .setQuantityTextColor(TEXT_COLORS.one)
              .setBodyBackgroundColor(bgColor)
              .setQuantityBackgroundColor(bgColor)
              .setCancelButtonBackgroundColor(bgColor)
              .setBodyBorderColor(borderColor)
              .setQuantityBorderColor(borderColor)
              .setCancelButtonBorderColor(borderColor)
              .setCancelButtonIconColor(ICON_COLORS.three)
              .setText(
                startCase(
                  `${o.side} ${
                    o.stop
                      ? getStopOrderName(t, o.order_type, o.stop, false)
                      : o.order_type
                  } ${o.reduce_only ? "Reduce" : ""}`
                )
              )
              .setQuantity(orderAmount.toFixed(amountDecimals));

            if (o.stop) {
              // If is stop order, price is trigger price
              line.setPrice(Number(o.trigger));

              // If close position, qty is the position size
              if (o.close_position) {
                line.setQuantity(
                  Number(position?.amount || 0).toFixed(amountDecimals)
                );
              }
            } else {
              line.setPrice(Number(o.price));
            }

            return line;
          } catch (error) {
            return null;
          }
        });
        orderLines.current = lines.filter((l) => !!l) as IOrderLineAdapter[];
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    chartReady,
    orderHighlightingOn,
    amountDecimals,
    isMobileScreen,
    onCancelOrder,
    position?.amount,
    JSON.stringify(orders),
  ]);

  useEffect(() => {
    if (tvWidget.current && !load && chartReady) {
      tvWidget.current.remove();
      tvWidget.current = null;
    }
  }, [chartReady, load]);

  return (
    <Container
      style={{
        height: isMobileScreen ? `${COMPONENTS.mobileTvChart}px` : "100%",
        opacity: hidden ? 0 : 1,
        pointerEvents: hidden ? "none" : "auto",
      }}
      ref={ref}
    />
  );
}

export default TradingViewChart;

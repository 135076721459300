/* eslint-disable no-nested-ternary */
import currency from "currency.js";
import { useAnimation } from "framer-motion";
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as Alert } from "../../../assets/svg/alert.svg";
import {
  CollateralAssetResponse,
  InstrumentTypeResponse,
  OrderTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import {
  BACKGROUND_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import {
  INDEX_PRICE_COLLAR_OPTIONS,
  MARK_PRICE_COLLAR,
  MIN_ORDER_VALUE,
} from "../../../constants/precision/form";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { MarketContext } from "../../../contexts/MarketContext";
import { FormValidatorKeysEnum } from "../../../enums/form";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import { useMarginRequirements } from "../../../hooks/api/margin/useMarginRequirements";
import { IOptionMarket } from "../../../contexts/MarketInstrumentContext/useGetMarkets";
import { useOrder } from "../../../hooks/api/order/useOrder";
import { useRFQ } from "../../../hooks/api/rfq/useRFQ";
import { useToast } from "../../../hooks/toast";
import usePrevious from "../../../hooks/usePrevious";
import useTickersWSS from "../../../hooks/wss/tickers/useTickersWSS";
import useIndexWSS from "../../../hooks/wss/useIndexWSS";
import useOrderbookWSS from "../../../hooks/wss/useOrderbookWSS";
import usePositionsWSS from "../../../hooks/wss/usePositionsWSS";
import { ICreateOrderBody } from "../../../interfaces/Order";
import { IPriceSize } from "../../../interfaces/Orderbook";
import { IPositionInfo, ITradeInfo } from "../../../interfaces/TradeInfo";
import { getAssetLogo } from "../../../utils/asset/assets";
import { roundToStepSize } from "../../../utils/format";
import { getContractPriceStep } from "../../../utils/instruments";
import {
  getValueOfContractsAmount,
  maxOrderSizeForMarkPriceCollar,
} from "../../../utils/orderbook";
import {
  ToastEnum,
  ToastStatusEnum,
  getToastTitleForInstrument,
} from "../../../utils/toast";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import ClosePositionModal from "../../ConfirmationModal/ClosePositionModal";
import OptionFilter from "../../OptionFilter";
import TicketDetails from "../../TicketDetails";
import SegmentedControl, {
  ISegmentedControlConfig,
} from "../../shared/SegmentedControl";
import { Spinner } from "../../shared/Spinner";
import { ReduceOnlyInput } from "../form";
import {
  BoostTitle,
  BoostValueWrapper,
  ContentWrapper,
  DeselectReduceOnlyContainer,
  DetailsWrapper,
  FormContent,
  InfoRow,
  InputsWrapper,
  OrderTypeWrapper,
  Padding,
  PlaceOrderButton,
  PositionChip,
  PositionInfoWrapper,
  SubmitWrapper,
  Title,
  TradeExecutedBar,
  TradeForm,
  TradeInfoWrapper,
  TransactionTypeWrapper,
  Value,
} from "../style";
import FeeInfo from "./FeeInfo";
import { PriceInput, SizeInput } from "./form";
import { OrderProtectionTitle, OrderProtectionWrapper } from "./style";
import { useFarm } from "../../../hooks/api/farm/useFarm";
import TradeFormFarmingTooltip from "../../shared/TradeFormFarmingTooltip";
import {
  calculateEstimatedTradeRewards,
  calculateFarmBoostMultiplier,
} from "../../../utils/math";
import { ExtraFarmBoostDetails } from "../ExtraFarmBoostDetails";
import { useEmissions } from "../../../hooks/api/emissions/useEmissions";
import { ReactComponent as AevoLogo } from "../../../assets/logo/logo_white.svg";

export interface ITradeModalProps {
  selectedInstrument?: IOptionMarket;
  mobileMode?: boolean;
  onClose?: () => void;
}

function OptionsTradeForm({
  selectedInstrument,
  mobileMode,
  onClose,
}: PropsWithChildren<ITradeModalProps>) {
  const { t: apiError } = useTranslation("apiErrors");
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const { data: farmData, mutate: mutateFarmData } = useFarm();
  const { market } = useContext(MarketContext);
  const { data: emissionsData } = useEmissions();
  const { t: commonFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.Common",
  });
  const { t: optionsTradeFormTranslations } = useTranslation("app", {
    keyPrefix: "TradeForm.OptionsTradeForm.OptionsTradeForm",
  });
  const { addToast, addErrorToast } = useToast();
  const { accountSigningKeyState } = useContext(AuthContext);
  const { orderbook: orderbookData } = useOrderbookWSS(
    selectedInstrument?.instrument_name
  );
  const { index } = useIndexWSS(selectedInstrument?.underlying_asset);

  const prevOrderbookInstrument = usePrevious(orderbookData?.instrument_name);
  const animControls = useAnimation();

  const [reduceOnly, setReduceOnly] = useState(false);
  const [orderSide, setOrderSide] = useState<SideResponse>(SideResponse.Buy);
  const [orderType, setOrderType] = useState<OrderTypeResponse>(
    OrderTypeResponse.Market
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false);

  const modalRef = useRef<HTMLDivElement>(null);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (modalRef.current?.clientHeight) {
        setIsSticky(modalRef.current?.clientHeight < 800);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize, { passive: true });

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    control,
    trigger,
    reset,
  } = useForm({
    mode: "onChange",
  });
  const amount = useWatch({ control, name: "amount" });
  const price = useWatch({ control, name: "price" });

  const { data: orderData, createOrder } = useOrder();
  const {
    data: accountData,
    showOnboarding,
    isLoading: accountDataLoading,
  } = useGetAccount();
  const { positionsMap } = usePositionsWSS();
  const { deleteRFQsByInstrumentId } = useRFQ(undefined, false);

  const { data: marginData } = useMarginRequirements(
    // Only sell have margin requirements
    orderSide === SideResponse.Sell &&
      amount &&
      selectedInstrument?.instrument_id
      ? {
          is_buy: false,
          limit_price: String(Math.round(Number(price) || 0)),
          amount,
          instrument_id: Number(selectedInstrument.instrument_id),
        }
      : undefined
  );

  const { instrumentTicker } = useTickersWSS(
    market.asset,
    market.derivative,
    selectedInstrument ? Number(selectedInstrument.expiry) : undefined
  );
  const ticker = selectedInstrument
    ? instrumentTicker[selectedInstrument.instrument_name]
    : undefined;

  // Also trigger updates whenever price is changed
  useEffect(() => {
    if (price && amount) {
      trigger("amount");
      trigger("price");
    }
  }, [price, amount, trigger, marginData]);

  // Update whenever reduce only checkbox is changed
  useEffect(() => {
    if (isDirty) {
      trigger("amount");
    }
  }, [reduceOnly, orderSide, trigger, isDirty, orderType]);

  const contractPriceStep = useMemo(
    () => getContractPriceStep(selectedInstrument),
    [selectedInstrument]
  );

  const buySellSegmentProps = useMemo(() => {
    const config: ISegmentedControlConfig = {
      theme: "outline",
      color:
        orderSide === SideResponse.Sell
          ? COLORS.negative.one
          : COLORS.positive.one,
      widthType: "fullWidth",
      backgroundColor: BACKGROUND_COLORS.eight,
      activeBackgroundColor:
        orderSide === SideResponse.Sell
          ? COLORS.negative.five
          : COLORS.positive.five,
      borderRadius: "10px",
      button: {
        height: 40,
        fontSize: FONT_SIZE.two,
      },
    };
    return {
      config,
      segments: [
        {
          value: String(SideResponse.Buy),
          display: commonFormTranslations("buy"),
          textColor:
            orderSide === SideResponse.Buy
              ? COLORS.positive.one
              : TEXT_COLORS.three,
        },
        {
          value: String(SideResponse.Sell),
          display: commonFormTranslations("sell"),
          textColor:
            orderSide === SideResponse.Sell
              ? COLORS.negative.one
              : TEXT_COLORS.three,
        },
      ],
    };
  }, [orderSide, commonFormTranslations]);

  const limitMarketSegmentProps = useMemo(() => {
    const config: ISegmentedControlConfig = {
      widthType: "fullWidth",
      backgroundColor: LAYER_COLORS.two,
      activeBackgroundColor: LAYER_COLORS.three,
      borderRadius: "10px",
      button: {
        height: 32,
        fontSize: FONT_SIZE.one,
      },
    };
    return {
      config,
      segments: [
        {
          value: OrderTypeResponse.Market,
          display: commonFormTranslations("market"),
          textColor:
            orderType === OrderTypeResponse.Market
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        },
        {
          value: OrderTypeResponse.Limit,
          display: commonFormTranslations("limit"),
          textColor:
            orderType === OrderTypeResponse.Limit
              ? TEXT_COLORS.one
              : TEXT_COLORS.three,
        },
      ],
    };
  }, [orderType, commonFormTranslations]);

  const currentPosition = useMemo(
    () =>
      selectedInstrument
        ? positionsMap[selectedInstrument.instrument_name]
        : undefined,
    [positionsMap, selectedInstrument]
  );

  // Utility callback to use when verifying input
  const calculateTotalValueWithSize = useCallback(
    (amountStr: string) => {
      let total = 0;
      if (orderType === OrderTypeResponse.Market) {
        total = getValueOfContractsAmount(
          orderSide,
          orderSide === SideResponse.Buy
            ? (orderbookData?.asks as IPriceSize[]) || []
            : (orderbookData?.bids as IPriceSize[]) || [],
          Number(amountStr)
        );
      } else {
        total = amountStr ? Number(amountStr) * Number(price) : total;
      }
      return total;
    },
    [orderSide, orderType, orderbookData?.asks, orderbookData?.bids, price]
  );

  // Total value in USD
  const totalValue = useMemo(
    () => calculateTotalValueWithSize(amount),
    [calculateTotalValueWithSize, amount]
  );

  const orderValueTooSmall = useMemo(() => {
    if (errors?.amount?.type === FormValidatorKeysEnum.orderValueTooSmall) {
      return true;
    }
    return false;
  }, [errors?.amount?.type]);

  const notEnoughBalanceError = useMemo(() => {
    if (errors?.amount?.type === FormValidatorKeysEnum.notEnoughBalance) {
      return true;
    }
    return false;
  }, [errors?.amount?.type]);

  const tradeInfo = useMemo<ITradeInfo[]>(() => {
    const totalInfo: ITradeInfo = {
      title: optionsTradeFormTranslations("total"),
      value: totalValue ? currency(totalValue).format() : "---",
      warningOrError:
        notEnoughBalanceError || orderValueTooSmall ? "error" : undefined,
    };

    const premiumsInfo: ITradeInfo = {
      title: optionsTradeFormTranslations("premiums_earned"),
      value: totalValue ? currency(totalValue).format() : "---",
    };

    const marginRequiredInfo: ITradeInfo = {
      title: optionsTradeFormTranslations("margin_required"),
      value: marginData?.initial_margin
        ? currency(marginData?.initial_margin).format()
        : "---",
      warningOrError:
        notEnoughBalanceError || orderValueTooSmall ? "error" : undefined,
    };

    // ================ MARKET ORDERS ================
    if (orderType === OrderTypeResponse.Market) {
      // MARKET BUY/SELL ORDER
      if (orderSide === SideResponse.Buy) {
        return [totalInfo];
      }
      return [premiumsInfo, marginRequiredInfo];
    }

    // ================ LIMIT ORDERS ================
    if (orderSide === SideResponse.Buy) {
      // LIMIT BUY ORDER
      return [totalInfo];
    }
    // LIMIT SELL ORDER
    return [premiumsInfo, marginRequiredInfo];
  }, [
    optionsTradeFormTranslations,
    orderType,
    orderSide,
    totalValue,
    notEnoughBalanceError,
    marginData,
    orderValueTooSmall,
  ]);

  const bottomTradeInfo = useMemo<ITradeInfo[]>(() => {
    const notionalVolume = Number(amount) * Number(index?.price || 0);
    const totalVolume =
      farmData && notionalVolume
        ? Number(farmData.trailing_volume || 0) + notionalVolume
        : notionalVolume;
    const farmBoostAfterTrade = calculateFarmBoostMultiplier(totalVolume);
    const boostedVolume = notionalVolume * farmBoostAfterTrade;
    const estimatedRewards = calculateEstimatedTradeRewards(
      boostedVolume,
      emissionsData
    );
    const farmBoost = farmData?.farm_boost;
    const boostIncrease =
      farmBoost && totalValue && totalVolume
        ? calculateFarmBoostMultiplier(totalVolume) - Number(farmBoost)
        : 0;
    const boostIncreasePercentage =
      farmBoost && boostIncrease
        ? String((boostIncrease / Number(farmBoost)) * 100)
        : undefined;
    return [
      {
        key: "estimated_rewards",
        title: (
          <BoostTitle>
            <div>{optionsTradeFormTranslations("est_rewards")}</div>
            <TradeFormFarmingTooltip
              farmData={farmData}
              farmBoostAfterTrade={farmBoostAfterTrade}
              boostIncreasePercentage={boostIncreasePercentage}
              estimatedRewards={estimatedRewards}
            />
          </BoostTitle>
        ),
        value: (
          <BoostValueWrapper>
            {estimatedRewards > 0 && <AevoLogo width={16} height={16} />}
            {estimatedRewards
              ? currency(estimatedRewards, {
                  symbol: "",
                }).format()
              : "---"}
          </BoostValueWrapper>
        ),
      },
    ];
  }, [
    amount,
    emissionsData,
    farmData,
    index?.price,
    optionsTradeFormTranslations,
    totalValue,
  ]);

  // Reduce only is disabled if ordertype is limit
  const reduceOnlyDisabled = orderType === OrderTypeResponse.Limit;

  // Reduce only is not allowed if theres no position, or position === orderDirection
  const reduceOnlyNotAllowed = useMemo(
    () =>
      !reduceOnlyDisabled &&
      (!currentPosition || currentPosition.side === orderSide),
    [currentPosition, orderSide, reduceOnlyDisabled]
  );

  const positionInfo = useMemo<IPositionInfo[]>(() => {
    // Options available balance is ONLY using USDC available balance
    const optionsAvailableBalance = accountData?.collaterals?.find(
      (c) => c.collateral_asset === CollateralAssetResponse.Usdc
    )?.available_balance;

    const balanceInfo: IPositionInfo =
      orderSide === SideResponse.Buy
        ? {
            title: optionsTradeFormTranslations("available_balance"),
            value:
              accountDataLoading || !optionsAvailableBalance
                ? "---"
                : currency(optionsAvailableBalance).format(),
            side: undefined,
            warningOrError: notEnoughBalanceError ? "error" : undefined,
            showErrorIcon: true,
          }
        : {
            title: commonFormTranslations("margin_balance"),
            value: accountData?.available_balance
              ? currency(accountData.available_balance).format()
              : "---",
            side: undefined,
            warningOrError: notEnoughBalanceError ? "error" : undefined,
            showErrorIcon: true,
          };

    const posInfo: IPositionInfo[] = [
      balanceInfo,
      {
        title: optionsTradeFormTranslations("current_position"),
        value: currentPosition
          ? Number(currentPosition.amount).toFixed(
              contractPriceStep.amount_precision
            )
          : "---",
        side: currentPosition?.side,
      },
    ];

    if (currentPosition) {
      const roi =
        Number(currentPosition.unrealized_pnl) /
        (Number(currentPosition.avg_entry_price) *
          Number(currentPosition.amount));
      posInfo.push({
        title: optionsTradeFormTranslations("position_roi"),
        value: currentPosition ? (
          <span
            style={{
              color:
                Number(currentPosition.unrealized_pnl) >= 0
                  ? COLORS.positive.one
                  : COLORS.negative.one,
            }}
          >
            {roi >= 0 ? "+" : ""}
            {(roi * 100).toFixed(2)}%
          </span>
        ) : (
          "---"
        ),
      });
    }

    return posInfo;
  }, [
    commonFormTranslations,
    optionsTradeFormTranslations,
    accountDataLoading,
    accountData?.collaterals,
    accountData?.available_balance,
    contractPriceStep.amount_precision,
    notEnoughBalanceError,
    currentPosition,
    orderSide,
  ]);

  const onClosePositionModalHide = useCallback(() => {
    if (currentPosition)
      deleteRFQsByInstrumentId(currentPosition.instrument_id);
    setShowCloseModal(false);
  }, [currentPosition, deleteRFQsByInstrumentId]);

  // Validates available liquidity for market buy/sell
  const insufficientMarketLiquidityWarning = useMemo(() => {
    if (!selectedInstrument || orderType === OrderTypeResponse.Limit) {
      return undefined;
    }

    // if buy, check asks liquidity
    let insufficientLiquidity = false;
    if (orderSide === SideResponse.Buy) {
      const totalAsksSize =
        orderbookData?.asks?.reduce((prev, ask) => {
          const askSize = Number(ask[1] || 0);
          return prev + askSize;
        }, 0) || 0;
      if (totalAsksSize < parseFloat(amount)) {
        insufficientLiquidity = true;
      }
    }

    // if sell, check bids liquidity
    if (orderSide === SideResponse.Sell) {
      const totalBidsSize =
        orderbookData?.bids?.reduce((prev, ask) => {
          const bidSize = Number(ask[1] || 0);
          return prev + bidSize;
        }, 0) || 0;
      if (totalBidsSize < parseFloat(amount)) {
        insufficientLiquidity = true;
      }
    }

    if (insufficientLiquidity) {
      return optionsTradeFormTranslations("insufficient_liquidity");
    }
    return undefined;
  }, [
    optionsTradeFormTranslations,
    selectedInstrument,
    orderType,
    orderSide,
    orderbookData?.asks,
    orderbookData?.bids,
    amount,
  ]);

  const inactiveSubmitButtonState = useMemo(
    () => (
      <Button
        fullWidth
        type="button"
        buttonTheme={ButtonThemeEnum.HIGHLIGHT}
        onClick={() => setShowConnectModal(true)}
      >
        {accountSigningKeyState === AccountStateEnum.REQUIRE_PASSWORD
          ? commonFormTranslations("unlock_trading")
          : accountSigningKeyState === AccountStateEnum.OK
          ? commonFormTranslations("continue_onboarding")
          : accountSigningKeyState === AccountStateEnum.REQUIRE_CONNECT
          ? commonFormTranslations("connect_wallet")
          : commonFormTranslations("complete_sign_in")}
      </Button>
    ),
    [accountSigningKeyState, commonFormTranslations, setShowConnectModal]
  );

  // Update default price for limit order
  useEffect(() => {
    // We're comparing the current value and the previous one to prevent the UI from
    // resetting the price/amount values in between renders
    if (
      orderbookData?.instrument_name &&
      prevOrderbookInstrument !== orderbookData?.instrument_name
    ) {
      // Update default values for the selected instrument
      let defaultPrice = "0";
      defaultPrice =
        orderSide === SideResponse.Buy
          ? orderbookData?.bids?.[0]?.[0] || "0"
          : orderbookData?.asks?.[0]?.[0] || "0";

      if (Number(defaultPrice)) {
        setValue("price", defaultPrice);
      } else {
        setValue("price", "");
      }
    }
  }, [
    orderSide,
    orderbookData?.asks,
    orderbookData?.bids,
    orderbookData?.instrument_name,
    prevOrderbookInstrument,
    setValue,
  ]);

  const showOrderCreatedAnimation = useCallback(async () => {
    await animControls.start({
      opacity: 1,
      scale: 1,
      transition: { duration: 0.6 },
    });
    await animControls.start({
      opacity: 0,
      scale: 0,
      transition: {
        delay: 3,
        duration: 0.5,
      },
    });
  }, [animControls]);

  const submitOrder = useCallback(async () => {
    if (selectedInstrument && Number(amount)) {
      const order: ICreateOrderBody =
        orderType === OrderTypeResponse.Market
          ? {
              amount,
              side: orderSide,
              instrument: Number(selectedInstrument.instrument_id),
              orderType: OrderTypeResponse.Market,
              stop: undefined,
              trigger: undefined,
              reduceOnly,
            }
          : {
              amount,
              side: orderSide,
              instrument: Number(selectedInstrument.instrument_id),
              orderType: OrderTypeResponse.Limit,
              price: String(price),
              stop: undefined,
              trigger: undefined,
            };

      const toastInterval = 4000;

      try {
        setIsLoading(true);
        const response = await createOrder(order);

        if (response.order_id) {
          // Only show immediate toast if is market order.
          // Else just show order placed
          if (orderType === OrderTypeResponse.Market) {
            addToast(
              {
                type: ToastEnum.INFO,
                icon: getAssetLogo(market.asset) as string,
                header: (
                  <p>
                    {getToastTitleForInstrument(
                      InstrumentTypeResponse.Option,
                      selectedInstrument.instrument_name,
                      Number(selectedInstrument.expiry),
                      selectedInstrument.strike
                    )}
                  </p>
                ),
                subheader:
                  orderSide === SideResponse.Buy ? (
                    <span style={{ color: COLORS.positive.one }}>
                      {optionsTradeFormTranslations("market_buy")}
                    </span>
                  ) : (
                    <span style={{ color: COLORS.negative.one }}>
                      {optionsTradeFormTranslations("market_sell")}
                    </span>
                  ),
                stats: [
                  {
                    label:
                      orderSide === SideResponse.Buy
                        ? commonFormTranslations("buy_amount")
                        : commonFormTranslations("sell_amount"),
                    value: (
                      <span
                        style={{
                          color:
                            orderSide === SideResponse.Buy
                              ? COLORS.positive.one
                              : COLORS.negative.one,
                        }}
                      >
                        {Number(response.filled).toFixed(
                          contractPriceStep.amount_precision
                        ) || "0.00"}
                      </span>
                    ),
                  },
                  {
                    label: optionsTradeFormTranslations("avg_price"),
                    value: currency(response.avg_price || 0).format(),
                  },
                ],
                status: ToastStatusEnum.SUCCESS,
              },
              toastInterval
            );
          } else {
            addToast(
              {
                type: ToastEnum.INFO,
                icon: getAssetLogo(market.asset) as string,
                header: (
                  <p>
                    {getToastTitleForInstrument(
                      InstrumentTypeResponse.Option,
                      selectedInstrument.instrument_name,
                      Number(selectedInstrument.expiry),
                      selectedInstrument.strike
                    )}
                  </p>
                ),
                subheader:
                  orderSide === SideResponse.Buy ? (
                    <span style={{ color: COLORS.positive.one }}>
                      {optionsTradeFormTranslations("bid_placed")}
                    </span>
                  ) : (
                    <span style={{ color: COLORS.negative.one }}>
                      {optionsTradeFormTranslations("offer_placed")}
                    </span>
                  ),
                stats: [
                  {
                    label: commonFormTranslations("contracts"),
                    value: Number(order.amount).toFixed(
                      contractPriceStep.amount_precision
                    ),
                  },
                  {
                    label: commonFormTranslations("limit_price"),
                    value: currency(order.price || 0).format(),
                  },
                ],
                status: ToastStatusEnum.SUCCESS,
              },
              toastInterval
            );
          }
          showOrderCreatedAnimation();
          mutateFarmData();
          // Reset form
          reset();
          onClose?.();
        }
      } catch (error: any) {
        let errorTitle = "";
        if (orderType === OrderTypeResponse.Market) {
          errorTitle =
            orderSide === SideResponse.Buy
              ? commonFormTranslations("market_buy_failed")
              : commonFormTranslations("market_sell_failed");
        } else {
          errorTitle =
            orderSide === SideResponse.Buy
              ? commonFormTranslations("limit_buy_failed")
              : commonFormTranslations("limit_sell_failed");
        }
        addErrorToast(
          <p>{errorTitle}</p>,
          apiError(error.message) || commonFormTranslations("place_order_again")
        );
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    selectedInstrument,
    amount,
    orderType,
    orderSide,
    reduceOnly,
    price,
    createOrder,
    showOrderCreatedAnimation,
    mutateFarmData,
    reset,
    onClose,
    addToast,
    market.asset,
    optionsTradeFormTranslations,
    commonFormTranslations,
    contractPriceStep.amount_precision,
    addErrorToast,
    apiError,
  ]);

  const onRowClick = useCallback(
    (p: string, size: string, side: SideResponse) => {
      setOrderSide(side);
      setValue("amount", size, { shouldValidate: true });
      setValue("price", p, { shouldValidate: true });

      // When the price is adjusted, modal scrolls back to the top
      if (modalRef.current) {
        modalRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    [setValue]
  );

  const onSetOrderSide = useCallback(
    (value: string) => setOrderSide(value as SideResponse),
    []
  );
  const onSetOrderType = useCallback(
    (value: string) => setOrderType(value as OrderTypeResponse),
    []
  );

  const verifyOrderValueTooSmall = useCallback(
    (contractSize: string) => {
      if (orderType === OrderTypeResponse.Limit && price && contractSize) {
        return (
          Number(contractSize || 0) * Number(price || 0) >= MIN_ORDER_VALUE
        );
      }
      return true;
    },
    [orderType, price]
  );

  const verifyNotEnoughBalance = useCallback(
    (size: string) => {
      if (
        currentPosition &&
        currentPosition.side !== orderSide &&
        Number(currentPosition.amount) >= Number(size)
      ) {
        return true;
      }

      if (orderSide === SideResponse.Sell && marginData) {
        return (
          Number(marginData.initial_margin) <=
          Number(accountData?.available_balance || 0)
        );
      }
      return accountData && size
        ? Number(accountData.balance) >= calculateTotalValueWithSize(size)
        : true;
    },
    [
      accountData,
      calculateTotalValueWithSize,
      currentPosition,
      marginData,
      orderSide,
    ]
  );

  const maxOrderSizeBeforeOBProtectionTriggered =
    orderType === OrderTypeResponse.Market &&
    market.derivative !== InstrumentTypeResponse.Perpetual &&
    !insufficientMarketLiquidityWarning
      ? maxOrderSizeForMarkPriceCollar(
          orderSide,
          orderbookData?.bids || [],
          orderbookData?.asks || [],
          Number(ticker?.mark?.price || 0),
          Number(index?.price || 0),
          MARK_PRICE_COLLAR,
          INDEX_PRICE_COLLAR_OPTIONS
        )
      : Number.MAX_VALUE;

  const feeStructure = accountData?.fee_structures?.find(
    (f) => f.asset === market.asset && f.instrument_type === market.derivative
  );

  const showExtraFarmBoostDetails = useMemo(() => {
    if (!farmData?.last_rare_farm_boost) {
      return false;
    }

    // Adding 1 hour to last rare boost in milliseconds
    const oneHourAfterLastRare =
      Number(farmData?.last_rare_farm_boost) * 1e3 + 3600000;

    return Date.now() > oneHourAfterLastRare;
  }, [farmData?.last_rare_farm_boost]);

  return (
    <ContentWrapper ref={modalRef} isMobile={mobileMode} style={{ bottom: 0 }}>
      <TradeForm onSubmit={handleSubmit(submitOrder)}>
        {showCloseModal && (
          <ClosePositionModal
            position={currentPosition}
            onHide={() => onClosePositionModalHide()}
            show={showCloseModal}
          />
        )}
        <FormContent>
          <Padding>
            <OptionFilter />
            <TransactionTypeWrapper>
              <SegmentedControl
                segments={buySellSegmentProps.segments}
                value={String(orderSide)}
                onSelect={onSetOrderSide}
                config={buySellSegmentProps.config}
              />
            </TransactionTypeWrapper>

            <OrderTypeWrapper>
              <SegmentedControl
                segments={limitMarketSegmentProps.segments}
                value={String(orderType)}
                onSelect={onSetOrderType}
                config={limitMarketSegmentProps.config}
              />
            </OrderTypeWrapper>
          </Padding>
          <DetailsWrapper>
            {!reduceOnlyDisabled && (
              <DeselectReduceOnlyContainer
                show={reduceOnlyNotAllowed && reduceOnly}
              >
                <div>{commonFormTranslations("deselect_reduce_only")}</div>
                <div>
                  {commonFormTranslations("reduce_only_error_desc_1", {
                    orderDirection: commonFormTranslations(
                      orderSide.toLowerCase()
                    ),
                    orderType: commonFormTranslations(orderType),
                  })}
                  <strong>
                    {currentPosition
                      ? currentPosition?.side === SideResponse.Buy
                        ? commonFormTranslations(
                            "reduce_only_error_desc_2_open_position_long"
                          )
                        : commonFormTranslations(
                            "reduce_only_error_desc_2_open_position_short"
                          )
                      : commonFormTranslations(
                          "reduce_only_error_desc_2_no_position"
                        )}
                  </strong>
                  {commonFormTranslations("reduce_only_error_desc_3_and")}
                  <strong>
                    {commonFormTranslations(
                      "reduce_only_error_desc_4_reduce_only"
                    )}
                  </strong>
                  {commonFormTranslations("reduce_only_error_desc_5")}
                </div>
              </DeselectReduceOnlyContainer>
            )}
            <InputsWrapper>
              {
                // Only shows price input if limit order.
                orderType === OrderTypeResponse.Limit && (
                  <PriceInput
                    minPrice={contractPriceStep.price_step}
                    register={register("price", {
                      disabled: isLoading,
                      required: true,
                      validate: {
                        [FormValidatorKeysEnum.moreThanZero]: (v) =>
                          parseFloat(v) > 0,
                        [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                          roundToStepSize(
                            v,
                            contractPriceStep.price_step,
                            contractPriceStep.price_precision
                          ) >=
                          1 / 10 ** contractPriceStep.price_precision,
                      },
                      onBlur(event) {
                        setValue(
                          "price",
                          roundToStepSize(
                            parseFloat(event.target.value),
                            contractPriceStep.price_step,
                            contractPriceStep.price_precision
                          ).toString()
                        );
                      },
                    })}
                    errors={errors}
                  />
                )
              }
              <SizeInput
                minAmountSize={contractPriceStep.amount_step || 0}
                register={register("amount", {
                  disabled: isLoading,
                  required: true,
                  validate: {
                    [FormValidatorKeysEnum.moreThanZero]: (v) =>
                      parseFloat(v) > 0,
                    [FormValidatorKeysEnum.notEnoughBalance]: (v) =>
                      verifyNotEnoughBalance(v),
                    [FormValidatorKeysEnum.orderValueTooSmall]: (v) =>
                      verifyOrderValueTooSmall(v),
                    [FormValidatorKeysEnum.decimalsTooSmall]: (v) =>
                      roundToStepSize(
                        v,
                        contractPriceStep.amount_step,
                        contractPriceStep.amount_precision
                      ) >=
                      1 / 10 ** contractPriceStep.amount_precision,
                    [FormValidatorKeysEnum.reduceOnlyOrderSizeValid]: (v) => {
                      if (!reduceOnly || reduceOnlyDisabled) {
                        return true;
                      }
                      return currentPosition
                        ? Number(v || 0) <= Number(currentPosition.amount)
                        : true;
                    },
                  },
                  onBlur(event) {
                    setValue(
                      "amount",
                      roundToStepSize(
                        parseFloat(event.target.value),
                        contractPriceStep.amount_step,
                        contractPriceStep.amount_precision
                      ).toString()
                    );
                  },
                })}
                style={{ paddingBottom: 0 }}
                errors={errors}
                warning={
                  Number(amount) <= maxOrderSizeBeforeOBProtectionTriggered
                    ? insufficientMarketLiquidityWarning
                    : undefined
                }
              />
            </InputsWrapper>
            {!reduceOnlyDisabled && (
              <ReduceOnlyInput
                reduceOnly={reduceOnly}
                onToggle={() => setReduceOnly(!reduceOnly)}
              />
            )}
            {Number(amount) > maxOrderSizeBeforeOBProtectionTriggered ? (
              <OrderProtectionWrapper>
                <div>
                  <OrderProtectionTitle>
                    {optionsTradeFormTranslations("order_protection_triggered")}
                    <Alert />
                  </OrderProtectionTitle>
                </div>
                <p>
                  {/* Protection has been triggered because this order will fill{" "}
                        {orderSide === SideResponse.Buy ? "an offer" : "a bid"} that is
                        outside the order protection barrier. Please{" "} */}
                  {orderSide === SideResponse.Buy
                    ? optionsTradeFormTranslations("order_protection_desc_bid")
                    : optionsTradeFormTranslations(
                        "order_protection_desc_offer"
                      )}
                  {maxOrderSizeBeforeOBProtectionTriggered > 0 ? (
                    <>
                      <span>
                        {optionsTradeFormTranslations(
                          "order_protection_desc_reduce_1"
                        )}
                      </span>
                      {optionsTradeFormTranslations(
                        "order_protection_desc_reduce_2"
                      )}
                      <span>
                        {optionsTradeFormTranslations(
                          "order_protection_desc_reduce_3",
                          {
                            amount:
                              maxOrderSizeBeforeOBProtectionTriggered.toFixed(
                                contractPriceStep.amount_precision
                              ),
                          }
                        )}
                      </span>
                      {optionsTradeFormTranslations(
                        "order_protection_desc_reduce_4"
                      )}
                    </>
                  ) : (
                    <span>
                      {orderSide === SideResponse.Buy
                        ? optionsTradeFormTranslations(
                            "order_protection_desc_try_limit_order_bid"
                          )
                        : optionsTradeFormTranslations(
                            "order_protection_desc_try_limit_order_ask"
                          )}
                    </span>
                  )}
                </p>
              </OrderProtectionWrapper>
            ) : (
              <TradeInfoWrapper>
                {tradeInfo.map((info) => (
                  <InfoRow
                    key={`${info.title}-${info.value}}`}
                    warningOrError={info.warningOrError}
                  >
                    <Title>
                      {info.title}
                      {Boolean(info.warningOrError && info.showErrorIcon) && (
                        <Alert
                          style={{
                            stroke:
                              info.warningOrError === "warning"
                                ? COLORS.system.one
                                : COLORS.negative.one,
                            marginLeft: `${SPACING.one}px`,
                            marginTop: `-${SPACING.one / 2}px`,
                          }}
                        />
                      )}
                    </Title>
                    <Value>{info.value}</Value>
                  </InfoRow>
                ))}

                {/* If market order, show fees */}
                {orderType === OrderTypeResponse.Market && (
                  <FeeInfo
                    asset={market.asset}
                    size={Number(amount || 0)}
                    feeStructure={Number(feeStructure?.taker_fee || 0)}
                  />
                )}
              </TradeInfoWrapper>
            )}
            <PositionInfoWrapper>
              {positionInfo.map((info) => (
                <InfoRow
                  key={`${info.title}-${info.value}}`}
                  warningOrError={info.warningOrError}
                >
                  <Title>
                    {info.title}
                    {Boolean(info.warningOrError && info.showErrorIcon) && (
                      <Alert
                        style={{
                          stroke:
                            info.warningOrError === "warning"
                              ? COLORS.system.one
                              : COLORS.negative.one,
                          marginLeft: `${SPACING.one}px`,
                          marginTop: `-${SPACING.one / 2}px`,
                        }}
                      />
                    )}
                    {info.side && (
                      <PositionChip type={info.side}>
                        {info.side === SideResponse.Buy
                          ? optionsTradeFormTranslations("long")
                          : optionsTradeFormTranslations("short")}
                      </PositionChip>
                    )}
                  </Title>
                  <Value>{info.value}</Value>
                </InfoRow>
              ))}
            </PositionInfoWrapper>
            <TradeInfoWrapper>
              {bottomTradeInfo.map((info) => (
                <InfoRow
                  key={info.key ?? `${info.title}-${info.value}`}
                  warningOrError={info.warningOrError}
                  marginBottom={SPACING.two}
                >
                  <Title>
                    {info.title}
                    {Boolean(info.warningOrError && info.showErrorIcon) && (
                      <Alert
                        style={{
                          stroke:
                            info.warningOrError === "warning"
                              ? COLORS.system.one
                              : COLORS.negative.one,
                          marginLeft: `${SPACING.one}px`,
                          marginTop: `-${SPACING.one / 2}px`,
                        }}
                      />
                    )}
                  </Title>
                  <Value>{info.value}</Value>
                </InfoRow>
              ))}
            </TradeInfoWrapper>
            {showExtraFarmBoostDetails && (
              <ExtraFarmBoostDetails staked={!!farmData?.staked} />
            )}
          </DetailsWrapper>
        </FormContent>
        <SubmitWrapper isSticky={isSticky}>
          {accountSigningKeyState === AccountStateEnum.OK && !showOnboarding ? (
            <PlaceOrderButton
              disabled={
                Object.values(errors).length > 0 ||
                !amount ||
                !selectedInstrument ||
                accountData?.in_liquidation ||
                Number(amount) > maxOrderSizeBeforeOBProtectionTriggered ||
                isLoading ||
                Boolean(
                  !reduceOnlyDisabled && reduceOnlyNotAllowed && reduceOnly
                )
              }
              type={"submit"}
              side={orderSide}
            >
              {!isLoading ? (
                optionsTradeFormTranslations("place_order", {
                  side: commonFormTranslations(orderSide.toLowerCase()),
                })
              ) : (
                <Spinner
                  color={
                    orderSide === SideResponse.Buy
                      ? COLORS.positive.one
                      : COLORS.negative.one
                  }
                />
              )}
            </PlaceOrderButton>
          ) : (
            inactiveSubmitButtonState
          )}
          <TradeExecutedBar
            type={orderSide}
            animate={animControls}
            initial={{
              opacity: 0,
              scale: 0,
            }}
          />
        </SubmitWrapper>
        <TicketDetails
          indexPriceCollar={INDEX_PRICE_COLLAR_OPTIONS}
          asset={market.asset}
          selectedInstrument={selectedInstrument}
          orderDirection={orderSide}
          averagePremium={
            Number.isNaN(totalValue / Number(amount))
              ? 0
              : totalValue / Number(amount)
          }
          totalSize={Number(amount)}
          showPlaceholder={!selectedInstrument}
          orderData={orderData}
          orderbookData={orderbookData}
          ticker={ticker}
          onOrderbookRowClick={onRowClick}
          mobileMode={mobileMode}
          indexPrice={Number(index?.price || 0)}
        />
      </TradeForm>
    </ContentWrapper>
  );
}

export default OptionsTradeForm;

import { useEffect, useState } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { SocketHelper, SocketHelper__factory } from "../../codegen";
import { ChainIdEnum } from "../../enums/chain";
import { MULTICHAIN_CONTRACT_ADDRESSES } from "../../constants/addresses";

export const getSocketHelperContract = (
  provider?: any,
  chainId?:
    | ChainIdEnum.OPTIMISM
    | ChainIdEnum.OPTIMISM_TESTNET
    | ChainIdEnum.ARBITRUM
    | ChainIdEnum.ARBITRUM_TESTNET
): SocketHelper | undefined => {
  const signerOrProvider = provider?.getSigner() || provider;
  if (signerOrProvider && chainId) {
    const chainAddresses = MULTICHAIN_CONTRACT_ADDRESSES[chainId];
    if (chainAddresses?.socketHelper) {
      return SocketHelper__factory.connect(
        chainAddresses.socketHelper,
        signerOrProvider
      );
    }
  }

  return undefined;
};

interface ISocketHelperProps {
  provider?: Web3Provider;
  chainId?: number;
}

const useSocketHelper = ({ provider, chainId }: ISocketHelperProps) => {
  const [socketHelper, setSocketHelper] = useState<SocketHelper>();

  useEffect(() => {
    setSocketHelper(getSocketHelperContract(provider, chainId));
  }, [chainId, provider]);

  return {
    socketHelper,
  };
};

export default useSocketHelper;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { close as Close } from "../../../assets/styledSvg/close";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE, FONT_STYLE } from "../../../constants/design/fontSize";
import { PageEndpointEnum } from "../../../enums/endpoint";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import ExternalLinkText from "../../ExternalLinkText";
import { LinkText } from "../Header/style";
import {
  AbsoluteCloseButton,
  BannerContent,
  BannerText,
  BannerWrapper,
  Indicator,
} from "./style";

export type AnnouncementType = "alert" | "feature";

interface IAnnouncementProp {
  announcementType: AnnouncementType;
}

function Announcement({ announcementType }: IAnnouncementProp) {
  const { isMobileScreen } = useScreenSize();
  const navigate = useNavigate();

  const announcementActionTitle =
    announcementType === "alert"
      ? process.env.REACT_APP_ALERT_ACTION_TITLE || ""
      : process.env.REACT_APP_ANNOUNCEMENT_ACTION_TITLE || "";
  const announcementKey =
    announcementType === "alert"
      ? process.env.REACT_APP_ALERT_KEY || ""
      : process.env.REACT_APP_ANNOUNCEMENT_KEY || "";
  const announcementLink =
    announcementType === "alert"
      ? process.env.REACT_APP_ALERT_LINK || ""
      : process.env.REACT_APP_ANNOUNCEMENT_LINK || "";
  const announcementTitle =
    announcementType === "alert"
      ? process.env.REACT_APP_ALERT_TITLE || ""
      : process.env.REACT_APP_ANNOUNCEMENT_TITLE || "";
  const dismissable =
    announcementType === "alert"
      ? process.env.REACT_APP_ALERT_DISMISSABLE === "true" || false
      : process.env.REACT_APP_ANNOUNCEMENT_DISMISSABLE === "true" || false;

  const [hideAnnouncement, setHideAnnouncement] = useState<boolean>(false);

  useEffect(() => {
    setHideAnnouncement(
      announcementKey === "" || Boolean(localStorage.getItem(announcementKey))
    );
  }, [announcementKey]);

  const setHideCallback = useCallback(() => {
    localStorage.setItem(announcementKey, "true");
    setHideAnnouncement(true);
  }, [announcementKey]);

  // change this callback when the announcement type changes
  const onAnnouncementActionClick = useCallback(() => {
    setHideCallback();
    navigate(PageEndpointEnum.TRADING);
  }, [navigate, setHideCallback]);

  const textColor =
    announcementType === "alert" ? COLORS.blue.one : TEXT_COLORS.one;

  const banner = useMemo(
    () => (
      <BannerWrapper type={announcementType}>
        <BannerContent isMobile={isMobileScreen}>
          <Indicator type={announcementType} />
          <BannerText type={announcementType}>{announcementTitle}</BannerText>
          {announcementLink ? (
            <ExternalLinkText
              color={textColor}
              hoverColor={textColor}
              wrapperStyle={{
                textDecoration: "underline",
                textDecorationColor: textColor,
                lineHeight: FONT_STYLE.body.five.lineHeight,
                fontSize: FONT_STYLE.body.five.fontSize,
              }}
              href={announcementLink}
              target="_blank"
              rel="noreferrer"
              onClick={onAnnouncementActionClick}
              hideArrow
            >
              {announcementActionTitle}
            </ExternalLinkText>
          ) : (
            <LinkText
              style={{
                color: textColor,
                textDecoration: "underline",
                fontSize: FONT_SIZE.one,
              }}
              onClick={onAnnouncementActionClick}
            >
              {announcementActionTitle}
            </LinkText>
          )}
        </BannerContent>
        {dismissable && (
          <AbsoluteCloseButton role="button" onClick={setHideCallback}>
            <Close color={textColor} />
          </AbsoluteCloseButton>
        )}
      </BannerWrapper>
    ),
    [
      announcementActionTitle,
      announcementLink,
      announcementTitle,
      announcementType,
      dismissable,
      isMobileScreen,
      onAnnouncementActionClick,
      setHideCallback,
      textColor,
    ]
  );

  if (hideAnnouncement) {
    return null;
  }

  return banner;
}

export default Announcement;

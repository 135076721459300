import { useCallback, useMemo, useRef } from "react";
import moment from "moment";
import {
  AccordionButton,
  AccordionWrapper,
  DateSelectionWrapper,
  MiniScrim,
  PaginationButtons,
  PositionOrderIndicator,
  PrevNextButton,
} from "./style";
import { ISelectOption, Select } from "../../shared/Select";
import { SPACING } from "../../../constants/design/spacing";
import { Chevron } from "../../shared/Chevron/style";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import { nanosToSeconds } from "../../../utils/date";
import useElementSize from "../../../hooks/elementSize/useElementSize";

interface IDateSelectionProps {
  // Nanos
  expiries: number[];
  selectedExpiry?: number;
  expiriesWithPosition?: string[];
  expiriesWithOrder?: string[];
  onSelectExpiry: (expiryDate: number) => void;
}

function DateSelection({
  expiries,
  selectedExpiry,
  onSelectExpiry,
  expiriesWithPosition,
  expiriesWithOrder,
}: IDateSelectionProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const accordionRef = useRef<HTMLDivElement>(null);
  const { width } = useElementSize(containerRef);

  const { isMediumScreen } = useScreenSize();

  const expiryDatesSelect = useMemo(() => {
    let updatedExpiryDates: ISelectOption[] = [];
    updatedExpiryDates = expiries.map((expiry) => {
      const prefixes: JSX.Element[] = [];

      if (expiriesWithPosition?.includes(String(expiry))) {
        prefixes.push(
          <PositionOrderIndicator type="position" key="position" />
        );
      }
      if (expiriesWithOrder?.includes(String(expiry))) {
        prefixes.push(<PositionOrderIndicator type="order" key="order" />);
      }

      return {
        label: moment.unix(nanosToSeconds(expiry)).format("DD MMM YY"),
        isActive: expiry === selectedExpiry,
        isHighlighted: !!prefixes.length,
        onClick: () => onSelectExpiry(expiry),
        prefixes,
      };
    });
    return updatedExpiryDates;
  }, [
    expiries,
    expiriesWithPosition,
    expiriesWithOrder,
    selectedExpiry,
    onSelectExpiry,
  ]);

  const isOverflowing =
    (accordionRef.current?.scrollWidth || 0) >
    (accordionRef.current?.clientWidth || 0);
  const scrollLeft = accordionRef.current?.scrollLeft || 0;
  const maxScrollLeft =
    (accordionRef.current?.scrollWidth || 0) -
    (accordionRef.current?.clientWidth || 0);

  const rangeButtonsDisabled = useMemo(
    () => scrollLeft <= 1 && scrollLeft >= maxScrollLeft - 1,
    [maxScrollLeft, scrollLeft]
  );

  const onScroll = useCallback(
    (side: "left" | "right") => {
      accordionRef.current?.scrollBy({
        left: side === "left" ? -width : width,
        behavior: "smooth",
      });
    },
    [width]
  );

  const scrollLeftDisabled = useMemo(() => scrollLeft <= 1, [scrollLeft]);
  const scrollRightDisabled = useMemo(
    () => scrollLeft >= maxScrollLeft - 1,
    [maxScrollLeft, scrollLeft]
  );

  return (
    <DateSelectionWrapper ref={containerRef}>
      {!isMediumScreen && isOverflowing && (
        <AccordionButton onClick={() => onScroll("left")} side="left">
          <Chevron direction="left" />
        </AccordionButton>
      )}
      <AccordionWrapper
        ref={accordionRef}
        mobileMode={isMediumScreen}
        padding={isOverflowing ? `0 ${SPACING.two}px 0 0` : "0"}
      >
        <Select
          isRound
          options={expiryDatesSelect}
          wrapperStyle={{
            height: "100%",
          }}
        />
      </AccordionWrapper>
      {
        // eslint-disable-next-line no-nested-ternary
        !isMediumScreen && isOverflowing ? (
          <AccordionButton onClick={() => onScroll("right")} side="right">
            <Chevron direction="right" />
          </AccordionButton>
        ) : isOverflowing ? (
          <>
            {!scrollLeftDisabled && <MiniScrim align="left" />}
            {!scrollRightDisabled && <MiniScrim align="right" />}
          </>
        ) : undefined
      }
      {isMediumScreen && !rangeButtonsDisabled && (
        <PaginationButtons>
          <PrevNextButton
            disabled={scrollLeftDisabled}
            onClick={() => onScroll("left")}
          >
            <Chevron direction="left" />
          </PrevNextButton>
          <PrevNextButton
            disabled={scrollRightDisabled}
            onClick={() => onScroll("right")}
          >
            <Chevron direction="right" />
          </PrevNextButton>
        </PaginationButtons>
      )}
    </DateSelectionWrapper>
  );
}

export default DateSelection;

export enum ChainEnum {
  NotSelected = "Unknown",
  Ethereum = "Ethereum",
  SepoliaTestnet = "Sepolia Testnet",
  AevoLocalnet = "Aevo Localnet",
  Optimism = "Optimism",
  Arbitrum = "Arbitrum",
  OptimismTestnet = "Optimism Sepolia",
  ArbitrumTestnet = "Arbitrum Sepolia",
}

// On dev, ETH_MAINNET is LOCAL
export enum ChainIdEnum {
  NONE = -1,
  ETH_MAINNET = 1,
  SEPOLIA_TESTNET = 11155111,
  LOCAL_TESTNET = 31337,
  OPTIMISM = 10,
  OPTIMISM_TESTNET = 11155420,
  ARBITRUM = 42161,
  ARBITRUM_TESTNET = 421614,
}

export const socketDepositChains = [
  ChainIdEnum.OPTIMISM,
  ChainIdEnum.OPTIMISM_TESTNET,
  ChainIdEnum.ARBITRUM,
  ChainIdEnum.ARBITRUM_TESTNET,
];

export const AllChainIdEnum: ChainIdEnum[] = Object.values(ChainIdEnum).filter(
  (v) => !Number.isNaN(Number(v))
) as ChainIdEnum[];

export enum AltChainIdEnum {
  GOERLI = 5,
  BINANCE = 56,
  POLYGON = 137,
  FANTOM = 250,
  BOBA = 288,
  MOONBEAM = 1284,
  EVMOS = 9001,
  CELO = 42220,
  OASIS = 42262,
  AVALANCHE = 43114,
  AURORA = 1313161554,
  HARMONY = 1666600000,
}

export const AllAltChainIdEnum: AltChainIdEnum[] = Object.values(
  AltChainIdEnum
).filter((v) => !Number.isNaN(Number(v))) as AltChainIdEnum[];

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Alert } from "../../../assets/svg/alert.svg";
import { COLORS, PRIMARY_COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { PORTFOLIO_MARGIN_MINIMUM_EQUITY } from "../../../constants/precision/margin";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import { useGetAccount } from "../../../hooks/api/account/useGetAccount";
import { shortenAddress } from "../../../utils/strings";
import ConnectWalletModal from "../../ConnectWalletModal";
import DepositModal from "../../DepositWithdrawModal/DepositModal";
import TransferModal from "../../DepositWithdrawModal/TransferModal";
import WithdrawModal from "../../DepositWithdrawModal/WithdrawModal";
import AccountModal from "../Header/AccountModal";
import {
  AddressWrapper,
  ButtonContent,
  Indicator,
  MarginButton,
  MarginButtonWrapper,
  SignInAddress,
  SignInContent,
  SignInContentDetails,
  SignInTitle,
} from "./style";
import { AccountDropdownStateEnum } from "./type";
import { isPWA } from "../../../utils/pwa";
import { useFarm } from "../../../hooks/api/farm/useFarm";
import FarmBoostBonusModal from "../../FarmBoostBonusModal";

interface IAccountDropdownProps {
  onSyncPWA?: () => void;
}

function AccountDropdown({ onSyncPWA }: IAccountDropdownProps) {
  const {
    accountApiKeyState,
    accountSigningKeyState,
    account,
    isAccountImported,
  } = useContext(AuthContext);
  const { showConnectModal, setShowConnectModal } =
    useContext(ConnectWalletContext);
  const navigate = useNavigate();

  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const { data: accountData } = useGetAccount();
  const { t } = useTranslation("app", { keyPrefix: "shared.AccountDropdown" });

  const accountDropdownState: AccountDropdownStateEnum = useMemo(() => {
    if (account) {
      return AccountDropdownStateEnum.WALLET_CONNECTED;
    }
    return AccountDropdownStateEnum.WALLET_DISCONNECTED;
  }, [account]);

  const buttonContent = useMemo(() => {
    switch (accountDropdownState) {
      case AccountDropdownStateEnum.WALLET_CONNECTED:
        return accountApiKeyState !== AccountStateEnum.OK ||
          accountSigningKeyState !== AccountStateEnum.OK ? (
          <SignInContent
            inLiquidation={accountData?.in_liquidation}
            onClick={() => setShowConnectModal(true)}
          >
            <SignInContentDetails>
              <SignInTitle>{t("complete_sign_in")}</SignInTitle>
              <SignInAddress inLiquidation={accountData?.in_liquidation}>
                {shortenAddress(account || "")}
              </SignInAddress>
            </SignInContentDetails>
          </SignInContent>
        ) : (
          <ButtonContent
            onClick={() => setShowAccountModal(true)}
            tradingEnabled={accountSigningKeyState === AccountStateEnum.OK}
          >
            {accountSigningKeyState === AccountStateEnum.OK ? (
              <Indicator
                color={
                  accountData?.in_liquidation
                    ? COLORS.negative.one
                    : COLORS.highlight.one
                }
              />
            ) : (
              <Alert
                style={{ marginRight: `${SPACING.two}px` }}
                stroke={PRIMARY_COLORS.one}
              />
            )}
            {isPWA && isAccountImported
              ? t("sync_active")
              : shortenAddress(account || "")}
          </ButtonContent>
        );
      default:
        return (
          <ButtonContent
            state="connectWallet"
            onClick={() => setShowConnectModal(true)}
          >
            {t("connect_wallet")}
          </ButtonContent>
        );
    }
  }, [
    isAccountImported,
    account,
    accountApiKeyState,
    accountData?.in_liquidation,
    accountDropdownState,
    accountSigningKeyState,
    setShowConnectModal,
    t,
  ]);

  const onHideModal = useCallback(
    () => setShowConnectModal(false),
    [setShowConnectModal]
  );

  const { data: farmData, mutate, setFarmBoost } = useFarm();

  const [showFarmBoostBonus, setShowFarmBoostBonus] = useState<boolean>(false);
  const [hasShownModal, setHasShownModal] = useState(false);
  const [farmBoostClaimed, setFarmBoostClaimed] = useState(false);

  useEffect(() => {
    if (
      farmData?.random_farm_boost_eligible &&
      !hasShownModal &&
      (showAccountModal || showDepositModal || showConnectModal)
    ) {
      setShowFarmBoostBonus(true);
      setHasShownModal(true);
    }
  }, [
    farmData?.random_farm_boost_eligible,
    hasShownModal,
    showAccountModal,
    showConnectModal,
    showDepositModal,
    showFarmBoostBonus,
  ]);

  const onHide = useCallback(() => {
    setShowFarmBoostBonus(false);
    if (farmBoostClaimed) {
      navigate("/aevo");
      setShowAccountModal(false);
      setShowDepositModal(false);
      setShowConnectModal(false);
    }
  }, [farmBoostClaimed, navigate, setShowConnectModal]);

  if (showFarmBoostBonus) {
    return (
      <FarmBoostBonusModal
        show={showFarmBoostBonus}
        onHide={onHide}
        mutate={mutate}
        setFarmBoost={setFarmBoost}
        setFarmBoostClaimed={setFarmBoostClaimed}
      />
    );
  }

  return (
    <>
      {showConnectModal && (
        <ConnectWalletModal show={showConnectModal} onHide={onHideModal} />
      )}
      {Number(accountData?.equity) >= PORTFOLIO_MARGIN_MINIMUM_EQUITY ? (
        <MarginButtonWrapper>
          <MarginButton
            onClick={() => navigate("/settings/portfolio-margin")}
            isActive={accountData?.portfolio}
          >
            {t("pm")}
          </MarginButton>
        </MarginButtonWrapper>
      ) : null}
      <AddressWrapper
        accountDropdownState={accountDropdownState}
        tradingEnabled={accountSigningKeyState === AccountStateEnum.OK}
      >
        {buttonContent}
        {account && (
          <>
            {showAccountModal && (
              <AccountModal
                show={showAccountModal}
                onSyncPWA={onSyncPWA}
                onHide={() => setShowAccountModal(false)}
                onDepositClick={() => setShowDepositModal(true)}
                onWithdrawClick={() => setShowWithdrawModal(true)}
                onTransferClick={() => setShowTransferModal(true)}
                onContinueOnboarding={() => setShowConnectModal(true)}
              />
            )}
            {showDepositModal && (
              <DepositModal
                show={showDepositModal}
                onHide={() => {
                  setShowDepositModal((prev) => {
                    if (prev) {
                      setShowAccountModal(true);
                    }
                    return false;
                  });
                  setShowAccountModal(false);
                }}
              />
            )}
            {showWithdrawModal && (
              <WithdrawModal
                show={showWithdrawModal}
                accountData={accountData}
                onHide={() => {
                  setShowWithdrawModal((prev) => {
                    if (prev) {
                      setShowAccountModal(true);
                    }
                    return false;
                  });
                }}
              />
            )}
            {showTransferModal && (
              <TransferModal
                show={showTransferModal}
                accountData={accountData}
                onHide={() => {
                  setShowTransferModal((prev) => {
                    if (prev) {
                      setShowAccountModal(true);
                    }
                    return false;
                  });
                }}
              />
            )}
          </>
        )}
      </AddressWrapper>
    </>
  );
}

export default AccountDropdown;

import { Suspense, lazy } from "react";
import { IPerpsTradingHeaderProps } from "./PerpsTradingHeader";
import { TradingHeaderWrapper } from "./style";

const PerpsTradingHeader = lazy(() => import("./PerpsTradingHeader"));

function LazyPerpsTradingHeader({
  perpInstrument,
  isMobileScreen,
}: IPerpsTradingHeaderProps) {
  return (
    <Suspense
      fallback={<TradingHeaderWrapper isMobileScreen={isMobileScreen} />}
    >
      <PerpsTradingHeader
        isMobileScreen={isMobileScreen}
        perpInstrument={perpInstrument}
      />
    </Suspense>
  );
}

export default LazyPerpsTradingHeader;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as AevoLogo } from "../../../assets/logo/logo_white.svg";
import { ReactComponent as Bell } from "../../../assets/svg/bell.svg";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import { SPACING } from "../../../constants/design/spacing";
import { AccountStateEnum, AuthContext } from "../../../contexts/AuthContext";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { PageEndpointEnum } from "../../../enums/endpoint";
import useOutsideAlerter from "../../../hooks/outsideAlerter/useOutsideAlerter";
import { usePWAFlow } from "../../../hooks/usePWAFlow";
import { isPWA } from "../../../utils/pwa";
import { Button, ButtonThemeEnum } from "../../Buttons/styles";
import { SettingsModal } from "../../SettingsModal";
import AccountDropdown from "../AccountDropdown";
import AboutAevoModal from "../Header/AboutAevoModal";
import MobileMaintenanceInProgress from "../Header/MobileMaintenanceInProgress";
import { BellWrapper, MobileButton, MobileMenuButton } from "../Header/style";
import MobileNavigationMenu from "../MobileNavigationMenu";
import { NotificationPanel } from "../NotificationPanel";
import MobilePWAModal from "../PWAFlowModal/MobilePWAModal";
import {
  LeftHeaderWrapper,
  MainHeaderWrapper,
  RightHeaderWrapper,
} from "./style";

/**
 * The BeforeInstallPromptEvent is fired at the Window.onbeforeinstallprompt handler
 * before a user is prompted to "install" a web site to a home screen on mobile.
 * Only supported on Chrome and Android Webview.
 */
interface IBeforeInstallPromptEvent extends Event {
  /**
   * Returns an array of DOMString items containing the platforms on which the event was dispatched.
   * This is provided for user agents that want to present a choice of versions to the user such as,
   * for example, "web" or "play" which would allow the user to chose between a web version or
   * an Android version.
   */
  readonly platforms: Array<string>;

  /**
   * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
   */
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;

  /**
   * Allows a developer to show the install prompt at a time of their own choosing.
   * This method returns a Promise.
   */
  prompt(): Promise<void>;
}

export function MobileHeader() {
  const { t } = useTranslation("app", { keyPrefix: "MobileHeader" });

  const [showAboutAevoModal, setShowAboutAevoModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showPWAFlow, setShowPWAFlow] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] =
    useState<IBeforeInstallPromptEvent>();

  const { pwaInfo } = usePWAFlow();

  const { accountApiKeyState } = useContext(AuthContext);

  // NOTIFICATIONS
  const { showPanel, setShowPanel } = useContext(NotificationContext);
  const modalRef = useRef(null);
  const notifButtonRef = useRef(null);

  const onHide = useCallback(() => {
    setShowPanel(false);
  }, [setShowPanel]);

  useOutsideAlerter([modalRef, notifButtonRef], onHide);

  // Auto shows pwa flow if pwaInfo is available
  useEffect(() => {
    setShowPWAFlow(!!pwaInfo);
  }, [pwaInfo]);

  useEffect(() => {
    const handleBeforeInstallPrompt: EventListener = (e: Event) => {
      const installPromptEvent = e as IBeforeInstallPromptEvent;
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(installPromptEvent);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = useCallback(() => {
    // Show the install prompt
    deferredPrompt?.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt?.userChoice.then(() => {
      setDeferredPrompt(undefined);
    });
  }, [deferredPrompt]);

  const onTriggerPanel = useCallback(() => {
    setShowPanel(!showPanel);
  }, [setShowPanel, showPanel]);

  const onShowPWAFlow = useCallback(() => {
    setShowPWAFlow(true);
  }, []);

  // If android, just show download pwa prompt
  const onDownloadPWA = useCallback(() => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    if (isAndroid && deferredPrompt) {
      handleInstallClick();
    } else {
      onShowPWAFlow();
    }
  }, [deferredPrompt, handleInstallClick, onShowPWAFlow]);

  return (
    <>
      <MobileNavigationMenu
        open={showMobileNav}
        onClose={() => setShowMobileNav(false)}
      />
      <SettingsModal
        show={showSettingsModal}
        onHide={() => setShowSettingsModal(false)}
        showOptionsChainSettings={false}
      />
      <MobilePWAModal
        show={showPWAFlow}
        onHide={() => {
          setShowPWAFlow(false);
        }}
      />
      <div style={{ overflow: "hidden" }}>
        <NotificationPanel
          isMobile
          modalRef={modalRef}
          onClose={() => setShowPanel(false)}
        />
      </div>

      <MainHeaderWrapper>
        <AboutAevoModal
          show={showAboutAevoModal}
          onHide={() => setShowAboutAevoModal(false)}
        />

        <LeftHeaderWrapper>
          <MobileMenuButton
            onClick={() => setShowMobileNav(true)}
            style={{ marginRight: SPACING.two }}
          />
          <Link to={PageEndpointEnum.TRADING}>
            <AevoLogo height={32} width={32} />
          </Link>
        </LeftHeaderWrapper>
        <RightHeaderWrapper>
          <MobileButton
            type="button"
            onClick={() => onTriggerPanel()}
            ref={notifButtonRef}
          >
            <BellWrapper>{showPanel ? <Close /> : <Bell />}</BellWrapper>
          </MobileButton>
          <AccountDropdown />
          {accountApiKeyState !== AccountStateEnum.OK &&
            (isPWA ? (
              <Button
                buttonTheme={ButtonThemeEnum.HIGHLIGHT}
                onClick={onShowPWAFlow}
                style={{ minHeight: 40, maxHeight: 40, fontSize: "12px" }}
              >
                {t("sync_with_desktop")}
              </Button>
            ) : (
              <Button
                buttonTheme={ButtonThemeEnum.PRIMARY}
                onClick={onDownloadPWA}
                style={{ minHeight: 40, maxHeight: 40, fontSize: "12px" }}
              >
                {t("download_pwa")}
              </Button>
            ))}
        </RightHeaderWrapper>
      </MainHeaderWrapper>
      <MobileMaintenanceInProgress />
    </>
  );
}

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseModal } from "../BaseModal";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { OFACWrapper } from "./style";
import { LocalStorageKeyEnum } from "../../enums/localStorage";
import { ModalKeyEnum } from "../../portal";
import { isPWA } from "../../utils/pwa";
import { usePWAFlow } from "../../hooks/usePWAFlow";

function OFACModal() {
  const [termsAccepted, setTermsAccepted] = useState<boolean>(true);
  const { t } = useTranslation("app", { keyPrefix: "OFACModal" });

  const { pwaInfo } = usePWAFlow();

  // Initial check to see whether terms have already been accepted
  useEffect(() => {
    const ofac = window.localStorage.getItem(LocalStorageKeyEnum.OFAC);
    setTermsAccepted(Boolean(ofac));
  }, []);

  const setTerms = useCallback(() => {
    window.localStorage.setItem(LocalStorageKeyEnum.OFAC, "true");
    setTermsAccepted(true);
  }, [setTermsAccepted]);

  if (pwaInfo && isPWA && termsAccepted) {
    return null;
  }

  return (
    <BaseModal
      id={ModalKeyEnum.OFAC}
      show={!termsAccepted}
      title={t("title")}
      style={{ width: 311 }}
    >
      <OFACWrapper>
        <p>{t("ofac_desc_1")}:</p>
        <ul>
          <li>{t("ofac_desc_2")}</li>
          <li>{t("ofac_desc_3")}</li>
        </ul>
        <Button
          fullWidth
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={setTerms}
        >
          {t("continue")}
        </Button>
      </OFACWrapper>
    </BaseModal>
  );
}

export default OFACModal;

import currency from "currency.js";
import { CSSProperties } from "styled-components";
import { useTranslation } from "react-i18next";
import { GetIndexHistory200Response } from "../../codegen-api";
import { COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { usePriceChangeColor } from "../../hooks/usePriceChangeColor";
import PriceChart from "../PriceChart";
import { LabelText, SublabelText } from "../shared/Label/style";
import { PriceChartWrapper, PriceOnlyLabel, PriceWrapper, SpotChartWrapper, SpotWrapper } from "./style";
import { AssetResponse } from "../../utils/asset";

interface IPriceProps {
  currentPrice?: number;
  priceChartData?: GetIndexHistory200Response;
  asset?: AssetResponse;
  type?: "default" | "priceOnly" | "raw";
  containerStyle?: CSSProperties;
}

function Price({
  currentPrice,
  priceChartData,
  asset,
  type,
  containerStyle
}: IPriceProps) {
  const { t } = useTranslation("app", { keyPrefix: "TradingHeader.Price" });
  const color = usePriceChangeColor(
    COLORS.negative.one,
    COLORS.positive.one,
    currentPrice,
    TEXT_COLORS.one,
    type === "raw" ? 0 : 300
  );

  if (type === "raw") {
    return (
      <span style={{ color }}>
        {currentPrice ? currency(currentPrice).format() : "---"}
      </span>
    );
  }

  const getContent = () => {
    switch (type) {
      case "priceOnly":
        return (
          <PriceOnlyLabel color={color}>
            {currentPrice ? currency(currentPrice).format() : "---"}
          </PriceOnlyLabel>
        );
      default:
        return (
          <SpotChartWrapper>
            <SpotWrapper>
              <SublabelText>{asset} {t("spot")}</SublabelText>
              <LabelText color={color}>
                {currentPrice ? currency(currentPrice).format() : "---"}
              </LabelText>
            </SpotWrapper>
            <PriceChartWrapper>
              <PriceChart
                timestampPriceData={(priceChartData?.history || []).slice().reverse()}
                chartStyle={"mini"}
                height={24}
                width={50}
              />
            </PriceChartWrapper>
          </SpotChartWrapper>
        );
    }
  };

  return (
    <PriceWrapper style={containerStyle}>
      {getContent()}
    </PriceWrapper>
  );
}

export default Price;

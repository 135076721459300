import { TFunction } from "i18next";
import {
  OrderStatusResponse,
  OrderTypeResponse,
  SideResponse,
  Stop,
  TradeStatusResponse,
} from "../codegen-api";

export const getTradeStatusTitle = (
  side: SideResponse,
  status?: TradeStatusResponse
) => {
  const type = side === SideResponse.Buy ? "Bid" : "Offer";
  let statusText = "";

  if (status) {
    switch (status) {
      case OrderStatusResponse.Partial:
        statusText = "Partial Fill";
        break;
      case OrderStatusResponse.Filled:
        statusText = "Filled";
        break;
      default:
        break;
    }
  }
  return `${type} ${statusText}`;
};

export function getStopOrderName(
  t: TFunction,
  orderType: OrderTypeResponse,
  stop?: Stop,
  shortened?: boolean
): string {
  const translateKey = "app:utils:order";

  if (!stop) {
    switch (orderType) {
      case OrderTypeResponse.Limit:
        return t(`${translateKey}:limit`);
      case OrderTypeResponse.Market:
        return t(`${translateKey}:market`);
    }
  }

  switch (stop) {
    case Stop.StopLoss: {
      switch (orderType) {
        case OrderTypeResponse.Limit:
          return shortened
            ? t(`${translateKey}:sl_limit_short`)
            : t(`${translateKey}:sl_limit`);
        default:
          return shortened
            ? t(`${translateKey}:sl_market_short`)
            : t(`${translateKey}:sl_market`);
      }
    }
    default: {
      switch (orderType) {
        case OrderTypeResponse.Limit:
          return shortened
            ? t(`${translateKey}:tp_limit_short`)
            : t(`${translateKey}:tp_limit`);
        default:
          return shortened
            ? t(`${translateKey}:tp_market_short`)
            : t(`${translateKey}:tp_market`);
      }
    }
  }
}

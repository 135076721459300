// Sends a toast whenever there is a new fill

import currency from "currency.js";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetOrders200Response,
  InstrumentTypeResponse,
  SideResponse,
  TradeStatusResponse,
} from "../../codegen-api";
import { COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { useOrder } from "../../hooks/api/order/useOrder";
import { useToast } from "../../hooks/toast";
import { useSFX } from "../../hooks/useSFX";
import useFillsWSS from "../../hooks/wss/useFillsWSS";
import { IToast } from "../../interfaces/Toast";
import { getAssetLogo } from "../../utils/asset/assets";
import { getAssetFromSymbol } from "../../utils/instruments";
import { getTradeStatusTitle } from "../../utils/order";
import {
  ToastEnum,
  ToastStatusEnum,
  getToastTitleForInstrument,
} from "../../utils/toast";
import FillBar from "../FillMeter/FillBar";
import { TotalFillSize, TotalFilledBarContainer } from "../TradeForm/style";
import useRateLimit from "../../hooks/useRateLimit";

type ITradeIdToProcessed = {
  [id: string]: boolean;
};

// When 4 toasts is shown consecutively, rate limit for TOAST_LIMITED_WAIT_SECONDS seconds
const MAX_TOASTS_BEFORE_LIMITED = 4;
const TOAST_LIMITED_WAIT_SECONDS = 15;

function FillsToastAlerter() {
  const { addToasts } = useToast();
  const { t } = useTranslation("app", { keyPrefix: "FillsToastAlerter" });
  const ordersHook = useOrder();
  const { trades } = useFillsWSS();
  const [processedTradeIds, setProcessedTradeIds] =
    useState<ITradeIdToProcessed>({});
  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const { playSound } = useSFX();
  const { rateLimited, increaseCount } = useRateLimit(
    MAX_TOASTS_BEFORE_LIMITED,
    1000,
    TOAST_LIMITED_WAIT_SECONDS
  );

  useEffect(() => {
    const newFills =
      trades?.filter((f) => {
        const isMaker = f.liquidity === "maker";
        const isProcessed = processedTradeIds[f.trade_id];
        return isMaker && !isProcessed;
      }) || [];

    if (newFills.length) {
      const toasts: IToast[] = [];

      // Only show top 3 toast
      for (
        let i = 0;
        i < Math.min(newFills.length, MAX_TOASTS_BEFORE_LIMITED);
        i += 1
      ) {
        const fill = newFills[i];
        const {
          amount,
          order_id,
          instrument_name,
          price,
          side,
          trade_status,
          instrument_type,
        } = fill;

        const matchingOrder = ordersHook.data?.find(
          (o) => o.order_id === order_id
        );

        if (!matchingOrder) {
          return;
        }

        const totalFilled = Number(matchingOrder.filled) + Number(amount);
        const asset = getAssetFromSymbol(instrument_name);
        const optionType = matchingOrder.option_type;
        const exp = matchingOrder.expiry
          ? Number(matchingOrder.expiry)
          : undefined;
        const strike = matchingOrder.strike
          ? Number(matchingOrder.strike)
          : undefined;

        // Push toasts
        toasts.push({
          type: ToastEnum.INFO,
          icon: getAssetLogo(asset) || "",
          header: (
            <p>
              {getToastTitleForInstrument(
                optionType
                  ? InstrumentTypeResponse.Option
                  : InstrumentTypeResponse.Perpetual,
                instrument_name,
                exp,
                strike
              )}
            </p>
          ),
          subheader: (
            <span
              style={{
                color:
                  side === SideResponse.Buy
                    ? COLORS.positive.one
                    : COLORS.negative.one,
              }}
            >
              {getTradeStatusTitle(side, trade_status)}
            </span>
          ),
          stats: [
            {
              label: t("fill_amount"),
              value: (
                <span
                  style={{
                    color:
                      side === SideResponse.Sell
                        ? COLORS.negative.one
                        : COLORS.positive.one,
                  }}
                >
                  {amount}
                </span>
              ),
            },
            {
              label: t("limit_price"),
              value: price
                ? currency(price, {
                    precision: getMarketPrecision(asset, instrument_type)
                      .price_precision,
                  }).format()
                : "-",
            },
            {
              label: t("total_filled"),
              value: (
                <TotalFilledBarContainer>
                  <FillBar
                    percent={(totalFilled / Number(matchingOrder.amount)) * 100}
                    fillColor={
                      side === SideResponse.Buy
                        ? COLORS.positive.one
                        : COLORS.negative.one
                    }
                    style={{
                      marginRight: SPACING.two,
                    }}
                  />
                  <span>{totalFilled.toFixed(2)}</span>
                  <TotalFillSize>
                    &nbsp;/&nbsp;{Number(matchingOrder.amount).toFixed(2)}
                  </TotalFillSize>
                </TotalFilledBarContainer>
              ),
            },
          ],
          status: ToastStatusEnum.SUCCESS,
        });

        // Also play a sound for each fill
        playSound("order_filled");
      }

      // Show toasts if not rate limited
      if (!rateLimited) {
        increaseCount(toasts.length);
        addToasts(toasts, 4000);
      }

      const newOrders: GetOrders200Response[] | undefined =
        ordersHook.data?.reduce((prev, order) => {
          const matchingFill = newFills.find(
            (f) => f.order_id === order.order_id
          );

          if (matchingFill) {
            // Order status is filled, skip
            if (matchingFill.trade_status === TradeStatusResponse.Filled) {
              return prev;
            }

            // Order is fully filled. Skip
            const filled = Number(order.filled) + Number(matchingFill.amount);
            if (Number(order.amount) - filled <= 0) {
              return prev;
            }

            // Not filled, just update the fills
            return [
              ...prev,
              {
                ...order,
                filled: String(
                  Number(order.filled) + Number(matchingFill.amount)
                ),
              },
            ];
          }
          return [...prev, order];
        }, [] as GetOrders200Response[]);

      ordersHook.mutate(newOrders, { revalidate: false });

      setProcessedTradeIds((prev) => {
        const newProcessed = newFills.reduce(
          (prevFill, fill) => ({
            ...prevFill,
            [fill.trade_id]: true,
          }),
          {} as ITradeIdToProcessed
        );
        return {
          ...prev,
          ...newProcessed,
        };
      });
    }
  }, [
    addToasts,
    getMarketPrecision,
    increaseCount,
    ordersHook,
    playSound,
    processedTradeIds,
    rateLimited,
    t,
    trades,
  ]);

  return null;
}

export default FillsToastAlerter;

import { AxiosError } from "axios";
import useSWRImmutable from "swr/immutable";
import { GetOptionsChain200Response } from "../../../codegen-api";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { publicApi } from "../../../services/api/apiFetcher";
import { useLoggerMiddleware } from "../middleware/useLoggerMiddleware";
import { AssetResponse } from "../../../utils/asset";

export const useGetOptionsChain = (asset?: AssetResponse, expiry?: number) => {
  const fetcher = publicApi();

  return useSWRImmutable<GetOptionsChain200Response, AxiosError>(
    asset && expiry
      ? [APIEndpointEnum.OPTIONS_CHAIN, asset, expiry]
      : undefined,
    async () => (await (await fetcher.getOptionsChain(asset!, String(expiry!)))()).data,
    {
      use: [useLoggerMiddleware],
    },
  );
};

import currency from "currency.js";
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  GetAccount200ResponsePositionsInner,
  InstrumentTypeResponse,
  SideResponse,
} from "../../../../codegen-api";
import { COLORS } from "../../../../constants/design/colors";
import { SPACING } from "../../../../constants/design/spacing";
import { MarketInstrumentContext } from "../../../../contexts/MarketInstrumentContext";
import { useGetAccount } from "../../../../hooks/api/account/useGetAccount";
import { useGetAccumulatedFundings } from "../../../../hooks/api/account/useGetAccumulatedFundings";
import { useOrder } from "../../../../hooks/api/order/useOrder";
import { useToast } from "../../../../hooks/toast";
import { formatSizePrecision } from "../../../../utils/format";
import {
  calculatePnl,
  calculatePnlPercent,
  calculatePositionLeverage,
} from "../../../../utils/math";
import { getProfitTextColor } from "../../../../utils/strings";
import { ToastEnum, ToastStatusEnum } from "../../../../utils/toast";
import ShareButton from "../../../Buttons/ShareButton";
import { Button, ButtonThemeEnum } from "../../../Buttons/styles";
import { PnLCardModal } from "../../../PnLCardModal";
import { ClosePositionOverlayInfo } from "../../ClosePositionOverlayInfo";
import BaseDetailsModal from "../BaseDetailsModal";
import { ViewDetailsContent, ViewDetailsRow } from "../style";
import { UnrealizedPnlCard } from "./style";

interface IPositionDetailsModalProps {
  position?: GetAccount200ResponsePositionsInner;
  onHide?: () => void;
  style?: CSSProperties;
}

function PositionDetailsModal({
  position,
  onHide,
  style,
}: IPositionDetailsModalProps) {
  const { closePosition } = useOrder();
  const { addToast } = useToast();
  const { t } = useTranslation("app", {
    keyPrefix: "shared.ViewDetailsModal.PositionDetailsModal",
  });
  const { t: apiErrors } = useTranslation("apiErrors");
  const { data: accountData } = useGetAccount();
  const { data: accumulatedFundingData } = useGetAccumulatedFundings();
  const { getMarketPrecision } = useContext(MarketInstrumentContext);

  const [showCopied, setShowCopied] = useState(false);
  const [showClosePositionConfirmation, setShowClosePositionConfirmation] =
    useState(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [showPnLCard, setShowPnLCard] = useState<boolean>(false);

  useEffect(() => {
    setError(undefined);
  }, [showClosePositionConfirmation]);

  // Dismiss copied text after 1 second
  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => {
        setShowCopied(!showCopied);
      }, 500);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [showCopied]);

  const onOpenPositionConfirmationModal = useCallback(() => {
    setShowClosePositionConfirmation(true);
  }, []);

  const onClosePositionConfirmationModal = useCallback(() => {
    setShowClosePositionConfirmation(false);
  }, []);

  const onHideModal = useCallback(() => {
    onHide?.();
    setShowClosePositionConfirmation(false);
  }, [onHide]);

  const onOpenPnLCard = useCallback(() => {
    setShowPnLCard(true);
  }, []);

  const onHidePnLCard = useCallback(() => {
    setShowPnLCard(false);
  }, []);

  const onClosePosition = useCallback(async () => {
    if (!position) {
      return;
    }

    try {
      setError(undefined);
      setLoading(true);
      await closePosition(position);
      onHide?.();
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: "Position Closed",
          subheader: "Your position was successfully closed",
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    } catch (createError: any) {
      setError(apiErrors(createError.message) || t("error_close_position"));
    } finally {
      setLoading(false);
    }
  }, [addToast, apiErrors, closePosition, onHide, position, t]);

  const posInfo = useMemo(() => {
    if (!position) {
      return undefined;
    }
    const mark = Number(position.mark_price);
    const totalCurrentValue = mark * Number(position.amount);
    const totalEntryValue =
      Number(position.avg_entry_price) * Number(position.amount);
    const unrealizedPnl = calculatePnl(
      totalEntryValue,
      totalCurrentValue,
      position.side
    );

    const leverage = calculatePositionLeverage(
      Number(position.avg_entry_price),
      Number(position.amount),
      Number(accountData?.equity)
    );

    const profitPercent = calculatePnlPercent(
      unrealizedPnl,
      totalEntryValue,
      Number(position.maintenance_margin)
    );
    const accumulatedFundingRow =
      accumulatedFundingData && accumulatedFundingData.accumulated_fundings
        ? accumulatedFundingData.accumulated_fundings.find(
            (row) => row.instrument_id === position.instrument_id
          )
        : undefined;

    const accumulatedFunding =
      position.instrument_type === InstrumentTypeResponse.Perpetual
        ? Number(accumulatedFundingRow?.accumulated_funding || "0")
        : undefined;
    return {
      totalCurrentValue,
      unrealizedPnl,
      profitPercent,
      leverage,
      mark,
      accumulatedFunding,
    };
  }, [position, accountData?.equity, accumulatedFundingData]);

  const accountLeverage = useMemo(
    () =>
      accountData?.leverages?.find(
        (lev) => lev.instrument_id === position?.instrument_id
      )?.leverage,
    [accountData?.leverages, position?.instrument_id]
  );

  if (!showPnLCard) {
    return (
      <BaseDetailsModal
        instrument={
          position
            ? {
                instrument_name: position.instrument_name,
                expiry: position.option?.expiry,
                strike: position.option?.strike,
                markPrice: String(posInfo?.mark || 0),
              }
            : undefined
        }
        show={!!position}
        onHide={onHideModal}
        style={style}
      >
        {position && (
          <ClosePositionOverlayInfo
            show={showClosePositionConfirmation}
            onClose={onClosePositionConfirmationModal}
            loading={loading}
            error={error}
            onClosePosition={onClosePosition}
            position={position}
          />
        )}

        {position && (
          <ViewDetailsContent>
            <UnrealizedPnlCard pnl={posInfo?.unrealizedPnl || 0}>
              <span>{t("unrealized_pnl")}</span>
              <span>
                {posInfo?.unrealizedPnl
                  ? currency(posInfo?.unrealizedPnl).format()
                  : "-"}
              </span>
              <span>ROI</span>
              <span>
                {posInfo ? (
                  <>
                    {posInfo.profitPercent >= 0 ? "+" : ""}
                    {(posInfo.profitPercent * 100).toFixed(2)}%
                  </>
                ) : (
                  "-"
                )}
              </span>
            </UnrealizedPnlCard>
            <ViewDetailsRow>
              <span>{t("side")}</span>
              <span
                style={{
                  color:
                    position.side === SideResponse.Buy
                      ? COLORS.positive.one
                      : COLORS.negative.one,
                }}
              >
                {position.side === SideResponse.Buy ? t("long") : t("short")}
              </span>
            </ViewDetailsRow>
            <ViewDetailsRow>
              <span>{t("contracts")}</span>
              <span>{formatSizePrecision(position.amount)}</span>
            </ViewDetailsRow>
            <ViewDetailsRow>
              <span>{t("avg_entry_price")}</span>
              <span>
                {currency(position.avg_entry_price || 0, {
                  precision: getMarketPrecision(
                    position.asset,
                    position.instrument_type
                  ).price_precision,
                }).format()}
              </span>
            </ViewDetailsRow>
            <ViewDetailsRow>
              <span>{t("total")}</span>
              <span>
                {posInfo
                  ? currency(posInfo.totalCurrentValue, {
                      precision: getMarketPrecision(
                        position.asset,
                        position.instrument_type
                      ).price_precision,
                    }).format()
                  : "-"}
              </span>
            </ViewDetailsRow>
            <ViewDetailsRow>
              <span>{t("maintenance_margin")}</span>
              <span>{currency(position.maintenance_margin).format()}</span>
            </ViewDetailsRow>
            {!position.option ? (
              <ViewDetailsRow
                style={{
                  textTransform: "none",
                }}
              >
                <span>{t("leverage")}</span>
                <span>{accountLeverage}x</span>
              </ViewDetailsRow>
            ) : null}
            {position.liquidation_price ? (
              <ViewDetailsRow>
                <span>{t("liquidation_price")}</span>
                <span>
                  {currency(position.liquidation_price, {
                    precision: getMarketPrecision(
                      position.asset,
                      position.instrument_type
                    ).price_precision,
                  }).format()}
                </span>
              </ViewDetailsRow>
            ) : null}
            {posInfo?.accumulatedFunding ? (
              <ViewDetailsRow>
                <span>{t("accumulated_funding")}</span>
                <span
                  style={{
                    color: getProfitTextColor(posInfo.accumulatedFunding),
                  }}
                >
                  {posInfo.accumulatedFunding > 0 && "+"}
                  {currency(posInfo.accumulatedFunding).format()}
                </span>
              </ViewDetailsRow>
            ) : null}
            {position.option ? (
              <>
                <ViewDetailsRow>
                  <span>{t("delta")}</span>
                  <span>{Number(position.option.delta).toFixed(2)}</span>
                </ViewDetailsRow>
                <ViewDetailsRow>
                  <span>{t("theta")}</span>
                  <span>{currency(position.option.theta).format()}</span>
                </ViewDetailsRow>
                <ViewDetailsRow>
                  <span>{t("vega")}</span>
                  <span>{Number(position.option.vega).toFixed(2)}</span>
                </ViewDetailsRow>
                <ViewDetailsRow>
                  <span>{t("gamma")}</span>
                  <span>{Number(position.option.gamma).toFixed(6)}</span>
                </ViewDetailsRow>
              </>
            ) : null}
            {!accountData?.in_liquidation ? (
              <div style={{ display: "flex" }}>
                <Button
                  fullWidth
                  buttonTheme={ButtonThemeEnum.NEUTRAL2}
                  onClick={onOpenPositionConfirmationModal}
                  style={{
                    marginRight: SPACING.two,
                  }}
                >
                  {t("close_position")}
                </Button>
                <ShareButton onClick={onOpenPnLCard} />
              </div>
            ) : null}
          </ViewDetailsContent>
        )}
      </BaseDetailsModal>
    );
  }

  return (
    <PnLCardModal
      pnlType="position"
      pnlData={position}
      onHideModal={onHideModal}
      show={!!position}
      onHidePnLCard={onHidePnLCard}
    />
  );
}

export default PositionDetailsModal;

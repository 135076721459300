import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReadyState } from "react-use-websocket";
import {
  IWebsocketRateLimitedResponse,
  IWebsocketResponse,
} from "../../hooks/wss/model/shared";
import useSharedWebsocket from "../../hooks/wss/useSharedWebsocket";
import { AuthContext } from "../AuthContext";
import { IWSSOrderbookRequest } from "../../hooks/wss/model/orderbook";
import { jsonStringify, jsonParse } from "../../utils/strings";
import { IWSSTickerRequest } from "../../hooks/wss/model/ticker";
import useInternetReconnected from "../../hooks/useInternetReconnected";
import { nanosToMillis } from "../../utils/date";

export type ISubscribeIDMap = {
  [id: number]: string;
};

interface IWebsocketContextType {
  authenticated: boolean;

  // Check if the given data is already subscribed. If not, triggers the callback
  // Optional provide a channel string to unsubsribe to if exists
  triggerSubscribe: (
    op: string,
    data: string[],
    autoUnsubToChannelString?: string
  ) => void;

  lastMessages: MessageEvent<any>[] | null;
}

interface IWebsocketContextProviderProps {
  children: ReactElement;
}

interface IAuthResponse extends IWebsocketResponse {
  data?: {
    success: boolean;
    account?: string;
  };
}

export const WebsocketContext = React.createContext<IWebsocketContextType>({
  authenticated: false,
  triggerSubscribe: () => {},
  lastMessages: null,
});

export function WebsocketContextProvider({
  children,
}: IWebsocketContextProviderProps) {
  const { apiKey, apiSecret } = useContext(AuthContext);
  const { sendMessage, lastMessages, readyState } = useSharedWebsocket();

  // An incremental id will be assigned to each subscribes
  // Using ref because we never want these to trigger any side effects
  const id = useRef(0);
  const subbedIdMap = useRef<ISubscribeIDMap>({});
  const [authenticated, setAuthenticated] = useState(false);

  const resub = useCallback(() => {
    if (readyState === ReadyState.OPEN) {
      const subDatas = [...Object.values(subbedIdMap.current)];
      // Resub all the existing subs
      console.log("WS Reconnected! Resubbing to: ", subDatas);
      subbedIdMap.current = {};

      for (let i = 0; i < subDatas.length; i += 1) {
        const data = jsonParse(subDatas[i]);
        id.current += 1;

        // Subscribe
        const subMessage: IWSSTickerRequest = {
          op: "subscribe",
          data,
          id: id.current,
        };
        sendMessage(jsonStringify(subMessage));

        subbedIdMap.current = {
          ...subbedIdMap.current,
          [id.current]: jsonStringify(data),
        };
      }
    }
  }, [readyState, sendMessage]);

  // On reconnect, resub all existing subscriptions
  useInternetReconnected(resub);

  // Automatic auth whenever api key is available
  useEffect(() => {
    if (readyState !== ReadyState.OPEN || !apiKey || !apiSecret) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log("WS Opened, and apikey available. Attempting auth...");

    const op = "auth";
    const data = {
      key: apiKey,
      secret: apiSecret,
    };
    const authMessage = {
      op,
      data,
    };

    sendMessage(JSON.stringify(authMessage));
  }, [apiKey, apiSecret, readyState, sendMessage]);

  // Receives messages and updates auth state
  useEffect(() => {
    if (lastMessages) {
      lastMessages.forEach((lastMessage) => {
        // Update auth state
        const { data }: IAuthResponse = jsonParse(lastMessage.data);
        if (data && data.success && data.account) {
          setAuthenticated(true);
        }
      });
    }
  }, [lastMessages]);

  const triggerSubscribe = useCallback(
    (op: string, data: string[], autoUnsubToChannelString?: string) => {
      // Check if any existing channels exists
      // If not subbed, increment id and SUB
      const exists = Object.values(subbedIdMap.current).some(
        (v) => v === jsonStringify(data)
      );
      if (!exists && readyState === ReadyState.OPEN) {
        // If auto unsub is provided, automatically unsub before subbing
        if (autoUnsubToChannelString) {
          const existingId = Object.keys(subbedIdMap.current).find((key) =>
            subbedIdMap.current[Number(key)].includes(autoUnsubToChannelString)
          );
          const idNum = Number(existingId);
          if (idNum) {
            const unsubMessage: IWSSOrderbookRequest = {
              op: "unsubscribe",
              data: jsonParse(subbedIdMap.current[idNum]),
            };
            sendMessage(jsonStringify(unsubMessage));
            delete subbedIdMap.current[idNum];
          }
        }

        id.current += 1;

        // Subscribe
        const subMessage: IWSSTickerRequest = {
          op: "subscribe",
          data,
          id: id.current,
        };
        sendMessage(jsonStringify(subMessage));

        subbedIdMap.current = {
          ...subbedIdMap.current,
          [id.current]: jsonStringify(data),
        };
      }
    },
    [readyState, sendMessage]
  );

  // If any messages is a rate limit exceeded, retry after x seconds
  useEffect(() => {
    if (lastMessages) {
      for (let i = 0; i < lastMessages.length; i += 1) {
        const lastMessage = lastMessages[i];
        const {
          data,
          error,
          id: resubId,
        }: IWebsocketRateLimitedResponse = jsonParse(lastMessage.data);
        if (error === "RATE_LIMIT_EXCEEDED" && resubId) {
          const subMsg = subbedIdMap.current[resubId];
          // If existing sub id exists
          if (subMsg) {
            const retryMs = nanosToMillis(data.retry_after);

            // If failed, we delete subbed id map
            delete subbedIdMap.current[resubId];
            console.log("RATE_LIMITED", lastMessage.data, subMsg);

            // And then resub again after a duration
            setTimeout(() => {
              console.log("RETRYING", subMsg);
              triggerSubscribe("subscribe", jsonParse(subMsg));
            }, retryMs);
          }
        }
      }
    }
  }, [lastMessages, triggerSubscribe]);

  return (
    <WebsocketContext.Provider
      value={{
        authenticated,
        triggerSubscribe,
        lastMessages,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}

import currency from "currency.js";
import { useContext, useMemo } from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { InstrumentTypeResponse } from "./codegen-api";
import { LINKS } from "./constants/links";
import { MarketContext } from "./contexts/MarketContext";
import { MarketInstrumentContext } from "./contexts/MarketInstrumentContext";
import { PageEndpointEnum } from "./enums/endpoint";
import { pwaURLPrefix, usePWAFlow } from "./hooks/usePWAFlow";
import useMarkPriceWSS from "./hooks/wss/useMarkPriceWSS";
import defaultManifest from "./manifest";
import { startCase } from "./utils/strings";

const helmetData = new HelmetData({});

function Meta() {
  const { market } = useContext(MarketContext);
  const { getMarketPrecision } = useContext(MarketInstrumentContext);

  const { pwaInfo } = usePWAFlow();
  const location = useLocation();

  const currentPage: PageEndpointEnum = useMemo(() => {
    const { pathname } = location;
    const allPages = Object.keys(PageEndpointEnum);
    const isTrading = pathname
      .split("/")
      .some((name) =>
        Object.values(InstrumentTypeResponse).some(
          (t) => t.toLowerCase() === name
        )
      );
    if (isTrading) {
      return PageEndpointEnum.TRADING;
    }

    const matchedRouteKeys: string[] = allPages.filter((k) => {
      const route = PageEndpointEnum[k as keyof typeof PageEndpointEnum];

      const routePath = route.split("/")?.[1];
      const firstPathName = pathname.split("/")?.[1];

      return routePath === firstPathName;
    });

    // Get the last matched route
    if (matchedRouteKeys.length) {
      const lastMatchedKey = matchedRouteKeys[matchedRouteKeys.length - 1];
      return PageEndpointEnum[lastMatchedKey as keyof typeof PageEndpointEnum];
    }
    return PageEndpointEnum.TRADING;
  }, [location]);

  // Dynamic manifest url to allow redirecting to app
  const manifestUrl = useMemo(() => {
    // getting web manifest from constants and modifying values
    const myDynamicManifest = defaultManifest;
    const startUrl = LINKS.app;

    // modify icons to use absolute path
    const icons = myDynamicManifest.icons.map((v) => ({
      ...v,
      src: `${startUrl}${v.src}`,
    }));
    myDynamicManifest.icons = icons;

    // for PWA
    const pwaParams = pwaInfo ? `${pwaURLPrefix}${pwaInfo?.query}` : "";
    myDynamicManifest.start_url = `${startUrl}${pwaParams}`;
    myDynamicManifest.scope = startUrl;

    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    return url;
  }, [pwaInfo]);

  const uniqueTickers = useMemo(
    () => ({
      [`${market.asset}-PERP`]: {
        asset: market.asset,
        derivative: InstrumentTypeResponse.Perpetual,
      },
    }),
    [market.asset]
  );

  const { instrumentMark } = useMarkPriceWSS(uniqueTickers);
  const mark = instrumentMark[`${market.asset}-PERP`];

  const tags = {
    title: "Aevo",
    description:
      "The world's first high-performance decentralized options exchange.",
    keywords: "aevo",
    titleTemplate: "Home",
  };

  switch (currentPage) {
    case PageEndpointEnum.PWA:
    case PageEndpointEnum.TRADING: {
      if (mark?.mark_price) {
        tags.titleTemplate = `${currency(mark?.mark_price, {
          precision: getMarketPrecision(market.asset, market.derivative)
            .price_precision,
        }).format()} ${market.asset}-USD`;
      }
      break;
    }
    case PageEndpointEnum.PORTFOLIO:
      tags.titleTemplate = "Portfolio";
      break;
    case PageEndpointEnum.SETTINGS:
      tags.titleTemplate = "Settings";
      break;
    default: {
      const cleaned = currentPage.replace(/[-/]/g, " ");
      tags.titleTemplate = startCase(cleaned);
      break;
    }
  }

  tags.titleTemplate = `${tags.titleTemplate} • %s`;

  return (
    <Helmet
      helmetData={helmetData}
      titleTemplate={tags.titleTemplate}
      defer={false}
    >
      {/* Dynamically generates */}
      <link rel="manifest" href={manifestUrl} />
      <title>{tags.title}</title>
      <meta name="og_title" property="og:title" content={tags.title} />
      <meta
        name="twitter_title"
        property="twitter:title"
        content={tags.title}
      />
      <meta
        name="twitter:image"
        content={`https://${window.location.hostname}/assets/airdrop.jpg`}
      />
      <meta name="description" content={tags.description} />
      <meta name="head_keywords" property="keywords" content={tags.keywords} />
    </Helmet>
  );
}

export default Meta;

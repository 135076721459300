import currency from "currency.js";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { GetFarmBoost200Response } from "../../../codegen-api";
import { ExplanationWrapper, Stat, StatNumber, StatTitle } from "./style";
import TooltipExplanation from "../Tooltip";
import { TooltipWrapper } from "../Header/AccountModal/style";
import { ICON_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import { ReactComponent as AevoLogo } from "../../../assets/logo/logo_white.svg";
import ExternalLinkText from "../../ExternalLinkText";
import { SPACING } from "../../../constants/design/spacing";
import { BoostValueWrapper } from "../../TradeForm/style";

interface IFarmingRewardsTooltipProp {
  farmData?: GetFarmBoost200Response;
  farmBoostAfterTrade: number;
  boostIncreasePercentage?: string;
  estimatedRewards: number;
}

function TradeFormFarmingTooltip({
  farmData,
  farmBoostAfterTrade,
  boostIncreasePercentage,
  estimatedRewards,
}: IFarmingRewardsTooltipProp) {
  const { t } = useTranslation("app", {
    keyPrefix: "TradeFormFarmingTooltip",
  });

  const tooltipExplanation = useMemo(
    () => (
      <ExplanationWrapper>
        <span>{t("explanation")}</span>
        <Stat style={{ marginTop: SPACING.two }}>
          <StatTitle>{t("base_aevo_earned")}</StatTitle>
          <StatNumber>
            <AevoLogo width={16} height={16} />
            {farmData
              ? currency(estimatedRewards / farmBoostAfterTrade, {
                  symbol: "",
                }).format()
              : "-"}
          </StatNumber>
        </Stat>
        <Stat>
          <StatTitle>{t("farm_boost")}</StatTitle>
          <StatNumber>
            {farmData
              ? `${currency(farmBoostAfterTrade, {
                  symbol: "",
                }).format()}x `
              : "-"}
            {boostIncreasePercentage && (
              <BoostValueWrapper>{`+${Number(boostIncreasePercentage).toFixed(
                2
              )}%`}</BoostValueWrapper>
            )}
          </StatNumber>
        </Stat>
        <Stat>
          <StatTitle>{t("total_aevo_earned")}</StatTitle>
          <StatNumber>
            <AevoLogo width={16} height={16} />
            {farmData
              ? currency(estimatedRewards, {
                  symbol: "",
                }).format()
              : "-"}
          </StatNumber>
        </Stat>
        <ExternalLinkText
          href="https://docs.aevo.xyz/trading-and-staking-incentives/earning-usdaevo/trade-on-aevo"
          target="_blank"
          rel="noreferrer"
          color={TEXT_COLORS.two}
          wrapperStyle={{
            fontSize: "12px",
            alignSelf: "flex-start",
            marginTop: SPACING.two,
          }}
        >
          {t("learn_more")}
        </ExternalLinkText>
      </ExplanationWrapper>
    ),
    [
      boostIncreasePercentage,
      estimatedRewards,
      farmBoostAfterTrade,
      farmData,
      t,
    ]
  );

  return (
    <TooltipExplanation
      placement="top"
      title={t("title")}
      explanation={tooltipExplanation}
      renderContent={({ ref, ...triggerHandler }) => (
        <TooltipWrapper stroke={ICON_COLORS.two} ref={ref} {...triggerHandler}>
          <Info />
        </TooltipWrapper>
      )}
    />
  );
}

export default TradeFormFarmingTooltip;

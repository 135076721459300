import { CSSProperties } from "react";
import { ReactComponent as ChevronRight } from "../../assets/svg/chevron-outline.svg";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import { SPACING } from "../../constants/design/spacing";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { Container, Label, StepButton, StepButtonContainer } from "./style";
import { RightStepButtonContainer } from "../IntercomSetupModal/style";

interface IStepButtonsProps {
  rightTitle?: string | JSX.Element;
  alternateRightTitle?: string | JSX.Element;
  onClickRight?: () => void;
  onClickRightAlternate?: () => void;
  onClickLeft?: () => void;
  leftHidden?: boolean;

  info?: {
    onInfoClick: () => void;
    disabled?: boolean;
  };
  style?: CSSProperties;

  leftButtonTheme?: ButtonThemeEnum;
  rightButtonTheme?: ButtonThemeEnum;
}

function StepButtons({
  rightTitle,
  alternateRightTitle,
  onClickRight,
  onClickRightAlternate,
  leftHidden,
  onClickLeft,
  info,
  style,
  leftButtonTheme = ButtonThemeEnum.NEUTRAL2,
  rightButtonTheme = ButtonThemeEnum.NEUTRAL2,
}: IStepButtonsProps) {
  return (
    <Container style={style}>
      <StepButtonContainer
        style={{
          marginRight: info ? SPACING.two : 0,
        }}
      >
        {!leftHidden && (
          <StepButton
            buttonTheme={leftButtonTheme}
            direction="left"
            disabled={!onClickLeft}
            onClick={onClickLeft}
          >
            <ChevronRight />
          </StepButton>
        )}
        <RightStepButtonContainer>
          <StepButton
            buttonTheme={rightButtonTheme}
            direction="right"
            disabled={!onClickRight}
            onClick={onClickRight}
            style={{
              width: "100%",
            }}
          >
            {rightTitle && <Label>{rightTitle}</Label>}
          </StepButton>
          {alternateRightTitle && (
            <StepButton
              buttonTheme={rightButtonTheme}
              direction="right"
              disabled={!onClickRightAlternate}
              onClick={onClickRightAlternate}
            >
              {alternateRightTitle && <Label>{alternateRightTitle}</Label>}
            </StepButton>
          )}
        </RightStepButtonContainer>
      </StepButtonContainer>
      {info && (
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          disabled={info.disabled}
          onClick={info.onInfoClick}
        >
          <Info />
        </Button>
      )}
    </Container>
  );
}

export default StepButtons;

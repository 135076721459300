import { AxiosError } from "axios";
import { useContext, useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import {
  GetTradeHistory200Response,
  InstrumentTypeResponse,
  OptionTypeResponse,
  TradeTypeResponse,
} from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { authApi } from "../../../services/api/apiFetcher";
import { sortTrades } from "../../../utils/trade";
import useFillsWSS from "../../wss/useFillsWSS";
import { AssetResponse } from "../../../utils/asset";

export const useTradeHistory = (
  startTime: number,
  asset?: AssetResponse,
  tradeTypes?: TradeTypeResponse[],
  agg?: boolean,
  endTime?: number,
  limit?: number,
  optionType?: OptionTypeResponse,
  instrumentType?: InstrumentTypeResponse,
  offset?: number
) => {
  // Get API Key
  const { apiKey, apiSecret } = useContext(AuthContext);
  const fetcher = useMemo(
    () => authApi(apiKey, apiSecret),
    [apiKey, apiSecret]
  );
  const { trades } = useFillsWSS();
  const [, setProcessedFillTradeId] = useState<{
    [key: string]: boolean;
  }>({});

  const swr = useSWR<GetTradeHistory200Response, AxiosError>(
    apiKey
      ? [
          APIEndpointEnum.TRADES,
          apiKey,
          startTime,
          asset,
          tradeTypes,
          agg,
          endTime,
          limit,
          offset,
          optionType,
          instrumentType,
        ]
      : undefined,
    async () => {
      const { data } = await (
        await fetcher.getTradeHistory(
          startTime ?? 0,
          asset,
          endTime,
          tradeTypes,
          instrumentType,
          optionType,
          limit,
          agg,
          offset,
          "created_timestamp",
          "DESC"
        )
      )();
      return {
        ...data,
        trade_history: data.trade_history?.sort(sortTrades),
      };
    },
    {
      revalidateOnFocus: false,
    }
  );

  // Mutate whenever a new fill is detected, just mutate
  useEffect(() => {
    setProcessedFillTradeId((prevProcessedFills) => {
      const unprocessedTrades = trades?.filter(
        (f) => !prevProcessedFills[f.trade_id]
      );

      if (unprocessedTrades && unprocessedTrades.length) {
        // Processed
        const newProcessedTrades = { ...prevProcessedFills };
        unprocessedTrades.forEach((f) => {
          newProcessedTrades[f.trade_id] = true;
        });

        // Mutate with the latest trade fills immediately
        swr.mutate(
          {
            count: swr.data?.count || "0",
            trade_history: [
              ...unprocessedTrades,
              ...(swr.data?.trade_history || []),
            ],
          },
          { revalidate: false }
        );

        return newProcessedTrades;
      }
      return prevProcessedFills;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, trades, swr.mutate, swr.data]);

  return swr;
};

import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import buffer from "buffer";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./i18n/config";
import { GlobalStyle } from "./style";
import { ModalPortal } from "./portal";

window.Buffer = window.Buffer || buffer.Buffer;

if (
  process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "production" ||
  process.env.REACT_APP_VERCEL_GIT_COMMIT_REF === "staging"
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_VERCEL_GIT_COMMIT_REF,

    // The release tag will be the commit sha that is set by vercel automatically
    release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,

    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new HttpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [502, 500],

        // This array can contain Regexes, strings, or a combination of both.
        // default: [/.*/]
        failedRequestTargets: [/.*\.aevo.xyz$/],
      }),
    ],
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
    <ModalPortal />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

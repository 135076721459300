import { AxiosError } from "axios";
import { BigNumber, constants, ethers } from "ethers";
import { useCallback, useContext, useMemo } from "react";
import useSWR from "swr";
import {
  DeleteOrdersOrderId200Response,
  GenericErrorResponse,
  GetAccount200ResponsePositionsInnerTriggers,
  GetOrders200Response,
  InstrumentTypeResponse,
  OrderTypeResponse,
  PostOrders200Response,
  PostOrdersPayload,
  SideResponse,
  Stop,
  TimeInForce,
} from "../../../codegen-api";
import { AuthContext } from "../../../contexts/AuthContext";
import { APIEndpointEnum } from "../../../enums/endpoint";
import { ICreateOrderBody } from "../../../interfaces/Order";
import { authApi } from "../../../services/api/apiFetcher";
import { nanosToSeconds } from "../../../utils/date";
import { getDomainData } from "../../../utils/signing";
import { supportedChainId } from "../../../utils/wallet/connectors";
import { useSFX } from "../../useSFX";
import { useGetAccount } from "../account/useGetAccount";
import { useClock } from "../clock/useClock";
import { AssetResponse } from "../../../utils/asset";
import { unwrapApiError } from "../../../utils/errors";

const orderType = [
  { name: "maker", type: "address" },
  { name: "isBuy", type: "bool" },
  { name: "limitPrice", type: "uint256" },
  { name: "amount", type: "uint256" },
  { name: "salt", type: "uint256" },
  { name: "instrument", type: "uint256" },
  { name: "timestamp", type: "uint256" },
];

interface IOrderMessage {
  maker: string;
  isBuy: boolean;
  limitPrice?: string;
  amount: string;
  salt: string;
  instrument: string;
  timestamp: number;
  stop?: Stop;
  trigger?: string;
  reduceOnly?: boolean;
  mmp?: boolean;
  timeInForce?: TimeInForce;
}

export const useOrder = () => {
  // Get API Key
  const { apiKey, apiSecret, signingKey, account, deleteAuthInfo } =
    useContext(AuthContext);
  const { playSound } = useSFX();
  const { mutate: mutateAccount } = useGetAccount();
  const { getTimestamp } = useClock();

  const fetcher = useMemo(
    () => authApi(apiKey, apiSecret),
    [apiKey, apiSecret]
  );

  const swr = useSWR<GetOrders200Response[], AxiosError>(
    apiKey ? [APIEndpointEnum.ORDERS, apiKey] : undefined,
    async () =>
      (await (await fetcher.getOrders())()).data.sort(
        (a, b) =>
          nanosToSeconds(b.created_timestamp!) -
          nanosToSeconds(a.created_timestamp!)
      )
  );

  // Handle error when signing key is invalid
  const handleInvalidSigningKeyError = useCallback(
    (error?: any) => {
      if (unwrapApiError(error) === "ORDER_INVALID_SIGNING_KEY") {
        // Delete signing key and resign
        deleteAuthInfo("signing");
      }
    },
    [deleteAuthInfo]
  );

  // Util function for creating an order request
  const getCreateOrderRequest = useCallback(
    async (data: ICreateOrderBody) => {
      if (!signingKey || !account) {
        return undefined;
      }

      // Get signing keys, and sign message
      const salt = Math.floor(Math.random() * 100000);
      let limitPrice;
      const triggerPrice = data.trigger
        ? ethers.utils.parseUnits(data.trigger, 6)
        : undefined;
      if (data.orderType === OrderTypeResponse.Limit) {
        limitPrice = ethers.utils.parseUnits(data.price, 6);
      } else {
        limitPrice =
          data.side === SideResponse.Buy
            ? constants.MaxUint256
            : BigNumber.from(0);
      }

      // Limit to 6 decimal places
      const amount = ethers.utils.parseUnits(Number(data.amount).toFixed(6), 6);
      const timestamp = getTimestamp();

      const orderMessage: IOrderMessage = {
        maker: account,
        isBuy: data.side === SideResponse.Buy,
        instrument: String(data.instrument),
        limitPrice: limitPrice.toString(),
        amount: amount.toString(),
        stop: data.stop,
        trigger: triggerPrice?.toString(),
        salt: String(salt),
        reduceOnly: data.reduceOnly,
        mmp: data.mmp,
        timestamp,
      };

      const signer = new ethers.Wallet(signingKey);
      const domainData = getDomainData(supportedChainId);

      /* eslint no-underscore-dangle: 0 */
      const orderSignature = await signer._signTypedData(
        domainData,
        { Order: orderType },
        orderMessage
      );

      const request: PostOrdersPayload = {
        maker: orderMessage.maker,
        is_buy: orderMessage.isBuy,
        instrument: Number(orderMessage.instrument),
        limit_price: String(orderMessage.limitPrice),
        amount: String(orderMessage.amount),
        salt: String(orderMessage.salt),
        stop: orderMessage.stop,
        trigger: orderMessage.trigger,
        signature: orderSignature,
        system_type: "WEB",
        reduce_only: orderMessage.reduceOnly,
        time_in_force: data.timeInForce as TimeInForce,
        mmp: orderMessage.mmp,
        timestamp: String(timestamp),
        close_position: data.closePosition,
      };
      return request;
    },
    [signingKey, account, getTimestamp]
  );

  const createOrder = useCallback(
    async (data: ICreateOrderBody) => {
      const createOrderRequest: PostOrdersPayload | undefined =
        await getCreateOrderRequest(data);
      if (!createOrderRequest) {
        throw Error("Unauthenticated");
      }

      // Submit order
      try {
        const response = (
          await (
            await fetcher.postOrders(createOrderRequest)
          )()
        ).data;

        // If valid orderId, refresh orders
        if (response.order_id) {
          playSound("yv_deposit", true);
          swr.mutate();
        }
        return response;
      } catch (error) {
        handleInvalidSigningKeyError(error);

        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error Creating Order"
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCreateOrderRequest, fetcher, playSound, swr.mutate]
  );

  const removeOrder = useCallback(
    async (order_id: string) => {
      const response = (await (await fetcher.deleteOrdersOrderId(order_id))())
        .data;

      // If success, mutate the current data and filter out the removed order id
      if (swr.data && response.order_id) {
        swr.mutate(swr.data.filter((o) => o.order_id !== order_id));
      }
      return response;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, swr.data, swr.mutate]
  );

  // This is an MARKET_MAKER specific API
  const removeAllOrders = useCallback(
    async (type?: InstrumentTypeResponse, asset?: AssetResponse) => {
      try {
        const response = (
          await (
            await fetcher.deleteOrdersAll({ instrument_type: type, asset })
          )()
        ).data;

        if (swr.data && response.order_ids !== undefined) {
          swr.mutate(
            swr.data?.filter((order) =>
              (response.order_ids as string[]).includes(order.order_id)
            )
          );
        }

        return response;
      } catch (error) {
        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error Cancelling All Orders"
        );
      }
    },
    [fetcher, swr]
  );

  const editOrder = useCallback(
    async (orderId: string, newOrder: ICreateOrderBody) => {
      const createOrderRequest = await getCreateOrderRequest(newOrder);

      if (!createOrderRequest) {
        throw Error("Unauthenticated");
      }

      // Submit order
      try {
        const response = (
          await (
            await fetcher.postOrdersOrderId(orderId, createOrderRequest)
          )()
        ).data;

        if (swr.data) {
          const newOrders = swr.data.map((order) => {
            if (order.order_id === orderId) {
              return {
                ...order,
                order_id: response.order_id,
                price: newOrder.price || order.price,
                amount: newOrder.amount || order.amount,
                trigger: newOrder.trigger || order.trigger,
                filled: "0",
              };
            }
            return order;
          });
          swr.mutate(newOrders, { revalidate: false });
        }
        return response;
      } catch (error) {
        handleInvalidSigningKeyError(error);

        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error Editing Order"
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher, getCreateOrderRequest, swr.data, swr.mutate]
  );

  // To close position, submit a market order matching the same exact position size
  // This also mutates the useGetAccount()
  const closePosition = useCallback(
    async (position: any) => {
      const data: ICreateOrderBody = {
        amount: position.amount,
        side:
          position.side === SideResponse.Buy
            ? SideResponse.Sell
            : SideResponse.Buy,
        instrument: Number(position.instrument_id),
        orderType: OrderTypeResponse.Market,
        reduceOnly: true,
        stop: undefined,
        trigger: undefined,
      };
      // No need to try catch because createOrder already handled error
      const res = await createOrder(data);
      if (res.order_id) {
        mutateAccount();
      }
      return res;
    },
    [createOrder, mutateAccount]
  );

  const createTPSLOrder = (
    instrumentId: string,
    stopType: Stop,
    side: SideResponse,
    triggerPrice?: any
  ): ICreateOrderBody | undefined =>
    triggerPrice
      ? ({
          instrument: Number(instrumentId),
          amount: "0",
          side,
          orderType: "market",
          stop: stopType,
          trigger: String(triggerPrice),
          reduceOnly: true,
          closePosition: true,
        } as ICreateOrderBody)
      : undefined;

  const createTPSLOrders = useCallback(
    async (
      tpslForPosition: GetAccount200ResponsePositionsInnerTriggers | undefined,
      tpOrder: ICreateOrderBody | undefined,
      slOrder: ICreateOrderBody | undefined
    ) => {
      const createTPOrderRequest: PostOrdersPayload | undefined = tpOrder
        ? await getCreateOrderRequest(tpOrder)
        : undefined;
      const createSLOrderRequest: PostOrdersPayload | undefined = slOrder
        ? await getCreateOrderRequest(slOrder)
        : undefined;

      const tpForPositionId = tpslForPosition?.take_profit?.order_id;
      const slForPositionId = tpslForPosition?.stop_loss?.order_id;

      const responses:
        | PostOrders200Response
        | DeleteOrdersOrderId200Response[] = [];

      let response;
      try {
        // if already have a tp for position and no input, cancel order
        if (!tpOrder && tpForPositionId) {
          response = await removeOrder(tpForPositionId);
          // if already have a tp for position and there is an input, edit order
        } else if (createTPOrderRequest) {
          if (tpForPositionId && tpOrder) {
            response = await editOrder(tpForPositionId, tpOrder);
          } else {
            // if no tp for position and there is an input, create order
            response = (
              await (
                await fetcher.postOrders(createTPOrderRequest)
              )()
            ).data;
          }
        }
        if (response) {
          responses.push(response);
        }

        if (!slOrder && slForPositionId) {
          response = await removeOrder(slForPositionId);
        } else if (createSLOrderRequest) {
          if (slForPositionId && slOrder) {
            response = await editOrder(slForPositionId, slOrder);
          } else {
            response = (
              await (
                await fetcher.postOrders(createSLOrderRequest)
              )()
            ).data;
          }
        }
        if (response) {
          responses.push(response);
        }
        swr.mutate();
        return responses;
      } catch (error) {
        swr.mutate();
        handleInvalidSigningKeyError(error);

        const genericResponseAxiosError =
          error as AxiosError<GenericErrorResponse>;
        throw Error(
          genericResponseAxiosError.response?.data?.error ||
            "Error Creating Order"
        );
      }
    },
    [
      getCreateOrderRequest,
      swr,
      removeOrder,
      editOrder,
      fetcher,
      handleInvalidSigningKeyError,
    ]
  );

  return {
    ...swr,
    createOrder,
    removeOrder,
    removeAllOrders,
    editOrder,
    createTPSLOrder,
    createTPSLOrders,
    closePosition,
  };
};

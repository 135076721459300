import currency from "currency.js";
import { forwardRef } from "react";
import { MarkPriceIV, MiddleRow } from "./style";
import { IWSSTicker } from "../../../hooks/wss/model/ticker";

interface IMarkRowProps {
  ticker?: IWSSTicker;
}

export const MarkRow = forwardRef<any, IMarkRowProps>(({ ticker }, ref) => (
  <MiddleRow ref={ref}>
    <span>
      {ticker ? (
        <>
          <span>{currency(ticker.mark.price || 0).format()}</span>
          {ticker.mark.greeks?.iv && (
            <MarkPriceIV>
              {(Number(ticker.mark.greeks.iv) * 100).toFixed(2)}%
            </MarkPriceIV>
          )}
        </>
      ) : (
        "---"
      )}
    </span>
  </MiddleRow>
));

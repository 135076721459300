/* eslint-disable no-nested-ternary */
import currency from "currency.js";
import { ethers } from "ethers";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ConfirmationModal, {
  IConfirmationButton,
  IConfirmationModalStat,
  Stat,
} from ".";
import {
  GetAccount200ResponsePositionsInner,
  GetOrderbook200Response,
  GetRfqs200ResponseBlocksInner,
  GetRfqsBlockIdQuotes200ResponseAsksInner,
  InstrumentTypeResponse,
  SideResponse,
} from "../../codegen-api";
import { BACKGROUND_COLORS, COLORS } from "../../constants/design/colors";
import { useGetAccount } from "../../hooks/api/account/useGetAccount";
import { useOrder } from "../../hooks/api/order/useOrder";
import { useToast } from "../../hooks/toast";
import useOrderbookWSS from "../../hooks/wss/useOrderbookWSS";
import { nanosToSeconds } from "../../utils/date";
import { formatSizePrecision } from "../../utils/format";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { IBaseModalProps } from "../BaseModal";
import { ClosePositionOverlayInfo } from "../shared/ClosePositionOverlayInfo";

import { SPACING } from "../../constants/design/spacing";
import ShareButton from "../Buttons/ShareButton";
import { PnLCardModal } from "../PnLCardModal";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { RFQ } from "./RFQ";
import { Button, ButtonThemeEnum } from "../Buttons/styles";
import { useRFQ } from "../../hooks/api/rfq/useRFQ";
import BackButton from "../Buttons/BackButton";
import { StatWrapper } from "./style";
import { ITakerQuotePayload, useQuotes } from "../../hooks/api/rfq/useQuotes";

enum ClosePositionPageEnum {
  DETAILS,
  REQUEST_RFQ,
}

interface IClosePositionModal extends IBaseModalProps {
  position?: GetAccount200ResponsePositionsInner;
  orderbook?: GetOrderbook200Response;
}

/**
 * The modal responsible for closing positions
 */
function ClosePositionModal({
  position,
  onHide,
  show,
  orderbook,
  ...modalProps
}: IClosePositionModal) {
  const [loading, setLoading] = useState(false);
  const [canClosePosition, setCanClosePosition] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [warning, setWarning] = useState<string | undefined>(undefined);
  const [showClosePositionConfirmation, setShowClosePositionConfirmation] =
    useState<boolean>(false);
  const [showPnLCard, setShowPnLCard] = useState<boolean>(false);

  const { t } = useTranslation("app", {
    keyPrefix: "ConfirmationModal.ClosePositionModal",
  });
  const { t: apiError } = useTranslation("apiErrors");
  const { addToast, addErrorToast } = useToast();
  const { closePosition } = useOrder();
  const { data: accountData } = useGetAccount();
  const { orderbook: wsOrderbook } = useOrderbookWSS(
    orderbook ? undefined : position?.instrument_name
  );
  const {
    data: rfqData,
    createRFQ,
    deleteRFQsByInstrumentId,
    getRFQQuotesByBlockId,
  } = useRFQ("taker");

  const { acceptQuote } = useQuotes(false);

  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const [availablePages, setAvailablePages] = useState<ClosePositionPageEnum[]>(
    [ClosePositionPageEnum.DETAILS]
  );
  const [pageIndex, setPageIndex] = useState(0);

  const orderbookData = wsOrderbook || orderbook;

  const page = useMemo(
    () => availablePages[pageIndex >= availablePages.length ? 0 : pageIndex],
    [availablePages, pageIndex]
  );

  const onHideModal = useCallback(() => {
    onHide?.();
    setShowClosePositionConfirmation(false);
    setTimeout(() => {
      setShowPnLCard(false);
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onHide]);

  const onOpenPositionConfirmationModal = useCallback(() => {
    setShowClosePositionConfirmation(true);
  }, []);

  const onClosePositionConfirmationModal = useCallback(() => {
    setShowClosePositionConfirmation(false);
  }, []);

  const onOpenPnLCard = useCallback(() => {
    setShowPnLCard(true);
  }, []);

  const onHidePnLCard = useCallback(() => {
    setShowPnLCard(false);
  }, []);

  const onClosePosition = useCallback(async () => {
    if (!position) {
      return;
    }

    try {
      setError(undefined);
      setLoading(true);
      await closePosition(position);
      onHide?.();
      addToast(
        {
          type: ToastEnum.SIMPLE,
          header: t("position_closed_header"),
          subheader: t("position_closed_subheader"),
          status: ToastStatusEnum.SUCCESS,
        },
        4000
      );
    } catch (createError: any) {
      setError(apiError(createError.message) || t("close_position_error"));
    } finally {
      setLoading(false);
    }
  }, [addToast, apiError, closePosition, onHide, position, t]);

  const [selectedQuote, setQuote] =
    useState<GetRfqsBlockIdQuotes200ResponseAsksInner>();
  const [selectedRfqBlock, setRfqBlock] =
    useState<GetRfqs200ResponseBlocksInner>();

  const invertedSide = useCallback((s: SideResponse) => {
    if (s === SideResponse.Buy) {
      return SideResponse.Sell;
    }
    return SideResponse.Buy;
  }, []);

  useEffect(() => {
    const fetchQuotes = async () => {
      if (selectedRfqBlock && position) {
        const quotes = await getRFQQuotesByBlockId(selectedRfqBlock.block_id);

        if (quotes) {
          const offers = quotes?.asks || [];

          if (offers && offers.length > 0) {
            const offer = offers.find(
              (o) =>
                o.legs &&
                o.legs.length === 1 &&
                String(o.legs[0].instrument_id) === position.instrument_id
            );

            if (offer) setQuote(offer);
          }
        }
      }
    };

    fetchQuotes();
  }, [getRFQQuotesByBlockId, invertedSide, position, selectedRfqBlock]);

  // This hook will check if there are any existing RFQs for this instrument upon entering modal
  // If there is, it will delete before the creation of a new RFQ
  useEffect(() => {
    if (position && !selectedRfqBlock && (rfqData?.blocks || []).length > 0) {
      deleteRFQsByInstrumentId(position.instrument_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  const hasRequest = useMemo(
    () => Boolean(selectedRfqBlock),
    [selectedRfqBlock]
  );
  const hasQuote = useMemo(() => Boolean(selectedQuote), [selectedQuote]);

  const onDelete = useCallback(async () => {
    if (position) {
      deleteRFQsByInstrumentId(position?.instrument_id);
      setQuote(undefined);
    }
  }, [deleteRFQsByInstrumentId, position]);

  const onPrevPage = useCallback(() => {
    setError("");
    setWarning("");
    switch (page) {
      case ClosePositionPageEnum.REQUEST_RFQ:
        setPageIndex(pageIndex - 1);
        onDelete();
        break;
      default:
        break;
    }
  }, [onDelete, page, pageIndex]);

  const onCreate = useCallback(async () => {
    try {
      // Filter taker's existing RFQs for this instrument_id
      const blocksWithAsset = (rfqData?.blocks || []).some((block) =>
        block.legs?.some((leg) => leg.instrument_id === position?.instrument_id)
      );
      if (position && !blocksWithAsset) {
        await createRFQ({
          legs: [
            {
              instrument: Number(position.instrument_id),
              is_buy: invertedSide(position.side) === SideResponse.Buy,
              ratio: 1,
            },
          ],
          full_size: true,
          amount: ethers.utils.parseUnits(position.amount, 6).toString(),
          duration: 1800,
        });
      }
    } catch (e: any) {
      onPrevPage();
      addErrorToast(
        t("create_rfq_failed"),
        apiError(e.message) || t("place_order_again")
      );
    }
  }, [
    addErrorToast,
    apiError,
    createRFQ,
    invertedSide,
    onPrevPage,
    position,
    rfqData?.blocks,
    t,
  ]);

  useEffect(() => {
    if (position && rfqData && (rfqData.blocks || []).length > 0) {
      // There are no roles attached to each rfq from the WSS
      const rfq = (rfqData.blocks || []).find(
        (r) =>
          r.legs &&
          r.legs.length === 1 &&
          String(r.legs[0].instrument_id) === position.instrument_id
      );

      setRfqBlock(rfq);
    } else {
      setRfqBlock(undefined);
    }
  }, [onCreate, position, rfqData]);

  const onAcceptQuote = useCallback(async () => {
    if (position && selectedRfqBlock && selectedQuote) {
      try {
        const payload: ITakerQuotePayload = {
          block_id: selectedRfqBlock.block_id,
          amount: String(Number(selectedQuote.amount) * 1000000),
          is_buy: true,
          limit_price: selectedQuote.limit_price
            ? String(Number(selectedQuote.limit_price) * 1000000)
            : undefined,
        };

        setLoading(true);
        const response = await acceptQuote(payload);

        if (response) {
          addToast({
            type: ToastEnum.SIMPLE,
            status: ToastStatusEnum.SUCCESS,
            header: t("offer_accepted"),
          });
          onHideModal();
        }
      } catch (e: any) {
        addErrorToast(t("rfq"), apiError(e.message) || t("try_again"));
      } finally {
        setLoading(false);
      }
    }
  }, [
    acceptQuote,
    addErrorToast,
    addToast,
    apiError,
    onHideModal,
    position,
    selectedQuote,
    selectedRfqBlock,
    t,
  ]);

  const onNextPage = useCallback(() => {
    setError("");
    setWarning("");
    switch (page) {
      case ClosePositionPageEnum.DETAILS:
        setPageIndex(pageIndex + 1);
        onCreate();
        break;
      default:
        break;
    }
  }, [onCreate, page, pageIndex]);

  // Whenever the modal is presented, reset state.
  useEffect(() => {
    if (!show) {
      setError(undefined);
      setWarning(undefined);
      setPageIndex(0);
    }
  }, [show]);

  useEffect(() => {
    // Only allow options to request RFQ
    if (
      !canClosePosition &&
      position?.instrument_type === InstrumentTypeResponse.Option
    ) {
      setAvailablePages([
        ClosePositionPageEnum.DETAILS,
        ClosePositionPageEnum.REQUEST_RFQ,
      ]);
    } else {
      setAvailablePages([ClosePositionPageEnum.DETAILS]);
    }
  }, [canClosePosition, position?.instrument_type]);

  const pageContent = useMemo(() => {
    switch (page) {
      case ClosePositionPageEnum.REQUEST_RFQ:
        return (
          <>
            <RFQ
              position={position!}
              hasQuote={hasQuote}
              hasRequest={hasRequest}
            />
            {selectedQuote ? (
              <StatWrapper>
                <Stat
                  key={`${t("contracts")}`}
                  title={t("contracts")}
                  data={formatSizePrecision(selectedQuote.amount)}
                />
                <Stat
                  key={`${t("price")}`}
                  title={t("price")}
                  data={currency(
                    Math.abs(Number(selectedQuote.limit_price))
                  ).format()}
                />
              </StatWrapper>
            ) : null}
          </>
        );
      default:
        return null;
    }
  }, [hasQuote, hasRequest, page, position, selectedQuote, t]);

  // Validates available liquidity for market buy/sell
  useEffect(() => {
    if (!position || page === ClosePositionPageEnum.REQUEST_RFQ) {
      return;
    }

    let totalLiquidity: number | undefined;

    if (position.side === SideResponse.Buy) {
      // if long, check bids liquidity (selling into bids)
      const totalBidsSize =
        orderbookData?.bids?.reduce((prev, bid) => {
          const bidSize = Number(bid[1] || 0);
          return prev + bidSize;
        }, 0) || 0;
      totalLiquidity = totalBidsSize;
    } else if (position.side === SideResponse.Sell) {
      // if short, check ask liquidity (buying from asks)
      const totalAsksSize =
        orderbookData?.asks?.reduce((prev, ask) => {
          const askSize = Number(ask[1] || 0);
          return prev + askSize;
        }, 0) || 0;
      totalLiquidity = totalAsksSize;
    }

    // Reset
    setCanClosePosition(true);
    setWarning("");

    if (totalLiquidity !== undefined) {
      setError(undefined);
      if (totalLiquidity === 0) {
        setWarning(t("insufficient_liquidity_error"));
        setCanClosePosition(false);
      } else if (totalLiquidity < parseFloat(position.amount)) {
        setWarning(t("partial_liquidity_warning"));
      }
    }
  }, [orderbookData?.bids, orderbookData?.asks, position, t, page]);

  const stats: IConfirmationModalStat[] = useMemo(() => {
    if (!position || page !== ClosePositionPageEnum.DETAILS) {
      return [];
    }

    const totalValue =
      Number(position.amount) * Number(position.avg_entry_price);
    const roi = (Number(position.unrealized_pnl) / totalValue) * 100;

    const items = [
      {
        title: t("contracts"),
        data: formatSizePrecision(position.amount),
      },
      {
        title: t("avg_entry_price"),
        data: currency(position.avg_entry_price || 0, {
          precision: getMarketPrecision(
            position.asset,
            position.instrument_type
          ).price_precision,
        }).format(),
      },
      {
        title: t("unrealized_pnl"),
        data: (
          <span
            style={{
              color:
                Number(position.unrealized_pnl) < 0
                  ? COLORS.negative.one
                  : COLORS.positive.one,
            }}
          >
            {Number(position.unrealized_pnl) < 0 ? "" : "+"}
            {currency(position.unrealized_pnl || 0).format()}
          </span>
        ),
      },
      {
        title: t("roi"),
        data: (
          <span
            style={{
              color: roi < 0 ? COLORS.negative.one : COLORS.positive.one,
            }}
          >
            {roi < 0 ? "" : "+"}
            {roi.toFixed(2)}%
          </span>
        ),
      },
    ];

    if (position.side === SideResponse.Sell) {
      items.push({
        title: t("maintenance_margin"),
        data: currency(position.maintenance_margin).format(),
      });
    }

    if (position.option) {
      items.push(
        ...[
          {
            title: t("delta"),
            data: Number(position.option.delta).toFixed(2) || "-",
          },
          {
            title: t("theta"),
            data: currency(position.option.theta).format() || "-",
          },
          {
            title: t("vega"),
            data: Number(position.option.vega).toFixed(2) || "-",
          },
          {
            title: t("gamma"),
            data: Number(position.option.gamma).toFixed(6) || "-",
          },
        ]
      );
    }

    return items;
  }, [position, t, getMarketPrecision, page]);

  const tags = useMemo(() => {
    if (position && page === ClosePositionPageEnum.DETAILS) {
      return {
        texts: position.option
          ? [
              position.side === SideResponse.Buy ? t("long") : t("short"),
              position.option.option_type,
              moment
                .unix(nanosToSeconds(position.option.expiry))
                .format("DD MMM YY"),
              currency(position.option.strike, { precision: 0 }).format(),
            ]
          : [
              position.side === SideResponse.Buy ? t("long") : t("short"),
              position.instrument_name,
            ],
        color:
          position.side === SideResponse.Buy
            ? COLORS.positive.one
            : COLORS.negative.one,
        backgroundColor:
          position.side === SideResponse.Buy
            ? COLORS.positive.four
            : COLORS.negative.four,
      };
    }
    return undefined;
  }, [page, position, t]);

  const confirmationButtons: IConfirmationButton[] = useMemo(() => {
    const buttons: IConfirmationButton[] = [];

    if (page === ClosePositionPageEnum.DETAILS) {
      const canRequestRFQ =
        !canClosePosition &&
        position?.instrument_type === InstrumentTypeResponse.Option;
      if (canRequestRFQ) {
        buttons.push({
          title: t("rfq"),
          onClick: onNextPage,
        });
      } else if (accountData?.in_liquidation) {
        buttons.push({
          overrideComponent: (
            <Button disabled buttonTheme={ButtonThemeEnum.NEGATIVE}>
              {t("liquidations_in_progress")}
            </Button>
          ),
        });
      } else {
        buttons.push({
          title: t("close_position"),
          onClick: onOpenPositionConfirmationModal,
          disabled: !canClosePosition,
        });
      }

      buttons.push({
        overrideComponent: <ShareButton onClick={onOpenPnLCard} />,
        style: { marginLeft: `${SPACING.two}px` },
      });
    }

    if (page === ClosePositionPageEnum.REQUEST_RFQ) {
      buttons.push({
        overrideComponent: <BackButton onClick={onPrevPage} />,
        style: { marginRight: `${SPACING.two}px` },
      });

      if (selectedRfqBlock) {
        buttons.push({
          title: t("accept_quotation"),
          onClick: () => onAcceptQuote(),
          theme: ButtonThemeEnum.NEUTRAL2,
          disabled: !selectedQuote || loading,
        });
      } else {
        buttons.push({
          title: t("create_rfq"),
          onClick: () => onCreate(),
          theme: ButtonThemeEnum.NEUTRAL2,
          disabled: selectedRfqBlock,
        });
      }
    }

    return buttons;
  }, [
    page,
    t,
    canClosePosition,
    position?.instrument_type,
    accountData?.in_liquidation,
    onOpenPnLCard,
    onNextPage,
    onOpenPositionConfirmationModal,
    onPrevPage,
    selectedRfqBlock,
    selectedQuote,
    loading,
    onAcceptQuote,
    onCreate,
  ]);

  if (!position) {
    return null;
  }

  const negativePnl = currency(position.unrealized_pnl || 0).value < 0;
  const primaryColor = negativePnl ? COLORS.negative.one : COLORS.positive.one;

  if (!showPnLCard) {
    return (
      <ConfirmationModal
        title={
          page === ClosePositionPageEnum.DETAILS
            ? position.side === SideResponse.Buy
              ? t("close_long_position")
              : t("close_short_position")
            : t("rfq")
        }
        primaryColor={primaryColor}
        style={{
          backgroundColor: BACKGROUND_COLORS.seven,
          width: 320,
        }}
        tag={tags}
        stats={stats}
        confirmationButtons={confirmationButtons}
        confirmationWrapperStyle={{ display: "flex" }}
        isLoading={loading}
        onHide={onHideModal}
        show={show}
        error={error}
        warning={warning}
        extras={
          <>
            {pageContent}
            <ClosePositionOverlayInfo
              show={showClosePositionConfirmation}
              onClose={onClosePositionConfirmationModal}
              loading={loading}
              error={error}
              onClosePosition={onClosePosition}
              position={position}
              orderbookData={orderbookData}
            />
          </>
        }
        {...modalProps}
      />
    );
  }

  return (
    <PnLCardModal
      pnlType="position"
      pnlData={position}
      onHideModal={onHideModal}
      show={!!position}
      onHidePnLCard={onHidePnLCard}
    />
  );
}

export default ClosePositionModal;

import currency from "currency.js";
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DownArrows } from "../../../assets/svg/chevrons-down.svg";
import {
  InstrumentTypeResponse,
  OptionTypeResponse,
  SideResponse,
} from "../../../codegen-api";
import { MarketContext } from "../../../contexts/MarketContext";
import { MarketInstrumentContext } from "../../../contexts/MarketInstrumentContext";
import SegmentedControl from "../../shared/SegmentedControl";
import Transition from "../../shared/Transition";

import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_STYLE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { useOrder } from "../../../hooks/api/order/useOrder";
import useTickersWSS from "../../../hooks/wss/tickers/useTickersWSS";
import useIndexWSS from "../../../hooks/wss/useIndexWSS";
import usePositionsWSS from "../../../hooks/wss/usePositionsWSS";
import MobileFooter from "../../MobileFooter";
import MobileTradeFormModal from "../../MobileTradeFormModal";
import MobileTradingBottomBar from "../../TradingBottomBar/MobileTradingBottomBar";
import CurrentSpotSection from "../CurrentSpotSection";
import DateSelection from "../DateSelection";
import { ITableContentRow } from "../OptionsTable";
import { OptionsChainContainer, Padding } from "../style";
import OptionRow from "./OptionRow";
import { TabsContainer, TapToScroll } from "./style";

interface IMobileOptionsChainProps {
  scrollableContainerRef: RefObject<HTMLDivElement>;
}

function MobileOptionsChain({
  scrollableContainerRef,
}: IMobileOptionsChainProps) {
  const bottomBarRef = useRef<HTMLDivElement>(null);

  // As long as bottom bar tabs covers over half the screen, its considered showing
  const isBottomBarShowing =
    (bottomBarRef.current?.getBoundingClientRect().top || Infinity) <=
    document.body.clientHeight - COMPONENTS.mobileTradeBottomBarMinHeight;

  const { market } = useContext(MarketContext);

  const {
    selectedValues,
    expiries,
    selectedOptionInstrument: selectedInstrument,
    setExpiry,
    setOrderType,
    setStrike,
    orderTypes,
    reset,
    marketsData,
  } = useContext(MarketInstrumentContext);

  const { expiry } = selectedValues;

  const marketsOfThisExpiry = useMemo(
    () => marketsData?.filter((v) => Number(v.expiry) === expiry) || [],
    [expiry, marketsData]
  );

  const putCalls = useMemo(
    () => ({
      calls: marketsOfThisExpiry.filter(
        (v) => v.option_type === OptionTypeResponse.Call
      ),
      puts: marketsOfThisExpiry.filter(
        (v) => v.option_type === OptionTypeResponse.Put
      ),
    }),
    [marketsOfThisExpiry]
  );

  const { data: orders } = useOrder();

  // Websocket
  const { instrumentTicker } = useTickersWSS(
    market.asset,
    InstrumentTypeResponse.Option,
    expiry
  );
  const { positions, positionsMap } = usePositionsWSS();
  const { index } = useIndexWSS(market.asset);
  const currentIndexPrice = index?.price;
  const { t } = useTranslation("app", {
    keyPrefix: "OptionsChain.MobileOptionsChain.MobileOptionsChain",
  });

  const [optionTypeFilter, setOptionTypeFilter] = useState<OptionTypeResponse>(
    OptionTypeResponse.Call
  );
  const [side, setSide] = useState<SideResponse>(SideResponse.Buy);
  const [showTradeForm, setShowTradeForm] = useState(false);

  const onTrade = useCallback(
    (orderType: OptionTypeResponse, exp: number, strike: string) => {
      setOrderType(orderType);
      setExpiry(exp);
      setStrike(strike);
      setShowTradeForm(true);
    },
    [setExpiry, setOrderType, setStrike]
  );

  const onSelectExpiry = useCallback(
    (expiryDate: number) => {
      setExpiry(expiryDate);
    },
    [setExpiry]
  );

  const onSelectOptionTypeFilterMobile = useCallback((value: string) => {
    setOptionTypeFilter(value as OptionTypeResponse);
  }, []);

  const onScrollToPortfolioTray = useCallback(() => {
    if (isBottomBarShowing) {
      scrollableContainerRef?.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    bottomBarRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [isBottomBarShowing, scrollableContainerRef]);

  const decoratedExpiries = useMemo(() => {
    const withPositions =
      positions
        .filter((pos) => !!pos.option)
        .map((pos) => pos.option!.expiry) ?? [];
    const withOrders =
      orders?.filter((o) => !!o.expiry).map((o) => o.expiry!) ?? [];
    return {
      withPositions,
      withOrders,
    };
  }, [orders, positions]);

  // Returns rows, split into below strike price and above strike price
  const filteredRows = useMemo(() => {
    const { calls, puts } = putCalls;

    // Finally, split into 2 arrays, less than and more than current price
    const lessThanCurrPriceRows: ITableContentRow[] = [];
    const moreThanCurrPriceRows: ITableContentRow[] = [];

    if (currentIndexPrice) {
      const currentOptions =
        optionTypeFilter === OptionTypeResponse.Call ? calls : puts;
      currentOptions.forEach((m) => {
        const ticker = instrumentTicker[m.instrument_name];
        if (m.strike && ticker) {
          const position = positionsMap[m.instrument_name];
          const row: ITableContentRow = {
            strike: m.strike,
            expiry: Number(m.expiry ?? "0"),
            ticker: {
              delta: ticker.mark?.greeks?.delta || "0",
              open_interest: ticker.open_interest || "0",
              bid: {
                price: ticker.bid?.price || "0",
                volume: ticker.bid?.amount || "0",
                iv: ticker.bid?.greeks?.iv || "0",
                orderVolume: Number(
                  orders?.find(
                    (o) =>
                      o.instrument_id === ticker.instrument_id &&
                      o.side === SideResponse.Buy
                  )?.amount || 0
                ),
              },
              ask: {
                price: ticker.ask?.price || "0",
                volume: ticker.ask?.amount || "0",
                iv: ticker.ask?.greeks?.iv || "0",
                orderVolume: Number(
                  orders?.find(
                    (o) =>
                      o.instrument_id === ticker.instrument_id &&
                      o.side === SideResponse.Sell
                  )?.amount || 0
                ),
              },
              instrumentName: ticker.instrument_name,
              instrumentId: Number(ticker.instrument_id),
              position: Number(position?.amount || 0),
              side: position?.side || SideResponse.Buy,
              markPrice: ticker.mark?.price || "0",
              iv: ticker.mark?.greeks?.iv || "0",
            },
          };

          if (currency(row.strike || 0).value <= Number(currentIndexPrice)) {
            lessThanCurrPriceRows.push(row);
          } else {
            moreThanCurrPriceRows.push(row);
          }
        }
      });
    }

    // Sorted by strike
    return {
      lessThanCurrPriceRows: lessThanCurrPriceRows.sort(
        (a, b) => Number(a.strike) - Number(b.strike)
      ),
      moreThanCurrPriceRows: moreThanCurrPriceRows.sort(
        (a, b) => Number(a.strike) - Number(b.strike)
      ),
    };
  }, [
    putCalls,
    instrumentTicker,
    optionTypeFilter,
    positionsMap,
    currentIndexPrice,
    orders,
  ]);

  // Whenever market changed, reset trade values
  useEffect(() => {
    reset();
  }, [market, reset]);

  // For initial loads where expiriesData gets updated and no expiry, set default expiry
  useEffect(() => {
    if (expiries.length && !expiry) {
      setExpiry(expiries[0]);
      setOrderType(orderTypes[0]);
    }
  }, [expiries, expiry, setExpiry, reset, setOrderType, orderTypes]);

  return (
    <OptionsChainContainer>
      <Padding>
        <TabsContainer>
          <SegmentedControl
            segments={[
              {
                value: String(SideResponse.Buy),
                display: t("buy"),
                textColor:
                  side === SideResponse.Buy
                    ? COLORS.positive.one
                    : TEXT_COLORS.three,
              },
              {
                value: String(SideResponse.Sell),
                display: t("sell"),
                textColor:
                  side === SideResponse.Sell
                    ? COLORS.negative.one
                    : TEXT_COLORS.three,
              },
            ]}
            value={side}
            onSelect={(value) => setSide(value as SideResponse)}
            config={{
              theme: "outline",
              color:
                side === SideResponse.Buy
                  ? COLORS.positive.one
                  : COLORS.negative.one,
              widthType: "fullWidth",
              backgroundColor: COLORS.white.five,
              activeBackgroundColor:
                side === SideResponse.Buy
                  ? COLORS.positive.six
                  : COLORS.negative.six,
              borderRadius: "100px",
              button: {
                height: 32,
                fontSize: FONT_STYLE.body.five.fontSize,
              },
            }}
          />
          <div style={{ width: SPACING.one }} />
          <SegmentedControl
            segments={[
              {
                value: String(OptionTypeResponse.Call),
                display: t("calls"),
                textColor:
                  optionTypeFilter === OptionTypeResponse.Call
                    ? COLORS.blue.one
                    : TEXT_COLORS.three,
              },
              {
                value: String(OptionTypeResponse.Put),
                display: t("puts"),
                textColor:
                  optionTypeFilter === OptionTypeResponse.Put
                    ? COLORS.blue.one
                    : TEXT_COLORS.three,
              },
            ]}
            value={optionTypeFilter}
            onSelect={onSelectOptionTypeFilterMobile}
            config={{
              theme: "outline",
              color: COLORS.blue.one,
              widthType: "fullWidth",
              backgroundColor: COLORS.white.five,
              activeBackgroundColor: COLORS.blue.five,
              borderRadius: "100px",
              button: {
                height: 32,
                fontSize: FONT_STYLE.body.five.fontSize,
              },
            }}
          />
        </TabsContainer>

        <DateSelection
          expiries={expiries}
          selectedExpiry={expiry}
          expiriesWithPosition={decoratedExpiries.withPositions}
          expiriesWithOrder={decoratedExpiries.withOrders}
          onSelectExpiry={onSelectExpiry}
        />
      </Padding>

      <Transition>
        <Padding style={{ paddingTop: 0 }}>
          {filteredRows.lessThanCurrPriceRows.map((v) => (
            <OptionRow
              highlighted={!!positionsMap[v.ticker.instrumentName]}
              onClick={() => {
                onTrade(optionTypeFilter, Number(v.expiry!), v.strike!);
              }}
              key={`${v.ticker.instrumentId}`}
              data={v}
              type={optionTypeFilter}
              side={side}
            />
          ))}
          <CurrentSpotSection
            asset={market.asset}
            price={Number(currentIndexPrice)}
            loading={!currentIndexPrice}
          />
          {filteredRows.moreThanCurrPriceRows.map((v) => (
            <OptionRow
              highlighted={v.ticker.position !== 0}
              onClick={() => {
                onTrade(optionTypeFilter, v.expiry, v.strike);
              }}
              key={v.ticker.instrumentId}
              data={v}
              type={optionTypeFilter}
              side={side}
            />
          ))}
          <MobileTradingBottomBar ref={bottomBarRef} />
        </Padding>
        {
          // Only show footer if content is scrollable
          (scrollableContainerRef.current?.scrollHeight || 0) >
            document.body.clientHeight && (
            <MobileFooter>
              <TapToScroll
                key={String(isBottomBarShowing)}
                onClick={onScrollToPortfolioTray}
                reversed={isBottomBarShowing}
              >
                {!isBottomBarShowing
                  ? t("tap_to_scroll_portfolio")
                  : t("tap_to_scroll_top")}
                <DownArrows />
              </TapToScroll>
            </MobileFooter>
          )
        }
      </Transition>

      <MobileTradeFormModal
        instrumentType={InstrumentTypeResponse.Option}
        show={showTradeForm}
        onHide={() => setShowTradeForm(false)}
        selectedInstrument={selectedInstrument}
      />
    </OptionsChainContainer>
  );
}

export default MobileOptionsChain;

import { useEffect, useState } from "react";
import usePrevious from "../usePrevious";

const useInternetReconnected = (reconnectedCallback: () => void) => {
  const [online, setOnline] = useState<boolean>();
  const prevOnline = usePrevious(online);

  useEffect(() => {
    const onlineListener = () => {
      setOnline(true);
    };
    const offlineListener = () => {
      setOnline(false);
    };
    window.addEventListener("online", onlineListener);
    window.addEventListener("offline", offlineListener);
    return () => {
      window.removeEventListener("online", onlineListener);
      window.removeEventListener("offline", offlineListener);
    };
  }, []);

  useEffect(() => {
    if (prevOnline === false && online) {
      reconnectedCallback();
    }
  }, [online, prevOnline, reconnectedCallback]);
};

export default useInternetReconnected;

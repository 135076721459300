import { SideResponse } from "../../codegen-api";
import { IPriceSize } from "../../interfaces/Orderbook";
import { roundToNearest } from "../math";

/**
 * Gets the cumulative value of an array up to a certain index.
 * Eg. [1,2,3,4] with `toIndex` 1 will be "1 + 2"
 * @param arr
 * @param toIndex
 */
export const getCumulativeValue = (arr: number[], toIndex: number) =>
  arr.slice(0, toIndex + 1).reduce((prev, curr) => prev + curr, 0);

// Given an array of bids and asks, find the mids price
export const getMidPrice = (bids: string[][], asks: string[][]): number => {
  // Sort bid high to low
  const sortedBids = bids
    .slice()
    .filter((v) => !!Number(v[0]))
    .sort((a, b) => Number(b[0]) - Number(a[0]));
  const sortedAsks = asks
    .slice()
    .filter((v) => !!Number(v[0]))
    .sort((a, b) => Number(a[0]) - Number(b[0]));

  if (!sortedBids.length || !sortedAsks.length) {
    return (
      Number(sortedAsks?.[0]?.[0] || 0) || Number(sortedBids?.[0]?.[0] || 0)
    );
  }

  const midPrice = (Number(sortedBids[0][0]) + Number(sortedAsks[0][0])) / 2;

  return midPrice;
};

// Given a $ value, find out the amount of contracts u can buy/sell to the book
// If orderDirection is BUY, the book should be an array of asks, and vice versa
export const getAmountOfFilledContracts = (
  orderDirection: SideResponse,
  book: IPriceSize[],
  value: number
) => {
  let remainingValue = value;
  let contractsCount = 0;

  // If buy, means the book is a list of asks.
  // sort it ascending
  const sortedBook = book.sort((a, b) =>
    orderDirection === SideResponse.Buy
      ? Number(a[0]) - Number(b[0])
      : Number(b[0]) - Number(a[0])
  );

  for (let i = 0; i < sortedBook.length; i += 1) {
    const [price, size] = sortedBook[i];
    const priceNum = Number(price);
    const sizeNum = Number(size);

    // Fill orders as long as there is remaining value
    if (remainingValue > 0) {
      const contractsBought =
        remainingValue / priceNum > sizeNum
          ? sizeNum
          : remainingValue / priceNum;
      contractsCount += contractsBought;
      remainingValue -= contractsBought * priceNum;
    } else {
      break;
    }
  }

  return contractsCount;
};

// Given a number of contracts and an orderbook, return how much it would cost to sell/buy that amount
export const getValueOfContractsAmount = (
  orderDirection: SideResponse,
  book: IPriceSize[],
  amount: number
) => {
  let remainingAmount = amount;
  let value = 0;

  // If buy, means the book is a list of asks.
  // sort it ascending
  const sortedBook = book.sort((a, b) =>
    orderDirection === SideResponse.Buy
      ? Number(a[0]) - Number(b[0])
      : Number(b[0]) - Number(a[0])
  );

  for (let i = 0; i < sortedBook.length; i += 1) {
    const [price, size] = sortedBook[i];
    const priceNum = Number(price);
    const sizeNum = Number(size);

    // Fill orders as long as there is remaining value
    if (remainingAmount > 0) {
      const contractsValue =
        remainingAmount - sizeNum < 0
          ? priceNum * remainingAmount
          : priceNum * sizeNum;
      remainingAmount -= sizeNum;
      value += contractsValue;
    } else {
      break;
    }
  }

  return value;
};

// Get the price impact of the newly
export const getPriceImpact = (
  bids: IPriceSize[],
  asks: IPriceSize[],
  orderDirection: SideResponse,
  size: number
): number => {
  // Ideal Price = ( Best Buy Price + Best Sell Price ) ÷ 2.
  // Actual Buy / Sell Price = Sum of ( Quantity x Execution Price ) ÷ Total Quantity.
  // Impact cost = (Actual Buy / Sell Price – Ideal Price ) ÷ Ideal Price x 100.

  const totalCost = getValueOfContractsAmount(
    orderDirection,
    orderDirection === SideResponse.Buy ? asks : bids,
    size
  );
  const avgPrice = totalCost / size;
  const idealPrice = getMidPrice(bids, asks);
  const priceImpact = (avgPrice - idealPrice) / idealPrice;
  return priceImpact;
};

export const isOrderPriceAllowed = (
  orderPrice: number,
  markPrice: number,
  indexPrice: number,
  percentAwayFromMarkPrice: number,
  percentAwayFromIndexPrice: number
) => {
  // Allow price where its 50% away from mark price, or 0.5% away from index price
  // 0.5, 0.005
  const allowedDiff = Math.max(
    markPrice * percentAwayFromMarkPrice,
    percentAwayFromIndexPrice * indexPrice
  );
  const minOrderPrice = markPrice - allowedDiff;
  const maxOrderPrice = markPrice + allowedDiff;

  return orderPrice >= minOrderPrice && orderPrice <= maxOrderPrice;
};

/**
 * Given the parameters, return the maximum amount allowed w the given mark iv collar
 * @param side side of the orders buying or selling
 * @param bids: Array of [price, size, iv][]
 * @param amount amount of orders to buy/sell into
 * @param markPrice mark price
 * @param percentAwayFromMarkPrice mark price collar collar
 * @returns `true` if orderbook protection is triggered, else `false`
 */
export const maxOrderSizeForMarkPriceCollar = (
  // Side of the order, buying or selling
  side: SideResponse,
  bids: Array<Array<string>>,
  asks: Array<Array<string>>,
  markPrice: number,
  indexPrice: number,
  percentAwayFromMarkPrice: number,
  percentAwayFromIndexPrice: number
) => {
  const useAsksOrderbook = side === SideResponse.Buy;
  const orders = useAsksOrderbook ? asks : bids;

  // Sum all the order sizes that falls within the price band
  const sizeLimit = orders.reduce((prevSize, order) => {
    const orderPrice = Number(order[0] || 0);

    // Set orderSize to 0 if orderPrice is not allowed
    let orderSize = Number(order[1] || 0);
    orderSize = isOrderPriceAllowed(
      orderPrice,
      markPrice,
      indexPrice,
      percentAwayFromMarkPrice,
      percentAwayFromIndexPrice
    )
      ? orderSize
      : 0;
    return prevSize + orderSize;
  }, 0);
  return sizeLimit;
};

/**
 * Groups price levels by their price
 * Example:
 *
 *  groupByPrice([ [1000, 100], [1000, 200], [993, 20] ]) // [ [ 1000, 300 ], [ 993, 20 ] ]
 *
 * @param levels
 */
export const groupByPrice = (levels: IPriceSize[]): IPriceSize[] =>
  levels.reduce((allLevels, level) => {
    const prevLevel = allLevels[allLevels.length - 1];

    // Add current level to prev level
    if (prevLevel && level[0] === prevLevel[0]) {
      // Construct new level by adding current size and prev size
      const res = [level[0], String(Number(level[1]) + Number(prevLevel[1]))];
      if (level[2]) {
        res.push(level[2]);
      }

      return [...allLevels.slice(0, -1), res as IPriceSize];
    }
    return [...allLevels, level];
  }, [] as IPriceSize[]);

/**
 * Group price levels by given ticket size. Uses groupByPrice() and roundToNearest()
 * roundUp determines rounding up or down.
 *
 * Eg.
 * roundUp = true, with ticketSize = 1
 * [10.1, 10.2, 10.3] will all fall under [11]
 *
 * roundUp = false, with ticketSize = 1
 * [10.1, 10.2, 10.3] will all fall under [10]
 *
 * Example:
 *
 * groupByTicketSize([ [1000.5, 100], [1000, 200], [993, 20] ], 1) // [[1000, 300], [993, 20]]
 */
export const groupByTicketSize = (
  levels: IPriceSize[],
  ticketSize: number,
  roundUp?: boolean
): IPriceSize[] => {
  // Round
  const rounded = levels.map((level) => {
    const res = [
      String(roundToNearest(Number(level[0]), ticketSize, roundUp)),
      level[1],
    ];
    if (level[2]) {
      res.push(level[2]);
    }
    return res as IPriceSize;
  });
  return groupByPrice(rounded);
};

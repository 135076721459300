import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { ChainIdEnum } from "../../enums/chain";
import { NetworkButton, NetworkButtonsContainer } from "./style";

import { ReactComponent as ARBLogo } from "../../assets/svg/assets/ARB.svg";
import { ReactComponent as ETHLogo } from "../../assets/svg/assets/ETH.svg";
import { ReactComponent as OPLogo } from "../../assets/svg/assets/OP.svg";
import { supportedSigningChainIds } from "../../utils/wallet/connectors";

interface ISelectMultichainNetworkProps {
  selectedChain: ChainIdEnum;
  onSelectChain: (chainId: ChainIdEnum) => void;
  chains?: ChainIdEnum[];
  style?: CSSProperties;
}

function SelectMultichainNetwork({
  selectedChain,
  onSelectChain,
  chains = supportedSigningChainIds,
  style,
}: ISelectMultichainNetworkProps) {
  const { t } = useTranslation("app", { keyPrefix: "SelectMultichainNetwork" });

  return (
    <NetworkButtonsContainer style={style}>
      {chains.map((id) => {
        switch (id) {
          case ChainIdEnum.NONE:
          case ChainIdEnum.ETH_MAINNET:
          case ChainIdEnum.SEPOLIA_TESTNET:
          case ChainIdEnum.LOCAL_TESTNET:
            return (
              <NetworkButton
                key={String(id)}
                isSelected={selectedChain === id}
                onClick={() => onSelectChain(id)}
              >
                <ETHLogo />
                {t("ethereum")}
              </NetworkButton>
            );
          case ChainIdEnum.OPTIMISM:
          case ChainIdEnum.OPTIMISM_TESTNET:
            return (
              <NetworkButton
                key={String(id)}
                isSelected={selectedChain === id}
                onClick={() => onSelectChain(id)}
              >
                <OPLogo />
                {t("optimism")}
              </NetworkButton>
            );
          case ChainIdEnum.ARBITRUM:
          case ChainIdEnum.ARBITRUM_TESTNET:
            return (
              <NetworkButton
                key={String(id)}
                isSelected={selectedChain === id}
                onClick={() => onSelectChain(id)}
              >
                <ARBLogo />
                {t("arbitrum")}
              </NetworkButton>
            );
          default:
            return (
              <NetworkButton key={id}>
                {t("unknown_network")} {id}
              </NetworkButton>
            );
        }
      })}
    </NetworkButtonsContainer>
  );
}

export default SelectMultichainNetwork;
